import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from './colors';
export const SyllabusCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: fit-content;
  border-radius: 5px;
  padding: 0px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #f3f5f9;
  color: ${colors.lightGrey};
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
`;

const Syllabus = ({ text }) => <SyllabusCard>{text}</SyllabusCard>;
Syllabus.propTypes = {
  text: PropTypes.string,
};
export default Syllabus;
