/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    CardTitle,
    CardText,
    Button,
    Form
} from "reactstrap";
import { createForm } from "rc-form";

import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PROVIDERS } from "../../../config/apiEndpoints";
import FormInput from "../../../components/FormInput";
import CheckBox from "../../../components/CheckBox";
import responseToast from "../../../utils/responseToast";


let REVIEW_PARAMETER = [
    { label: "Friendliness of director and staff", checked: false , key: 'staff' },
    { label: "Care for your child", checked: false, key: 'care' },
    { label: "Facilities", checked: false, key: 'facility' },
    { label: "Hygiene", checked: false, key: 'hygiene' },
    { label: "Teachers", checked: false, key: 'teachers' },
    { label: "Safety", checked: false, key: 'safety' },
    { label: "Curriculum", checked: false, key: 'curriculum' },
    { label: "Trust", checked: false, key: 'trust' },
    { label: "Development of your children", checked: false, key: 'development' },
    { label: "Location", checked: false, key: 'location' },
    { label: "Communication (ease of communication with the teacher, director, staff)", checked: false, key: 'communication' },
    { label: "Fees and other expenses", checked: false, key: 'fees' },
];


function ParentsReview(props) {
    const [reviewParams, setReviewParams] = useState(REVIEW_PARAMETER);
    const [checkedReviewParamsErr, setCheckedReviewParamsErr] = useState(false);
    const [finalSubmit, setFinalSubmit] = useState(false);



    const [, , addReview] = useCommonCRUD({
        CRUDaseUrl: {
              create: `${API_PROVIDERS}provider-parent-invite/`
        }
    });

    const handleReviewParamsCheck = i => {
        let value = reviewParams.slice();
        setCheckedReviewParamsErr(false);
        value[i].checked = !value[i].checked;
        setReviewParams(value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        setFinalSubmit(true);

        setCheckedReviewParamsErr(true)
        let checkedReviewParams = reviewParams.filter(k => k.checked && k.label);

        props.form.validateFields(async (errors, values) => {
            if (!errors && checkedReviewParams.length > 0) {
                setFinalSubmit(false);
                setCheckedReviewParamsErr(false);
                reviewParams.map(dt => {
                    if(dt.checked){
                        values[`${dt.key}`] = true
                    } else {
                        values[`${dt.key}`] = false
                    }
                })
                addReview({
                    data: values,
                    cb: err => {
                        if (!err) {
                            props.setAddParent();
                        } else{
                            responseToast(err);
                        }
                    }
                })

            }
        });
    };

    return (
        <>
            <div className="main-content" style={{ marginTop: 110 }}>
                <Container
                    className="formClass basicContainer mt--7 formClass-container"
                    style={{ maxWidth: "97%" }}
                >
                    <Row>
                        <Col>
                            <Card className="shadow">

                                <CardBody>
                                    <div className="cardHeader">
                                        <CardTitle className="cardTitle">Reviews </CardTitle>
                                        <CardText className="cardProfileText">
                                            Please enter email and phone number for parents you wish to get reviews from for your page
                                </CardText>
                                    </div>
                                </CardBody>
                                <Form className="form-horizontal" onSubmit={handleSubmit}>
                                    <CardBody className="formContent">
                                        <Row>
                                            <FormInput
                                                form={props.form}
                                                name="name"
                                                placeholder="Enter parent name"
                                                label="Name"
                                                colSpan="12"
                                                isName
                                                rightPadding={0}
                                                maxLength={100}
                                            />
                                        </Row>
                                        <Row>
                                            <FormInput
                                                form={props.form}
                                                name="email_id"
                                                placeholder="Enter parent email ID"
                                                label="E-mail ID"
                                                colSpan="12"
                                                type="email"
                                                rightPadding={0}

                                            />
                                        </Row>
                                        <Row>
                                            <FormInput
                                                form={props.form}
                                                name="phone_number"
                                                placeholder="Enter parent phone number"
                                                label="Phone number"
                                                colSpan="12"
                                                isMobile
                                                rightPadding={0}
                                                contact="contact"

                                            />
                                        </Row>
                                        <Row>
                                            <FormInput
                                                form={props.form}
                                                name="greetings"
                                                placeholder="Greetings"
                                                label="Greetings message:"
                                                colSpan="12"
                                                rightPadding={0}
                                                type="textarea"
                                            />
                                        </Row>
                                        <Row>
                                            <Col xs="6" className='formInputName'>Review: *
                                            <div className="sublabel" style={{ fontSize: 13 }}>
                                                    Pick the review points on which parents have to review
                                             </div>
                                            </Col>
                                            <Col style={{padding: window.screen.width < 1250 && 0}}>
                                            
                                                <div className="sublabel" style={{ width: '95%', paddingBottom: 24 }}>Rate us Between 1 to 5 on following parameters: 1 worst, 5 best</div>
                                                <Row>
                                                    {reviewParams &&
                                                        reviewParams.map((k, i) => (
                                                            <CheckBox
                                                                label={k.label}
                                                                name={k.key}
                                                                isRequired={false}
                                                                xsval="6"
                                                                key={`review_${i}`}
                                                                onClick={() => handleReviewParamsCheck(i)}
                                                                form={props.form}
                                                                paddingBottom={10}
                                                                padding='0px 0px 0px 30px'
                                                            />
                                                        ))}
                                                </Row>
                                                {checkedReviewParamsErr && (
                                                    <Row
                                                        style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 0, marginTop: '-20px' }}
                                                    >
                                                        Select atleast one parameter
                                                        </Row>
                                                )}
                                            </Col>

                                        </Row>

                                        {/* <Row>
                                            <FormInput
                                                form={props.form}
                                                name="areas"
                                                placeholder="Mention if any"
                                                label="Areas of improvement:"
                                                colSpan="12"
                                                type="textarea"
                                            />
                                        </Row> */}
                                    </CardBody>
                                    <Row style={{ padding: "20px 0px" }}>
                                        <Col xs="12" className="formFlex">
                                            <Button type="submit">
                                                Send
                                            </Button>
                                            {finalSubmit && (
                                                <div
                                                    style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                                                >
                                                    Some fields are not filled
                    </div>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
}

ParentsReview.propTypes = {};

export default createForm()(ParentsReview);
