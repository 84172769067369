import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
export const ProfileDetail = styled.div`
  margin: ${props => props.margin || '0px'};
  ul {
    margin: 0x;
    padding: 0px;
  }
  & h4 {
    color: #666c78;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
  }
  & li {
    color: #30333b;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    list-style-type: none;
    margin-top: 10px;
  }
`;

const ProfileDetails = ({ heading, data1, data2, margin }) => (
  <ProfileDetail margin={margin}>
    <ul>
      <h4>{heading}</h4>
      <li>{data1} </li>
      <li>{data2}</li>
    </ul>
  </ProfileDetail>
);
ProfileDetails.propTypes = {
  heading: PropTypes.string,
  data1: PropTypes.string,
  data2: PropTypes.string,
  margin: PropTypes.string,
};
export default ProfileDetails;
