import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  position: relative;
  height: ${props => props.flexHeight || "auto"};
  width: ${props => props.flexwidth || "auto"};
  padding: ${props => props.flexPadding || "0px"};
  margin: ${props => props.flexMargin || "0px"};
  background: ${props => props.background || "transparent"};
  box-sizing: border-box;
  cursor: ${props => (props.pointer ? "pointer" : "auto")};
  flex: ${props => (props.flex1 ? "1" : "none")};
  flex-wrap: ${props => {
    if (props.wrapReverse) return "wrap-reverse";
    if (props.noWrap) return "nowrap";
    return "wrap";
  }};
  justify-content: ${props => {
    if (props.justifyContent) return props.justifyContent;
    if (props.justifyCenter) return "center";
    if (props.justifyAround) return "space-around";
    if (props.justifyBetween) return "space-between";
    if (props.justifyEvenly) return "space-evenly";
    if (props.justifyEnd) return "flex-end";
    return "flex-start";
  }};
  align-items: ${props => {
    if (props.alignItems) return props.alignItems;
    if (props.alignStretch) return "stretch";
    if (props.alignEnd) return "flex-end";
    if (props.alignCenter) return "center";
    if (props.alignBaseline) return "baseline";
    return "flex-start";
  }};
  align-content: ${props => {
    if (props.alignContent) return props.content;
    if (props.contentStart) return "flex-start";
    if (props.contentEnd) return "flex-end";
    if (props.contentCenter) return "center";
    if (props.contentBetween) return "space-between";
    if (props.contentAround) return "contentAround";
    return "stretch";
  }};
  flex-direction: ${props => (props.column ? "column" : "row")};

  &.howresponsive {
    display: none;
  }
  @media (max-width: 767px) {
    &.flexResponsive {
      padding: 30px 0 0 !important;
    }

    .howdesktop {
      display: none;
    }

    &.howresponsive {
      display: flex;
      margin-top: 15px;
      display: none;
    }
  }
`;

export const PaginationWrapper = styled(Flex)`
  background-color: #fff;
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 10px 15px;
  margin-top: 20px;
`;
export const InputWrapper = styled(Flex)`
  span {
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }
`;
export const CustomButton = styled.button`
  height: 40px;
  width: 40px;
  background-color: #EAEFF9;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 50%;
  margin-right: 20px;
`;
