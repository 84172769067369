import React from "react";
import { Modal } from "reactstrap";
import styled from "styled-components";
import gifImage from "../../assets/gif/loader_transparent.gif";
export const KiddenLoader = styled.div`
  width: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#fff;
  height: 100vh;
  .loaderImage {
    width:300px;
    height:300px;
    img
    {
      width:100%;
      height:100%;
    }
  }
`;

function Loader(props) {
  return (
    <>
      {props.isOpen && (
        <KiddenLoader>
          <div className="loaderImage">
            <img src={gifImage} alt="gif" />
          </div>
        </KiddenLoader>
      )}
    </>
  );
}

export default Loader;
