import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flex from "./flex";
export const BranchName = styled.div`
  position: relative;
  color: rgba(48, 51, 59, 1);
  font-size: 14px;
  line-height: 14px;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid;
`;
export const TotalRating = styled.div`
  display: flex;
  span {
    color: rgba(48, 51, 59, 1);
    font-size: 14px;
    line-height: 14px;
    margin-left: 5px;
  }
`;

const BranchRating = ({ branchname, starImage, ratingNum, margin }) => (
  <Flex alignCenter flexMargin={margin}>
    <BranchName>{branchname}</BranchName>
    <TotalRating>
      <img src={starImage} alt="" />
      <span>{ratingNum}</span>
    </TotalRating>
  </Flex>
);
BranchRating.propTypes = {
  branchname: PropTypes.string,
  starImage: PropTypes.string,
  ratingNum: PropTypes.string,
  margin: PropTypes.string
};
export default BranchRating;
