/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import Loader from "../../../components/Loader";
import { Row } from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import Qs from "query-string";
import { createForm } from "rc-form";
import Button from "../../Pages/SchoolProfile/Button";
import Rating from "react-rating";
import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PROVIDERS, API_REVIEWS } from "../../../config/apiEndpoints";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import EnquiryQuestion from "../../../components/EnquiryQuestion";
import star from "../../../assets/img/star.svg";
import colorsGeneration from "../../../utils/textColorGeneration";
import star1 from "../../../assets/img/star.1.svg";
import EmptyState from "../../../components/EmptyState";
import responseToast from "../../../utils/responseToast";
import Pagination from "../../../components/Pagination";
import emptyImage from "../../../assets/img/illustrtns_review.svg";
import { envType } from '../../../config/baseURL';

import {
  DropdownMenu,
  DropdownMenuItem
} from "../../../components/CustomDropdown";

const DROPDOWN_CONFIG = [
  {
    id: "01",
    text: "Old to New",
    key: "ASC"
  },
  {
    id: "04",
    text: "New to Old",
    key: "DESC"
  },
];

function Review(props) {
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [showReviews, setShowReviews] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [parentList, setParentList] = useState([]);
  const [selectedParentList, setSelectedParentList] = useState([]);
  const [parentErr, setSelectParentErr] = useState(false);
  const [greetingsData, setGreetingsData] = useState("");
  const [greetingsErr, setGreetingsErr] = useState(false);
  const [dataClick, setDataClick] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownValue, setDropdownValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [lastReview, setLastReview] = useState("");
  const [selectedReview, setSelectedReview] = useState("");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [reviewExpand, setReviewExpand] = useState({});
  const query = Qs.parse(decodeURIComponent(props.history.location.search));
  const { addparent } = query;

  const [getEmails,,,,getEmailsId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}parent-email-list/`,
      readId: `${API_PROVIDERS}parent-email-list/`,

    }
  });

  const [getLastReview] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}review-last-invite/`
    }
  });

  const [, , postMail, updateRead] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}provider-parent-invite/`,
      update: `${API_REVIEWS}get-particular-review/`
    }
  });


  const [, , , raiseDispute] = useCommonCRUD({
    CRUDaseUrl: {
      update: `${API_REVIEWS}raise-dispute/`
    }
  });


  const [getAllReviews, , , , getReviewsFilter] = useCommonCRUD({
    CRUDaseUrl: {
      // read: `${API_REVIEWS}get-reviews-list/?sort=${dropdownValue}&skip=${skip}&limit=10${filter ? `&is_read=${filter}` : ''}&search=`,
      readId: `${API_REVIEWS}get-reviews-list/?sort=${dropdownValue}&skip=${skip}&limit=10${filter ? `&is_read=${filter}` : ''}&search=`
    }
  });

  useEffect(() => {
    getReviewsFilter({ data: searchValue });
  }, [searchValue, dropdownValue, skip, filter]);

  const selectedParent = value => {
    let parent = [...selectedParentList];
    if (!parent.includes(value)) {
      parent.push(value);
    }
    setSelectedParentList(parent);
    setSelectParentErr(false);
    setOpenDropdown(false);
  };

  const removeParent = value => {
    let parent = [...selectedParentList];
    parent.splice(parent.indexOf(value), 1);
    setSelectedParentList(parent);
  };

  const submitData = () => {
    if (!(selectedParentList.length > 0) || !greetingsData) {
      if (!greetingsData) {
        setGreetingsErr(true);
      }
      if (!(selectedParentList.length > 0)) setSelectParentErr(true);
      return;
    }
    let data = {};
    data.data = selectedParentList.map(a => {
      delete a.id;
      a.email_id = a.email;
      delete a.email;
      a.greetings = greetingsData;
    
      return a;
    });
    data.server_type =  envType === 'staging' ? 'staging' : 'production';
    postMail({
      data,
      cb: err => {
        if (!err) {
          setSelectParentErr(false);
          setGreetingsErr(false);
          setShowReviews(true);
          setSelectedParentList([]);
          setGreetingsData("");
          props.history.push("review?activeTab=reviews");
          getEmailsId({data: ''})
        } else {
          responseToast(err);
        }
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      dropdownRef.current.contains(e.target)
    ) {
      return 0;
    }
    setOpenDropdown(false);
    return 0;
  };

  useEffect(() => {
    if (addparent) {
      setShowReviews(false);
    }
  }, []);

  useEffect(() => {
    if (getEmails && getEmails.data && getEmails.data.length > 0) {
      let emailData = getEmails.data;
      setParentList(emailData);
    } else setParentList([]);
  }, [getEmails]);

  useEffect(() => {
    if (getLastReview.data && typeof getLastReview.data === 'string') {
      setLastReview(moment(getLastReview.data).format("DD MMMM YYYY"));
    }
  }, [getLastReview]);

  useEffect(() => {
    if (getAllReviews && getAllReviews.data && getAllReviews.data.length > 0) {
      setData(getAllReviews.data);
    } else setData([]);

    getAllReviews &&
      getAllReviews.globalData &&
      setCount(getAllReviews.globalData.count);
  }, [getAllReviews]);

  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar
          {...props}
          brandText="Kiddenz"
          search={showReviews && true}
          searchValue={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <Loader isOpen={getAllReviews.isLoading} />
      {!getAllReviews.isLoading &&
          <div className="dashboardContainer">
            {!showReviews ? (
              <div className="reviews">
                <div className="mainHeading spaceBetweenAlignCenter">
                  <div>
                    <h3>
                      Get Parents Review
                      {lastReview && <span>Last review sent on {lastReview}</span>}
                    </h3>
                    <span>
                      Please enter email and phone number for parents you wish
                      to get reviews for your page
                    </span>
                  </div>
                  <ul className="filterBy">
                    <li
                      onClick={() =>
                        props.history.push(
                          "offline-data?activeTab=offlineData&open=providers&showforms=true"
                        )
                      }
                    >
                      Add new parent
                    </li>
                  </ul>
                </div>
                <Row>
                  <div className="studentDetails-input">
                    <label>Parent List</label>
                    <div className="selectParent" ref={dropdownRef}>
                      <div
                        className="selectParent-top"
                        onClick={e => {
                          setOpenDropdown(!openDropdown);
                        }}
                      >
                        <span>Select parent/s</span>
                        {openDropdown ? (
                          <i className="fa fa-chevron-up"> </i>
                        ) : (
                          <i className="fa fa-chevron-down"> </i>
                        )}
                      </div>
                      {parentErr && <Error> Select Parent</Error>}
                      {openDropdown && (
                        <div className={`selectParent-bottom`}>
                          {parentList.map(d => (
                            <li key={d.id} onClick={() => selectedParent(d)}>
                              {d.email}
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="studentDetails-input"></div>
                </Row>
                {selectedParentList.length > 0 && (
                  <div className="selectedParent">
                    <h2>Selected parents</h2>
                    <div className="selectedParent-list">
                      {selectedParentList.map(data => (
                        <div
                          className="selectedParent-tag"
                          onClick={() => removeParent(data)}
                        >
                          <span>{data.name}</span>
                          <span
                            class="iconify"
                            data-icon="ion:close-outline"
                            data-inline="false"
                          ></span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="studentDetails-input" style={{ width: "100%" }}>
                  <label>Greetings message</label>
                  <textarea
                    cols="4"
                    onChange={e => {
                      setGreetingsData(e.target.value);
                      setGreetingsErr(false);
                    }}
                    placeholder="Greetings,"
                    value={greetingsData}
                  />
                  {greetingsErr && <Error>This field is required</Error>}
                </div>

                <Button
                  text="Send"
                  type="mobile"
                  height="50px"
                  onClick={() => submitData()}
                />
              </div>
            ) : (
              <div className={`reviews-content ${dataClick && "active"}`}>
                <div className="reviews-one">
                  <div className="mainHeading spaceBetweenAlignCenter">
                    <div>
                      <h3>Reviews</h3>
                      <span>
                        User reviews will be shown here- you can read it and
                        then post review on your school page
                      </span>
                    </div>
                    <ul className="filterBy">
                        <li onClick={() => setShowReviews(false)}>
                          Get Parents Review
                        </li>
                    </ul>
                  
                  </div>
                  <div className="spaceBetweenAlignCenter">
                <ul className="filterBy">
                  <span>Filters by: </span>
                  <li
                    onClick={() => {
                      setFilter("True");
                      setPage(1);
                      setSkip(0);
                      setDataClick(false)
                      setSelectedReview(null)
                    }}
                    className={filter === "True" ? "active" : ""}
                  >
                    Read
                  </li>
                  <li
                    onClick={() => {
                      setFilter("False");
                      setPage(1);
                      setSkip(0);
                      setDataClick(false)
                      setSelectedReview(null)
                    }}
                    className={filter === "False" ? "active" : ""}
                  >
                    Unread
                  </li>
                  <li
                    onClick={() => {
                      setFilter("");
                      setPage(1);
                      setSkip(0);
                      setDataClick(false)
                      setSelectedReview(null)
                    }}
                    className={filter === "" ? "active" : ""}
                  >
                    All
                  </li>
                </ul>
                <ul className="filterBy" ref={dropdownRef}>
                  <li
                    onClick={() => {
                      setOpenDropdown(!openDropdown);
                    }}
                  >
                    Sort By
                    <i
                      className={
                        openDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down"
                      }
                      style={{ marginLeft: 10 }}
                    />
                  </li>
                  {openDropdown && (
                    <DropdownMenu top={"147px"} left={dataClick ? "33%" : "80%"} width="20%">
                      {DROPDOWN_CONFIG.map(items => (
                        <DropdownMenuItem
                          key={items.id}
                          className={
                            dropdownValue === items.key ? "active" : ""
                          }
                          onClick={e => {
                            setDropdownValue(items.key);
                            setOpenDropdown(false);
                            setPage(1);
                            setSkip(0);
                          }}
                        >
                          {items.text}
                          <span
                            className="iconify"
                            data-icon="mdi-light:check"
                            data-inline="false"
                          />
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenu>
                  )}
                </ul>
              </div>
                
                  <div className="enquiryReport">
                    <div style={{ marginBottom: 30 }}>
                      {data.length > 0 ? (
                        <>
                          {data.map((d, i) => (
                          <EnquiryQuestion
                            imgpath={d.parent_details && d.parent_details.parent_image}
                            active={i === selectedReview}
                            imgText={
                              (d.parent_details && !d.parent_details.parent_image) && d.parent_details && d.parent_details.name.match(/\b(\w)/g).join("")
                            }
                            // bgColor={colorsGeneration(d.parent_details && d.parent_details.name[0].toUpperCase())}
                            name={`${d.parent_details &&
                              d.parent_details
                                .name} has posted a review on your school`}
                            time={moment(d.created_on).format("hh:mmA")}
                            question={d.review}
                            btnText2="Raise Dispute"
                            classType={i !== selectedReview && d.is_read}
                            date={moment(d.created_on).format("MMM DD")}
                            onClick={() => {
                              setSelectedReview(i);
                              setDataClick(true);
                              setReviewExpand(d);
                              if(!d.is_read) {
                                let data = {};
                                data.id = d.id;
                                data.noToast = true;
                                
                                updateRead({
                                  data,
                                  cb: err => {
                                    if (!err) {
                                      getReviewsFilter({ data: searchValue });

                                    } else {
                                      responseToast(err);
                                    }
                                  }
                                });
                              }
                           
                            
                            }}
                            openTextArea={() => {
                              let data = {};
                              data.id = d.id;
                              raiseDispute({
                                data,
                                cb: err => {
                                  if (!err) {
                                  } else {
                                    responseToast(err);
                                  }
                                }
                              });
                            }}
                          />
                          ))}
                          {count > 10 && (
                            <Pagination
                              totalPages={Math.ceil(count / 10)}
                              onFirstPageClick={() => {
                                setPage(1);
                                setSkip(0);
                              }}
                              isPrevDisabled={page === 1}
                              isNextDisabled={Math.ceil(count / 10) === page}
                              currentPage={page}
                              onPrevClick={() => {
                                if (page > 1) {
                                  setSkip(skip - 10);
                                  setPage(page - 1);
                                }
                              }}
                              onNextClick={() => {
                                if (page < Math.ceil(count / 10)) {
                                  setPage(page + 1);
                                  setSkip(skip + 10);
                                }
                              }}
                              onLastPageClick={() => {
                                setPage(Math.ceil(count / 10));
                                setSkip(Number(`${Math.floor(count / 10)}0`));
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <EmptyState
                          text="No Reviews yet"
                          desc="Hey! you dont have any reviews for your daycare yet, why dont you send in review request for parents to get the reviews, click on “Get parents review” button below to proceed"
                          emptyImage={emptyImage}
                          onClick={() => setShowReviews(false)}
                          btntext="Get Parents Review"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="reviews-details">
                  <div className="mainHeading spaceBetweenAlignCenter">
                    <h3>
                      Review detail
                      <div className="infoIcon">
                        <span
                          class="iconify"
                          data-icon="ant-design:info-circle-outlined"
                          data-inline="false"
                        ></span>
                        <div class="ratingInfo">
                          <li>
                            <div>
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                            </div>
                            <span>Best</span>
                          </li>
                          <li>
                            <div>
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                            </div>
                            <span>Like it</span>
                          </li>
                          <li>
                            <div>
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                            </div>
                            <span>Neutral</span>
                          </li>
                          <li>
                            <div>
                              <img src={star} alt="" />
                              <img src={star} alt="" />
                            </div>
                            <span>Dislike</span>
                          </li>
                          <li>
                            <img src={star} alt="" />
                            <span>Worst</span>
                          </li>
                        </div>
                      </div>
                    </h3>
                    <i
                      className="fa fa-times"
                      onClick={() => {
                        setDataClick(false);
                        setSelectedReview("");
                      }}
                    />
                  </div>

                  <div className="reviews-table">
                    <li className="reviews-tableList">
                      <h4>Name</h4>
                      <span>
                        {reviewExpand["parent_details"] &&
                          reviewExpand["parent_details"].name}
                      </span>
                    </li>
                    <li className="reviews-tableList">
                      <h4>E-mail ID</h4>
                      <span>
                        {reviewExpand["parent_details"] &&
                          reviewExpand["parent_details"].email}
                      </span>
                    </li>
                    <li className="reviews-tableList">
                      <h4>Phone number</h4>
                      <span>
                        +91{" "}
                        {reviewExpand["parent_details"] &&
                          reviewExpand["parent_details"].phone_number}
                      </span>
                    </li>
                    <li className="reviews-tableList">
                      <h4>Over all review</h4>
                      <span>
                        <Rating
                          emptySymbol={
                            <img src={star1} alt="star1" className="starIcon" />
                          }
                          fullSymbol={
                            <img src={star} alt="star" className="starIcon" />
                          }
                          className="star_Rating"
                          initialRating={reviewExpand["over_all_rating"]}
                          readonly
                        />
                      </span>
                    </li>
                    <li className="reviews-tableList">
                      <h4>Reviews*</h4>
                      <span>{reviewExpand["review"]}</span>
                    </li>
                    <li className="reviews-tableList">
                      <h4>Testimonial*</h4>
                      <span>{reviewExpand["testimonial"] || "None"}</span>
                    </li>
                    <li className="reviews-tableList">
                      <h4>Improvements</h4>
                      <span>{reviewExpand["improvement"] || "None"}</span>
                    </li>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
    </>
  );
}

Review.propTypes = {};

export default createForm()(Review);
const Error = styled.div`
  font-size: 12px;
  color: red;
`;
