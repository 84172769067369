import React from 'react';
import PropTypes from 'prop-types';
// import { mdiHeartOutline, mdiShareVariant } from '@mdi/js';
import BranchRating from './branchRating';
import IconButton from './Iconcard';
import styled from 'styled-components';
import colors from './colors';
import starImage from '../../../assets/img/star.svg';
import Flex from './flex';
export const TitleName = styled.div`
  color: ${colors.lightBlack};
  font-family: Quicksand;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
`;


const TitleCard = ({ title, branchName }) => (
  <Flex alignCenter>
    <Flex column>
      <TitleName>{title} </TitleName>
      <BranchRating
        branchname={branchName}
        starImage={starImage}
        ratingNum="4.3"
        margin="10px 0px"
      />
    </Flex>
    <Flex flexMargin="0px 0px 0px 124px">
      <IconButton iconName="ei-heart" margin="0px 20px 0px 0px" />
      <IconButton iconName="ei:share-google" />
    </Flex>
  </Flex>
);
TitleCard.propTypes = {
  title: PropTypes.string,
  branchName: PropTypes.string,
};
export default TitleCard;
