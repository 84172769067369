/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    CardTitle,
    CardText,
    Button,
    Form
} from "reactstrap";
import { createForm } from "rc-form";

import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";


function ReviewDetail(props) {

    return (
        <>
            <div className="header pb-6 ">
                <AdminNavbar {...props} brandText="Kiddenz" />
            </div>
            <div className="main-content" style={{ marginTop: 110 }}>
                <Container
                    className="formClass basicContainer mt--7 formClass-container"
                    style={{ maxWidth: "97%" }}
                >
                    <Row>
                        <Col>
                            <Card className="shadow formContent">

                                <CardBody className="alignItems">
                                    <div className="cardHeader">
                                        <CardTitle className="cardTitle">Review detail</CardTitle>
                                        <CardText className="sublabel">
                                            Oct 29   |  2:30PM
                                            </CardText>
                                    </div>
                                    <div className='cursor' onClick={() => props.history.push('review?activeTab=reviews')}>Post Review</div>
                                </CardBody>
                                <CardBody >
                                    <Row>
                                        <Col xs="8">
                                            <Row className="marginTop20">
                                                <Col xs="4" className='formInputName'>Name</Col>
                                                <Col xs="4"><CardText>Rohan</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4" className='formInputName'>E-mail ID</Col>
                                                <Col xs="4"><CardText>rohan@gmail.com</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4" className='formInputName'>Phone number</Col>
                                                <Col xs="4"><CardText>+91 9987657898</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4" className='formInputName'>Review:</Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Friendliness of director and staff</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Facilities</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Teachers</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Curriculum</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Development of your children</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Care for your child</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Hygiene</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Safety</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Trust</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Location</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Fees and other expenses</CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4"><CardText>Communication (ease of communication with the teacher, director,staff) </CardText></Col>
                                                <Col xs="4"><CardText>1</CardText></Col>
                                            </Row>
                                            <Row className=" marginTop20">
                                                <Col xs="4" className='formInputName'>Areas of Improvement</Col>
                                                <Col xs="4"></Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4" className='formInputName'>Reviews*</Col>
                                                <Col xs="4">Good</Col>
                                            </Row>
                                            <Row className="marginTop20">
                                                <Col xs="4" className='formInputName'>Testimonial*</Col>
                                                <Col xs="4">Glad to find the school on kiddenz, it is a very userfriendly
platform, would highly recommend</Col>
                                            </Row>
                                            <Row className="formFlex marginTop20">
                                                <Button onClick={() => props.history.push('review?activeTab=reviews')}>Post review</Button>
                                            </Row>
                                        </Col>
                                        <Col xs="4">
                                            <ul className="reviewRectangle">
                                                <li>1- Best</li>
                                                <li>2- Like it</li>
                                                <li>3- Neutral </li>
                                                <li>4- Dislike</li>
                                                <li>5- Worst</li>

                                            </ul>
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
}

ReviewDetail.propTypes = {};

export default createForm()(ReviewDetail);
