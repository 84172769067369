import styled from "styled-components";
import colors from "./colors";

export const ProfileSection = styled.div`
width:100%;
// background-color: ${colors.white};
margin-left:auto;
padding:30px 0px 50px 200px;
`;

export const ProfileGrid = styled.div`
// background-color: ${colors.white};
display: flex;
margin:0px 30px;
border-top: 1px solid #E6E8EC;

.gridColumn
{
width:30%;
padding-top:50px;
padding-right:50px;
margin-right:50px;
border-right: 1px solid #E6E8EC;
&:nth-child(2)
{
    width:46%; 
}
&:last-child
{
    width:24%; 
    padding-right:0px;
    margin-right:0px; 
    border-right: 0px solid #E6E8EC;

}
}
.gridItem
{
border-bottom: 1px solid #E6E8EC;
margin-bottom:20px;
.flexGrid
{
  display: grid;
  width: 100%;
  // flex-flow: row wrap;
  // justify-content: space-between;
  grid-template-columns: repeat( auto-fit, minmax(160px, 1fr) );
  grid-gap: 20px;
}
.flexGrid::after {
  content: "";
  flex: auto;
}

}
.galleryItem:last-child
{
   opacity:0.4;
}
`;
export const AdditionalInfo = styled.div`
  color: #666c78;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 22px;
  span {
    color: ${colors.lightBlack};
  }
`;

export const Schedule = styled.div`
  display: flex;
  color: #30333b;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 33px;
  & span:first-child {
    margin-right: 20px;
    min-width: 100px;
  }
`;

export const ParaGraph = styled.div`
  color: #666c78;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0px;
  ul {
    padding: 0px;
  }
  li {
    list-style: none;
  }
`;

export const Facility = styled.div`
  color: #666c78;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 21px;

  &.provide {
    position: relative;
    padding-left: 20px;
    &::before {
      position: absolute;
      content: "";
      height: 8px;
      width: 8px;
      left: 0px;
      top: 6px;
      background-color: #8a909d;
      border-radius: 50%;
    }
  }
  &.notProvide {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    &::before {
      position: absolute;
      content: "";
      height: 10px;
      width: 10px;
      left: 0px;
      top: 7px;
      border: 2px solid #8a909d;
      border-radius: 50%;
    }
  }
  .iconify {
    color: #30333b;
    height: 26px;
    width: 26px;
    margin-right: 18px;
  }
`;

export const BranchName = styled.div`
  color: #3b74e7;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: justify;
`;
