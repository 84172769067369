import styled from 'styled-components';

const MainWrapper = styled.div`
  background-color: ${props => props.background || 'transperant'};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.paddingTop || '76px'};
  z-index: 1000;
`;
export default MainWrapper;
