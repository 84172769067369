import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
export const ImageBanner = styled.div`
  position: relative;
  margin-right: 40px;
  max-height: ${props => props.maxHeight || "480px"};
  height: ${props => props.height || "480px"};
  width: 100%;
  background-color: #f0f0f6;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const JumboImage = styled.div`
  position: absolute;
  left: 25px;
  bottom: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 3px solid #fff;
  background-color: rgba(255, 238, 79, 1);
  overflow: hidden;
  & > img {
    width: 90%;
    height: 70%;
  }
`;

const Imagebanner = ({ imgpath, jumboImage }) => (
  <ImageBanner>
    {imgpath && <img src={imgpath} alt="imagebanner" />}
    <JumboImage>{jumboImage && <img src={jumboImage} alt="" />}</JumboImage>
  </ImageBanner>
);
Imagebanner.propTypes = {
  imgpath: PropTypes.string,
  jumboImage: PropTypes.string
};
export default Imagebanner;
