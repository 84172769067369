import React, { useEffect } from "react";
import { Col, FormGroup, Label, Input, FormFeedback } from "reactstrap";

function Dropdown({ form, label, xsval, options, showData,defaultValue, onChange, isRequired = true, ...rest }) {
  const error = form.getFieldError(rest.name);
  useEffect(() => {
    form.setFieldsValue({
      [rest.name]: defaultValue,
    }, () => console.log('after', defaultValue));

  }, [defaultValue]);

  return (
    <Col xs={xsval || "6"}>
      <FormGroup className={`formFlex ${rest.classtype}`}>
        <Col xs={label ? "6" : "0"}>
          <Label htmlFor={rest.name}>
            {label}
            {isRequired && label ? " *" : ""}
          </Label>
        </Col>
        <Col xs={label && "6"} className={showData && 'flex'} style={{ flexWrap: showData && 'wrap', padding: showData && 0}}>
          <Input
            type="select"
            id={rest.name}
            className={`form-control-alternative`}
            placeHolder="Select"
            {...rest}
            {...form.getFieldProps(rest.name, {
              // validateTrigger: ["onChange"],
              validateTrigger: isRequired ? "onChange" : null,
              rules: [{ required: isRequired }],
              initialValue: defaultValue,
              onChange: (e) =>
                onChange && onChange(e.target.value)

            })}
            style={{minWidth: showData && '66px',maxWidth: showData && '66px'}}
            // defaultValue={defaultValue}
            invalid={!!error}
          >
            <option value="" key="" selected hidden>{rest.placeholder || 'Select'}</option>
            {Object.keys(options).map(spKey => (

              <option key={options[spKey].id} value={options[spKey].id}
                disabled={options[spKey].disabled}
              >
                {options[spKey].name}
              </option>
            ))}
          </Input> {showData && <span className="yearSubInfo" style={{marginLeft: 10}}>{showData}</span>}
          {rest.submit && rest.msg && (
            <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
              {rest.msg}
            </div>
          )}
          <FormFeedback> {error && error[0] && error[0].includes("_")
            ? error[0].charAt(0).toUpperCase() +
            error[0]
              .slice(1)
              .split("_")
              .join(" ")
            : error}</FormFeedback>
        </Col>
      </FormGroup>
    </Col>
  );
}

Dropdown.propTypes = {};

export default Dropdown;
