/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Form,
  CardTitle,
  CardText,
  FormGroup,
  Label
} from "reactstrap";
import { RegionDropdown } from 'react-country-region-selector';

import { createForm } from "rc-form";
import FormInput from "../../../components/FormInput";
import CheckBox from "../../../components/CheckBox";
import RadioButton from "../../../components/RadioButton";
import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PROVIDERS } from "../../../config/apiEndpoints";
import responseToast from "../../../utils/responseToast";
import CardFooters from "../../../components/CardFooter";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import Logo from "../../../assets/img/whitelogo.png";
import FileUploader from "../../../components/Uploader";
import { UserContext } from '../../../App'

let reason = [
  { label: "To increase visibility and get more leads", checked: false },
  { label: "Promotion on platform", checked: false },
  {
    label:
      "To get additional services (Photographer, storytelling events, Painter, Toys, others)",
    checked: false
  }
];

let market = [
  { label: "Justdial", checked: false },
  { label: "Sulekha", checked: false },
  { label: "Google Ads", checked: false },
  { label: "Facebook", checked: false },
  { label: "Print Ads", checked: false },

];

let abt = [
  { label: "Marketing officer from Kiddenz contacted us", checked: false },
  { label: "Saw on google", checked: false },
  { label: "Email from Kiddenz", checked: false },
  { label: "Word of mouth (Heard from an acquaintance)", checked: false },
  { label: "Facebook or other social media", checked: false }
];

function Forms(props) {
  const [submitted, setSubmitted] = useState(false);
  const [reasons, setReasons] = useState(reason);
  const [marketing, setMarketing] = useState(market);
  const ctx = useContext(UserContext);

  const [about, setAbout] = useState(abt);
  const [childCareVal, setChildcareVal] = useState("3");
  const [checkedReasonsErr, setCheckedReasonsErr] = useState(false);
  const [checkedMarketErr, setCheckedMarketErr] = useState(false);

  const [checkedHearErr, setCheckedHearErr] = useState(false);
  const [license, setLicense] = useState("No");
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [region, setRegion] = useState("");

  const [getForms, , addForms] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}`,
    }
  });

  const [, , , updateForms] = useCommonCRUD({
    CRUDaseUrl: {
      update: `${API_PROVIDERS}provider-update/`,
    }
  });

  const handleReasonCheck = i => {
    let value = reasons.slice();
    let check = reasons.filter(a => a.checked === true);
    setCheckedReasonsErr(false);
    // if(check.length > 0) {
    //     setCheckedReasonsErr(false)
    // } else {
    //     setCheckedReasonsErr(true)
    // }

    // setCheckedReasonsErr(false)
    value[i].checked = !value[i].checked;
    // checkedReasonsErr(false)
    setReasons(value);
  };

  const handleAboutCheck = i => {
    let value = about.slice();
    let check = about.filter(a => a.checked);
    setCheckedHearErr(false);
    // if(check.length === 0) {
    //     setCheckedHearErr(false)
    // } else {
    //     setCheckedHearErr(true)
    // }
    value[i].checked = !value[i].checked;
    // checkedHearErr(false)
    setAbout(value);
  };

  const handleMarketCheck = i => {
    let value = marketing.slice();
    let check = marketing.filter(a => a.checked);
    setCheckedMarketErr(false);
    value[i].checked = !value[i].checked;
    setMarketing(value);
  };

  const handleRadioCheck = i => {
    setLicense(i);
  };

  const handleChildCareCheck = (i, val) => {
    setChildcareVal(val);
  };

  const handelSubmit = e => {
    e.preventDefault();
    setFinalSubmit(true);

    var checkedReasons = reasons.filter(k => k.checked && k.label);
    var checkedHear = about.filter(k => k.checked && k.label);
    var checkedMarket = marketing.filter(k => k.checked && k.label);

    props.form.validateFields(async (errors, values) => {
      if (!errors && checkedReasons.length > 0 && checkedHear.length > 0 && checkedMarket.length > 0) {
        setFinalSubmit(false);
        setCheckedReasonsErr(false);
        setCheckedHearErr(false);
        setCheckedMarketErr(false);

        // values.child_care_providers = childCareVal;
        var rsn = reasons.filter(k => k.checked && k.label).map(a => a.label);
        var abts = about.filter(k => k.checked && k.label).map(a => a.label);
        var mrkt = marketing.filter(k => k.checked && k.label).map(a => a.label);

        values.reasons_to_join = rsn && rsn.join(",");
        values.hear_about_kiddenz = abts && abts.join(",");
        values.marketing_medium = mrkt && mrkt.join(",");

          addForms({
            data: values,
            cb: err => {
              if (!err) {
                props.history.push("/dashboard?activeTab=dashboard");
                ctx.actions.onClickTabs()
              } else {
                responseToast(err);
              }
            }
          });
      }
      if (checkedReasons.length === 0) {
        setCheckedReasonsErr(true);
      }
      if (checkedHear.length === 0) {
        setCheckedHearErr(true);
      }
      if (checkedMarket.length === 0) {
        setCheckedMarketErr(true);
      }
      if (checkedReasons.length > 0) setCheckedReasonsErr(false);
      if (checkedHear.length > 0) setCheckedHearErr(false);
      if (checkedMarket.length > 0) setCheckedMarketErr(false);

    });
  };

  useEffect(() => {
      setReasons(reason)
      setAbout(about)
      setMarketing(market)
  }, [])

  return (
    <>
        <div className="header pb-6 ">
          <AdminNavbar {...props} brandText="Kiddenz" />
        </div> 

      <Card className="formClass">
        {submitted ? (
          <CardBody style={{ height: 600 }} className="flexColumnCenter">
            <CardTitle className="cardTitle">Thank You!</CardTitle>
            <CardText className="cardText">
              Our team will get in touch with you and help in creating your
              profile
            </CardText>
          </CardBody>
        ) : (
            <Card className="formClass-container">
              <CardHeader className="bg-white border-0">
                <h3 className="mb-0">For Providers</h3>
                <span className="subInfo">
                  Please fill in the below form and our team will get in touch
                  with you shortly and assist you with creating your provider
                  account
              </span>
              </CardHeader>
              <Form className="form-horizontal" onSubmit={handelSubmit}>
                <CardBody>
                  <Row>
                    <FormInput
                      form={props.form}
                      name="business_name"
                      placeholder="Enter registered business name"
                      label="Registered Business Name"
                      classtype="column"
                      isName
                      alphanumeric={true}
                      maxLength={100}
                      TooltipHover='Provide full name with which your business is registered.
                      This will be used for invoicing any commercial transaction.'
                    />
                    <FormInput
                      form={props.form}
                      name="name"
                      placeholder="Enter Contact Person Name"
                      label="Contact Person Name"
                      classtype="column"
                      maxLength={100}
                      isName
                    />
                  </Row>
                  <Row>
                    <FormInput
                      form={props.form}
                      name="email"
                      placeholder="Enter email ID"
                      label="E-mail ID"
                      classtype="column"
                      type="email"
                      maxLength={100}
                    />
                    {/* <FormInput
                      form={props.form}
                      name="location_address"
                      placeholder="Enter your location"
                      label="Location Address"
                      type="textarea"
                      classtype="column"
                    /> */}
                    <FormInput
                      form={props.form}
                      name="city"
                      placeholder="Enter City Name"
                      label="City"
                      classtype="column"
                      maxLength={50}
                    />
                  </Row>
                  <Row>
                    {/* <Col xs='6'>
                      <FormGroup className={`formFlex`}>
                        <Col xs='6' style={{ paddingLeft: 0 }}> <Label>State</Label></Col>
                        <Col
                          xs='6'
                          style={{
                            paddingRight: 25,
                          }}
                        >
                          <RegionDropdown
                            country={'India'}
                            value={region}
                            className="form-control-alternative form-control"
                            onChange={(val) => { setRegion(val); setFinalSubmit(false) }} />
                          {finalSubmit && !region && <div style={{ fontWeight: 300, fontSize: 11, color: 'red' }}> State is required</div>}
                        </Col>
                      </FormGroup>
                    </Col> */}
                    <FormInput
                      form={props.form}
                      name="phone_number"
                      placeholder="Enter contact number"
                      label="Phone Number"
                      classtype="column"
                      isMobile
                      contact="contact"
                    />
                    <FormInput
                      form={props.form}
                      name="pincode"
                      placeholder="Enter Pincode here"
                      label="Pincode"
                      classtype="column"
                      pincode
                    />
                  </Row>
                  {/* <Row className="marginBottom20 radioBtn">
                    <Col xs="6">
                      <Col>What type of childcare are you providing? *</Col>
                      <Col>
                        <Row style={{ marginTop: 10 }}>
                          <RadioButton
                            name="Pre-school"
                            label="Pre-school"
                            form={props.form}
                            xsval="4"
                            checked={childCareVal === "1"}
                            onClick={label => handleChildCareCheck(label, "1")}
                          />
                          <RadioButton
                            name="Daycare"
                            label="Daycare"
                            form={props.form}
                            xsval="4"
                            checked={childCareVal === "2"}
                            onClick={label => handleChildCareCheck(label, "2")}
                          />
                          <RadioButton
                            name="Both"
                            label="Both"
                            form={props.form}
                            xsval="4"
                            checked={childCareVal === "3"}
                            onClick={label => handleChildCareCheck(label, "3")}
                          />
                        </Row>
                      </Col>
                    </Col>
                    <Col xs="6">
                      <Col style={{ paddingLeft: 15 }}>
                        Do you have a license for your childcare? *
                    </Col>
                      <Row style={{ paddingLeft: 15, marginTop: 10 }}>
                        <RadioButton
                          name="Yes"
                          label="Yes"
                          form={props.form}
                          xsval="6"
                          checked={license === "Yes"}
                          onClick={label => handleRadioCheck(label)}
                        />
                        <RadioButton
                          name="No"
                          label="No"
                          form={props.form}
                          xsval="6"
                          checked={license === "No"}
                          onClick={label => handleRadioCheck(label)}
                        />
                      </Row>
                      {license === "Yes" && (
                        <Row style={{ marginTop: 20 }}>
                          <FormInput
                            form={props.form}
                            name="license_number"
                            placeholder="License Number"
                            label="If Yes, please provide the license number"
                            colSpan="12"
                            maxLength={100}
                          />
                        </Row>
                      )}
                    </Col>
                  </Row> */}
                  <Row className="formCheckbox marginBottom20">
                    <Col xs="6" style={{ paddingLeft: 0 }}>
                      <Col xs="8" style={{ paddingLeft: 15 }}>
                        What are the reason(s) to join Kiddenz? *
                    </Col>
                      <Col xs="8" style={{ paddingLeft: 5, paddingTop: 10 }}>
                        {reasons &&
                          reasons.map((k, i) => (
                            <CheckBox
                              label={k.label}
                              name={`${k.label}reason`}
                              isRequired={false}
                              xsval="12"
                              checked={k.checked}
                              key={`reason_${i}`}
                              onClick={() => handleReasonCheck(i)}
                              form={props.form}
                            />
                          ))}
                      </Col>
                      {checkedReasonsErr && (
                        <Col
                          style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                        >
                          Select atleast one reason
                      </Col>
                      )}
                    </Col>
                    <Col xs="6" style={{ paddingLeft: 0 }}>
                      <Col xs="8" style={{ paddingLeft: 15 }}>
                        How did you hear about Kiddenz? *
                    </Col>
                      <Col xs="8" style={{ paddingLeft: 5, paddingTop: 10 }}>
                        {about &&
                          about.map((k, i) => (
                            <CheckBox
                              label={k.label}
                              name={`${k.label}about`}
                              isRequired={false}
                              xsval="12"
                              checked={k.checked}
                              key={i}
                              onClick={() => handleAboutCheck(i)}
                              form={props.form}
                            />
                          ))}
                      </Col>
                      {checkedHearErr && (
                        <Col
                          style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                        >
                          Select atleast one reason
                      </Col>
                      )}
                    </Col>
                  </Row>
                  <Row className="formCheckbox marginBottom20">
                    <Col xs="6" style={{ paddingLeft: 0 }}>
                      <Col xs="8" style={{ paddingLeft: 15 }}>
                        What are the current marketing tools you are currently using ? *
                    </Col>
                      <Col xs="8" style={{ paddingLeft: 5, paddingTop: 10 }}>
                        {marketing &&
                          marketing.map((k, i) => (
                            <CheckBox
                              label={k.label}
                              name={`${k.label}market`}
                              isRequired={false}
                              xsval="12"
                              checked={k.checked}
                              key={`market_${i}`}
                              onClick={() => handleMarketCheck(i)}
                              form={props.form}
                            />
                          ))}
                      </Col>
                      {checkedMarketErr && (
                        <Col
                          style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                        >
                          Select atleast one marketing tool
                      </Col>
                      )}
                    </Col>

                  </Row>
                  <Row className="formFlex">
                    <Button type="submit">{'Submit'}</Button>
                    {finalSubmit && (
                      <div
                        style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                      >
                        Some fields are not filled
                    </div>
                    )}
                  </Row>
                </CardBody>
              </Form>
            </Card>
          )}
        {/* {!props.initial && <CardFooters />} */}
      </Card>
    </>
  );
}

Forms.propTypes = {};

export default createForm()(Forms);
