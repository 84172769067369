/**
 * This is axios intercepter which intercepts all the incoming and outgoing requests
 */
import request from "axios";
import baseURL from "../config/baseURL";

const axios = request;
axios.defaults.withCredentials = true;
// axios.defaults.headers.common.origin = 'www.example.com'; // for cookie based auth
axios.interceptors.request.use(
  async config => {
    // if (!config.baseURL) {
    //   axios.defaults.baseURL = baseURL;
    //   config.baseURL = baseURL; // eslint-disable-line no-param-reassign
    // }
    // return config;
    if (!config.baseURL) {
      axios.defaults.baseURL = baseURL;
      config.baseURL = baseURL; // eslint-disable-line no-param-reassign
    }
    if (!config.headers.Authorization) {
      // setting token if it not present
      const token = window.localStorage.getItem("x-access-token");
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  // SPECIFIC_ERROR_HANDLER([], error);
  error => Promise.reject(error)
);

// eslint-disable-next-line arrow-body-style
axios.interceptors.response.use(
  response => {
    // console.log(response.data.data["x-access-token"], response.config.url);
    if (response.config.url === baseURL + "users/provider-login/" || response.config.url === baseURL + "users/provider-reset-password/") {

      const token = response.data.data["x-access-token"] || response.data["x-access-token"];
      console.log('hi', token);

      // CookieManager.get(response.config.url).then(async res => {
      // `res` will be true or false depending on success.
      axios.defaults.headers.common.Authorization = `bearer ${token}`;
      try {
        // console.log(response.data.data["x-access-token"]);
        if (response.config.url === baseURL + "users/provider-reset-password/")
          window.localStorage.setItem(
            "x-refresh-token",
            token
          );
        else
          window.localStorage.setItem(
            "x-access-token",
            token
          );
      } catch (error) {
        console.log('error while setting token in storage', error);
      }
      // });
    }
    return response;
  },
  error => {
    // Handle your common errors here
    // SPECIFIC_ERROR_HANDLER([500, 503], error);
    return Promise.reject(error);
  }
);

export default axios;
