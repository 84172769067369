/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Button from "../SchoolProfile/Button";

import { createForm } from "rc-form";
import { Col, Row, Button as ReactButton } from "reactstrap";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import EmptyState from "../../../components/EmptyState";
import profileImage from "../../../assets/img/profilePlaceholder.jpg";
import emptyImage from "../../../assets/img/Kiddenz_Enquiry.png";
import styled from "styled-components";
import blueSpinner from "../../../assets/img/blueSpinner.gif";
import axios from "../../../utils/axios";
import { getUploadURL } from "../../../components/Uploader";
import responseToast from "../../../utils/responseToast";
import { API_RESET_PSWD, API_PROVIDERS } from "../../../config/apiEndpoints";
import useCommonCRUD from "../../../hooks/commonCRUD";
import Loader from "../../../components/Loader";

function Settings(props) {
  const [editProfile, setEditProfile] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changeInputType, setChangeInputType] = useState(false);
  const [changeOldInputType, setChangeOldInputType] = useState(false);
  const [changeNewInputType, setChangeNewInputType] = useState(false);


  const [details, setDetails] = useState({
    name: "name@gmail.com",
    phone_number: "1234567890",
    email: "abc@gmail.com",
    director_photo_id: ""
  });

  const [passwordDetails, setPasswordDetails] = useState({
    old_password: "",
    new_password: ""
  });

  const [detailsErr, setDetailsErr] = useState({});
  const [passwordDetailsErr, setPasswordDetailsErr] = useState({});

  const [, , passwordChange] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_RESET_PSWD}`
    }
  });

  const matchRegex = (exp, target, value, condn) => {
    let regExp = exp;
    if (value === "" || (regExp.test(value) && condn)) {
      setValue(target, value);
    }
  };

  const [formData, , , formdataUpdate, formDataId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}`,
      update: `${API_PROVIDERS}provider-update/`,
      readId: `${API_PROVIDERS}`
    }
  });

  useEffect(() => {
    if (formData && formData.data) {
      console.log(formData.data);
      setDetails(formData.data);
    }
  }, [formData]);

  const uploadImage = e => {
    let dataCopy = { ...details };
    let fieldError = { ...detailsErr };
    dataCopy["director_photo_url"] = blueSpinner;
    setDetails(dataCopy);
    const fd = new FormData();
    fd.append("media", e.target.files[0]);
    const url = getUploadURL("avatar");
    axios
      .post(url, fd)
      .then(res => res.data.data)
      .then(file => {
        dataCopy["director_photo_url"] = file.image_url;
        dataCopy["director_photo_id"] = file.id;
        fieldError.director_photo_id_error = "";
        setDetailsErr(fieldError);
        setDetails(dataCopy);
      })
      .catch(() => {
        responseToast("Something went wrong");
        dataCopy["director_photo_url"] = null;
        setDetails(dataCopy);
      });
  };

  const setValue = (field, value) => {
    let fieldData = { ...details };
    let fieldError = { ...detailsErr };
    fieldData[field] = value;
    value === ""
      ? (fieldError[`${field}_error`] = "This Field is required")
      : (fieldError[`${field}_error`] = "");
    setDetailsErr(fieldError);
    setDetails(fieldData);
  };
  const setPasswordValue = (field, value) => {
    let fieldData = { ...passwordDetails };
    let fieldError = { ...detailsErr };
    fieldData[field] = value;
    value === ""
      ? (fieldError[`${field}_error`] = "This Field is required")
      : (fieldError[`${field}_error`] = "");
    setPasswordDetailsErr(fieldError);
    setPasswordDetails(fieldData);
  };
  const submitData = () => {
    let output = Object.keys(details);
    let fieldError = { ...detailsErr };
    let errData = output.filter(
      a =>
        a !== "director_photo_id" &&
        (!details.name || !details.phone_number || !details.email)
    );
    if (errData.length > 0) {
      errData.map(d => {
        fieldError[`${d}_error`] = "This field is required";
      });
      setDetailsErr(fieldError);
      return;
    }

    const emailReg = new RegExp(
      "^([\\w\\.\\+\\-]+\\@[a-zA-Z0-9\\.\\-]+\\.[a-zA-z0-9]{2,4})$"
    );
    if (!emailReg.test(details.email.trim())) {
      fieldError[`email_error`] = "Enter Valid Email Address";
      setDetailsErr(fieldError);
      return;
    }
    if (details.phone_number.length !== 10) {
      fieldError[`phone_number_error`] = "Enter Valid Mobile Number";
      setDetailsErr(fieldError);
      return;
    }
    if (!details.director_photo_id) {
      delete details.director_photo_id;
    }

    formdataUpdate({
      data: details,
      cb: err => {
        if (!err) {
          setEditProfile(false);
          formDataId({ data: "" });
        } else {
          responseToast(err);
        }
      }
    });
  };

  const submitPassword = () => {
    let output = Object.keys(passwordDetails);
    let fieldError = { ...passwordDetailsErr };
    let errData = output.filter(a => !passwordDetails[a]);
    if (errData.length > 0) {
      errData.map(d => {
        fieldError[`${d}_error`] = "This field is required";
      });
      setPasswordDetailsErr(fieldError);
      return;
    }
    if (confirmPasswordErr) return;

    if (passwordDetails.old_password.length < 6) {
      let fieldError = { ...passwordDetailsErr };
      fieldError["old_password_error"] =
        "Password must be atleast 6 characters";
      setPasswordDetailsErr(fieldError);
      return;
    }
    if (passwordDetails.new_password.length < 6) {
      let fieldError = { ...passwordDetailsErr };
      fieldError["new_password_error"] =
        "Password must be atleast 6 characters";
      setPasswordDetailsErr(fieldError);
      return;
    }

    if (confirmPassword !== passwordDetails.new_password) {
      if (confirmPassword === "") {
        setConfirmPasswordErr("Enter confirm password");
      } else setConfirmPasswordErr("Password mismatch");
      return;
    }

    passwordChange({
      data: passwordDetails,
      cb: err => {
        if (!err) {
          setPasswordDetails({
            old_password: "",
            new_password: ""
          });
          setUpdatePassword(false);
          setConfirmPassword('')
        } else {
          responseToast(
            err.data && err.data.data && Object.keys(err.data.data).length === 0
              ? err.data.message || "Something went wrong"
              : err.data.data.old_password && err.data.data.new_password
              ? `${err.data.data.old_password[0]} ${err.data.data.new_password[0]}` ||
                "Something went wrong"
              : err.data.data.old_password
              ? err.data.data.old_password[0] || "Something went wrong"
              : Array.isArray(err.data.data.new_password)
              ? err.data.data.new_password[0] || "Something went wrong"
              : err.data.data.new_password || "Something went wrong"
          );
        }
      }
    });
  };

  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar
          {...props}
          brandText="Kiddenz"
          userImage={details.director_photo_url}
        />
      </div>
      <Loader isOpen={formData.isLoading} />
      {!formData.isLoading && (
        <div className="settingsProfile">
          <div className="mainHeading spaceBetweenAlignCenter">
            <div>
              <h3>Settings</h3>
              <span>Update passwords, profile details, contact details</span>
            </div>
            {editProfile ? (
              <span className="spaceBetweenAlignCenter formFlex">
                <ReactButton
                  color="success"
                  onClick={() => submitData()}
                  style={{ marginRight: "30px" }}
                >
                  Update
                </ReactButton>
                <ReactButton
                  color="primary"
                  onClick={() => {
                    formDataId({ data: "" });
                    setEditProfile(false);
                  }}
                  style={{ marginLeft: "0px" }}
                >
                  Cancel
                </ReactButton>
              </span>
            ) : (
              <div
                className="settingsProfile-edit"
                onClick={() => setEditProfile(true)}
              >
                <span
                  class="iconify"
                  data-icon="feather:edit"
                  data-inline="false"
                ></span>
                Edit Profile
              </div>
            )}
          </div>

          <div className="settingsProfile-content">
            <h3>Profile</h3>
            <Row>
              <div className="settingsProfile-picture">
                <div className="settingsProfile-image">
                  <img
                    src={(details.director_photo_url || profileImage)}
                    alt=""
                  />
                  {editProfile && (
                    <div className="camera">
                      <label for="upload-photo" className="upload-file-text">
                        <span
                          class="iconify"
                          data-icon="carbon:camera"
                          data-inline="false"
                        ></span>
                      </label>
                      <input
                        type="file"
                        name="photo"
                        id="upload-photo"
                        accept=".png,.jpeg,.jpg"
                        onChange={uploadImage}
                        disabled={!editProfile}
                      />
                    </div>
                  )}
                </div>
                {editProfile && (
                  <>
                    {" "}
                    <label for="upload-photo" className="upload-file-text">
                      Change Picture
                    </label>
                    <input
                      type="file"
                      name="photo"
                      id="upload-photo"
                      accept=".png,.jpeg,.jpg"
                      onChange={uploadImage}
                      disabled={!editProfile}
                    />
                  </>
                )}
              </div>
              <Col>
                <div className="studentDetails-input">
                  <label>Director Name*</label>
                  <input
                    type="text"
                    placeholder="Enter name"
                    readOnly={!editProfile}
                    value={details.name}
                    onChange={e =>
                      matchRegex(/^[A-Za-z ]+$/, "name", e.target.value, "name")
                    }
                  />
                  <Error>{detailsErr.name_error}</Error>
                </div>
                <div className="studentDetails-input">
                  <label>Phone number</label>
                  <div className="">
                    <span
                      style={{
                        borderBottom: "1px solid rgba(102, 108, 120, 0.13)",
                        fontSize: 14,
                        fontWeight: 500,
                        padding: "11px 0px"
                      }}
                    >
                      +91{" "}
                    </span>
                    <input
                      type="text"
                      placeholder="Enter phone number"
                      readOnly={!editProfile}
                      value={details.phone_number}
                      onChange={e => {
                        if (
                          e.target.value === "" ||
                          (Number(e.target.value) &&
                            e.target.value.length <= 10)
                        ) {
                          matchRegex(
                            /^[0-9]*$/,
                            "phone_number",
                            e.target.value,
                            "phone_number"
                          );
                        }
                      }}
                    />
                    <Error>{detailsErr.phone_number_error}</Error>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="studentDetails-input">
                  <label>Email ID</label>
                  <input
                    type="text"
                    placeholder="Enter email-id"
                    readOnly={!editProfile}
                    value={details.email}
                    onChange={e => setValue("email", e.target.value)}
                  />
                  <Error>{detailsErr.email_error}</Error>
                </div>
                {/* {!updatePassword && (
                  <div className="studentDetails-input">
                    <label>Password</label>
                    <div
                      className="spaceBetweenAlignCenter"
                      style={{
                        borderBottom: "1px solid rgba(102, 108, 120, 0.13)",
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >
                      <input
                        type="password"
                        placeholder="Enter name"
                        value={"123456"}
                        readOnly={true}
                        style={{ border: 0 }}
                      />
                      <span
                        style={{ color: "#613A95", cursor: "pointer" }}
                        onClick={() => setUpdatePassword(true)}
                      >
                        Update Password
                      </span>
                    </div>
                  </div>
                )} */}
              </Col>
            </Row>

            {updatePassword && (
              <div className="changePassword">
                <h3>Password</h3>
                <Col>
                  <div className="studentDetails-input">
                    <label>Old Password</label>
                    <div
                      className="spaceBetweenAlignCenter"
                      style={{
                        borderBottom: "1px solid rgba(102, 108, 120, 0.13)",
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >
                      <input
                        type={changeOldInputType ? "text" : "password"}
                        placeholder="Enter old password"
                        value={passwordDetails.old_password}
                        onChange={e =>
                          setPasswordValue("old_password", e.target.value)
                        }
                        readOnly={!updatePassword}
                      />
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setChangeOldInputType(!changeOldInputType)
                        }
                      >
                        {changeOldInputType ? (
                          <i className="fa fa-eye-slash" fontSize="20" />
                        ) : (
                          <i className="fa fa-eye" fontSize="20" />
                        )}
                      </span>
                    </div>
                    <Error>{passwordDetailsErr.old_password_error}</Error>
                  </div>
                  <div className="studentDetails-input">
                    <label>Confirm new Password</label>
                    <div
                      className="spaceBetweenAlignCenter"
                      style={{
                        borderBottom: "1px solid rgba(102, 108, 120, 0.13)",
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >
                      <input
                        type={changeInputType ? "text" : "password"}
                        placeholder="Enter new password"
                        readOnly={!updatePassword}
                        onChange={e => {
                          if (passwordDetails.new_password) {
                            setConfirmPassword(e.target.value);
                          } else {
                            setConfirmPasswordErr("Enter new password first");
                          }
                        }}
                        value={confirmPassword}
                        onBlur={e => {
                          if (
                            confirmPassword !== passwordDetails.new_password
                          ) {
                            setConfirmPasswordErr("Password mismatch");
                          } else {
                            setConfirmPasswordErr("");
                          }
                        }}
                        style={{ border: 0 }}
                      />
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setChangeInputType(!changeInputType)}
                      >
                        {console.log(changeInputType)}
                        {changeInputType ? (
                          <i className="fa fa-eye-slash" fontSize="20" />
                        ) : (
                          <i className="fa fa-eye" fontSize="20" />
                        )}
                      </span>
                    </div>
                    {confirmPasswordErr && <Error>{confirmPasswordErr}</Error>}
                  </div>
                </Col>
                <Col>
                  <div className="studentDetails-input">
                    <label>New Password</label>
                    <div
                      className="spaceBetweenAlignCenter"
                      style={{
                        borderBottom: "1px solid rgba(102, 108, 120, 0.13)",
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >
                    <input
                      type={changeNewInputType ? "text" : "password"}
                      placeholder="Enter new password"
                      value={passwordDetails.new_password}
                      onChange={e =>
                        setPasswordValue("new_password", e.target.value)
                      }
                      onBlur={e => {
                        if (
                          confirmPassword && confirmPassword !== passwordDetails.new_password
                        ) {
                          setConfirmPasswordErr("Password mismatch");
                        } else {
                          setConfirmPasswordErr("");
                        }
                      }}
                      readOnly={!updatePassword}
                    />
                     <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setChangeNewInputType(!changeNewInputType)
                        }
                      >
                        {changeNewInputType ? (
                          <i className="fa fa-eye-slash" fontSize="20" />
                        ) : (
                          <i className="fa fa-eye" fontSize="20" />
                        )}
                      </span>
                    </div>
                    <Error>{passwordDetailsErr.new_password_error}</Error>
                  </div>
                  <div
                    className="studentDetails-input"
                    style={{ flexDirection: "row" }}
                  >
                    <span
                      style={{
                        color: "#613A95",
                        cursor: "pointer",
                        marginTop: 60,
                        marginRight: 60,
                        fontWeight: 600
                      }}
                      onClick={() => submitPassword()}
                    >
                      Update password
                    </span>
                    <span
                      style={{
                        color: "#555569",
                        cursor: "pointer",
                        marginTop: 60
                      }}
                      onClick={() => {
                        setPasswordDetails({
                          old_password: "",
                          new_password: ""
                        });
                        setPasswordDetailsErr({});
                        setDetailsErr({});
                        setUpdatePassword(false);
                        setConfirmPasswordErr("");
                        setConfirmPassword("");
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                </Col>
              </div>
            )}
          </div>

          {/* <EmptyState text="No Reviews yet" desc="Hey! you dont have any reviews for your daycare yet, why dont you send in review request for parents to get the reviews, click on “Get parents review” button below to proceed" emptyImage={emptyImage} btntext="Get Parents Review" /> */}
        </div>
      )}{" "}
    </>
  );
}

Settings.propTypes = {};

export default createForm()(Settings);
const Error = styled.div`
  font-size: 12px;
  color: red;
`;
