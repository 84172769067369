import React, { useReducer, useRef, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import CookieConsent, { Cookies } from "react-cookie-consent";
import axios from "./utils/axios";
import { API_LOGOUT, API_MY_PROFILE } from "./config/apiEndpoints";
import toast from "./utils/toast";
import Loader from './components/Loader'
// import 
const loading = () => (
  // <div className="animated fadeIn pt-3 text-center">Loading...</div>
  <Loader isOpen={true} />
);

// Containers
const DefaultLayout = React.lazy(() => import("./layouts/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./layouts/Login"));
// const Register = React.lazy(() => import("./views/Pages/Register"));
// const Page404 = React.lazy(() => import("./views/Pages/Page404"));
// const Page500 = React.lazy(() => import("./views/Pages/Page500"));

export const UserContext = React.createContext({ actions: {} });

const initialState = {
  profile: null,
  isLoggedIn: false,
  lastUpdated: 0,
  isLoading: true
};
const profileReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_PROFILE":
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        profile: payload,
        lastUpdated: new Date().getTime()
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: payload
      };
    case "LOGOUT":
      return { ...initialState, isLoading: false };

    default:
      return state;
  }
};

// let nextPath = "/";

const App = props => {
  const [user, dispatch] = useReducer(profileReducer, initialState);

  const { isLoggedIn, isLoading, profile } = user;
  const redirect = useRef(null);
  useEffect(() => {
    const token = window.localStorage.getItem("x-access-token");
    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  
    console.log(props)
    axios
      .get(API_MY_PROFILE)
      .then(res => res.data.data)
      .then(login)
      .catch(() => {
        redirect.current = "/login";
        // nextPath = props.location.pathname;
        dispatch({ type: "LOGOUT", payload: false });
      });
  }, []);

  const logout = e => {
    // axios
    //   .get(API_LOGOUT)
    //   .then(() => {
    //     redirect.current = "/login";
    //     dispatch({ type: "LOGOUT" });
    //   })
    //   .catch(err => {
    //     toast.error(err.response.message, {}, "LOGOUT");
    //   });
    window.localStorage.removeItem("x-access-token");
    redirect.current = "/login";
    dispatch({ type: "LOGOUT" });
  };

  const onClickTabs = () => {
    const token = window.localStorage.getItem("x-access-token");
    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  
    console.log(props)
    axios
      .get(API_MY_PROFILE)
      .then(res => res.data.data)
      .then(login)
      .catch(() => {
        redirect.current = "/login";
        // nextPath = props.location.pathname;
        dispatch({ type: "LOGOUT", payload: false });
      });
  }

  const login = payload => {
    // console.log(payload)
    // payload.data && setProviderForm(payload.data.provider_form)
    dispatch({ type: "SET_PROFILE", payload });
  };
  return (
    <>
      <ToastContainer />
      {/* <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="x-access-token"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        This website uses cookies to ensure you get the best experience on our
        website. Please allow cookies to continue using the website
      </CookieConsent> */}
      {isLoading ? (
        loading()
      ) : (
          <UserContext.Provider value={{ actions: { login, logout, onClickTabs }, user }}>
            <BrowserRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    name="Login Page"
                    render={props => {
                      if (isLoggedIn) {
                        return (
                          <Redirect
                          to={
                            (profile &&
                              profile.data &&
                              profile.data.provider_form) ||
                            (profile && profile.provider_form)
                              ? "/form?activeTab=formUpdate"
                              : "/dashboard?activeTab=dashboard"
                          }
                        />
                        );
                      }
                      return <Login {...props} />;
                    }}
                  />
                  {/* <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={props => <Register {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={props => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={props => <Page500 {...props} />}
                /> */}
                  <Route
                    path="/"
                    name="Home"
                    render={props => {
                      if (!isLoggedIn) {
                        return <Redirect to="/login" />;
                      }
                      if(props.location && props.location.search && props.location.search.includes('?email=True')) {
                        logout();
                        return <Redirect to="/login" />;
                      }
                      return (
                        <DefaultLayout
                          providerForm={
                            ((profile &&
                              profile.data &&
                              profile.data.provider_form ) || 
                              (profile && profile.provider_form))
                          }
                          isLoggedIn={isLoggedIn}
                          {...props}
                          test='abc'
                        />
                      );
                    }}
                  />
                  {console.log('test-props', props)}
                  {redirect.current && (
                    <Redirect
                      to={{
                        pathname: redirect.current
                      }}
                    />
                  )}
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </UserContext.Provider>
        )}
    </>
  );
};

export default App;
