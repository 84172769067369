export const envType = 'prod1' // prod1, prod2

const env = process.env.REACT_APP_SERVER_ENV || "development";
const baseURL = {
  production: process.env.REACT_APP_PRODUCTION_API_URL,
  staging: process.env.REACT_APP_STAGING_API_URL,
  // development: "http://13.235.51.70/api/"
    development: envType === 'staging' ? "https://daycarebe.kiddenz.com/daycare/api/" : "https://daycareprodbe.kiddenz.com/daycare/api/",// staginhg  // development: "https://daycareprodbe.kiddenz.com/api/"
  //  development: "http://13.235.51.70/api/" // test server
}[env];

export default baseURL;
