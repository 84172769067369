/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "../../views/Pages/SchoolProfile/Button.js";

import Flex from "../../views/Pages/SchoolProfile/flex";

function EnquiryQuestion(props) {
  return (
    <>
      <Enquiry
        className={props.classType ? "disable" : props.active && "active"}
        onClick={e => {
          props.onClick && props.onClick();
        }}
      >
        <div className="enquiryTop">
          <EnquiryImage bgColor={props.bgColor}>
            {props.imgpath && <img src={props.imgpath} alt="" />}
            {props.imgText && (
              <span style={{ textTransform: "capitalize" }}>
                {props.imgText}
              </span>
            )}
          </EnquiryImage>
          <EnquiryDetails>
            <EnquiryDetailsItem>
              <div style={{ display: "Flex" }}>
                <Name>{props.name}</Name>
                {props.btnText && <EnquiryBtn>{props.btnText}</EnquiryBtn>}
                {props.date && <Date>|&nbsp;&nbsp;{props.date}</Date>}
              </div>
              <Time>{props.time}</Time>
            </EnquiryDetailsItem>
            <EnquiryDetailsItem>
              <Question className="question">{props.question}</Question>
              {!props.textarea && (
                <ResponseBtn
                  className="responseBtn"
                  style={{ color: props.btnText2 === "Close" && "#555457" }}
                  onClick={e => {
                    e.stopPropagation();
                    if (props.openTextArea) {
                      if (props.btnText2 === "Close") {
                        props.cancelTextArea();
                      } else props.openTextArea();
                    }
                  }}
                >
                  {props.btnText2}
                </ResponseBtn>
              )}
            </EnquiryDetailsItem>
          </EnquiryDetails>
        </div>
        {props.response && (
          <div className="enquiryTop" style={{ marginTop: 30 }}>
            <EnquiryImage style={{ visibility: "hidden" }}></EnquiryImage>
            <EnquiryDetails>
              <EnquiryDetailsItem>
                <div style={{ display: "Flex" }}>
                  <Name style={{ color: "#30333B" }}>Response </Name>
                </div>
              </EnquiryDetailsItem>
              <EnquiryDetailsItem>
                {/* <div style={{ display: "Flex" }}> */}
                <Question className="question">{props.responseData}</Question>
                {/* </div> */}
              </EnquiryDetailsItem>
            </EnquiryDetails>
          </div>
        )}
        {props.textarea && (
          <div className="enquiryBottom">
            <TextArea>
              <textarea
                cols="4"
                type="text"
                placeholder={props.textarea}
                onChange={props.onChange}
              />
              {props.responseErr && <Error>This field is required</Error>}
              <div className="attachEnquiry">
                <label for="upload-photo" className="upload-file-text">
                  <span
                    class="iconify"
                    data-icon="entypo:attachment"
                    data-inline="false"
                  ></span>
                  {props.uploadDocName || "Attach documents or images"}
                </label>
                <input
                  type="file"
                  name="photo"
                  id="upload-photo"
                  accept=".png,.jpeg,.jpg,.pdf"
                  onChange={props.uploadImage}
                />
              </div>
            </TextArea>
            <div style={{ width: "15%" }}>
              <Button
                type="mobile"
                height="50px"
                text="Send to Parents"
                marginRight="24px"
                onClick={e => {
                  e.stopPropagation();
                  if (props.responseValue) props.sendResponse();
                  else {
                    props.setResponseErr(true);
                  }
                }}
              />
            </div>
            <div style={{ width: "15%" }}>
              <Button
                type="Contact"
                height="50px"
                text="Cancel"
                onClick={e => {
                  e.stopPropagation();
                  props.cancelTextArea();
                }}
              />
            </div>
          </div>
        )}
      </Enquiry>
    </>
  );
}

EnquiryQuestion.propTypes = {
  imgpath: PropTypes.string,
  imgText: PropTypes.string,
  name: PropTypes.string,
  btnText: PropTypes.string,
  btnText2: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  question: PropTypes.string,
  classType: PropTypes.string,
  textarea: PropTypes.string
};

export default EnquiryQuestion;

export const EnquiryImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 57px;
  min-width: 57px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || "#f2d68b"};
  overflow: hidden;
  margin-right: 24px;
  span {
    font-family: Quicksand;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;
export const Name = styled.div`
  color: #30333b;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`;
export const Time = styled.div`
  opacity: 0.7;
  color: #666c78;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`;
export const Date = styled.div`
  color: #666c78;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 10px;
  font-weight: 300;
`;

export const Question = styled.div`
  width: 80%;
  color: #666c78;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 19px;
`;
export const ResponseBtn = styled.div`
  cursor: pointer;
  color: #613a95;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
`;
export const Enquiry = styled.div`
  padding: 25px 0px;
  border-bottom: 1px solid rgba(102, 108, 120, 0.13);
  &.active {
    padding: 25px 10px;
    background-color: #ffffff;
    box-shadow: 0 25px 62px 0 rgba(48, 51, 59, 0.09);
    border-radius: 4px;
    border-bottom: 0px;
  }
  .enquiryTop {
    display: flex;
  }
  .enquiryBottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .mobile,
    .Contact {
      width: 100%;
    }
  }
  &:last-child {
    border-bottom: 0px solid rgba(102, 108, 120, 0.13);
  }
  &.disable {
    ${EnquiryImage} {
      background-color: #dbdee5;
      span {
        color: #fff;
      }
    }
    ${Name} {
      opacity: 0.5;
    }
    ${Time} {
      opacity: 0.5;
    }
    ${Question} {
      opacity: 0.5;
    }
    ${ResponseBtn} {
      color: rgba(102, 108, 120, 0.5);
    }
  }
`;

export const EnquiryDetails = styled.div`
  width: -webkit-fill-available;
`;

export const EnquiryDetailsItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const EnquiryBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
  height: 26px;
  width: fit-content;
  border: 1px solid #ced1d8;
  border-radius: 18px;
  background-color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  color: #ced1d8;
  margin-left: 14px;
  cursor: pointer;
  &:hover,
  &.active {
    border-color: #60b947;
    color: #60b947;
  }
`;

export const TextArea = styled.div`
  padding: 24px;
  margin-top: 34px;
  width: 65%;
  border: 1px solid #ced1d8;
  border-radius: 5px;
  textarea {
    width: 100%;
    border: none;
    resize: none;
  }
  .attachEnquiry {
    width: fit-content;
    opacity: 0.5;
    color: #666c78;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      color: #60b947;
      opacity: 1;
    }
    .iconify {
      margin-right: 14px;
    }
  }
`;
const Error = styled.div`
  font-size: 12px;
  color: red;
`;
