import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
export const Emptystate = styled.div`
  display:flex;
  width:55%;
  flex-direction:column;
  align-items:center;
  margin:90px auto;
  h2
  {
    color: #30333B;
    font-family: Quicksand;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 30px;
    margin:0px 0px 22px;
  }
  p
  {
    color: #666C78;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin:0px 0px 30px;
    text-align:center;
  }
  img
  {
      margin-bottom:30px;
  }
  .reviewBtn
  {
    display:flex;
    justify-content:center;
    align-items:center;
    height:36px;
    padding:0px 22px;
    width: fit-content;
    border: 1px solid #DBDEE5;
    border-radius: 18px;
    background-color: #FFFFFF;
    color: #666C78;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    cursor:pointer;
    &:hover
    {
        border-color:#613A95;
        color:#613A95;
    }
  }
`;

const EmptyState = ({ text,desc,emptyImage,btntext,onClick }) => <Emptystate>

              <h2>{text}</h2>
              <p>{desc}</p>
              <img src={emptyImage} alt="" />
              {btntext && <div className="reviewBtn" onClick={onClick}>{btntext}</div>}
        
</Emptystate>;
EmptyState.propTypes = {
  text: PropTypes.string,
  desc:PropTypes.string,
  emptyImage:PropTypes.string,
  btntext:PropTypes.string,


};
export default EmptyState;
