import React from "react";
import { Col, FormGroup, Label, Input } from "reactstrap";
import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";

function RadioButton({ onClick, checked, label, className, isRequired = false, disabled = false, ...rest }) {

    return (
        <Col xs={rest.xsval || "3"} key={rest.key} className={className}>
            <FormGroup check className="customRadio" onClick={() => !disabled && onClick(label)}>
                <Input type="radio" checked={checked} id={rest.name}
                    {...rest}
                    name={rest.name}
                    disabled={disabled}
                    noValidate

                />
                <Label htmlFor={rest.name} check>
                    <span className="formInputName">{label}</span>
                    {rest.isDesignation && <span className="information">
                        <Icon path={mdiInformationOutline} />
                        <div className="tooltip-hover" >
                            <span>{'Center head/ Center manager/ Center co-ordinator'}</span>
                        </div>
                    </span>}
                </Label>

            </FormGroup>
        </Col>
    );
}

RadioButton.propTypes = {};

export default RadioButton;
