const responseErrorParser = data =>
  (Array.isArray(data) &&
    data.reduce((acc, curr) => {
      const [key, message] = Object.entries(curr)[0];
      const payloadKey = key.split(",")[1];
      return {
        ...acc,
        [payloadKey]: message
      };
    }, {})) ||
  {};

export default responseErrorParser;
