import React, { useEffect } from "react";
import { Col, Row, FormGroup, Label, FormFeedback } from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
// import colors from "./colors";

export const creatableMenu = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 22
  }),
  container: provided => ({
    ...provided,
    border: 0,
    borderRadius: 4
  }),
  control: (provided, state) => ({
    ...provided,
    bordercolor: "transparent",
    borderWidth: 0,
    borderRadius: 4
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: "0.8rem 0.75rem"
  })
};

function Selects({
  form,
  isResponsive,
  type,
  label,
  value,
  multiple,
  disabled,
  onChange,
  sendData,
  options,
  onInputChange,
  defaultValue,
  isResponsivePadding,
  isRequired = true,
  ...rest
}) {
  const error = form.getFieldError(rest.name);


  return (
    <Col
      xs={
        isResponsive && window.screen.width < 1250 ? "5" : rest.colSpan || "12"
      }
    >
      <FormGroup className={`formFlex ${rest.classtype}`}>
        {label && (
          <Col
            xs="4"
            style={{
              paddingLeft: rest.paddingLeft
            }}
          >
            <Label htmlFor={rest.name}>{label}</Label>
          </Col>
        )}
        <Col
          style={{
            padding: isResponsivePadding && window.screen.width < 1250 && 0,
            fontSize: 14,
            marginRight: rest.marginRight,
            paddingLeft: rest.paddingLeft,
            marginLeft: rest.marginLeft
          }}
        >
          {/* <SelectBoxContent> */}
          {rest.isOnlySelect ?
            <Select 
            placeholder={rest.placeholder}
            id={rest.name}
            noValidate
            {...rest}
            styles={creatableMenu}

            className="form-control-alternative"
            options={options}
              isMulti={false}
              {...rest}
              {...form.getFieldProps(rest.name, {
                validateTrigger: "onChange",
                initialValue: defaultValue && {
                  value: defaultValue,
                  label: defaultValue
                },

                rules: [{ required: isRequired }],
                onChange: (e) => {
                  onChange && onChange(e)
                }
              })}
              invalid={!!error}
            />
            : <CreatableSelect
              placeholder="Select / Enter..."
              id={rest.name}
              noValidate
              {...rest}
              isDisabled={disabled}
              styles={creatableMenu}
              className="form-control-alternative"
              options={options}
              isMulti={false}
              {...form.getFieldProps(rest.name, {
                validateTrigger: "onChange",
                initialValue: defaultValue && {
                  value: defaultValue,
                  label: defaultValue
                },

                rules: [{ required: isRequired }],
                onChange: e => {
                  onChange && onChange(e);
                },
                // value: value,
                // onInputChange: (e) => {
                // },
              })}
              invalid={!!error}
            />}
          {rest.isRequired && rest.msg && (
            <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
              {rest.msg}
            </div>
          )}
          {error && (
            <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
              {error[0] ? error[0].replace(/[0-9]/g, '')
                : error.replace(/[0-9]/g, '')}
            </div>
          )}
          {/* </SelectBoxContent> */}
        </Col>

        <FormFeedback>
          {error && error[0] && error[0].includes("_")
            ? error[0].charAt(0).toUpperCase() + error[0].slice(1)
            : error}
        </FormFeedback>
      </FormGroup>
    </Col>
  );
}

Selects.propTypes = {};

export default Selects;
