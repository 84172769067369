/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  CardBody,
  Col,
  Row,
  Button,
  Form,
  Input,
  CardTitle,
  CardText,
  CardFooter,
  FormGroup,
  Modal
} from "reactstrap";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import remove from "lodash/remove";
import cloneDeep from "lodash/cloneDeep";
import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";
import { createForm } from "rc-form";
import FormInput from "../../../components/FormInput";
import Dropdown from "../../../components/Dropdown";
import RadioButton from "../../../components/RadioButton";
import responseToast from "../../../utils/responseToast";
import TimePicker from "../../../components/TimePicker";
import DatePicker from "../../../components/DatePicker";
import CheckBox from "../../../components/CheckBox";
import Select from "../../../components/Select";

import FileUploader from "../../../components/Uploader";
import Trash from "../../../assets/img/icons/common/trash.png";
import TrashHover from "../../../assets/img/icons/common/trash-hover.png";
import AccountReject from "../../../components/AccountReject";

const DEFAULT_SCHEDULE_DUPLICATE = [
  "Start of the day",
  "Breakfast",
  "Milk",
  "Play Time",
  "Lunch",
  "Nap Time",
  "Learning Activities",
  "Snacks",
  "Outdoor Activities",
  "End of the day"
];

const DEFAULT_YEAR_DUPLICATE = [
  'Varalaxmi Festival',
  'Bakrid',
  'Independence day',
  'Ganesh Chaturthi',
  'Muharram',
  'Mahalaya Amavasaya',
  'Gandhi Jayanthi',
  'Dussehra',
  'Kannada Rajyotsava',
  'Diwali',
  'Christmas',
  'Sankranti',
  'Republic Day',
  'Shivarathiri',
]


let holiday_list = [
  { label: 'Varalaxmi Festival', start: '2020-07-31', end: '2020-07-31', },
  { label: 'Bakrid', start: '2020-08-01', end: '2020-08-01', },
  { label: 'Independence day', start: '2020-08-15', end: '2020-08-15', },
  { label: 'Ganesh Chaturthi', start: '2020-08-22', end: '2020-08-22', },
  { label: 'Muharram', start: '2020-08-29', end: '2020-08-29', },
  { label: 'Mahalaya Amavasaya', start: '2020-09-17', end: '2020-09-17', },
  { label: 'Gandhi Jayanthi', start: '2020-10-02', end: '2020-10-02', },
  { label: 'Dussehra', start: '2020-10-19', end: '2020-10-19', },
  { label: 'Kannada Rajyotsava', start: '2020-11-01', end: '2020-11-01', },
  { label: 'Diwali', start: '2020-11-14', end: '2020-11-16', },
  { label: 'Christmas', start: '2020-12-25', end: '2020-12-25', },
  { label: 'Sankranti', start: '2021-01-15', end: '2021-01-15', },
  { label: 'Republic Day', start: '2021-01-26', end: '2021-01-26', },
  { label: 'Shivarathiri', start: '2021-03-11', end: '2021-03-11', },
];


let globalId = null;
let globalHolidayKey = null;
let globalIndex = null;
let globalCalender = false;


let weeksArr = [
  { id: "mon", name: "Monday", disabled: false },
  { id: "tue", name: "Tuesday", disabled: false },
  { id: "wed", name: "Wednesday", disabled: false },
  { id: "thu", name: "Thursday", disabled: false },
  { id: "fri", name: "Friday", disabled: false },
  { id: "sat", name: "Saturday", disabled: false },
  { id: "sun", name: "Sunday", disabled: false }
];

let ageYear = [
  // { id: 0, name: 0 },
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 },
  { id: 12, name: 12 },
  { id: 13, name: 13 },
  { id: 14, name: 14 },
  { id: 15, name: 15 }
];


let ageYearDayCare = [
  { id: 0, name: 0 },
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 },
  { id: 12, name: 12 },
  { id: 13, name: 13 },
  { id: 14, name: 14 },
  { id: 15, name: 15 }
];

let weekdaysSelect = [
  { id: 1, name: 'Monday-Friday' },
  { id: 2, name: 'Monday-Saturday' },
  { id: 3, name: 'Monday-Sunday' }
];

let ageMonthPreSchool = [
  { id: 0, name: 0 },
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 }
];

let ageMonth = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 }
];

let weeksArr1 = [
  { value: "mon", label: "Monday" },
  { value: "tue", label: "Tuesday" },
  { value: "wed", label: "Wednesday" },
  { value: "thu", label: "Thursday" },
  { value: "fri", label: "Friday" },
  { value: "sat", label: "Saturday" },
  { value: "sun", label: "Sunday" }
];

let scheduleDropdown1 = [
  { label: "Start of the day", value: "Start of the day", disabled: false },
  { label: "Breakfast", value: "Breakfast", disabled: false },
  { label: "Milk", value: "Milk", disabled: false },
  { label: "Play Time", value: "Play Time", disabled: false },
  { label: "Lunch", value: "Lunch", disabled: false },
  { label: "Nap Time", value: "Nap Time", disabled: false },
  {
    label: "Learning Activities",
    value: "Learning Activities",
    disabled: false
  },
  { label: "Snacks", value: "Snacks", disabled: false },
  { label: "Outdoor Activities", value: "Outdoor Activities", disabled: false },
  { label: "End of the day", value: "End of the day", disabled: false }
];


let yearDropdown1 = [
  { label: 'Varalaxmi Festival', value: 'Varalaxmi Festival', disabled: false },
  { label: 'Bakrid', value: 'Bakrid', disabled: false },
  { label: 'Independence day', value: 'Independence day', disabled: false },
  { label: 'Ganesh Chaturthi', value: 'Ganesh Chaturthi', disabled: false },
  { label: 'Muharram', value: 'Muharram', disabled: false },
  { label: 'Mahalaya Amavasaya', value: 'Mahalaya Amavasaya', disabled: false },
  { label: 'Gandhi Jayanthi', value: 'Gandhi Jayanthi', disabled: false },
  { label: 'Dussehra', value: 'Dussehra', disabled: false },
  { label: 'Kannada Rajyotsava', value: 'Kannada Rajyotsava', disabled: false },
  { label: 'Diwali', value: 'Diwali', disabled: false },
  { label: 'Christmas', value: 'Christmas', disabled: false },
  { label: 'Sankranti', value: 'Sankranti', disabled: false },
  { label: 'Republic Day', value: 'Republic Day', disabled: false },
  { label: 'Shivarathiri', value: 'Shivarathiri', disabled: false },
];

const DEFAULT_ACTIVITIES = [
  "Singing",
  "SummerCamps",
  "Camping",
  "Football",
  "Cricket",
  "Speaking",
  "Painting",
  "Role playing",
  "Dancing",
  "Story telling",
  "Field tours",
  "Swimming",
  "Music",
  "Drawing",
  "Fancy dress",
  "Arts and crafts"
];

const DEFAULT_SCHEDULE = [
  "Start of the day",
  "Breakfast",
  "Milk",
  "Play Time",
  "Lunch",
  "Nap Time",
  "Learning Activities",
  "Snacks",
  "Outdoor Activities",
  "End of the day"
];

let activity = [
  { label: "Singing", checked: false },
  { label: "SummerCamps", checked: false },
  { label: "Camping", checked: false },
  { label: "Football", checked: false },
  { label: "Cricket", checked: false },
  { label: "Speaking", checked: false },
  { label: "Painting", checked: false },
  { label: "Role playing", checked: false },
  { label: "Dancing", checked: false },
  { label: "Story telling", checked: false },
  { label: "Field tours", checked: false },
  { label: "Swimming", checked: false },
  { label: "Music", checked: false },
  { label: "Drawing", checked: false },
  { label: "Fancy dress", checked: false },
  { label: "Arts and crafts", checked: false }
];

let initial = true;

function BasicInfo(props) {
  const [openHolidayModal, setOpenHolidayModal] = useState(false);
  const [preSchoolProgram, setPreSchoolProgram] = useState([]);

  const [sameCalenderModal, openSameCalenderModal] = useState(false);

  const [holidayCheck, setHolidayCheck] = useState({});
  const batchArr = [];
  const [batch, setBatch] = useState([1]);
  const [batchDropdown, setBatchDropdown] = useState([1]);
  const [dcDropdown, setDcDropdown] = useState(1);

  const [sameCalender, setSameCalender] = useState(false);


  // type of childcare
  const [childCare, setChildCare] = useState("");
  const [image, setImage] = useState("");
  const [photoDeleted, setPhotoDeleted] = useState(false);

  // licence and licence number
  const [license, setLicense] = useState(false);
  const [licenseNumber, setLicenseNumber] = useState("");

  // year of establishment
  const [establishedYear, setEstablishedYear] = useState("");

  // number of working days
  const [workingArr, setWorkingArr] = useState([]);
  const [workingArr1, setWorkingArr1] = useState([]);

  const [preSchoolMin, setPreSchoolMin] = useState(null);
  const [preSchoolMax, setPreSchoolMax] = useState(null);

  const [dcFullMin, setDcFullMin] = useState(null);
  const [dcFullMax, setDcFullMax] = useState(null);

  const [dcHalfMin, setDcHalfMin] = useState(null);
  const [dcHalfMax, setDcHalfMax] = useState(null);

  const [activityValue, setActivityValue] = useState("");
  const [scheduleShow, setScheduleShow] = useState([1, 1]);
  const [scheduleNewTime, setNewScheduleTime] = useState("");
  const [scheduleNewEvent, setNewScheduleEvENt] = useState("");
  const [yearShow, setYearShow] = useState([1, 1]);
  const [yearShow1, setYearShow1] = useState([1, 1]);

  const [weekdaysArray, setWeekDaysArray] = useState(weeksArr);
  const [scheduleDropdown, setScheduleDropdown] = useState(scheduleDropdown1);
  const [yearDropdown, setYearDropdown] = useState(yearDropdown1);

  const [reasons, setReasons] = useState(activity);
  const [checkedReasonsErr, setCheckedReasonsErr] = useState(false);
  const [dcActive, setDcActive] = useState(true);
  const [preActive, setPreActive] = useState(true);



  const [newCalenderStart, setNewCalenderStart] = useState("");
  const [newCalenderTo, setNewCalenderTo] = useState("");
  const [newCalenderEvent, setNewCalenderEvENt] = useState("");

  const [tags, setTags] = useState([]);
  const [activePayable, setActivePayable] = useState("");
  const [activeDayCarePayable, setActiveDayCarePayable] = useState("Monthly");
  const [yearEstablishedDropdown, setYearEstablishedDropdown] = useState([]);

  const [extendedHrs, setExtendedHrs] = useState("No");
  const [weekendCare, setWeekendCare] = useState("No");
  const [activeSchedule, setActiveSchedule] = useState([]);
  const [activePreSchoolSchedule, setActivePreSchoolSchedule] = useState([1]);

  const [totalCapacity, setTotalCapacity] = useState("");
  const [yearlyArr, setYearlyArr] = useState([]);
  const [yearlyArr1, setYearlyArr1] = useState([]);

  const [yearVal, setYearVal] = useState("");
  const [scheduleArr, setScheduleArr] = useState([]);
  const [disableds, setDisabled] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [summerclass, setsummerclass] = useState("no");
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [childrenErr, setChildrenErr] = useState(false);
  const [preSchoolErr, setPreSchoolErr] = useState(false);
  const [preSchoolCapacityErr, setPreSchoolCapacityErr] = useState(false);
  const [dayCareCapacityErr, setDayCareCapacityErr] = useState(false);
  const [yearlyAddErr, setyearlyAddErr] = useState(false);
  const [yearlyAddErr1, setyearlyAddErr1] = useState(false);

  const [scheduleAddErr, setscheduleAddErr] = useState(false);
  const [achievementCount, setAchievementCount] = useState([1]);
  const [achievementData, setAchievementData] = useState([]);

  const achievementArr = [];

  const [summerClassCount, setsummerClassCount] = useState([1]);
  const [summerClassData, setsummerClassData] = useState([]);

  const summerClassArr = [];


  const [dayCareFullDayErr, setDayCareFullDayErr] = useState(false);
  const [dayCareHalfDayErr, setDayCareHalfDayErr] = useState(false);

  const [preSchoolCapacity, setPreSchoolCapacity] = useState("");
  const [dayCareCapacity, setDayCareCapacity] = useState("");

  const [preSchoolRatioFirst, setPreSchoolRatioFirst] = useState("");
  const [preSchoolRatioSecond, setPreSchoolRatioSecond] = useState("");

  const [preSchoolRatioErr, setPreSchoolRatioErr] = useState(false);

  const [dcToddlerRatioFirst, setDcToddlerRatioFirst] = useState("");
  const [dcToddlerRatioSecond, setDcToddlerRatioSecond] = useState("");

  const [dcToddlerRatioErr, setDcToddlerRatioErr] = useState(false);

  const [dcInfantRatioFirst, setDcInfantRatioFirst] = useState("");
  const [dcInfantRatioSecond, setDcInfantRatioSecond] = useState("");

  const [dcInfantRatioErr, setDcInfantRatioErr] = useState(false);


  const [minAge, setMinAge] = useState("");
  const [minMonth, setMinMonth] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [maxMonth, setMaxMonth] = useState("");
  const [ageErr, setAgeErr] = useState("");

  const [minAgePreSchool, setMinAgePreSchool] = useState("");
  const [minMonthPreSchool, setMinMonthPreSchool] = useState("");
  const [maxAgePreSchool, setMaxAgePreSchool] = useState("");
  const [maxMonthPreSchool, setMaxMonthPreSchool] = useState("");
  const [ageErrPreSchool, setAgeErrPreSchool] = useState("");

  const [yearErr, setYearErr] = useState("");
  const [commonYear, setCommonYear] = useState([]);
  const [deleteBatchModal, setDeleteBatchModal] = useState(false);
  const [batchId, setBatchId] = useState(null);


  const [newCalenderStart1, setNewCalenderStart1] = useState("");
  const [newCalenderTo1, setNewCalenderTo1] = useState("");
  const [newCalenderEvent1, setNewCalenderEvENt1] = useState("");


  useEffect(() => {
    if(childCare === '2' || childCare === '3') {
      const { form } = props;
      let obj = {}
      scheduleArr.map((a, i) => {
        obj[`programme0${i + 1}`] = a.programm ? { label: a.programm, value: a.programm } : null
      })
      form.setFieldsValue(obj);
    }
  }, [scheduleArr, childCare]);


  useEffect(() => {
    if(childCare === '1' || childCare === '3') {
    const { form } = props;
    let obj = {}
    yearlyArr.map((a, i) => {
      obj[`event0${i + 1}`] = a.event ? { label: a.event, value: a.event } : null
    })
    setYearShow(yearlyArr)
    form.setFieldsValue(obj);
    console.log(form.getFieldsValue())
  }
  }, [yearlyArr, childCare]);


  useEffect(() => {
    if(childCare === '2' || childCare === '3') {
    const { form } = props;
    let obj = {}
    yearlyArr1.map((a, i) => {
      obj[`event1${i + 1}`] = a.event ? { label: a.event, value: a.event } : null
    })
    setYearShow1(yearlyArr1)

    form.setFieldsValue(obj);
  }
  }, [yearlyArr1, childCare]);



  useEffect(() => {
    initial = true;
  }, [props.lastUpdated]);

  useEffect(() => {
    let yearsNew = new Date().getFullYear();
    let yearDrop = [];
    for (let y = Number(yearsNew); y < Number(yearsNew) + 10; y++) {
      yearDrop.push({ id: `${y}-${y + 1}`, name: `${y}-${y + 1}` });
    }
    setCommonYear(yearDrop);
  }, []);

  
  useEffect(() => {
    let yearEst = new Date().getFullYear();
    let yrDrop = [];
    for (let y = Number(yearEst); y >= 1950; y--) {
      yrDrop.push({ label: `${y}`, value: `${y}` });
    }
    setYearEstablishedDropdown(yrDrop);
  }, []);

  useEffect(() => {
    if(childCare === '2' || childCare === '3') {
    if (String(minAge) && String(minMonth) && String(maxAge) && String(maxMonth)) {
      if (
        Number(minAge) * 12 + Number(minMonth) >=
        Number(maxAge) * 12 + Number(maxMonth)
      ) {
        setAgeErr("Minimum age should be less than maximum age");
      } else {
        setAgeErr("");
      }
    }
  } else {
    setAgeErr("");
  }
  }, [minAge, minMonth, maxAge, maxMonth, childCare]);

  useEffect(() => {
    if(childCare === '1' || childCare === '3') {
    if (
      minAgePreSchool &&
      minMonthPreSchool && String(minMonthPreSchool) &&
      maxAgePreSchool &&
      maxMonthPreSchool && String(maxMonthPreSchool)
    ) {
      if (
        Number(minAgePreSchool) * 12 + Number(minMonthPreSchool) >=
        Number(maxAgePreSchool) * 12 + Number(maxMonthPreSchool)
      ) {
        setAgeErrPreSchool("Minimum age should be less than maximum age");
      } else {
        setAgeErrPreSchool("");
      }
    }
  }else {
    setAgeErrPreSchool("");
  }
  }, [minAgePreSchool, minMonthPreSchool, maxAgePreSchool, maxMonthPreSchool, childCare]);

  useEffect(() => {
    if(childCare === '1' || childCare === '3') {
    if (preSchoolMin && preSchoolMax) {
      if (Number(preSchoolMin) < 500) {
        setPreSchoolErr("Minimum fee should be greater than 500");
      } else if (Number(preSchoolMin) > Number(preSchoolMax)) {
        setPreSchoolErr(true);
      } else {
        setPreSchoolErr(false);
      }
    } else {
      setPreSchoolErr(false);
    }
  } else {
    setPreSchoolErr(false);
  }
  }, [preSchoolMin, preSchoolMax, childCare]);


  const setOpenHolidayModalFn = (boolVal, key) => {
    globalHolidayKey = key;
    setOpenHolidayModal(boolVal)
  }


  useEffect(() => {
    if (preSchoolCapacity || dayCareCapacity) {
      if(childCare === '1' || childCare === '3') {
      if (preSchoolCapacity && Number(preSchoolCapacity) < 1) {
        setPreSchoolCapacityErr("Capacity should not be 0");
      } else if (preSchoolCapacity && Number(preSchoolCapacity) > 1000) {
        setPreSchoolCapacityErr("Capacity should be less than 1000");
      } else {
        setPreSchoolCapacityErr(false);
      }
    } else {
      setPreSchoolCapacityErr(false);
    }
    if(childCare === '2' || childCare === '3') {
      if (dayCareCapacity && Number(dayCareCapacity) < 1) {
        setDayCareCapacityErr("Capacity should not be 0");
      } else if (dayCareCapacity && Number(dayCareCapacity) > 1000) {
        setDayCareCapacityErr("Capacity should be less than 1000");
      } else {
        setDayCareCapacityErr(false);
      }
    } else {
      setDayCareCapacityErr(false);
    }
    }
  }, [preSchoolCapacity, dayCareCapacity, childCare]);


  useEffect(() => {
    if (preSchoolRatioFirst || preSchoolRatioSecond || dcToddlerRatioFirst || dcToddlerRatioSecond || dcInfantRatioFirst || dcInfantRatioSecond) {
      if(childCare === '1' || childCare === '3') {
      if (preSchoolRatioSecond && preSchoolRatioFirst) {
        if (Number(preSchoolRatioFirst) > Number(preSchoolRatioSecond)) {
          setPreSchoolRatioErr("Incorrect ratio");
        } else {
          setPreSchoolRatioErr(false);
        }
      } else {
        setPreSchoolRatioErr(false);
      }
    }else {
      setPreSchoolRatioErr(false);
    }
    if(childCare === '2' || childCare === '3') {
      if (dcToddlerRatioFirst && dcToddlerRatioSecond) {
        if (Number(dcToddlerRatioFirst) > Number(dcToddlerRatioSecond)) {
          setDcToddlerRatioErr("Incorrect ratio");
        } else {
          setDcToddlerRatioErr(false);
        }
      } else {
        setDcToddlerRatioErr(false);
      }
    } else {
      setDcToddlerRatioErr(false);
    }
    if(childCare === '2' || childCare === '3') {
      if (dcInfantRatioFirst && dcInfantRatioSecond) {
        if (Number(dcInfantRatioFirst) > Number(dcInfantRatioSecond)) {
          setDcInfantRatioErr("Incorrect ratio");
        } else {
          setDcInfantRatioErr(false);
        }
      } else {
        setDcInfantRatioErr(false);
      }

    } else {
      setDcInfantRatioErr(false);
    }

    }
  }, [preSchoolRatioFirst, preSchoolRatioSecond, dcToddlerRatioFirst, dcToddlerRatioSecond, dcInfantRatioFirst, dcInfantRatioSecond, childCare]);

  useEffect(() => {
    if (scheduleNewEvent && scheduleNewTime) {
      setscheduleAddErr(false)
    }
  }, [scheduleNewEvent, scheduleNewTime]);

  useEffect(() => {
    if (newCalenderEvent && newCalenderStart && newCalenderTo) {
      if (newCalenderTo && newCalenderStart) {
        if (newCalenderStart > newCalenderTo)
          setyearlyAddErr('Start date should be less than end date');
        else {
          setyearlyAddErr(false)
        }
      }
    }
  }, [newCalenderEvent, newCalenderStart, newCalenderTo]);


  useEffect(() => {
    if (newCalenderEvent1 && newCalenderStart1 && newCalenderTo1) {
      if (newCalenderTo1 && newCalenderStart1) {
        if (newCalenderStart1 > newCalenderTo1)
          setyearlyAddErr1('Start date should be less than end date');
        else {
          setyearlyAddErr1(false)
        }
      }
    }
  }, [newCalenderEvent1, newCalenderStart1, newCalenderTo1]);


  useEffect(() => {
    if(childCare === '2' || childCare === '3') {
    if (dcFullMin && dcFullMax) {
      if (Number(dcFullMin) < 500) {
        setDayCareFullDayErr("Minimum fee should be greater than 500");
      } else if (Number(dcFullMin) > Number(dcFullMax)) {
        setDayCareFullDayErr(true);
      } else {
        setDayCareFullDayErr(false);
      }
    } else {
      setDayCareFullDayErr(false);
    }
  } else {
    setDayCareFullDayErr(false);
  }
  }, [dcFullMin, dcFullMax, childCare]);

  useEffect(() => {
    if(childCare === '2' || childCare === '3') {
    if (dcHalfMin && dcHalfMax) {
      if (Number(dcHalfMin) < 500) {
        setDayCareHalfDayErr("Minimum fee should be greater than 500");
      } else if (Number(dcHalfMin) > Number(dcHalfMax)) {
        setDayCareHalfDayErr(true);
      } else {
        setDayCareHalfDayErr(false);
      }
    } else {
      setDayCareHalfDayErr(false);
    }
  }else {
    setDayCareHalfDayErr(false);
  }
  }, [dcHalfMin, dcHalfMax, childCare]);



  const handleSubmit = e => {
    setSubmit(true);
    setChildrenErr(false);
    e.preventDefault();
    if (!disableds) {
      var checkedReasons = reasons.filter(k => k.checked && k.label);

      props.form.validateFields(async (errors, values) => {
        if (!errors && checkedReasons.length > 0) {
          if (
            (childCare === "3" || childCare === "2") &&
            (workingArr.length === 0 ||
              (workingArr.length > 0 && !workingArr[0]))
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }
          if (
            (childCare === "3" || childCare === "1") &&
            ((preSchoolProgram.length === 0) || preSchoolProgram.includes(''))
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }

          if (
            (childCare === "2" || childCare === "3") &&
            (workingArr.length === 0 ||
              (workingArr.length > 0 && !workingArr[0]))
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }

          if (
            (childCare === "2" || childCare === "3")
          ) {
            if (workingArr.length !== Number(dcDropdown)) {
              setSubmit(true);
              setFinalSubmit(true);
              return;
            } else if (workingArr.length === Number(dcDropdown)) {
              const tst = workingArr.map(b => {
                if (!b.from_hour || !b.to_hour) {
                  setSubmit(true);
                  setFinalSubmit(true);
                  return false;
                }
                return 0;
              })
              if (tst.includes(false))
                return;
            }
          }

          if (
            (childCare === "3" || childCare === "2") &&
            activeSchedule.length === 0
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }

          if (
            (childCare === "3" || childCare === "1") &&
            yearlyArr.length === 0
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }

          if (
            (childCare === "3" || childCare === "2") &&
            yearlyArr1.length === 0
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }


          if (
            (childCare === "3" || childCare === "1") &&
            activePreSchoolSchedule.length === 0
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }

          if (
            (childCare === "1" || childCare === "3") &&
            workingArr1.length === 0
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }
          if (
            (childCare === "1" || childCare === "3")
          ) {
            const test = workingArr1.map((a, i) => {
              if (a.length !== Number(batchDropdown[i])) {
                setSubmit(true);
                setFinalSubmit(true);
                return false;
              } else if (a.length === Number(batchDropdown[i])) {
                const test1 = a.map(b => {
                  if (!b.from_hour || !b.to_hour) {
                    setSubmit(true);
                    setFinalSubmit(true);
                    return false;
                  }
                  return 0;
                })
                if (test1.includes(false))
                  return;
              }
            });
            if (test.includes(false))
              return;
          }


          if ((childCare === "1" || childCare === "3") && !activePayable) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }
          if (
            (childCare === "3" || childCare === "2") &&
            !activeDayCarePayable
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }
          setFinalSubmit(false);
          setCheckedReasonsErr(false);
          setSubmit(false);
          values.schedule = activeSchedule;
          values.preschool_schedule = activePreSchoolSchedule;

          values.payable = activePayable;
          values.daycare_payable = activeDayCarePayable;
          values.preschool_programme = preSchoolProgram.join(',');


          if (image) {
            values.payable_attachment_id = image;
            values.photo_deleted = false;
          } else {
            if (props.data && props.data.length > 0) {
              values.photo_deleted = true;
            }
          }

          var rsn = reasons.filter(k => k.checked && k.label).map(a => a.label);
          values.activity = rsn.join(",");
          values.children_min_age =
            Number(values.min_age_yr * 12) + Number(values.min_age_month) ||
            Number(minAge * 12) + Number(minMonth);
          values.children_max_age =
            Number(values.max_age_yr * 12) + Number(values.max_age_month) ||
            Number(maxAge * 12) + Number(maxMonth);

          values.preschool_children_min_age =
            Number(values.preschool_min_age_year * 12) +
            Number(values.preschool_min_age_month) ||
            Number(minAgePreSchool * 12) + Number(minMonthPreSchool);
          values.preschool_children_max_age =
            Number(values.preschool_max_age_year * 12) +
            Number(values.preschool_max_age_month) ||
            Number(maxAgePreSchool * 12) + Number(maxMonthPreSchool);
          values.preschool_teacher_student_ratio = `${preSchoolRatioFirst}:${preSchoolRatioSecond}`
          values.day_care_toddler_teacher_student_ratio = `${dcToddlerRatioFirst}:${dcToddlerRatioSecond}`
          values.day_care_infant_teacher_student_ratio = `${dcInfantRatioFirst}:${dcInfantRatioSecond}`

          if (values.children_min_age > values.children_max_age) {
            // setSubmit(true);
            setFinalSubmit(true);
            // setChildrenErr(true)
            return;
          }

          if (
            values.preschool_children_min_age >
            values.preschool_children_max_age
          ) {
            // setSubmit(true);
            setFinalSubmit(true);
            // setChildrenErr(true)
            return;
          }

          if (ageErr !== "") {
            // setSubmit(true);
            setFinalSubmit(true);
            setAgeErr("Enter proper age and month");
            return;
          }

          if (ageErrPreSchool !== "") {
            // setSubmit(true);
            setFinalSubmit(true);
            setAgeErrPreSchool("Enter proper age and month");
            return;
          }

          if (yearErr !== "") {
            setSubmit(true);
            setFinalSubmit(true);
            setYearErr("Year should be number, 4 digits long and less than current year");
            return;
          }
          if (
            dayCareFullDayErr ||
            dayCareHalfDayErr ||
            preSchoolErr ||
            preSchoolCapacityErr ||
            dayCareCapacityErr ||
            preSchoolRatioErr ||
            dcToddlerRatioErr ||
            dcInfantRatioErr
          ) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }

          // if (values.preschool_fee_min > values.preschool_fee_max) {
          //   setPreSchoolErr(true)
          //   setSubmit(true);
          //   setFinalSubmit(true);
          //   return;
          // }
          // if (values.daycare_full_day_fee_min > values.daycare_full_day_fee_max) {
          //   setDayCareFullDayErr(true)
          //   setSubmit(true);
          //   setFinalSubmit(true);
          //   return;
          // }
          // if (values.daycare_half_day_fee_min > values.daycare_half_day_fee_max) {
          //   setDayCareHalfDayErr(true)
          //   setSubmit(true);
          //   setFinalSubmit(true);
          //   return;
          // }

          values.syllabus_activities = tags.join(",");
          values.summer_classes_offered = summerclass;
          values.achievement = achievementData.map(a => a.split(',').join(' ')).join(',')
          values.summer_classes_offered_description = summerClassData.map(a => a.split(',').join(' ')).join(',')
          values.year_of_establishment = Number(establishedYear)

          delete values.min_age_yr;
          delete values.min_age_month;

          delete values.max_age_yr;
          delete values.max_age_month;

          delete values.weekdays;
          delete values.from_hour;
          delete values.to_hour;
          // number of working days filter and error checking
          let work1FilterTemp = workingArr1.filter(a => a);
          let workFilter = workingArr.filter(a => a);
          if (
            (childCare === "1" || childCare === "3") &&
            work1FilterTemp.length !== batch.length
          ) {
            setFinalSubmit(true);
            setSubmit(true);
            return;
          }

          const work1Filter = [];
          work1FilterTemp.map(d => {
            d.filter(x => x).map(y => work1Filter.push(y));
          });

          if (
            ((childCare === "1" || childCare === "3") &&
              work1Filter.length === 0) ||
            ((childCare === "2" || childCare === "3") &&
              workFilter.length === 0)
          ) {
            setFinalSubmit(true);
            setSubmit(true);
            return;
          }
          var workingFilter = [...workingArr, ...work1Filter];
          var workingDaysFilter = workingFilter.filter(
            a => a && a.weekdays && (!a.from_hour || !a.to_hour)
          );
          var workingErr = workingFilter.filter(a => a && (a.err || a.err1));

          var workingArrFilter = workingFilter.filter(
            a => a && a.weekdays && a.from_hour && a.to_hour
          );

          if (workingErr.length > 0 || workingDaysFilter.length > 0) {
            setFinalSubmit(true);
            return;
          }

          if (childCare === "2" || childCare === "3") {
            var scheduleFilter = [...scheduleArr];
            var scheduleErr = scheduleFilter.filter(a => a.err);
            if (scheduleErr.length > 0) {
              setFinalSubmit(true);
              return;
            }
            var scheduleArrFilter = scheduleFilter.filter(
              a => a.start_time && a.programm
            );
          }
          var yearFilter = [...yearlyArr, ...yearlyArr1];
          var yearsErr = yearFilter.filter(a => a.err);
          if (yearsErr.length > 0) {
            setFinalSubmit(true);
            return;
          }
          var yearlyArrFilter = yearFilter.filter(
            a => a.start_date && a.to_date && a.event && a.year
          );

          var form = props.formData;
          delete form.director_photo_id;
          if (license) {
            form.license_number = licenseNumber;
          } else {
            form.license_number = "";
          }
          form.business_name = values.child_care_name;

          form.child_care_providers = childCare;

          setDisabled(true);
          props.setContentChanged(false)
          if (props.data && props.data.length > 0) {
            console.log(1)

            values.id = props.data[0].id;
            props.updateForms({
              data: values,
              cb: err => {
                if (!err) {
                  // setReasons(activity)
                  props.updateWorkinDaysApi({
                    data: {
                      working_day: workingArrFilter
                    },
                    cb: err => {
                      if (!err) {
                        props.updateYearlyCalender({
                          data: {
                            yearly_calendar: yearlyArrFilter
                          },
                          cb: err => {
                            if (!err) {
                              props.updateScheduleApi({
                                data: {
                                  schedule_for_the_day:
                                    childCare === "1" ? [] : scheduleArrFilter
                                },
                                cb: err => {
                                  if (!err) {
                                    props.formdataUpdate({
                                      data: form,
                                      cb: err => {
                                        if (!err) {
                                          handleContinue();
                                        } else {
                                          responseToast(err);
                                        }
                                      }
                                    });
                                  } else {
                                    responseToast(err);
                                  }
                                }
                              });
                            } else {
                              responseToast(err);
                            }
                          }
                        });
                      } else {
                        responseToast(err);
                      }
                    }
                  });
                } else {
                  responseToast(err);
                }
              }
            });
          } else
            props.addForms({
              data: values,
              cb: err => {
                if (!err) {
                  props.workinDaysApi({
                    data: workingArrFilter,
                    cb: err => {
                      if (!err) {
                        props.yearlyCalender({
                          data: yearlyArrFilter,
                          cb: err => {
                            if (!err) {
                              props.scheduleApi({
                                data:
                                  childCare === "1" ? [] : scheduleArrFilter,
                                cb: err => {
                                  if (!err) {
                                    props.formdataUpdate({
                                      data: form,
                                      cb: err => {
                                        if (!err) {
                                          handleContinue();
                                        } else {
                                          responseToast(err);
                                        }
                                      }
                                    });
                                  } else {
                                    responseToast(err);
                                  }
                                }
                              });
                            } else {
                              responseToast(err);
                            }
                          }
                        });
                      } else {
                        responseToast(err);
                      }
                    }
                  });
                } else {
                  responseToast(err);
                }
              }
            });
        } else {
          setFinalSubmit(true);
        }
        if (checkedReasons.length === 0) {
          setCheckedReasonsErr(true);
        }
        if (checkedReasons.length > 0) setCheckedReasonsErr(false);
      });
    }
  };

  const handleContinue = () => {
    props.setComponent("Location");
  };

  const handleReasonCheck = i => {
    props.setContentChanged(true)

    // let value = reasons.slice();
    let value = [...reasons];
    // let check = value.filter(a => a.checked);
    // if (check.length === 0) {
    //   setCheckedReasonsErr(false);
    // } else {
    //   setCheckedReasonsErr(true);
    // }
    setCheckedReasonsErr(false);
    value[i].checked = !value[i].checked;

    setReasons(value);
  };

  const yearlyCalenderFn = (index, object, value) => {
    props.setContentChanged(true)

    let dummyArr = [...yearlyArr];
    dummyArr[index] = dummyArr[index] || {};
    dummyArr[index][object] = value;
    dummyArr[index]["year"] = yearVal;
    dummyArr[index]["school_type"] = 'preschool';
    // if (dummyArr[index] && !dummyArr[index].event) {
    //   dummyArr[index].err = "Select event";
    // }
    if (
      dummyArr[index].event &&
      (!dummyArr[index].start_date || !dummyArr[index].to_date)
    ) {
      dummyArr[index].err = "Date is required";
    } else if (dummyArr[index].start_date > dummyArr[index].to_date) {
      dummyArr[index].err = true;
    } else {
      dummyArr[index].err = false;
    }
    // if (dummyArr[index] && !dummyArr[index].event) {
    //   dummyArr[index].err = "Select event";
    // }
    setYearlyArr(dummyArr);
    if (sameCalender) {
      let dcYearCopy = cloneDeep(dummyArr);
      let dayCareCopy = cloneDeep(yearlyArr1);
      if (globalCalender) {
        for (let lp = 0; lp < dcYearCopy.length; lp++) {
          if (dcYearCopy[lp] && (dcYearCopy[lp].start_date === dayCareCopy[lp].start_date || dcYearCopy[lp].to_date === dayCareCopy[lp].to_date || dcYearCopy[lp].event === dayCareCopy[lp].event)) {
            dayCareCopy[lp] = dcYearCopy[lp];
            dayCareCopy[lp].id = yearlyArr1[lp].id;
            dayCareCopy[lp].school_type = 'day_care';

          }
        }
        setYearlyArr1(dayCareCopy);
      } else {
        dcYearCopy.map(d => { d.school_type = 'day_care'; delete d.id; return 0; })
        setYearlyArr1(dcYearCopy);
      }
    }
  };


  const yearlyCalenderPreSchool = (index, object = {}) => {
    props.setContentChanged(true)
    let dummyArr = cloneDeep(yearlyArr);
    dummyArr[index] = dummyArr[index] || {}
    dummyArr[index] = { ...dummyArr[index], ...object };
    dummyArr[index]["year"] = yearVal;
    dummyArr[index]["school_type"] = 'preschool';
    setYearlyArr(dummyArr);
    if (sameCalender) {
      let dcYearCopy = cloneDeep(dummyArr);
      let dayCareCopy = cloneDeep(yearlyArr1);
      if (globalCalender) {
        for (let lp = 0; lp < dcYearCopy.length; lp++) {
          if (dcYearCopy[lp] && (dcYearCopy[lp].start_date === dayCareCopy[lp].start_date || dcYearCopy[lp].to_date === dayCareCopy[lp].to_date || dcYearCopy[lp].event === dayCareCopy[lp].event)) {
            dayCareCopy[lp] = dcYearCopy[lp];
            dayCareCopy[lp].id = yearlyArr1[lp].id;
            dayCareCopy[lp].school_type = 'day_care';

          }
        }
        setYearlyArr1(dayCareCopy);
      } else {
        dcYearCopy.map(d => { d.school_type = 'day_care'; delete d.id; return 0; })
        setYearlyArr1(dcYearCopy);
      }
    }
  }

  useEffect(() => {
    if (sameCalender && !globalCalender) {

      let preYearCopy = cloneDeep(yearlyArr);
      let dcYearCopy = cloneDeep(yearlyArr1);

      dcYearCopy.map((a, x) => {
        if (a.id) {
          props.deleteYearApi(a);
          delete dcYearCopy[x];
        } else {
          delete dcYearCopy[x];
        }
      })

      dcYearCopy = cloneDeep(yearlyArr);

      dcYearCopy.map(d => { d.school_type = 'day_care'; delete d.id; return 0; })


      setYearShow1(yearShow)
      setYearlyArr1(dcYearCopy);
    }
  }, [sameCalender])


  const yearlyCalenderFn1 = (index, object, value) => {
    props.setContentChanged(true)

    let dummyArr = [...yearlyArr1];
    dummyArr[index] = dummyArr[index] || {};
    dummyArr[index][object] = value;
    dummyArr[index]["year"] = yearVal;
    dummyArr[index]["school_type"] = 'day_care';

    if (
      dummyArr[index].event &&
      (!dummyArr[index].start_date || !dummyArr[index].to_date)
    ) {
      dummyArr[index].err = "Date is required";
    } else if (dummyArr[index].start_date > dummyArr[index].to_date) {
      dummyArr[index].err = true;
    } else {
      dummyArr[index].err = false;
    }
    // if (dummyArr[index] && !dummyArr[index].event) {
    //   dummyArr[index].err = "Select event";
    // }
    setYearlyArr1(dummyArr);
  };


  const yearlyCalenderDaycare = (index, object = {}) => {
    props.setContentChanged(true)
    let dummyArr = cloneDeep(yearlyArr1);
    dummyArr[index] = dummyArr[index] || {}
    dummyArr[index] = { ...dummyArr[index], ...object };
    dummyArr[index]["year"] = yearVal;
    dummyArr[index]["school_type"] = 'day_care';
    setYearlyArr1(dummyArr);
  }






  const scheduleDay = (index, object, value) => {
    props.setContentChanged(true)

    let dummyArr = [...scheduleArr];
    dummyArr[index] = dummyArr[index] || {};
    dummyArr[index][object] = value;
    if ((dummyArr[index].programm !== "" && !dummyArr[index].start_time) || (!dummyArr[index].programm && dummyArr[index].start_time !== "")) {
      dummyArr[index].err = true;
    } else {
      dummyArr[index].err = false;
    }
    setScheduleArr(dummyArr);
  };

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      if (props.data[0].summer_classes_offered) {
        setsummerclass("yes");
      } else {
        setsummerclass("no");
      }

      props.data[0].min_age_month = props.data[0].children_min_age > 0 ? (
        props.data[0].children_min_age % 12
      ).toString() : '';
      props.data[0].min_age_yr = props.data[0].children_min_age > 0 ? Math.floor(
        props.data[0].children_min_age / 12
      ).toString() : '';
      props.data[0].max_age_month = props.data[0].children_max_age > 0 ? (
        props.data[0].children_max_age % 12
      ).toString() : '';
      props.data[0].max_age_yr = props.data[0].children_max_age > 0 ? Math.floor(
        props.data[0].children_max_age / 12
      ).toString() : '';

      props.data[0].preschool_min_age_month = props.data[0].preschool_children_min_age > 0 ? (
        props.data[0].preschool_children_min_age % 12
      ).toString() : '';
      props.data[0].preschool_min_age_year = props.data[0].preschool_children_min_age > 0 ? Math.floor(
        props.data[0].preschool_children_min_age / 12
      ).toString() : '';
      props.data[0].preschool_max_age_month = props.data[0].preschool_children_max_age ? (
        props.data[0].preschool_children_max_age % 12
      ).toString() : '';
      props.data[0].preschool_max_age_year = props.data[0].preschool_children_max_age ? Math.floor(
        props.data[0].preschool_children_max_age / 12
      ).toString() : '';

      setMinMonth(props.data[0].children_min_age % 12);
      setMinAge(Math.floor(props.data[0].children_min_age / 12));
      setMaxMonth(props.data[0].children_max_age % 12);
      setMaxAge(Math.floor(props.data[0].children_max_age / 12));

      setMinMonthPreSchool(props.data[0].preschool_children_min_age % 12);
      setMinAgePreSchool(
        Math.floor(props.data[0].preschool_children_min_age / 12)
      );
      setMaxMonthPreSchool(props.data[0].preschool_children_max_age % 12);
      setMaxAgePreSchool(
        Math.floor(props.data[0].preschool_children_max_age / 12)
      );

      setPreSchoolCapacity(props.data[0].preschool_total_capacity);
      setDayCareCapacity(props.data[0].day_care_total_capacity);

      props.data[0].year_of_establishment = props.data[0].year_of_establishment.toString();
      props.data[0][`year of establishment`] = { label: props.data[0].year_of_establishment.toString(), value: props.data[0].year_of_establishment.toString() };

      setEstablishedYear(props.data[0].year_of_establishment.toString())
      setPreSchoolMin(props.data[0].preschool_fee_min);
      setPreSchoolMax(props.data[0].preschool_fee_max);
      setDcFullMin(props.data[0].daycare_full_day_fee_min);
      setDcFullMax(props.data[0].daycare_full_day_fee_max);
      setDcHalfMin(props.data[0].daycare_half_day_fee_min);
      setDcHalfMax(props.data[0].daycare_half_day_fee_max);
      setDcInfantRatioFirst(props.data[0].day_care_infant_teacher_student_ratio.split(':')[0])
      setDcInfantRatioSecond(props.data[0].day_care_infant_teacher_student_ratio.split(':')[1])

      setDcToddlerRatioFirst(props.data[0].day_care_toddler_teacher_student_ratio.split(':')[0])
      setDcToddlerRatioSecond(props.data[0].day_care_toddler_teacher_student_ratio.split(':')[1])

      setPreSchoolRatioFirst(props.data[0].preschool_teacher_student_ratio.split(':')[0])
      setPreSchoolRatioSecond(props.data[0].preschool_teacher_student_ratio.split(':')[1])

      if (props.data[0].achievement) {
        setAchievementData(props.data[0].achievement.split(','))
        let count = [];
        props.data[0].achievement.split(',').map(a => count.push(a))
        setAchievementCount(count)
      }
      if (props.data[0].summer_classes_offered_description) {
        setsummerClassData(props.data[0].summer_classes_offered_description.split(','))
        let count = [];
        props.data[0].summer_classes_offered_description.split(',').map(a => count.push(a))
        setsummerClassCount(count)
      }
      if (props.data[0].preschool_programme === '') {
        setPreSchoolProgram([])
      } else
        setPreSchoolProgram(props.data[0].preschool_programme.split(','))
      props.form.setFieldsValue(props.data[0]);
      setActiveSchedule(props.data[0].schedule);
      // setActivePreSchoolSchedule(props.data[0].preschool_schedule);

      setActivePayable(props.data[0].payable);
      // setActiveDayCarePayable("Monthly");

      setImage(props.data[0].payable_attachment_id);
    }
    if (props.getWorkingDayData.length > 0) {
      let pre_school_Arr_temp = props.getWorkingDayData.filter(
        a => a.school_type === "preschool"
      );
      const temp = groupBy(pre_school_Arr_temp, o => o.batch_slug_name);
      delete temp[""];
      const temp1 = Object.values(temp);

      const pre_school_Arr = Object.keys(temp)
        .map(function (a) {
          return { key: a, value: +a.split("batch")[1] };
        })
        .sort(function (a, b) {
          return a.value - b.value;
        })
        .map(function (a) {
          return a.key;
        })
        .map((d, i) => d === `batch${i + 1}` && temp[d]);

      let daycare_Arr = props.getWorkingDayData.filter(
        a => a.school_type === "day_care"
      );
      let preschoolData = [];
      let daycareData = [];
      pre_school_Arr.map((d, i) => {
        preschoolData[i] = [];
        d &&
          d.map(a => {
            if (a.weekdays === "mon_fri") {
              preschoolData[i][0] = a;
            }
            if (a.weekdays === "sat") {
              preschoolData[i][1] = a;
            }
            if (a.weekdays === "sun") {
              preschoolData[i][2] = a;
            }
          });
      });

      let dummyBatch = [];
      preschoolData.length > 0
        ? preschoolData.map(d => dummyBatch.push(1))
        : dummyBatch.push(1);
      daycare_Arr.map(a => {
        if (a.weekdays === "mon_fri") {
          daycareData[0] = a;
        }
        if (a.weekdays === "sat") {
          daycareData[1] = a;
        }
        if (a.weekdays === "sun") {
          daycareData[2] = a;
        }
      });
      setWorkingArr(daycareData);
      setWorkingArr1(preschoolData);
      let dummyCount = [];
      preschoolData.map((a, i) => a && dummyCount.push(a.length));
      if (preschoolData.length === 0) {
        dummyCount[0] = 1
      }
      setBatchDropdown(dummyCount)
      if (daycareData.length > 0)
        setDcDropdown(daycareData.length)
      setBatch(dummyBatch);
    }

    if (props.getScheduleDayData.length > 0) {
      setScheduleArr(props.getScheduleDayData);
    }
  }, [
    props.data,
    childCare,
    props.getWorkingDayData,
    props.getScheduleDayData
  ]);

  useEffect(() => {
    if (props.data.length > 0 && initial) {
      initial = false;
      // setReasons(activity);
      var dummyAct = props.data[0].activity.split(",");
      var availAct = [
        { label: "Singing", checked: false },
        { label: "SummerCamps", checked: false },
        { label: "Camping", checked: false },
        { label: "Football", checked: false },
        { label: "Cricket", checked: false },
        { label: "Speaking", checked: false },
        { label: "Painting", checked: false },
        { label: "Role playing", checked: false },
        { label: "Dancing", checked: false },
        { label: "Story telling", checked: false },
        { label: "Field tours", checked: false },
        { label: "Swimming", checked: false },
        { label: "Music", checked: false },
        { label: "Drawing", checked: false },
        { label: "Fancy dress", checked: false },
        { label: "Arts and crafts", checked: false }
      ];

      for (var lp = 0; lp < availAct.length; lp++) {
        if (dummyAct.includes(availAct[lp].label)) {
          availAct[lp].checked = true;
        }
      }

      dummyAct.map((d, i) => {
        if (!DEFAULT_ACTIVITIES.includes(d)) {
          availAct = [...availAct, { label: d, checked: true }];
        }
      });
      setReasons(availAct.reduce((acc, curr) => acc.concat([{ ...curr }]), []));
    }
  }, [props.data]);

  useEffect(() => {

    if (yearlyArr.length === 0) {
      let calenderArr = [];
      holiday_list.map(a => {
        calenderArr.push({ start_date: a.start, to_date: a.end, event: a.label, year: commonYear.length > 0 && commonYear[0].id, school_type: 'preschool' })
      })


      setYearVal(commonYear.length > 0 && commonYear[0].id)
      setYearlyArr(calenderArr);
      setYearShow(calenderArr);

      const { form } = props;
      let obj = {}
      calenderArr.map((a, i) => {
        obj[`event0${i + 1}`] = a.event ? { label: a.event, value: a.event } : null
      })
      form.setFieldsValue(obj);

    }
    if (yearlyArr1.length === 0) {
      let calenderArr1 = [];
      holiday_list.map(a => {
        calenderArr1.push({ start_date: a.start, to_date: a.end, event: a.label, year: commonYear.length > 0 && commonYear[0].id, school_type: 'day_care' })
      })
      setYearlyArr1(calenderArr1);
      setYearShow1(calenderArr1);
      setYearVal(commonYear.length > 0 && commonYear[0].id)
      let obj = {}

      calenderArr1.map((a, i) => {
        obj[`event1${i + 1}`] = a.event ? { label: a.event, value: a.event } : null
      })
      props.form.setFieldsValue(obj);
    }
  }, [yearlyArr, yearlyArr1, childCare])

  useEffect(() => {
    if (props.getScheduleDayData.length > 0) {
      var scheduleNew = props.getScheduleDayData.map(a => 1);
      if (scheduleNew > scheduleShow) {
        setScheduleShow(scheduleNew);
      }
      var availSch = [...scheduleDropdown];
      let schedule_default = availSch.map(a => a.value);
      props.getScheduleDayData.map((d, i) => {
        if (!schedule_default.includes(d.programm)) {
          availSch = [
            ...availSch,
            { value: d.programm, label: d.programm, disabled: false }
          ];
        }
      });
      // let uniqueSchedule = [];
      // availSch.map(d => {
      //   if(!uniqueSchedule.includes(d)) {
      //     uniqueSchedule.push(d);
      //   }
      // })
      setScheduleDropdown(availSch);
    }
  }, [props.getScheduleDayData]);

  useEffect(() => {
    if (props.getYearlyCalenderData.length > 0) {
      var preYrNew = props.getYearlyCalenderData.filter(a => a.school_type === 'preschool');
      var dcYrNew = props.getYearlyCalenderData.filter(a => a.school_type === 'day_care');

      var availYr = [...yearDropdown];
      let year_default = availYr.map(a => a.value);
      props.getYearlyCalenderData.map((d, i) => {
        if (!year_default.includes(d.event)) {
          availYr = [
            ...availYr,
            { value: d.event, label: d.event, disabled: false }
          ];
          year_default.push(d.event)
        }
      });
      setYearDropdown(availYr);
    }

    if (props.getYearlyCalenderData.length > 0) {
      let preschoolYearData = props.getYearlyCalenderData.filter(d => d.school_type === 'preschool')
      let daycareYearData = props.getYearlyCalenderData.filter(d => d.school_type === 'day_care')
      if (preYrNew.length > yearShow.length) {
        setYearShow(preYrNew);
      }

      if (dcYrNew.length > yearShow1.length) {
        setYearShow1(dcYrNew);
      }



      setYearlyArr(preschoolYearData);
      setYearlyArr1(daycareYearData);
      let sameCount = 0;
      let same = 0;
      if (childCare === "3") {

        if (preschoolYearData.length === daycareYearData.length) {
          same = preschoolYearData.length
          for (let loop = 0; loop < preschoolYearData.length; loop++) {
            if ((preschoolYearData[loop].start_date === daycareYearData[loop].start_date) && preschoolYearData[loop].to_date === daycareYearData[loop].to_date && preschoolYearData[loop].start_date === daycareYearData[loop].start_date && (preschoolYearData[loop].event === daycareYearData[loop].event)) {
              sameCount++;
            }
          }
        }
        if (sameCount > 0 && same > 0 && sameCount === same) {
          setSameCalender(true)
          globalCalender = true;
        } else {
          setSameCalender(false)
          globalCalender = false;
        }
      }

      props.getYearlyCalenderData[0] && props.getYearlyCalenderData[0].year && setYearVal(props.getYearlyCalenderData[0].year);
    } else {
      setYearlyArr([])
      setYearlyArr1([])

    }

  }, [props.getYearlyCalenderData]);

  // type of Childcare
  useEffect(() => {
    setChildCare(props.childCare);
  }, [props.childCare]);

  // licence
  useEffect(() => {
    if (props.formData && props.formData.license_number) {
      setLicense(true);
      setLicenseNumber(props.formData.license_number);
    }
  }, [props.formData]);

  // year of establishment
  useEffect(() => {
    if (establishedYear) {
      if (
        Number(establishedYear) > new Date().getFullYear() ||
        Number(establishedYear) < 1947
      ) {
        setYearErr("Year should be number, 4 digits long and less than current year");
      } else {
        setYearErr("");
      }
    }
  }, [establishedYear]);

  useEffect(() => {
    if ((childCare === "1" || childCare === "3") && workingArr1.length === 0) {
      let dummyBatch = [];
      dummyBatch[0] = 1;
      setBatch(dummyBatch);

    }
  }, [childCare]);

  // number of working days

  const workingDaysFn = (index, object, value) => {
    props.setContentChanged(true)

    let dummyArr = [...workingArr];
    dummyArr[index] = dummyArr[index] || {};
    dummyArr[index][object] = value;
    dummyArr[index].school_type = "day_care";
    if (index === 0) {
      dummyArr[index]["weekdays"] = "mon_fri";
    } else if (index === 1) {
      dummyArr[index]["weekdays"] = "sat";
    } else {
      dummyArr[index]["weekdays"] = "sun";
    }
    if (index === 1 || index === 2) {
      globalId = null;
      let chk = { ...holidayCheck };
      chk[`dcholiday${index === 1 ? 3 : 4}`] = false;
      setHolidayCheck(chk)
      globalHolidayKey = null;
      globalIndex = null;
    }
    if (dummyArr[index].from_hour > dummyArr[index].to_hour) {
      dummyArr[index].err = "Start time should be less than end time";
    } else {
      dummyArr[index].err = false;
    }

    if (dummyArr[index].id) {
      if (dummyArr[index].from_hour === "" && dummyArr[index].to_hour === "") {
        props.deleteWorkingDay(dummyArr[index]);
        dummyArr[index].err1 = false;
        dummyArr[index].err = "From and to time is required";
        delete dummyArr[index].weekdays;
        delete dummyArr[index].id;
        delete dummyArr[index];
      }
    } else {
      if (dummyArr[index].from_hour === "" && dummyArr[index].to_hour === "") {
        dummyArr[index].err1 = false;
        dummyArr[index].err = "From and to time is required";
      }
    }
    setWorkingArr(dummyArr);
  };

  const workingDaysFn1 = (id, index, object, value, batch) => {
    props.setContentChanged(true)

    let dummyArr = [...workingArr1];
    dummyArr[id] = dummyArr[id] ? [...dummyArr[id]] : [];
    dummyArr[id][index] = dummyArr[id][index] || {};
    dummyArr[id][index][object] = value;
    dummyArr[id][index]["batch_slug_name"] = batch;
    dummyArr[id][index].school_type = "preschool";
    if (index === 0) {
      dummyArr[id][index]["weekdays"] = "mon_fri";
    } else if (index === 1) {
      dummyArr[id][index]["weekdays"] = "sat";
    } else {
      dummyArr[id][index]["weekdays"] = "sun";
    }
    if (index === 1 || index === 2) {
      globalId = null;
      let chk = { ...holidayCheck };
      chk[`holiday${index === 2 ? '1' : ''}${id}`] = false;
      setHolidayCheck(chk)
      globalHolidayKey = null;
      globalIndex = null;
    }
    if (dummyArr[id][index].from_hour > dummyArr[id][index].to_hour) {
      dummyArr[id][index].err = "Start time should be less than end time";
    } else {
      dummyArr[id][index].err = false;
    }
    if (dummyArr[id][index].id) {
      if (
        dummyArr[id][index].from_hour === "" &&
        dummyArr[id][index].to_hour === ""
      ) {
        props.deleteWorkingDay(dummyArr[id][index]);
        delete dummyArr[id][index].id;
        delete dummyArr[id][index].weekdays;
        dummyArr[id][index].err = "From and to time is required";
      }
    } else {
      if (
        dummyArr[id][index].from_hour === "" &&
        dummyArr[id][index].to_hour === ""
      ) {
        dummyArr[id][index].err = "From and to time is required";
      }
    }

    setWorkingArr1(dummyArr);
  };

  return (
    <>
      <CardBody style={{ paddingLeft: "50px" }}>
        <div className="cardHeader">
          <CardTitle className="cardTitle">Understanding you</CardTitle>
          <CardText className="cardProfileText">
            Complete your basic profile, upload pictures and videos, list down
            availability and many more. This helps the parent to get a better
            understanding on what you provide and helps them to decide.
          </CardText>
        </div>
      </CardBody>
      <Form className="form-horizontal" onSubmit={handleSubmit}>
        {/* <Row className="radioBtn marginBottom20"> */}

        <CardBody className="formContent">
          <Row style={{ paddingLeft: 15, marginBottom: 50, alignItems: 'center' }}>
            <Col xs="4" className="formInputName">What type of Childcare do you provide? *</Col>
            <Col>
              <Row style={{ marginTop: 10 }}>
                <RadioButton
                  name="Pre-School"
                  label="Pre-School"
                  xsval="4"
                  form={props.form}
                  checked={childCare === "1"}
                  className="marginRight-24"
                  onClick={() => {
                    props.setContentChanged(true)
                    setChildCare("1")
                  }}
                />
                {console.log(yearVal)}
                <RadioButton
                  name="Daycare"
                  label="Daycare"
                  xsval="4"
                  form={props.form}
                  checked={childCare === "2"}
                  onClick={() => {
                    props.setContentChanged(true)
                    setChildCare("2")
                  }}
                  className="marginRight-24"
                />
                <RadioButton
                  name="Both"
                  label="Both"
                  xsval="4"
                  form={props.form}
                  checked={childCare === "3"}
                  onClick={() => {
                    props.setContentChanged(true)

                    setChildCare("3")
                  }}
                  className="marginRight-60"
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 15, marginBottom: 50, alignItems: 'center' }}>
            <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
              Do you have a license? *
              </Col>
            <Row style={{ paddingLeft: 15, marginTop: 10, width: "62%" }}>
              <RadioButton
                name="Yes"
                label="Yes"
                form={props.form}
                xsval="4"
                checked={props.formData && license}
                onClick={() => {
                  props.setContentChanged(true)
                  setLicense(true)
                }}
              />
              <RadioButton
                name="No"
                form={props.form}
                label="No"
                xsval="4"
                checked={props.formData && !license}
                onClick={() => {
                  props.setContentChanged(true)
                  setLicense(false)
                }}
              />
            </Row>
            {props.formData && license && (
              <Row style={{ width: "100%" }}>
                <FormInput
                  form={props.form}
                  name="licence"
                  placeholder="Please Enter your License Number"
                  classType="margin0"
                  defaultValue={licenseNumber}
                  label="  "
                  isRequired={true}
                  onChange={val => {
                    props.setContentChanged(true)
                    setLicenseNumber(val)
                  }}
                  colSpan="8"
                  paddingLeft={25}
                  rightPadding={10}
                  paddingLeftFirst={15}
                />
              </Row>
            )}
          </Row>
          {/* </Row> */}
          <Row style={{ paddingLeft: 15 }}>
            <FormInput
              form={props.form}
              name="child_care_name"
              placeholder="Name of the Childcare"
              label="Name of the Childcare"
              classtype="column"
              isName
              alphanumeric={true}
              defaultValue={props.formData && props.formData.business_name}
              maxLength={100}
              paddingLeft={10}
              responsive
              setContentChanged={props.setContentChanged}
            // disabled={true}

            />
          </Row>
          <Row style={{ paddingLeft: 15, marginTop: -51 }}>
            <FormInput
              form={props.form}
              name="tag_line"
              placeholder="Tagline, eg. Nike-Just Do it. (optional)"
              label="  "
              isRequired={false}
              classtype="column"
              maxLength={254}
              responsive
              paddingLeft={10}
              setContentChanged={props.setContentChanged}

            />
          </Row>
          <Row style={{ paddingLeft: 15 }}>

            {/* <FormInput
              form={props.form}
              name="year_of_establishment"
              placeholder="Enter year"
              label="Year of Establishment"
              classtype="column"
              onChange={v => {
                props.setContentChanged(true)

                setEstablishedYear(v)
              }}
              year
              yearErr={yearErr}
              responsive
              paddingLeft={10}
            /> */}
            <Col xs='4' className='formInputName'>Year of Establishment</Col>
            <Select
              form={props.form}
              label=""
              colSpan="4"
              marginRight="0px"
              name={`year of establishment`}
              paddingLeft={6}
              paddingRight={10}
              marginLeft={-27}
              placeholder='Enter Year'
              isOnlySelect
              options={yearEstablishedDropdown}

              // defaultValue={
              //   scheduleArr.length > 0 && scheduleArr[id]
              //     ? scheduleArr[id].programm
              //     : null
              // }
              onChange={val => setEstablishedYear(val.value)}

              isResponsive
              // msg="Programme is required"
              isRequired={true}
            />
          </Row>
          <Row className="achievement" style={{ paddingLeft: 15 }}>
            {/* <FormInput
                form={props.form}
                name="achievement"
                placeholder="Mention any awards or recognition"
                label="Achievement"
                type="textarea"
                classtype="column"
                isRequired={false}
                responsive
                paddingLeft={10}
              /> */}
            <Col xs='4' className='formInputName'>Achievement </Col>
            <Col>
              <Row>
                {achievementCount.map((val, id) => {

                  achievementArr.push(
                    <Col xs='6' className='buttonSchool' style={{ paddingLeft: 20, paddingRight: 20, marginRight: 20, marginBottom: 20 }}>
                      <Input
                        type="text"
                        value={achievementData[id] || ''}
                        onChange={e => {
                          props.setContentChanged(true)

                          let achieve = [...achievementData];
                          achieve[id] = (e.target.value);
                          setAchievementData(achieve)
                        }}
                        placeholder="Enter Recognition received"
                      />
                      <span
                        className="trashIcon"
                        onClick={() => {
                          let achieve = [...achievementData];

                          let count = [...achievementCount];
                          if (achieve.length === count.length)
                            props.setContentChanged(true)
                          achieve.splice(id, 1);
                          setAchievementData(achieve)
                          if (count.length > 1)
                            count.pop()
                          setAchievementCount(count)
                        }}
                        style={{ position: 'absolute', right: -30 }}
                      >
                        <img
                          className="trash"
                          src={Trash}
                          alt="trash"
                        />
                        <img
                          className="trashHover"
                          src={TrashHover}
                          alt="trash"
                        />
                      </span>
                    </Col>
                  );
                  return '';
                }
                )}
                {achievementArr}
                <Col style={{ paddingLeft: 40, marginLeft: 10 }}>
                  <div className="plusIcon" onClick={() => {
                    let ct = [...achievementCount];
                    if (achievementCount.length === achievementData.length) {
                      ct.push(1);
                      setAchievementCount(ct)
                    }
                  }}
                  >
                    <span class="iconify" data-icon="fe:plus" data-inline="false"></span>
                  </div>
                </Col>
              </Row>
            </Col>

          </Row>



        </CardBody>




        {/* Day care */}

        {(childCare === "2" || childCare === "3") && (
          <div className="animateDiv">
            {childCare === "3" && <div className="animateDiv-header" onClick={() => setDcActive(!dcActive)}>
              <span> Day Care</span>
              <div className={`animateDiv-icon ${dcActive && 'active'}`}>


                <span class="iconify minus" data-icon="ps:minus" data-inline="false"></span>
                <span class="iconify plus" data-icon="zmdi:plus" data-inline="false"></span>
              </div>
            </div>}
            <div className={`borderDashed ${dcActive && 'active'}`}>
              <CardBody className="formContent">
                <h4 className="sub-heading">When do you Operate</h4>
                <Row>
                  <Row style={{ paddingLeft: 45, width: "100%", marginBottom: 20 }}>
                    <Row style={{ paddingLeft: 15, width: "33.5%" }} className="formInputName">
                      Number of working days *

                    </Row>
                    <Col style={{ paddingLeft: 0 }}>
                      <Col>
                        <div className="flex" style={{ marginLeft: -15 }}>
                          <Dropdown
                            form={props.form}
                            label=""
                            xsVal="6"
                            isRequired={true}
                            options={weekdaysSelect}
                            name={`selectDaycare`}
                            placeholder="Select"
                            onChange={v => {

                              props.setContentChanged(true)
                              setDcDropdown(v)
                              let wA = [...workingArr]
                              if(v == 2) {
                                wA[2] = undefined;
                              }
                              if(v == 1) {
                                wA[1] = undefined;
                                wA[2] = undefined;
                              }
                              setWorkingArr(wA.filter(a => a ))
                            }}
                            defaultValue={(workingArr && workingArr.length) || 1}
                          />
                        </div>
                        <div className="workingDays" style={{ marginTop: -25 }}>
                          <Col xs="2" className="subTitle">
                            Days
                        </Col>
                          <Col xs="3" className="subTitle">
                            Start Time
                        </Col>
                          <Col xs="3" className="subTitle">
                            End Time
                        </Col>
                        </div>
                        {dcDropdown >= 1 && <div className="flex">
                          <Col xs="2" className="weekDays">Mon - Fri</Col>

                          <TimePicker
                            placeholder="Select start time"
                            colSpan="3"
                            name="from_hour"
                            form={props.form}
                            isRequired={true}
                            onChange={val => {
                              workingDaysFn(0, "from_hour", val);
                            }}
                            submit={submit}
                            defaultValue={
                              workingArr.length > 0 &&
                              workingArr[0] &&
                              workingArr[0].from_hour
                            }
                            error={
                              workingArr.length > 0 &&
                              workingArr &&
                              workingArr.length > 0 &&
                              workingArr[0] &&
                              (workingArr[0].err || workingArr[0].err1) &&
                              (workingArr[0].err || workingArr[0].err1)
                            }
                          />
                          <TimePicker
                            placeholder="Select end time"
                            colSpan="3"
                            name="to_hour"
                            form={props.form}
                            isRequired={true}
                            onChange={val => {
                              workingDaysFn(0, "to_hour", val);
                            }}
                            submit={submit}

                            defaultValue={
                              workingArr.length > 0 &&
                              workingArr[0] &&
                              workingArr[0].to_hour
                            }
                            error={
                              workingArr.length > 0 &&
                              workingArr &&
                              workingArr.length > 0 &&
                              workingArr[0] &&
                              (workingArr[0].err || workingArr[0].err1) &&
                              (workingArr[0].err || workingArr[0].err1)
                            }
                          />
                        </div>}
                        {dcDropdown >= 2 && <div className="flex">
                          <Col xs="2" className="weekDays">Saturday</Col>
                          <TimePicker
                            placeholder="Select start time"
                            colSpan="3"
                            name="from_hour1"
                            form={props.form}
                            // isRequired={workingArr.length >= 2 ? true : false}
                            isRequired={true}
                            submit={submit}

                            onChange={val => {
                              workingDaysFn(1, "from_hour", val);
                            }}
                            defaultValue={
                              workingArr.length > 0 &&
                              workingArr[1] &&
                              workingArr[1].from_hour
                            }
                            error={
                              workingArr.length > 0 &&
                              workingArr &&
                              workingArr.length > 0 &&
                              workingArr[1] &&
                              (workingArr[1].err || workingArr[1].err1) &&
                              (workingArr[1].err || workingArr[1].err1)
                            }
                          />
                          <TimePicker
                            placeholder="Select end time"
                            colSpan="3"
                            name="to_hour1"
                            form={props.form}
                            // isRequired={workingArr.length >= 2 ? true : false}
                            isRequired={true}
                            onChange={val => {
                              workingDaysFn(1, "to_hour", val);
                            }}
                            submit={submit}

                            defaultValue={
                              workingArr.length > 0 &&
                              workingArr[1] &&
                              workingArr[1].to_hour
                            }
                            error={
                              workingArr.length > 0 &&
                              workingArr &&
                              workingArr.length > 0 &&
                              workingArr[1] &&
                              (workingArr[1].err || workingArr[1].err1) &&
                              (workingArr[1].err || workingArr[1].err1)
                            }
                          />
                          {/* <div className="col3">
                            <div class="customCheckBox form-check">
                              <input
                                novalidate=""
                                id="dcholiday3"
                                name="dcholiday3"
                                xsval="6"
                                type="checkbox"
                                class="form-check-input"
                                checked={holidayCheck[`dcholiday3`]}
                                onClick={() => {
                                  let check = { ...holidayCheck };
                                  check[`dcholiday3`] = check[`dcholiday3`] ? !check[`dcholiday3`] : true;
                                  setHolidayCheck(check)
                                  if (check[`dcholiday3`] && workingArr && workingArr[1] && workingArr[1].to_hour && workingArr[1].from_hour) {
                                    setOpenHolidayModalFn(true, 'dcholiday3');
                                    globalId = (workingArr[1] && workingArr[1].id) || null;
                                  } else {
                                    const dcTemp = [...workingArr];
                                    dcTemp[1].err = false;
                                    dcTemp[1].err1 = false;
                                    delete dcTemp[1];
                                    setWorkingArr(dcTemp)
                                  }
                                }}
                              />
                              <label
                                for="dcholiday3"
                                class="form-check-label"
                                style={{ marginBottom: 0 }}
                              >
                                <span>Mark this day as holiday</span>
                              </label>
                              <div class="invalid-feedback"></div>
                            </div>
                          </div> */}
                        </div>}
                        {dcDropdown == 3 && <div className="flex">
                          <Col xs="2" className="weekDays">Sunday</Col>
                          <TimePicker
                            placeholder="Select start time"
                            colSpan="3"
                            name="from_hour2"
                            form={props.form}
                            isRequired={true}
                            onChange={val => {
                              workingDaysFn(2, "from_hour", val);
                            }}
                            submit={submit}

                            defaultValue={
                              workingArr.length > 0 &&
                              workingArr[2] &&
                              workingArr[2].from_hour
                            }
                            error={
                              workingArr.length > 0 &&
                              workingArr &&
                              workingArr.length > 0 &&
                              workingArr[2] &&
                              (workingArr[2].err || workingArr[2].err1) &&
                              (workingArr[2].err || workingArr[2].err1)
                            }
                          />
                          <TimePicker
                            placeholder="Select end time"
                            colSpan="3"
                            name="to_hour2"
                            form={props.form}
                            isRequired={true}
                            onChange={val => {
                              workingDaysFn(2, "to_hour", val);
                            }}
                            submit={submit}

                            defaultValue={
                              workingArr.length > 0 &&
                              workingArr[2] &&
                              workingArr[2].to_hour
                            }
                            error={
                              workingArr.length > 0 &&
                              workingArr &&
                              workingArr.length > 0 &&
                              workingArr[2] &&
                              (workingArr[2].err || workingArr[2].err1) &&
                              (workingArr[2].err || workingArr[2].err1)
                            }
                          />
                          {/* <div className="col3">
                            <div class="customCheckBox form-check">
                              <input
                                novalidate=""
                                id="dcholiday4"
                                name="dcholiday4"
                                xsval="6"
                                type="checkbox"
                                class="form-check-input"
                                checked={holidayCheck[`dcholiday4`]}
                                onClick={() => {
                                  let check = { ...holidayCheck };
                                  check[`dcholiday4`] = check[`dcholiday4`] ? !check[`dcholiday4`] : true;
                                  setHolidayCheck(check)
                                  if (check[`dcholiday4`] && workingArr && workingArr[2] && workingArr[2].to_hour && workingArr[2].from_hour) {
                                    setOpenHolidayModalFn(true, "dcholiday4");
                                    globalId = (workingArr[2] && workingArr[2].id) || null;
                                  } else {
                                    const dcTemp = [...workingArr];
                                    dcTemp[2].err = false;
                                    dcTemp[2].err1 = false;
                                    delete dcTemp[2];
                                    setWorkingArr(dcTemp)
                                  }
                                }}
                              />
                              <label
                                for="dcholiday4"
                                class="form-check-label"
                                style={{ marginBottom: 0 }}
                              >
                                <span>Mark this day as holiday</span>
                              </label>
                              <div class="invalid-feedback"></div>
                            </div>
                          </div> */}
                        </div>}
                      </Col>
                    </Col>
                  </Row>
                </Row>
                <Row className="scheduleRow" style={{ marginTop: 15, marginBottom: 40 }}>
                  <Col xs="12" style={{ display: "flex" }}>
                    <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                      Schedule{" "}
                      <span className="information">
                        <Icon path={mdiInformationOutline} />
                        <div className="tooltip-hover">
                          <span>Select all that applies</span>
                        </div>
                      </span>
                    </Col>
                    <Col xs="8" className="schedule">
                      {props.scheduleChoice &&
                        props.scheduleChoice.map(dt => {
                          return (
                            <div
                              className={`form-control-alternative ${activeSchedule.includes(
                                dt.id
                              ) && "activeSchedule"}`}
                              key={dt.id}
                              onClick={() => {
                                props.setContentChanged(true)

                                let dummy = [...activeSchedule];
                                if (dummy.includes(dt.id)) {
                                  dummy.splice(dummy.indexOf(dt.id), 1);
                                } else {
                                  dummy.push(dt.id);
                                }
                                setActiveSchedule(dummy);
                              }}
                            >
                              <span>{dt.name}</span>
                            </div>
                          );
                        })}

                      {submit && activeSchedule.length === 0 && (
                        <span
                          className="scheduleText"
                          style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                        >
                          Schedule is required
                      </span>
                      )}
                    </Col>
                  </Col>
                </Row>
                <Row className="scheduleRow" style={{ marginTop: 15, marginBottom: 40 }}>
                  <Col xs="12" style={{ display: "flex" }}>
                    <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                      Payable *
                  </Col>
                    <Col xs="8" className="schedule">
                      <div
                        className={`form-control-alternative ${activeDayCarePayable ===
                          "Monthly" && "activeSchedule"}`}
                        onClick={() => {
                          setActiveDayCarePayable("Monthly");
                        }}
                      >
                        <span>Monthly</span>
                      </div>

                      {submit && !activeDayCarePayable && (
                        <span
                          className="scheduleText"
                          style={{
                            color: "red",
                            fontSize: 11,
                            fontWeight: 300
                          }}
                        >
                          Payable is required
                      </span>
                      )}
                    </Col>
                  </Col>
                </Row>

                <Row style={{ margin: "30px 0px 40px" }}>
                  <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                    Fee *
                  <p style={{ fontSize: 13, paddingRight: 30 }} className="subText">
                      Enter the minimum and maximum for your program. If you don’t
                      offer flexible daycare then enter the details of fullday
                      daycare.
                  </p>
                  </Col>
                  <Col>
                    <>
                      {" "}
                      <div>
                        <div
                          className="monthlyFee"
                          style={{
                            color: "#643b99",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "19px"
                          }}
                        >
                          Fullday
                      </div>
                        <div className="monthlyFee" style={{ marginLeft: -10 }}>
                          <li>Minimum/Month</li>
                          <li>Maximum/Month</li>
                        </div>
                        <Row
                          className="dayTime minmax"
                          style={{ marginLeft: -30 }}
                        >
                          <FormInput
                            form={props.form}
                            name="daycare_full_day_fee_min"
                            placeholder="Enter amount"
                            label=""
                            colSpan="4"
                            maxLength={10}
                            type="number"
                            submit={dayCareFullDayErr}
                            msg={
                              typeof dayCareFullDayErr === "string"
                                ? dayCareFullDayErr
                                : "Daycare full day min fee should be less than max fee"
                            }
                            onChange={val => {
                              props.setContentChanged(true)
                              setDcFullMin(val)
                            }}
                          />
                          <Col xs='2' style={{ marginTop: 10 }}><span className="yearSubInfo">INR</span></Col>

                          <FormInput
                            form={props.form}
                            name="daycare_full_day_fee_max"
                            placeholder="Enter amount"
                            label=""
                            colSpan="4"
                            onChange={val => {
                              props.setContentChanged(true)
                              setDcFullMax(val)
                            }}
                            maxLength={10}
                            isfee
                          />
                          <Col xs='2' style={{ marginTop: 10, marginLeft: -45 }}><span className="yearSubInfo">INR</span></Col>

                        </Row>
                      </div>
                      <div>
                        <div
                          className="monthlyFee"
                          style={{
                            color: "#643b99",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "19px"
                          }}
                        >
                          Half day
                      </div>
                        <div className="monthlyFee" style={{ marginLeft: -10 }}>
                          <li>Minimum/Month</li>
                          <li>Maximum/Month</li>
                        </div>
                        <Row
                          className="dayTime minmax"
                          style={{ marginLeft: -30 }}
                        >
                          <FormInput
                            form={props.form}
                            name="daycare_half_day_fee_min"
                            placeholder="Enter amount"
                            label=""
                            maxLength={10}
                            colSpan="4"
                            type="number"
                            submit={dayCareHalfDayErr}
                            onChange={val => {
                              props.setContentChanged(true)
                              setDcHalfMin(val)
                            }}
                            msg={
                              typeof dayCareHalfDayErr === "string"
                                ? dayCareHalfDayErr
                                : "Daycare half day min fee should be less than max fee"
                            }
                          />
                          <Col xs='2' style={{ marginTop: 10 }}><span className="yearSubInfo">INR</span></Col>

                          <FormInput
                            form={props.form}
                            name="daycare_half_day_fee_max"
                            placeholder="Enter amount"
                            label=""
                            maxLength={10}
                            onChange={val => {
                              props.setContentChanged(true)
                              setDcHalfMax(val)
                            }}
                            colSpan="4"
                            isfee
                          />
                          <Col xs='2' style={{ marginTop: 10, marginLeft: -45 }}><span className="yearSubInfo">INR</span></Col>

                        </Row>
                      </div>
                    </>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4" style={{ paddingLeft: 30 }} className="formInputName">
                    Age of Children *
                  <p style={{ fontSize: 13, paddingRight: 30 }} className="subText">
                      Please mention min and max age for enrollment in your
                      school/facility
                  </p>
                  </Col>
                  <>
                    <Col
                      xs="4"
                      className="childrenAge"
                      style={{ marginLeft: 0, padding: '0px 0px 0px 20px' }}
                    >
                      <div className="sideHeading">Min Age</div>
                      <Row>
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageYearDayCare}
                          name="min_age_yr"
                          placeholder="Select"
                          onChange={v => {
                            props.setContentChanged(true)
                            setMinAge(v)
                          }}
                          showData='year(s)'

                        />
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageMonth}
                          name="min_age_month"
                          placeholder="Select"
                          showData='month(s)'

                          onChange={v => {
                            props.setContentChanged(true)
                            setMinMonth(v)
                          }}
                        />
                      </Row>
                      {ageErr && (
                        <div
                          style={{
                            color: "red",
                            fontSize: 11,
                            fontWeight: 300,
                            marginTop: -63,
                            marginLeft: 0
                          }}
                        >
                          {ageErr}{" "}
                        </div>
                      )}
                    </Col>
                    <Col xs="4" className="childrenAge" style={{ marginLeft: 0, padding: '0px 0px 0px 20px' }}>
                      <div className="sideHeading"> Max Age</div>
                      <Row>
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageYearDayCare}
                          name="max_age_yr"
                          placeholder="Select"
                          showData='year(s)'

                          onChange={v => {
                            props.setContentChanged(true)
                            setMaxAge(v)
                          }}
                        />
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageMonthPreSchool}
                          name="max_age_month"
                          placeholder="Select"
                          showData='month(s)'

                          onChange={v => {
                            props.setContentChanged(true)
                            setMaxMonth(v)
                          }}
                        />
                      </Row>
                    </Col>
                  </>
                </Row>

                <Row className="daySchedule" style={{ marginTop: 20 }}>
                  <Col xs="4" style={{ paddingLeft: 30 }} className="formInputName">
                    Schedule for the day *
                  <p className="subText">
                      Please select the time and activity. You may also add your
                      custom programs.
                  </p>
                  </Col>
                  <Col style={{ marginLeft: -25 }}>
                    {/* <div className="selectDate">
                    <li style={{ color: "#33333f", fontWeight: 500 }}>
                      Day Care
                    </li>
                  </div> */}
                    <div className="selectDate">
                      <div
                        class="col-4 sideHeading"
                        style={{
                          maxWidth: "203px",
                          marginRight: "6px",
                          paddingLeft: "30px"
                        }}
                      >
                        Programme
                    </div>
                      <div class="col-4 sideHeading">Start Time</div>
                    </div>
                    {scheduleShow &&
                      scheduleShow.map((a, id) => (
                        <Col className="dayTime" style={{ padding: 0 }}>
                          <Select
                            form={props.form}
                            label=""
                            colSpan="4"
                            marginRight="14px"
                            options={uniq(scheduleDropdown)}
                            name={`programme0${id + 1}`}
                            // defaultValue={
                            //   scheduleArr.length > 0 && scheduleArr[id]
                            //     ? scheduleArr[id].programm
                            //     : null
                            // }
                            onChange={val => {
                              let schedule = [...scheduleDropdown];
                              let scheduleName = schedule.map(a => a.value);
                              if (!scheduleName.includes(val.value)) {
                                schedule.push(val);
                              }
                              setScheduleDropdown(schedule);
                              scheduleDay(id, "programm", val.value);
                            }}

                            isResponsive
                            msg="Programme is required"
                            isRequired={
                              scheduleArr &&
                                scheduleArr[id] &&
                                !scheduleArr[id].programm
                                ? true
                                : id === 0 && scheduleArr.length === 0
                                  ? true
                                  : false
                            }
                          />
                          <TimePicker
                            placeholder="Select start time"
                            colSpan="4"
                            name={`start_time${id + 1}`}
                            form={props.form}
                            isRequired={
                              scheduleArr &&
                                scheduleArr[id] &&
                                scheduleArr[id].programm &&
                                !scheduleArr[id].start_time
                                ? true
                                : id === 0 && scheduleArr.length === 0
                                  ? true
                                  : id === 0 && scheduleArr.length === 0
                                    ? true
                                    : false
                            }
                            onChange={val => scheduleDay(id, "start_time", val)}
                            submit={scheduleArr &&
                              scheduleArr[id] &&
                              scheduleArr[id].programm}
                            defaultValue={
                              scheduleArr.length > 0 && scheduleArr[id]
                                ? scheduleArr[id].start_time
                                : ""
                            }
                            isResponsive
                          />
                          {/* <Dropdown
                    form={props.form}
                    label=""
                    xsval="4"
                    options={scheduleDropdown}
                    name={`programme0${id + 1}`}
                    defaultValue={
                      scheduleArr.length > 0 && scheduleArr[id]
                        ? scheduleArr[id].programm
                        : ""
                    }
                    onChange={val => scheduleDay(id, "programm", val)}
                    isRequired={scheduleArr && scheduleArr[id] && !scheduleArr[id].programm ? true : id === 0 ? true : false}
                  /> */}

                          <span
                            className="trashIcon"
                            onClick={() => {

                              const { form } = props;

                              form.setFieldsValue({
                                [`programme0${id + 1}`]: null,
                              });

                              let actD = [...scheduleShow];
                              let schdl = [...scheduleArr];


                              let removeFromDropdown = [...scheduleDropdown];
                              let datas = schdl.filter((a, i) => i === id);
                              let deleteData = datas[0];
                              if (schdl[id] && schdl[id].programm) {
                                if (
                                  !DEFAULT_SCHEDULE_DUPLICATE.includes(
                                    schdl[id].programm
                                  )
                                ) {
                                  removeFromDropdown = removeFromDropdown.filter(
                                    a => a.value !== schdl[id].programm
                                  );
                                }
                              }

                              setScheduleDropdown(removeFromDropdown);
                              if (deleteData && deleteData.id) {
                                props.deleteSchedulerApi(deleteData);
                                schdl.splice(schdl.indexOf(datas[0]), 1);
                              } else {
                                schdl.splice(schdl.indexOf(datas[0]), 1);
                              }
                              if (actD.length > 2) {
                                actD.pop();
                              }
                              setScheduleShow(actD);
                              setScheduleArr(schdl);
                              props.setContentChanged(true)

                            }}
                          >
                            <img
                              className="trash"
                              src={Trash}
                              alt="trash"
                            />
                            <img
                              className="trashHover"
                              src={TrashHover}
                              alt="trash"
                            />
                          </span>
                        </Col>
                      ))}
                    <Col className="dayTime">
                      <Select
                        form={props.form}
                        label=""
                        colSpan="4"
                        marginRight="14px"
                        paddingLeft="0"
                        options={uniq(scheduleDropdown)}
                        name="program"
                        onChange={val => setNewScheduleEvENt(val.value)}
                        isRequired={false}
                        isResponsive
                      />
                      <TimePicker
                        placeholder="Select start time"
                        colSpan="4"
                        name="schedule_start_time"
                        form={props.form}
                        isRequired={false}
                        onChange={val => {
                          return setNewScheduleTime(val);
                        }}
                        isResponsive
                        defaultValue={scheduleNewTime}
                      />
                      {/* <FormInput
                    form={props.form}
                    label=""
                    colSpan="4"
                    name="program"
                    defaultValue={scheduleNewEvent}
                    onChange={e => setNewScheduleEvENt(e)}
                    isRequired={false}
                    placeholder="Enter Program"
                  /> */}

                      <span
                        onClick={() => {
                          if (scheduleNewTime && scheduleNewEvent) {
                            props.setContentChanged(true)

                            setscheduleAddErr(false)
                            var actD = [...scheduleShow];
                            var sch = [...scheduleArr];
                            var drp = [...scheduleDropdown];
                            let filterDrop = drp.filter(a => a.value);
                            if (!filterDrop.includes(scheduleNewEvent)) {
                              drp.push({
                                label: scheduleNewEvent,
                                value: scheduleNewEvent,
                                disabled: false
                              });
                            }

                            setScheduleDropdown(drp);
                            actD.push(1);
                            setScheduleShow(actD);
                            sch.push({
                              start_time: scheduleNewTime,
                              programm: scheduleNewEvent
                            });
                            setScheduleArr(sch);
                            setNewScheduleEvENt("");
                            setNewScheduleTime("");
                          } else {
                            setscheduleAddErr(true)
                          }
                          const { form } = props;
                          form.setFieldsValue({
                            [`program`]: null,
                          });
                        }}
                      >
                        {" "}
                        <span className="addEvent">Add</span>
                      </span>
                    </Col>
                    {scheduleAddErr && <div
                      style={{
                        color: "red",
                        fontSize: 11,
                        fontWeight: 300,
                        marginTop: -15,
                        marginLeft: 30
                      }}
                    >Select programme and date</div>}
                  </Col>
                </Row>
              </CardBody>
            </div>

          </div>
        )}


        {/* Pre School */}
        {(childCare === "1" || childCare === "3") && (
          <div className="animateDiv">
            {childCare === "3" && (<div className="animateDiv-header" onClick={() => setPreActive(!preActive)} >
              <span>Preschool</span>
              <div className={`animateDiv-icon ${preActive && 'active'}`}>
                <span class="iconify minus" data-icon="ps:minus" data-inline="false"></span>
                <span class="iconify plus" data-icon="zmdi:plus" data-inline="false"></span>
              </div>
            </div>
            )}
            <div className={`borderDashed ${preActive && 'active'}`}>
              <CardBody className="formContent">
                <h4 className="sub-heading">When do you Operate</h4>

                <Row>
                  <Row style={{ paddingLeft: 45, width: "100%" }}>
                    <Row style={{ paddingLeft: 15, width: "33.5%" }} className="formInputName">
                      Number of working days *


                    </Row>
                    <Col style={{ paddingLeft: 0 }}>
                      <Col>
                        {/* <div className="workingDays">
                      <li style={{ color: "#33333f", fontWeight: 500 }}>
                        Pre School
                      </li>
                    </div> */}
                        {batch.map((val, id) => {
                          batchArr.push(
                            <>
                              <div className="workingDays">
                                <li style={{ color: "#33333f", fontWeight: 700 }}>
                                  Batch {id + 1}
                                </li>
                              </div>
                              <div className="flex" style={{ marginLeft: -15 }}>
                                <Dropdown
                                  form={props.form}
                                  label=""
                                  xsVal="6"
                                  isRequired={true}
                                  options={weekdaysSelect}
                                  name={`batchSelect${id + 1}`}
                                  placeholder="Select"
                                  onChange={v => {
                                    props.setContentChanged(true)
                                    let batch = [...batchDropdown];
                                    batch[id] = v;
                                    setBatchDropdown(batch)
                                    let wA = [...workingArr1]
                                    wA[id] = wA[id] || []
                                    if(v == 2) {
                                      wA[id][2] = undefined;
                                    }
                                    if(v == 1) {
                                      wA[id][1] = undefined;
                                      wA[id][2] = undefined;
                                    }
                                    wA[id] = wA[id].filter(a => a)
                                    setWorkingArr1(wA)
                                  }}
                                  defaultValue={(workingArr1 && workingArr1[id] && workingArr1[id].length) || 1}
                                />
                              </div>
                              <div className="workingDays" style={{ marginTop: -25 }}>
                                <Col xs="2" className="subTitle">
                                  Days
                              </Col>
                                <Col xs="3" className="subTitle">
                                  Start Time
                              </Col>
                                <Col xs="3" className="subTitle">
                                  End Time
                              </Col>
                              </div>

                              {batchDropdown[id] >= 1 && <div className="flex">
                                <Col xs="2" className="weekDays">Mon - Fri</Col>
                                <TimePicker
                                  placeholder="Select start time"
                                  colSpan="3"
                                  name={`pre_from_hour${id + 1}`}
                                  form={props.form}
                                  isRequired={true}
                                  onChange={val => {
                                    workingDaysFn1(
                                      id,
                                      0,
                                      "from_hour",
                                      val,
                                      `batch${id + 1}`
                                    );
                                  }}
                                  submit={submit}
                                  defaultValue={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id].length > 0 &&
                                    workingArr1[id][0] &&
                                    workingArr1[id][0].from_hour
                                  }
                                  error={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id].length > 0 &&
                                    workingArr1[id][0] &&
                                    (workingArr1[id][0].err ||
                                      workingArr1[id][0].err1) &&
                                    (workingArr1[id][0].err ||
                                      workingArr1[id][0].err1)
                                  }
                                />
                                <TimePicker
                                  placeholder="Select end time"
                                  colSpan="3"
                                  name={`pre_to_hour${id + 1}`}
                                  form={props.form}
                                  isRequired={true}
                                  onChange={val => {
                                    workingDaysFn1(
                                      id,
                                      0,
                                      "to_hour",
                                      val,
                                      `batch${id + 1}`
                                    );
                                  }}
                                  submit={submit}
                                  defaultValue={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id].length > 0 &&
                                    workingArr1[id][0] &&
                                    workingArr1[id][0].to_hour
                                  }
                                  error={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id].length > 0 &&
                                    workingArr1[id][0] &&
                                    (workingArr1[id][0].err ||
                                      workingArr1[id][0].err1) &&
                                    (workingArr1[id][0].err ||
                                      workingArr1[id][0].err1)
                                  }
                                />
                              </div>}

                              {batchDropdown[id] >= 2 && <div className="flex">
                                <Col xs="2" className="weekDays">Saturday</Col>

                                <TimePicker
                                  placeholder="Select start time"
                                  colSpan="3"
                                  style
                                  name={`pre_from_hour1${id + 1}`}
                                  form={props.form}
                                  // isRequired={workingArr1.length >= 2 ? true : false}
                                  isRequired={true}
                                  submit={submit}
                                  onChange={val => {
                                    workingDaysFn1(
                                      id,
                                      1,
                                      "from_hour",
                                      val,
                                      `batch${id + 1}`
                                    );
                                  }}
                                  defaultValue={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id][1] &&
                                    workingArr1[id][1].from_hour
                                  }
                                  error={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id].length > 0 &&
                                    workingArr1[id][1] &&
                                    (workingArr1[id][1].err ||
                                      workingArr1[id][1].err1) &&
                                    (workingArr1[id][1].err ||
                                      workingArr1[id][1].err1)
                                  }
                                />
                                <TimePicker
                                  placeholder="Select end time"
                                  colSpan="3"
                                  name={`pre_to_hour1${id + 1}`}
                                  form={props.form}
                                  // isRequired={workingArr1.length >= 2 ? true : false}
                                  isRequired={true}
                                  onChange={val => {
                                    workingDaysFn1(
                                      id,
                                      1,
                                      "to_hour",
                                      val,
                                      `batch${id + 1}`
                                    );
                                  }}
                                  submit={submit}
                                  defaultValue={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id][1] &&
                                    workingArr1[id][1].to_hour
                                  }
                                  error={
                                    workingArr1.length > 0 &&
                                    workingArr1[id] &&
                                    workingArr1[id].length > 0 &&
                                    workingArr1[id][1] &&
                                    (workingArr1[id][1].err ||
                                      workingArr1[id][1].err1) &&
                                    (workingArr1[id][1].err ||
                                      workingArr1[id][1].err1)
                                  }
                                />
                                {/* <div className="col3">
                                  <div class="customCheckBox form-check">
                                    <input
                                      novalidate=""
                                      id={`holiday${id}`}
                                      name={`holiday${id}`}
                                      xsval="6"
                                      type="checkbox"
                                      class="form-check-input"
                                      checked={holidayCheck[`holiday${id}`]}
                                      onClick={() => {
                                        let check = { ...holidayCheck };
                                        check[`holiday${id}`] = check[`holiday${id}`] ? !check[`holiday${id}`] : true;
                                        setHolidayCheck(check)
                                        globalIndex = 1
                                        if (check[`holiday${id}`] && workingArr1[id] && workingArr1[id][1] && workingArr1[id][1].to_hour && workingArr1[id][1].from_hour) {
                                          setOpenHolidayModalFn(true, `holiday${id}`);
                                          globalId = (workingArr1[id][1] && workingArr1[id][1].id) || null;
                                        } else {
                                          const preTemp = [...workingArr1];
                                          delete preTemp[id][1];
                                          setWorkingArr1(preTemp)
                                        }
                                      }}
                                    />
                                    <label
                                      for={`holiday${id}`}
                                      class="form-check-label"
                                      style={{ marginBottom: 0 }}
                                    >
                                      <span>Mark this day as holiday</span>
                                    </label>
                                    <div class="invalid-feedback"></div>
                                  </div>
                                </div> */}
                              </div>}

                              {batchDropdown[id] == 3 &&
                                <div className="flex">
                                  <Col xs="2" className="weekDays">Sunday</Col>
                                  <TimePicker
                                    placeholder="Select start time"
                                    colSpan="3"
                                    name={`pre_from_hour2${id + 1}`}
                                    form={props.form}
                                    isRequired={true}
                                    onChange={val => {
                                      workingDaysFn1(
                                        id,
                                        2,
                                        "from_hour",
                                        val,
                                        `batch${id + 1}`
                                      );
                                    }}
                                    submit={submit}

                                    defaultValue={
                                      workingArr1.length > 0 &&
                                      workingArr1[id] &&
                                      workingArr1[id][2] &&
                                      workingArr1[id][2].from_hour
                                    }
                                    error={
                                      workingArr1.length > 0 &&
                                      workingArr1[id] &&
                                      workingArr1[id].length > 0 &&
                                      workingArr1[id][2] &&
                                      (workingArr1[id][2].err ||
                                        workingArr1[id][2].err1) &&
                                      (workingArr1[id][2].err ||
                                        workingArr1[id][2].err1)
                                    }
                                  />

                                  <TimePicker
                                    placeholder="Select end time"
                                    colSpan="3"
                                    name={`pre_to_hour2${id + 1}`}
                                    form={props.form}
                                    isRequired={true}
                                    onChange={val => {
                                      workingDaysFn1(
                                        id,
                                        2,
                                        "to_hour",
                                        val,
                                        `batch${id + 1}`
                                      );
                                    }}
                                    submit={submit}

                                    defaultValue={
                                      workingArr1.length > 0 &&
                                      workingArr1[id] &&
                                      workingArr1[id][2] &&
                                      workingArr1[id][2].to_hour
                                    }
                                    error={
                                      workingArr1.length > 0 &&
                                      workingArr1[id] &&
                                      workingArr1[id].length > 0 &&
                                      workingArr1[id][2] &&
                                      (workingArr1[id][2].err ||
                                        workingArr1[id][2].err1) &&
                                      (workingArr1[id][2].err ||
                                        workingArr1[id][2].err1)
                                    }
                                  />
                                  {/* <div className="col3">
                                    <div class="customCheckBox form-check">
                                      <input
                                        novalidate=""
                                        id={`holiday1${id}`}
                                        name={`holiday1${id}`}
                                        xsval="6"
                                        type="checkbox"
                                        class="form-check-input"
                                        checked={holidayCheck[`holiday1${id}`]}
                                        onClick={() => {
                                          let check = { ...holidayCheck };
                                          check[`holiday1${id}`] = check[`holiday1${id}`] ? !check[`holiday1${id}`] : true;
                                          setHolidayCheck(check)
                                          globalIndex = 2
                                          if (check[`holiday1${id}`] && workingArr1[id] && workingArr1[id][2] && workingArr1[id][2].to_hour && workingArr1[id][2].from_hour) {
                                            setOpenHolidayModalFn(true, `holiday1${id}`);

                                            globalId = (workingArr1[id][2] && workingArr1[id][2].id) || null;
                                          } else {
                                            const preTemp = [...workingArr1];
                                            delete preTemp[id][2];
                                            setWorkingArr1(preTemp)
                                          }
                                        }}
                                      />
                                      <label
                                        for={`holiday1${id}`}
                                        class="form-check-label"
                                        style={{ marginBottom: 0 }}
                                      >
                                        <span>Mark this day as holiday</span>
                                      </label>
                                      <div class="invalid-feedback"></div>
                                    </div>
                                  </div> */}
                                </div>}

                              {id > 0 && (
                                <Row
                                  className="formFlex"
                                  style={{
                                    display: "block",
                                    paddingLeft: "15px",
                                    marginBottom: 20
                                  }}
                                >
                                  <Col xs="2" style={{ padding: "0px" }}>
                                    <Button
                                      onClick={() => {
                                        setDeleteBatchModal(true);
                                        setBatchId(id);

                                        props.setContentChanged(true)

                                      }}
                                    >
                                      Delete Batch
                                  </Button>
                                  </Col>
                                </Row>
                              )}
                            </>
                          );
                        })}
                        {batchArr}

                        <Row className="formFlex" style={{ display: "block" }}>
                          <Col xs="2" style={{ paddingLeft: "15px" }}>
                            <Button
                              onClick={() => {
                                const dummy = [...batch];
                                dummy.push(1);
                                setBatch(dummy);
                                let dummyCount = [...batchDropdown];
                                dummyCount.push(1);
                                setBatchDropdown(dummyCount)
                              }}
                              disabled={batch.length !== workingArr1.length}
                              style={{ display: 'flex', alignItems: 'center' }}

                            >
                              <div className="plusIcon1">
                                <span class="iconify" data-icon="fe:plus" data-inline="false"></span>
                              </div>
                              Add Batch
                          </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </Row>

                <Row className="scheduleRow" style={{ marginTop: 15, marginBottom: 40 }}>
                  <Col xs="12" style={{ display: "flex" }}>
                    <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                      Schedule{" "}
                      {/* <span className="information">
                      <Icon path={mdiInformationOutline} />
                      <div className="tooltip-hover">
                        <span>Select all that applies</span>
                      </div>
                    </span> */}
                    </Col>
                    <Col xs="8" className="schedule">
                      <div
                        className={`form-control-alternative ${activePreSchoolSchedule.includes(
                          1
                        ) && "activeSchedule"}`}
                        onClick={() => {

                          let dummy = [...activePreSchoolSchedule];
                          if (!dummy.includes(1)) {
                            dummy.push(1);
                          }
                          setActivePreSchoolSchedule(dummy);
                        }}
                      >
                        <span>{"Full day"}</span>
                      </div>
                      {submit && activePreSchoolSchedule.length === 0 && (
                        <span
                          className="scheduleText"
                          style={{
                            color: "red",
                            fontSize: 11,
                            fontWeight: 300
                          }}
                        >
                          Schedule is required
                      </span>
                      )}
                    </Col>
                  </Col>
                </Row>
                <Row className="scheduleRow" style={{ marginTop: 15, marginBottom: 40 }}>
                  <Col xs="12" style={{ display: "flex" }}>
                    <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                      Payable *
                  </Col>
                    <Col>
                      <Col xs="8" className="schedule">
                        <div
                          className={`form-control-alternative ${activePayable ===
                            "Monthly" && "activeSchedule"}`}
                          onClick={() => {
                            props.setContentChanged(true)

                            setActivePayable("Monthly");
                          }}
                        >
                          <span>Monthly</span>
                        </div>
                        <div
                          className={`form-control-alternative ${activePayable ===
                            "Quarterly" && "activeSchedule"}`}
                          onClick={() => {
                            props.setContentChanged(true)

                            setActivePayable("Quarterly");
                          }}
                        >
                          <span>Quarterly</span>
                        </div>
                        <div
                          className={`form-control-alternative ${activePayable ===
                            "Annually" && "activeSchedule"}`}
                          onClick={() => {
                            props.setContentChanged(true)

                            setActivePayable("Annually");
                          }}
                        >
                          <span>Annually</span>
                        </div>
                        {submit && !activePayable && (
                          <span
                            className="scheduleText"
                            style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                          >
                            Payable is required
                      </span>
                        )}
                      </Col>
                      <Col style={{ marginTop: 20 }}>
                        <FileUploader
                          pdfUpload
                          form={props.form}
                          urlImage={
                            props.data &&
                            props.data.length > 0 &&
                            props.data[0] &&
                            props.data[0].payable_attachment_url
                          }
                          attachmentName={props.data &&
                            props.data.length > 0 &&
                            props.data[0] &&
                            props.data[0].payable_attachment_name}
                          errMargin={255}
                          name="payable_photo"
                          xsval="6"
                          acceptType="application/pdf"
                          topMargin="0px"
                          type="banner"
                          uploadUrl={id => {
                            props.setContentChanged(true)

                            setImage(id);
                            setPhotoDeleted(false);
                          }}
                          setCancelImg={id => {
                            props.setContentChanged(true)

                            setImage("");
                            setPhotoDeleted(true);
                          }}
                          displayFlex={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            padding: 0,
                            margin: 0
                          }}
                          uploadIcon={
                            <span className='upload-file-custom'>+ Attach Fees structure file (Optional)</span>
                          }
                          // marginLeft="-450px"
                          isRequired={false}
                        />
                      </Col>

                    </Col>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 15, marginBottom: 50, alignItems: 'center' }}>
                  <Col xs="4" className="formInputName">Programmes Offered *</Col>
                  <Col>
                    <Row style={{ marginTop: 10 }}>
                      <CheckBox
                        name="Play Group / Pre Nursery"
                        label="Play Group / Pre Nursery"
                        xsval="4"
                        form={props.form}
                        checked={preSchoolProgram.includes('Play Group / Pre Nursery')}
                        className="marginRight-24"
                        onClick={(v) => {
                          props.setContentChanged(true)

                          let d = [...preSchoolProgram]
                          if (!d.includes('Play Group / Pre Nursery')) {
                            d.push('Play Group / Pre Nursery')
                          } else {
                            d.splice(d.indexOf('Play Group / Pre Nursery'), 1)
                          }
                          setPreSchoolProgram(d)
                        }}
                      />
                      <CheckBox
                        name="Nursery"
                        label="Nursery"
                        xsval="2"
                        form={props.form}
                        checked={preSchoolProgram.includes('Nursery')}
                        onClick={() => {
                          props.setContentChanged(true)

                          let d = [...preSchoolProgram]
                          if (!d.includes('Nursery')) {
                            d.push('Nursery')
                          } else {
                            d.splice(d.indexOf('Nursery'), 1)
                          }
                          setPreSchoolProgram(d)
                        }}
                        className="marginRight-24"
                      />
                      <CheckBox
                        name="LKG"
                        label="LKG"
                        xsval="2"
                        form={props.form}
                        checked={preSchoolProgram.includes('LKG')}
                        onClick={() => {
                          props.setContentChanged(true)

                          let d = [...preSchoolProgram]
                          if (!d.includes('LKG')) {
                            d.push('LKG')
                          } else {
                            d.splice(d.indexOf('LKG'), 1)
                          }
                          setPreSchoolProgram(d)
                        }}
                        className="marginRight-60"
                      />
                      <CheckBox
                        name="UKG"
                        label="UKG"
                        xsval="2"
                        form={props.form}
                        checked={preSchoolProgram.includes('UKG')}
                        onClick={() => {
                          props.setContentChanged(true)

                          let d = [...preSchoolProgram]
                          if (!d.includes('UKG')) {
                            d.push('UKG')
                          } else {
                            d.splice(d.indexOf('UKG'), 1)
                          }
                          setPreSchoolProgram(d)
                        }}
                        className="marginRight-60"
                      />
                    </Row>
                    {submit && (preSchoolProgram.length === 0 || preSchoolProgram.includes('')) && (
                      <span
                        className="scheduleText"
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 300
                        }}
                      >
                        Select atleast one programme
                      </span>
                    )}
                  </Col>

                </Row>


                <Row style={{ margin: "30px 0px 30px" }}>
                  <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                    Fee *
                  <p style={{ fontSize: 13, paddingRight: 30 }} className="subText">
                      Please mention the minimum acceptable, this will not be
                      shown to parents, this is for suggesting your school based
                      on price filter
                  </p>
                  </Col>
                  <Col>
                    <div>
                      {/* <div
                      className="monthlyFee"
                      style={{
                        marginLeft: 0,
                        color: "#33333f",
                        fontWeight: 500
                      }}
                    >
                      Preschool
                    </div> */}
                      <div className="monthlyFee" style={{ marginLeft: -10 }}>
                        <li>Minimum/Annum</li>
                        <li>Maximum/Annum</li>
                      </div>
                      <Row className="dayTime minmax" style={{ marginLeft: -30 }}>
                        <FormInput
                          form={props.form}
                          name="preschool_fee_min"
                          placeholder="Enter amount"
                          label=""
                          colSpan="4"
                          type="number"
                          onChange={val => {
                            props.setContentChanged(true)

                            setPreSchoolMin(val)
                          }}
                          submit={preSchoolErr}
                          msg={
                            typeof preSchoolErr === "string"
                              ? preSchoolErr
                              : "Preschool min fee should be less than max fee"
                          }
                          maxLength={10}
                        />
                        <Col xs='2' style={{ marginTop: 10 }}><span >INR</span></Col>
                        <FormInput
                          form={props.form}
                          name="preschool_fee_max"
                          placeholder="Enter amount"
                          label=""
                          onChange={val => {
                            props.setContentChanged(true)

                            setPreSchoolMax(val)
                          }}
                          maxLength={10}
                          colSpan="4"
                          isfee
                        />
                        <Col xs='2' style={{ marginTop: 10, marginLeft: -45 }}><span>INR</span></Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs="4" style={{ paddingLeft: 30 }} className="formInputName">
                    Age of Children *
                  <p style={{ fontSize: 13, paddingRight: 30 }} className="subText">
                      Please mention min and max age for enrollment in your
                      school/facility
                  </p>
                  </Col>
                  <>
                    <Col
                      xs="4"
                      className="childrenAge"
                      style={{ padding: '0px 0px 0px 20px' }}
                    >

                      {/* <div style={{ marginLeft: 15 }}>Pre School</div> */}
                      <div className="sideHeading">Min Age</div>
                      <Row>
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageYear}
                          name="preschool_min_age_year"
                          placeholder="Select"
                          showData='year(s)'
                          onChange={v => {
                            props.setContentChanged(true)

                            setMinAgePreSchool(v)
                          }}
                        />
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageMonthPreSchool}
                          name="preschool_min_age_month"
                          placeholder="Select"
                          showData='month(s)'
                          onChange={v => {
                            props.setContentChanged(true)
                            setMinMonthPreSchool(v)
                          }}
                        />
                      </Row>
                      {ageErrPreSchool && (
                        <div
                          style={{
                            color: "red",
                            fontSize: 11,
                            fontWeight: 300,
                            marginTop: -63,
                            marginLeft: 0
                          }}
                        >
                          {ageErrPreSchool}{" "}
                        </div>
                      )}
                    </Col>
                    <Col xs="4" className="childrenAge" style={{ marginLeft: 0, padding: '0px 0px 0px 20px' }}>
                      {/* <div style={{ marginLeft: 15, opacity: 0 }}>Pre School</div> */}
                      <div className="sideHeading"> Max Age</div>
                      <Row>
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageYear}
                          name="preschool_max_age_year"
                          placeholder="Select"
                          showData='year(s)'

                          onChange={v => {
                            props.setContentChanged(true)
                            setMaxAgePreSchool(v)
                          }}
                        />
                        <Dropdown
                          form={props.form}
                          label=""
                          xsVal="6"
                          options={ageMonthPreSchool}
                          name="preschool_max_age_month"
                          placeholder="Select"
                          showData='month(s)'
                          onChange={v => {
                            props.setContentChanged(true)
                            setMaxMonthPreSchool(v)
                          }}
                        />
                      </Row>
                    </Col>
                  </>
                </Row>
              </CardBody>
            </div>
          </div>
        )}
        <CardBody className="formContent">
          <Row style={{ margin: "30px 0px 0px" }}>
            <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
              Total Capacity *
              </Col>
            <Col>
              <div>
                <div className="totalCapacity" style={{ marginLeft: -10 }}>
                  {(childCare === "1" || childCare === "3") && (
                    <li>Preschool </li>
                  )}
                  {(childCare === "2" || childCare === "3") && (
                    <li>Daycare</li>
                  )}
                </div>
                <Row className="dayTime minmax" style={{ marginLeft: -30 }}>
                  {(childCare === "1" || childCare === "3") && (
                    <FormInput
                      form={props.form}
                      name="preschool_total_capacity"
                      placeholder="Enter Count"
                      label=""
                      colSpan="4"
                      type="number"
                      onChange={val => {
                        props.setContentChanged(true)
                        setPreSchoolCapacity(val)
                      }}
                      maxLength={10}
                      submit={preSchoolCapacityErr}
                      msg={
                        typeof preSchoolCapacityErr === "string" &&
                        preSchoolCapacityErr
                      }
                    />
                  )}
                  {(childCare === "2" || childCare === "3") && (
                    <FormInput
                      form={props.form}
                      name="day_care_total_capacity"
                      placeholder="Enter Count"
                      label=""
                      colSpan="4"
                      type="number"
                      onChange={val => {
                        props.setContentChanged(true)
                        setDayCareCapacity(val)
                      }}
                      maxLength={10}
                      submit={dayCareCapacityErr}
                      msg={
                        typeof dayCareCapacityErr === "string" &&
                        dayCareCapacityErr
                      }
                    />
                  )}
                </Row>
              </div>
            </Col>
          </Row>

          {(childCare === "1" || childCare === "3") && (
            <Row style={{ margin: "30px 0px 0px" }}>
              <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                Teacher : Children ratio *
                </Col>
              <Col>
                <div>
                  <div className="monthlyFee" style={{ marginLeft: -10 }}>
                    <li>Preschool </li>
                  </div>
                  <Row className="dayTime minmax" style={{ marginLeft: -30 }}>
                    <FormInput
                      form={props.form}
                      name="preschool_teacher_student_ratio_first"
                      placeholder=""
                      label=""
                      colSpan="2"
                      ratio1
                      onChange={val => {
                        props.setContentChanged(true)
                        setPreSchoolRatioFirst(val)
                      }}
                      maxLength={1}
                      submit={preSchoolRatioErr}
                      msg={
                        typeof preSchoolRatioErr === "string" &&
                        preSchoolRatioErr
                      }
                      defaultValue={preSchoolRatioFirst}
                    />
                    <Col xs='1' style={{ marginTop: 10, paddingLeft: 20, fontWeight: 900 }}><span >  :</span></Col>
                    <FormInput
                      form={props.form}
                      name="preschool_teacher_student_ratio_second"
                      placeholder=""
                      label=""
                      colSpan="2"
                      ratio2
                      onChange={val => {
                        props.setContentChanged(true)
                        setPreSchoolRatioSecond(val)
                      }}
                      maxLength={2}
                      defaultValue={preSchoolRatioSecond}

                    />
                  </Row>
                </div>
              </Col>
            </Row>
          )}

          {(childCare === "2" || childCare === "3") && (
            <Row style={{ margin: "30px 0px 0px" }}>
              <Col xs="4" style={{ paddingLeft: 15 }} className="formInputName">
                Staff : kids Ratio for daycare *
                </Col>
              <Col xs='8'>
                <div className="monthlyFee" style={{ marginLeft: -10 }}>
                  <li> Toddler &#60; 2.5 years </li>
                </div>
                <Row className="dayTime minmax" style={{ marginLeft: -30, marginBottom: 40 }}>
                  <FormInput
                    form={props.form}
                    name="day_care_toddler_teacher_student_ratio_first"
                    placeholder=""
                    label=""
                    colSpan="2"
                    ratio1
                    maxLength={1}
                    onChange={val => {
                      props.setContentChanged(true)
                      setDcToddlerRatioFirst(val)
                    }}
                    submit={dcToddlerRatioErr}
                    msg={
                      typeof dcToddlerRatioErr === "string" &&
                      dcToddlerRatioErr
                    }
                    defaultValue={dcToddlerRatioFirst}
                  />
                  <Col xs='1' style={{ marginTop: 10, paddingLeft: 20, fontWeight: 900 }}><span >  :</span></Col>

                  <FormInput
                    form={props.form}
                    name="day_care_toddler_teacher_student_ratio_second"
                    placeholder=""
                    label=""
                    colSpan="2"
                    ratio2
                    maxLength={2}
                    onChange={val => {
                      props.setContentChanged(true)
                      setDcToddlerRatioSecond(val)
                    }}
                    defaultValue={dcToddlerRatioSecond}

                  />

                </Row>

                <div className="monthlyFee" style={{ marginLeft: -10 }}>
                  <li> Above 2.5 years </li>
                </div>
                <Row className="dayTime minmax" style={{ marginLeft: -30 }}>
                  <FormInput
                    form={props.form}
                    name="day_care_infant_teacher_student_ratio_first"
                    placeholder=""
                    label=""
                    colSpan="2"
                    ratio1
                    maxLength={1}
                    onChange={val => {
                      props.setContentChanged(true)
                      setDcInfantRatioFirst(val)
                    }}
                    submit={dcInfantRatioErr}
                    msg={
                      typeof dcInfantRatioErr === "string" &&
                      dcInfantRatioErr
                    }
                    defaultValue={dcInfantRatioFirst}
                  />
                  <Col xs='1' style={{ marginTop: 10, paddingLeft: 20, fontWeight: 900 }}><span >  :</span></Col>

                  <FormInput
                    form={props.form}
                    name="day_care_infant_teacher_student_ratio_second"
                    placeholder=""
                    label=""
                    colSpan="2"
                    ratio2
                    maxLength={2}
                    onChange={val => {
                      props.setContentChanged(true)
                      setDcInfantRatioSecond(val)
                    }}
                    defaultValue={dcInfantRatioSecond}

                  />
                </Row>
              </Col>

            </Row>
          )}
        </CardBody>


        <div className="borderDashed" style={{ border: 0 }}>
          <CardBody className="formContent">

            <Row className="daySchedule">
              <Col xs="4" style={{ paddingLeft: 30 }} className="formInputName">
                Yearly Calendar *
                <p className="subText">
                  Select the start date and end date of the event and add the
                  event details. Select from dropdown or add your custom text.
                </p>
              </Col>
              <Col style={{ marginLeft: -40 }}>
                <Col className="dayTime">
                  {/* <FormInput
                  label=""
                  name="year"
                  placeholder="Year"
                  form={props.form}
                  colSpan="4"
                  onChange={val => {
                    setYearVal(val);
                  }}
                  year
                  defaultValue={
                    props.getYearlyCalenderData.length > 0 && yearlyArr[0]
                      ? yearlyArr[0].year && yearlyArr[0].year
                      : ""
                  }
                /> */}
                  <Dropdown
                    form={props.form}
                    label=""
                    xsval="4"
                    options={commonYear}
                    name="year"
                    placeholder="Year"
                    isRequired={true}
                    onChange={v => {
                      props.setContentChanged(true)
                      setYearVal(v)
                    }}
                    defaultValue={
                      props.getYearlyCalenderData.length > 0 && props.getYearlyCalenderData[0]
                        ? props.getYearlyCalenderData[0].year && props.getYearlyCalenderData[0].year
                        : props.getYearlyCalenderData.length === 0 ? yearVal : ''
                    }
                  />
                </Col>
                {(childCare === '1' || childCare === '3') &&
                  <>
                    <div className='paddingLeft selectDate'>
                      <h4 className="subHeading">Preschool</h4>

                    </div>
                    <div className="selectDate setWidth">
                      <li>Event</li>
                      <li>Select Date</li>
                      <li>End Date</li>
                    </div>
                    {yearShow &&
                      yearShow.map((a, id) => (
                        <>
                          <Col className="dayTime">
                            <Select
                              form={props.form}
                              label=""
                              colSpan="4"
                              options={uniq(yearDropdown)}
                              name={`event0${id + 1}`}
                              // defaultValue={
                              //   yearlyArr.length > 0 && yearlyArr[id]
                              //     ? yearlyArr[id].event
                              //     : null
                              // }
                              onChange={val => {
                                let yrs = [...yearDropdown];
                                let yearName = yrs.map(a => a.value);
                                if (!yearName.includes(val.value)) {
                                  yrs.push(val);
                                }
                                if (DEFAULT_YEAR_DUPLICATE.includes(val.value)) {
                                  let obj = {};
                                  obj.event = val.value;
                                  holiday_list.map(a => {
                                    if (a.label === val.value) {
                                      obj.start_date = a.start;
                                      obj.to_date = a.end;
                                    }
                                  })
                                  yearlyCalenderPreSchool(id, obj);
                                } else {
                                  yearlyCalenderFn(id, "event", val.value);
                                }
                                setYearDropdown(yrs);
                              }}
                              isResponsivePadding
                              msg="Event is required"
                              isRequired={
                                yearlyArr &&
                                  yearlyArr[id] ? true
                                  : (id === 0 && yearlyArr.length === 0
                                    ? true
                                    : false)
                              }
                            />
                            <DatePicker
                              form={props.form}
                              onChange={val => {
                                yearlyCalenderFn(id, "start_date", val);
                              }}
                              isRequired={id === 0 ? true : false}
                              colSpan="4"
                              submit={submit}
                              defaultValue={
                                yearlyArr.length > 0 &&
                                yearlyArr[id] &&
                                yearlyArr[id].start_date
                              }
                              minDate={yearVal}
                            />
                            <DatePicker
                              form={props.form}
                              onChange={val => yearlyCalenderFn(id, "to_date", val)}
                              isRequired={id === 0 ? true : false}
                              colSpan="4"
                              submit={submit}
                              defaultValue={
                                yearlyArr.length > 0 &&
                                yearlyArr[id] &&
                                yearlyArr[id].to_date
                              }
                              minDate={yearVal}
                              displayErr={yearVal}
                              minStartDate={yearlyArr.length > 0 &&
                                yearlyArr[id] &&
                                yearlyArr[id].start_date}
                            />

                            <span
                              className="trashIcon"
                              onClick={() => {
                                let sch = [...yearlyArr];
                                let sch1 = [...yearlyArr1];

                                let actD = [...yearShow];
                                let removeFromDropdown = [...yearDropdown];
                                const { form } = props;

                                form.setFieldsValue({
                                  [`event0${id + 1}`]: null,
                                });

                                let datas = sch.filter((a, i) => i === id);
                                let deleteData = datas[0];
                                if (sch[id] && sch[id].event) {
                                  if (
                                    !DEFAULT_YEAR_DUPLICATE.includes(sch[id].event)
                                  ) {
                                    removeFromDropdown = removeFromDropdown.filter(
                                      a => a.value !== sch[id].event
                                    );
                                  }
                                }
                                setYearDropdown(removeFromDropdown);
                                if (deleteData && deleteData.id) {
                                  props.deleteYearApi(deleteData);
                                  sch.splice(sch.indexOf(datas[0]), 1);
                                } else {
                                  sch.splice(id, 1);
                                }
                                if (actD.length > 2) {
                                  actD.pop();
                                }
                                setYearShow(actD);
                                setYearlyArr(sch);

                                if (sameCalender) {
                                  form.setFieldsValue({
                                    [`event1${id + 1}`]: null,
                                  });
                                  let datas1 = sch1.filter((a, i) => i === id);
                                  let deleteData1 = datas1[0];

                                  if (deleteData1 && deleteData1.id) {
                                    props.deleteYearApi(deleteData1);
                                    sch1.splice(sch1.indexOf(datas1[0]), 1);
                                  } else {
                                    sch1.splice(id, 1);
                                  }
                                  setYearShow1(actD);
                                  setYearlyArr1(sch1);

                                }
                                props.setContentChanged(true)

                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                className="trash"
                                src={Trash}
                                alt="trash"
                              />
                              <img
                                className="trashHover"
                                src={TrashHover}
                                alt="trash"
                              />
                            </span>
                          </Col>
                          {yearlyArr && yearlyArr[id] && yearlyArr[id].err && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 11,
                                fontWeight: 300,
                                marginTop: -25,
                                marginLeft: 50
                              }}
                            >
                              {typeof yearlyArr[id].err === "string"
                                ? yearlyArr[id].err
                                : "Start date should be less than End date"}{" "}
                            </div>
                          )}
                        </>
                      ))}
                    <Col className="dayTime">
                      <Select
                        form={props.form}
                        colSpan="4"
                        options={uniq(yearDropdown)}
                        name="events"
                        onChange={val => {
                          setNewCalenderEvENt(val.value);
                          holiday_list.map(a => {
                            if (a.label === val.value) {
                              setNewCalenderStart(a.start);
                              setNewCalenderTo(a.end);
                            }
                          })
                        }}
                        // defaultValue={" "}
                        isRequired={false}
                        isResponsivePadding
                      />
                      <DatePicker
                        form={props.form}
                        onChange={val => setNewCalenderStart(val)}
                        isRequired={false}
                        colSpan="4"
                        defaultValue={newCalenderStart}
                        minDate={yearVal}
                      />
                      <DatePicker
                        form={props.form}
                        onChange={val => setNewCalenderTo(val)}
                        isRequired={false}
                        colSpan="4"
                        defaultValue={newCalenderTo}
                        minDate={yearVal}
                        displayErr={yearVal}
                        minStartDate={newCalenderStart}
                      />

                      {/* <FormInput
                  form={props.form}
                  label=""
                  colSpan="4"
                  name="events"
                  defaultValue={newCalenderEvent}
                  onChange={val => setNewCalenderEvENt(val)}
                  isRequired={false}
                  placeholder="Enter Event"
                /> */}
                      <span
                        onClick={() => {
                          if (
                            newCalenderStart &&
                            newCalenderTo &&
                            newCalenderEvent
                          ) {
                            props.setContentChanged(true)

                            setyearlyAddErr(false)

                            var actD = [...yearShow];
                            var sch = [...yearlyArr];
                            var drp = [...yearDropdown];
                            let filterDrop = drp.filter(a => a.value);
                            if (!filterDrop.includes(newCalenderEvent)) {
                              drp.push({
                                label: newCalenderEvent,
                                value: newCalenderEvent,
                                disabled: false
                              });
                            }
                            setYearDropdown(drp);
                            actD.push(1);
                            setYearShow(actD);
                            sch.push({
                              start_date: newCalenderStart,
                              to_date: newCalenderTo,
                              event: newCalenderEvent,
                              year: yearVal,
                              school_type: "preschool"
                            });

                            if (sameCalender) {
                              let dayCareCopy = cloneDeep(yearlyArr1);
                              dayCareCopy.push({
                                start_date: newCalenderStart,
                                to_date: newCalenderTo,
                                event: newCalenderEvent,
                                year: yearVal,
                                school_type: "day_care"
                              });
                              setYearShow1(dayCareCopy);
                              setYearlyArr1(dayCareCopy);
                            }



                            setYearlyArr(sch);
                            setNewCalenderStart("");
                            setNewCalenderTo("");
                            setNewCalenderEvENt("");
                          } else {
                            setyearlyAddErr(true)
                          }
                          const { form } = props;

                          form.setFieldsValue({
                            [`events`]: null,
                          });

                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <span className="addEvent"> Add</span>
                      </span>
                    </Col>
                    {yearlyAddErr && <div
                      style={{
                        color: "red",
                        fontSize: 11,
                        fontWeight: 300,
                        marginTop: -15,
                        marginLeft: 45
                      }}
                    >{typeof yearlyAddErr === 'string' ? yearlyAddErr : 'Select all three field'}</div>}

                  </>}



                {(childCare === '2' || childCare === '3') &&
                  <>
                    <div className='paddingLeft'>
                      <h4 className="subHeading">Daycare</h4>
                    </div>
                    {childCare === '3' && <div className="col3" style={{ marginLeft: 45, marginBottom: 20, marginTop: 20 }}>
                      <div class="customCheckBox form-check" onClick={() => {
                        if (!sameCalender) {
                          openSameCalenderModal(true)
                        } else {
                          openSameCalenderModal(false);
                          setSameCalender(false);
                          globalCalender = false
                        }

                      }

                      }>
                        <input
                          novalidate=""
                          name={`sameCalender`}
                          xsval="6"
                          type="checkbox"
                          class="form-check-input"
                          checked={sameCalender}

                        />
                        <label
                          for={`sameCalender`}
                          class="form-check-label"
                          style={{ marginBottom: 0 }}
                        >
                          <span>Same Calendar for daycare</span>
                        </label>
                      </div>
                    </div>}
                    <div className="selectDate setWidth">
                      <li>Event</li>
                      <li>Select Date</li>
                      <li>End Date</li>
                    </div>


                    {yearShow1 &&
                      yearShow1.map((a, id) => (
                        <>
                          <Col className="dayTime">
                            <Select
                              form={props.form}
                              label=""
                              colSpan="4"
                              options={uniq(yearDropdown)}
                              name={`event1${id + 1}`}
                              // defaultValue={
                              //   yearlyArr1.length > 0 && yearlyArr1[id]
                              //     ? yearlyArr1[id].event
                              //     : null
                              // }
                              onChange={val => {

                                let yrs = [...yearDropdown];
                                let yearName = yrs.map(a => a.value);
                                if (!yearName.includes(val.value)) {
                                  yrs.push(val);
                                }
                                if (DEFAULT_YEAR_DUPLICATE.includes(val.value)) {
                                  let obj = {};
                                  obj.event = val.value;
                                  holiday_list.map(a => {
                                    if (a.label === val.value) {
                                      obj.start_date = a.start;
                                      obj.to_date = a.end;
                                    }
                                  })
                                  yearlyCalenderDaycare(id, obj);
                                } else {
                                  yearlyCalenderFn1(id, "event", val.value);
                                }

                                setYearDropdown(yrs);
                              }}
                              disabled={sameCalender}

                              isResponsivePadding
                              msg="Event is required"
                              isRequired={
                                yearlyArr1 &&
                                  yearlyArr1[id] &&
                                  !yearlyArr1[id].programm
                                  ? true
                                  : id === 0 && yearlyArr1.length === 0
                                    ? true
                                    : false
                              }
                            />
                            <DatePicker
                              form={props.form}
                              onChange={val => {
                                yearlyCalenderFn1(id, "start_date", val);
                              }}
                              isRequired={id === 0 ? true : false}
                              colSpan="4"
                              submit={submit}
                              disabled={sameCalender}

                              defaultValue={
                                yearlyArr1.length > 0 &&
                                yearlyArr1[id] &&
                                yearlyArr1[id].start_date
                              }
                              minDate={yearVal}
                            />
                            <DatePicker
                              form={props.form}
                              onChange={val => yearlyCalenderFn1(id, "to_date", val)}
                              isRequired={id === 0 ? true : false}
                              colSpan="4"
                              submit={submit}
                              disabled={sameCalender}

                              defaultValue={
                                yearlyArr1.length > 0 &&
                                yearlyArr1[id] &&
                                yearlyArr1[id].to_date
                              }
                              minDate={yearVal}
                              displayErr={yearVal}
                              minStartDate={yearlyArr1.length > 0 &&
                                yearlyArr1[id] &&
                                yearlyArr1[id].start_date}
                            />

                            {!sameCalender && <span
                              className="trashIcon"
                              onClick={() => {
                                let sch = [...yearlyArr1];
                                let actD = [...yearShow1];
                                let removeFromDropdown = [...yearDropdown];
                                const { form } = props;

                                form.setFieldsValue({
                                  [`event1${id + 1}`]: null,
                                });

                                let datas = sch.filter((a, i) => i === id);
                                let deleteData = datas[0];
                                if (sch[id] && sch[id].event) {
                                  if (
                                    !DEFAULT_YEAR_DUPLICATE.includes(sch[id].event)
                                  ) {
                                    removeFromDropdown = removeFromDropdown.filter(
                                      a => a.value !== sch[id].event
                                    );
                                  }
                                }
                                setYearDropdown(removeFromDropdown);
                                if (deleteData && deleteData.id) {
                                  props.deleteYearApi(deleteData);
                                  sch.splice(sch.indexOf(datas[0]), 1);
                                } else {
                                  sch.splice(id, 1);
                                }

                                if (actD.length > 2) {
                                  actD.pop();
                                }
                                setYearShow1(actD);

                                setYearlyArr1(sch);
                                props.setContentChanged(true)

                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                className="trash"
                                src={Trash}
                                alt="trash"
                              />
                              <img
                                className="trashHover"
                                src={TrashHover}
                                alt="trash"
                              />
                            </span>}
                          </Col>
                          {yearlyArr1 && yearlyArr1[id] && yearlyArr1[id].err && (
                            <div
                              style={{
                                color: "red",
                                fontSize: 11,
                                fontWeight: 300,
                                marginTop: -25,
                                marginLeft: 50
                              }}
                            >
                              {typeof yearlyArr1[id].err === "string"
                                ? yearlyArr1[id].err
                                : "Start date should be less than End date"}{" "}
                            </div>
                          )}
                        </>
                      ))}


                    {!sameCalender && <Col className="dayTime">
                      <Select
                        form={props.form}
                        colSpan="4"
                        options={uniq(yearDropdown)}
                        name="events1"
                        onChange={val => {
                          setNewCalenderEvENt1(val.value)
                          holiday_list.map(a => {
                            if (a.label === val.value) {
                              setNewCalenderStart1(a.start);
                              setNewCalenderTo1(a.end);
                            }
                          })
                        }}
                        // defaultValue={" "}
                        isRequired={false}
                        isResponsivePadding
                      />
                      <DatePicker
                        form={props.form}
                        onChange={val => setNewCalenderStart1(val)}
                        isRequired={false}
                        colSpan="4"
                        defaultValue={newCalenderStart1}
                        minDate={yearVal}
                      />
                      <DatePicker
                        form={props.form}
                        onChange={val => setNewCalenderTo1(val)}
                        isRequired={false}
                        colSpan="4"
                        defaultValue={newCalenderTo1}
                        minDate={yearVal}
                        displayErr={yearVal}
                        minStartDate={newCalenderStart1}
                      />
                      <span
                        onClick={() => {
                          if (
                            newCalenderStart1 &&
                            setNewCalenderTo1 &&
                            newCalenderEvent1
                          ) {
                            props.setContentChanged(true)

                            setyearlyAddErr1(false)
                            var actD = [...yearShow1];
                            var sch = [...yearlyArr1];
                            var drp = [...yearDropdown];
                            let filterDrop = drp.filter(a => a.value);
                            if (!filterDrop.includes(newCalenderEvent1)) {
                              drp.push({
                                label: newCalenderEvent1,
                                value: newCalenderEvent1,
                                disabled: false
                              });
                            }
                            setYearDropdown(drp);
                            actD.push(1);
                            setYearShow1(actD);
                            sch.push({
                              start_date: newCalenderStart1,
                              to_date: newCalenderTo1,
                              event: newCalenderEvent1,
                              year: yearVal,
                              school_type: "day_care"
                            });
                            setYearlyArr1(sch);
                            setNewCalenderStart1("");
                            setNewCalenderTo1("");
                            setNewCalenderEvENt1("");
                          } else {
                            setyearlyAddErr1(true)
                          }
                          const { form } = props;

                          form.setFieldsValue({
                            [`events1`]: null,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <span className="addEvent"> Add</span>
                      </span>
                    </Col>}



                    {yearlyAddErr1 && !sameCalender && <div
                      style={{
                        color: "red",
                        fontSize: 11,
                        fontWeight: 300,
                        marginTop: -15,
                        marginLeft: 45
                      }}
                    >{typeof yearlyAddErr1 === 'string' ? yearlyAddErr1 : 'Select all three field'}</div>}


                  </>}


              </Col>

            </Row>



            <Row className="formCheckbox marginBottom20">
              <Col xs="4" style={{ paddingLeft: 30 }}>
                <div
                  style={{
                    position: "relative",
                    maxWidth: "253px"
                  }}
                  className="formInputName"
                >
                  Activities for preschool and daycare children * 
                  <span className="information">
                    <Icon path={mdiInformationOutline} />
                    <div className="tooltip-hover">
                      <span>Select all that applies</span>
                    </div>
                  </span> 
                </div>
              </Col>
              <Col
                xs="8"
                style={{
                  paddingLeft: 5,
                  paddingTop: 10,
                  display: "flex",
                  flexFlow: "row wrap"
                }}
              >
                {reasons &&
                  reasons.map((k, i) => (
                    <CheckBox
                      label={k.label}
                      name={`${k.label}act`}
                      isRequired={false}
                      xsval="6"
                      key={`reason_${i}`}
                      onClick={() => handleReasonCheck(i)}
                      form={props.form}
                      checked={k.checked}
                    />
                  ))}
                {checkedReasonsErr && (
                  <Col style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                    Select atleast one activity
                  </Col>
                )}
              </Col>
              <Col xs="4"></Col>
              <Col xs="8" style={{ marginTop: 20, paddingLeft: 8 }}>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <Col xs="4">
                    <h6 className="addMore">Add more activities</h6>
                    <Input
                      type="text"
                      value={activityValue}
                      onChange={e => {
                        setActivityValue(e.target.value)
                      }}
                      placeholder="Enter the Activity"
                    />
                  </Col>
                  <Col xs="3" style={{ paddingLeft: 0 }}>
                    <Button
                      // type="links"
                      className="addActivity"
                      onClick={() => {
                        if (activityValue) {
                          props.setContentChanged(true)

                          var actD = [...reasons];
                          var actName = actD.map(a => a.label);

                          activityValue.split(",").map(d => {
                            if (!actName.includes(d)) {
                              actD.push({ label: d, checked: true });
                            }
                          });
                          setCheckedReasonsErr(false)
                          setReasons(actD);
                          setActivityValue("");
                        }
                      }}
                      style={{ display: 'flex', alignItems: 'center' }}

                    >
                      <div className="plusIcon1">
                        <span class="iconify" data-icon="fe:plus" data-inline="false"></span>
                      </div>
                      Add activity
                    </Button>
                  </Col>
                </div>
              </Col>
            </Row>

            <Row className="radioBtn marginBottom20">
              <Row style={{ width: "100%" }}>
                <Col xs="4" style={{ paddingLeft: 30 }} className="formInputName">
                  Summer classes provided? *
                </Col>
                <Col style={{ paddingLeft: 15 }}>
                  <Row style={{ paddingLeft: 15, marginTop: 10 }}>
                    <RadioButton
                      name="Yes1"
                      label="Yes"
                      form={props.form}
                      xsval="2"
                      checked={summerclass === "yes"}
                      onClick={label => {
                        props.setContentChanged(true)
                        setsummerclass("yes")
                      }}
                    />
                    <RadioButton
                      name="No1"
                      form={props.form}
                      label="No"
                      xsval="2"
                      checked={summerclass === "no"}
                      onClick={label => {
                        props.setContentChanged(true)
                        setsummerclass("no")
                      }}
                    />
                  </Row>
                  {summerclass === "yes" && (
                    <Row className="achievement" style={{ paddingLeft: 15 }}>
                      {/* <FormInput
                           form={props.form}
                           name="achievement"
                           placeholder="Mention any awards or recognition"
                           label="Achievement"
                           type="textarea"
                           classtype="column"
                           isRequired={false}
                           responsive
                           paddingLeft={10}
                         /> */}
                      <Col>
                        <Row>
                          {summerClassCount.map((val, id) => {

                            summerClassArr.push(
                              <Col xs='6' className='buttonSchool' style={{ paddingLeft: 33, paddingRight: 20, marginRight: 20, marginBottom: 20 }}>
                                <Input
                                  type="text"
                                  value={summerClassData[id] || ''}
                                  onChange={e => {
                                    props.setContentChanged(true)

                                    let achieve = [...summerClassData];
                                    achieve[id] = (e.target.value);
                                    setsummerClassData(achieve)
                                  }}
                                  placeholder="Enter the type of the class"
                                />
                                <span
                                  className="trashIcon"
                                  onClick={() => {

                                    let sc = [...summerClassData];
                                    let count = [...summerClassCount];
                                    if (sc.length === count.length)
                                      props.setContentChanged(true)
                                    sc.splice(id, 1);
                                    setsummerClassData(sc)
                                    if (count.length > 1)
                                      count.pop()
                                    setsummerClassCount(count)
                                  }}
                                  style={{ position: 'absolute', right: -30 }}
                                >
                                  <img
                                    className="trash"
                                    src={Trash}
                                    alt="trash"
                                  />
                                  <img
                                    className="trashHover"
                                    src={TrashHover}
                                    alt="trash"
                                  />
                                </span>
                              </Col>
                            );
                            return '';
                          }
                          )}
                          {summerClassArr}
                          <Col style={{ paddingLeft: 40, marginLeft: 10 }}>
                            <div className="plusIcon" onClick={() => {
                              let ct = [...summerClassCount];
                              if (summerClassCount.length === summerClassData.length) {
                                ct.push(1);
                                setsummerClassCount(ct)
                              }

                            }}>
                              <span class="iconify" data-icon="fe:plus" data-inline="false"></span>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                    </Row>

                    // <Row style={{ paddingLeft: 15 }}>
                    //   <FormInput
                    //     form={props.form}
                    //     name="summer_classes_offered_description"
                    //     placeholder="Give more details with comma seperator"
                    //     label=""
                    //     colSpan="6"
                    //     type="textarea"
                    //     defaultValue={
                    //       props.data && props.data.length > 0
                    //         ? props.data[0].summer_classes_offered_description
                    //         : ""
                    //     }
                    //   />
                    // </Row>
                  )}
                </Col>
              </Row>
            </Row>
          </CardBody>
        </div>

        {/* <div className="">
          <CardBody className="formContent">
            
          </CardBody>
        </div> */}

        <CardFooter>
          <Row className="formFlex" style={{ margin: 0 }}>
            <Col xs="4"></Col>
            <Col xs="8">
              {finalSubmit && (
                <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                  Some fields are not filled
                </div>
              )}

              <Button type="submit" disabled={disableds}>
                {props.data.length > 0 ? "Update" : "Save"} and Continue
              </Button>
              {/* <Button
                onClick={() => props.data.length > 0 ? handleContinue() : !disableds && handleContinue()}
                disabled={props.data.length > 0 ? false : disableds}
              >
                Continue
              </Button> */}
            </Col>
          </Row>
        </CardFooter>
      </Form>{" "}
      {deleteBatchModal && (
        <Modal
          className="modal-dialog-centered"
          isOpen={deleteBatchModal}
          toggle={() => {
            setDeleteBatchModal(false);
            setBatchId(null);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Are you sure want to delete?
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDeleteBatchModal(false);
                setBatchId(null);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDeleteBatchModal(false);
                setBatchId(null);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                const temp = [...workingArr1];
                const batches = [...batch];
                props.setContentChanged(true)

                workingArr1[batchId] &&
                  workingArr1[batchId].map(
                    d => d.id && props.deleteWorkingDay(d)
                  );
                // props.deleteWorkingDay(workingArr1[id]);
                remove(temp, (n, i) => i === batchId);
                remove(batches, (n, i) => i === batchId);
                // const dummy = [...batch];
                // dummy.push(1);
                setBatch(batches);
                setWorkingArr1(temp);
                setDeleteBatchModal(false);
                let dummyCount = [...batchDropdown];
                dummyCount.splice(batchId, 1);
                setBatchDropdown(dummyCount)
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
      )}
      {openHolidayModal && (
        <Modal
          className="modal-dialog-centered"
          isOpen={openHolidayModal}
          toggle={() => {
            setOpenHolidayModal(false);
            globalId = null;
            let chk = { ...holidayCheck };
            chk[globalHolidayKey] = false;
            setHolidayCheck(chk)
            globalHolidayKey = null;
            globalIndex = null;
          }}
        >
          <div className="modal-header">
            <div><h5 className="modal-title" id="exampleModalLabel">
              Are you sure want to mark as holiday ?
            </h5>
              <h5 className="modal-title" id="exampleModalLabel">
                If so data entered will be lost.
            </h5>
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setOpenHolidayModal(false);
                globalId = null;
                let chk = { ...holidayCheck };
                chk[globalHolidayKey] = false;
                setHolidayCheck(chk)
                globalHolidayKey = null;
                globalIndex = null;
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setOpenHolidayModal(false);
                globalId = null;
                let chk = { ...holidayCheck };
                chk[globalHolidayKey] = false;
                setHolidayCheck(chk)
                globalHolidayKey = null;
                globalIndex = null;
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                props.setContentChanged(true)

                const preTemp = [...workingArr1];
                const dcTemp = [...workingArr];
                if (globalId) {
                  if (globalHolidayKey === 'dcholiday3') {
                    props.deleteWorkingDay(dcTemp[1]);
                    dcTemp[1].err = false;
                    dcTemp[1].err1 = false;
                    delete dcTemp[1];
                  } else if (globalHolidayKey === 'dcholiday4') {
                    dcTemp[2].err = false;
                    dcTemp[2].err1 = false;
                    props.deleteWorkingDay(dcTemp[2]);
                    delete dcTemp[2];
                  } else {
                    props.deleteWorkingDay(preTemp[globalHolidayKey.slice(globalHolidayKey.length - 1)][globalIndex]);
                    preTemp[globalHolidayKey.slice(globalHolidayKey.length - 1)][globalIndex].err = false;
                    preTemp[globalHolidayKey.slice(globalHolidayKey.length - 1)][globalIndex].err1 = false;
                    delete preTemp[globalHolidayKey.slice(globalHolidayKey.length - 1)][globalIndex];
                  }
                } else {
                  if (globalHolidayKey === 'dcholiday3') {
                    dcTemp[1].err = false;
                    dcTemp[1].err1 = false;
                    delete dcTemp[1];

                  } else if (globalHolidayKey === 'dcholiday4') {
                    dcTemp[2].err = false;
                    dcTemp[2].err1 = false;
                    delete dcTemp[2];
                  } else {
                    delete preTemp[globalHolidayKey.slice(globalHolidayKey.length - 1)][globalIndex];

                  }
                }
                setWorkingArr1(preTemp)
                setWorkingArr(dcTemp)
                globalId = null;
                setOpenHolidayModal(false);
                globalHolidayKey = null;
              }}
            >
              Yes
  </Button>
          </div>
        </Modal>
      )}

      {sameCalenderModal && (
        <Modal
          className="modal-dialog-centered"
          isOpen={sameCalenderModal}
          toggle={() => {
            openSameCalenderModal(false);
          }}
        >
          <div className="modal-header">
            <div> <h5 className="modal-title" id="exampleModalLabel">
              Are you sure want to mark same to daycare ?
            </h5>
              <h5 className="modal-title" id="exampleModalLabel">
                The data entered in preschool will replicate in daycare.
                </h5>
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                openSameCalenderModal(false);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                openSameCalenderModal(false);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                props.setContentChanged(true)

                setSameCalender(!sameCalender)
                globalCalender = false
                openSameCalenderModal(false);
              }}
            >
              Yes
  </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

BasicInfo.propTypes = {};

export default createForm()(BasicInfo);
