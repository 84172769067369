import React, { useCallback, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// import PDFViewer from 'pdf-viewer-reactjs'
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import {
  Col,
  FormFeedback,
  Label,
  Media,
  Modal,
  Row,
  Button
} from "reactstrap";
import Cropper from "react-easy-crop";
import { API_MEDIA } from "../../config/apiEndpoints";
import axios from "../../utils/axios";
import download from "../../assets/img/icons/common/download (1).png";
import pdf from "../../assets/img/pdf.png";
import blueSpinner from "../../assets/img/blueSpinner.gif";
import { envType } from '../../config/baseURL';
export const getUploadURL = type => {
  if (type === "avatar") {
    // https://daycareprodbe.kiddenz.com/ http://52.66.208.57/api/ 13.235.51.70-test
    return `${API_MEDIA}upload-file/`;
  }
  if (type === "banner") {
    return `${API_MEDIA}upload-misc-file/`;
  }

    if (type === "360picture") {
        return envType === 'staging' ? `https://daycarebe.kiddenz.com/daycare/api/media/upload-360-provider-image/` : `https://daycareprodbe.kiddenz.com/360/api/media/upload-360-provider-image/`;
      }
  return false;
};

const FileUploader = ({
  type,
  width,
  setTagErr,
  tagName,
  photoUrl,
  disabled,
  attachmentName,
  pdfUpload,
  height,
  isResponsive,
  isPhoto360,
  imageSize,
  labelStyle,
  photosVideos,
  errMargin,
  teacherstaff,
  topMargin,
  justify,
  form,
  images,
  name,
  setCancelImg,
  xsval,
  marginLeft,
  label,
  uploadUrl,
  urlImage,
  uploadText,
  deleteMedia,
  deleted = false,
  displayFlex,
  idVal,
  acceptType,
  uploadIcon,
  isLogo,
  cropFeatureDisabled,
  isRequired = true
}) => {
  const [uploadError, setUploadError] = useState("");
  const [imgId, setImgId] = useState("");
  const [dimensionErr, setDimensionErr] = useState("");
  const [imageName, setImageName] = useState("");
  const [cancel, setCancel] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [idData, setIdData] = useState(null);
  const [result, setResult] = useState();
  const [imgRef, setImgRef] = useState(null);
  const [source, setSource] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedAreaPixels, croppedArea);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", error => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }
  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));

    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
      canvas.toBlob(file => {
        console.log(file);
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        source,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      uploadImages(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  // const onImageLoaded = useCallback(img => {
  //   setImgRef(img);
  // }, []);

  // const onCropComplete = crop => {
  //   makeClientCrop(crop);
  // };

  // const onCropChange = (crop, percentCrop) => {
  //   setCrop(crop);
  // };

  // const makeClientCrop = crop => {
  //   if (imgRef && crop.width && crop.height) {
  //     const croppedImageUrl = getCroppedImg(imgRef, crop, "newFile.jpeg");
  //     setResult(croppedImageUrl);
  //   }
  // };

  // useEffect(() => {
  //   makeClientCrop(crop);
  // }, [crop]);

  // const getCroppedImg = (image, crop, fileName) => {
  //   const canvas = document.createElement("canvas");
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;
  //   canvas.width = crop.width;
  //   canvas.height = crop.height;
  //   const ctx = canvas.getContext("2d");

  //   ctx.drawImage(
  //     image,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     crop.width,
  //     crop.height
  //   );

  //   return canvas.toDataURL("image/jpeg");
  // };

  const ref = useRef(
    form.getFieldProps(name, {
      validateTrigger: "onChange",
      rules: isRequired ? [{ required: true }] : []
    })
  );

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles, ...rest) => {
      if (
        (tagName && type === "360picture") ||
        type === "banner" ||
        type === "avatar"
      ) {
        setTagErr && setTagErr(false);
        setDimensionErr("");
        if (rejectedFiles && rejectedFiles.length) {
          setUploadError(`Something went wrong...Upload the image again`);
        } else if (acceptedFiles && acceptedFiles.length) {
          if (!pdfUpload && !cropFeatureDisabled) {
            const onSelectFile = e => {
              if (e[0].target.files && e[0].target.files.length > 0) {
                const reader = new FileReader();
                console.log(reader.result);
                reader.addEventListener("load", () => setSource(reader.result));
                reader.readAsDataURL(e[0].target.files[0]);
              }
            };
            onSelectFile(rest);
          }
          // disable crop for 360
          if (pdfUpload || cropFeatureDisabled) {
            if (uploadError) {
              setUploadError("");
            }
            const fd = new FormData();
            fd.append("media", acceptedFiles[0]);
            if (tagName) {
              fd.append("tag_name", tagName.split(" ").join("_"));
            }
            const url = getUploadURL(type);
            axios
              .post(url, fd)
              .then(res => res.data.data)
              .then(file => {
                var img = new Image();
                img.src = file.image_url;
                if (!pdfUpload) {
                  img.onload = function() {
                    if (this.width >= width && this.height >= height) {
                      ref.current.onChange(file.image_url);
                      uploadUrl(file.id);
                      setImgId(file.id);
                      // setIdData(file.id)
                      setImageUrl(file.image_url);
                      setCancel(false);
                      setDimensionErr("");
                    } else {
                      ref.current.onChange("");
                      setDimensionErr(
                        `Please make sure dimensions are greater than ${width}x${height}`
                      );
                    }
                  };
                } else {
                  ref.current.onChange(file.image_url);
                  uploadUrl(file.id);
                  setImgId(file.id);
                  setImageUrl(file.image_url);
                  setImageName(file.image_name);
                  setCancel(false);
                  setDimensionErr("");
                }
              })
              .catch(() => ref.current.onChange(""));
          }
        }
      } else {
        setTagErr && setTagErr(true);
      }
    },
    [type, uploadError, uploadUrl]
  );

  const uploadImages = img => {
    setDimensionErr("");
    if (uploadError) {
      setUploadError("");
    }
    setImageLoading(true);
    const url1 = img;
    let binary = "";
    fetch(url1)
      .then(res => res.blob())
      .then(a => {
        binary = a;
        const fd1 = new FormData();
        if (tagName) {
          fd1.append("tag_name", tagName.split(" ").join("_"));
        }
        fd1.append("media", binary);
        const url = getUploadURL(type);
        axios
          .post(url, fd1)
          .then(res => res.data.data)
          .then(file => {
            var img = new Image();
            img.src = file.image_url;
            img.onload = function() {
              ref.current.onChange(file.image_url);
              uploadUrl(file.id);
              photoUrl && photoUrl(file.image_url);
              setImgId(file.id);
              // setIdData(file.id)
              setImageUrl(file.image_url);
              setCancel(false);
              setDimensionErr("");
              setImageLoading(false);
              setSource(null);
            };
          })
          .catch(() => {
            ref.current.onChange("");
            setImageLoading(true);
            setSource(null);
          });
      });
    // setResult(null);
    // setImgRef(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // maxSize: (pdfUpload ? 51200 : isPhoto360 ? 51200 : 5120) * 1024,
    // minSize: ((pdfUpload || isLogo) ? 0 : 100) * 1024,
    multiple: false,
    accept: acceptType ? "application/pdf" : "image/jpeg, image/png",
    disabled: disabled
    // noClick: disabled
  });

  const error = uploadError || form.getFieldError(name);
  const value = form.getFieldValue(name);
  useEffect(() => {
    idVal && setIdData(idVal);
  }, [idVal]);
  return (
    <>
      <Col
        xs={isResponsive && window.screen.width < 1250 ? "5" : xsval || "12"}
        ref={ref.current && ref.current.ref}
        style={displayFlex}
      >
        {label && (
          <Label style={labelStyle}>
            {label}
            {isRequired && label ? "*" : ""}
          </Label>
        )}
        {value && !deleted ? (
          <Media>
            {/* <Media left> */}
            {pdfUpload ? (
              <>
                <Media left style={{ position: "relative" }}>
                  <img
                    src={pdf}
                    style={{
                      width: 50,
                      height: 50,
                      maxWidth: 75,
                      maxHeight: 75
                    }}
                    alt="pdfimage"
                    onClick={() => window.open(`${value.split(".pdf")[0]}.pdf`)}
                  ></img>
                  <div
                    class="redCloseIcon pdf"
                    onClick={() => {
                      setConfirmDeleteModal(true);
                    }}
                  >
                    {" "}
                    <i className="fa fa-times" aria-hidden="true" />
                  </div>
                </Media>

                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#60b947",
                    marginLeft: 10
                  }}
                >
                  {imageName && `${imageName.split(".pdf")[0]}.pdf`}
                </p>
              </>
            ) : (
              <Media left style={{ position: "relative" }}>
                <Media
                  object
                  src={value && value}
                  alt="Generic placeholder image"
                  style={{
                    width: imageSize || 200,
                    height: imageSize || 135,
                    maxWidth: imageSize || 200,
                    maxHeight: imageSize || 135,
                    margin: "1rem 0px"
                    // marginRight: 40
                  }}
                />
                <div
                  class="redCloseIcon"
                  style={{ right: 25 }}
                  onClick={() => {
                    window.open(value && value, "_blank");
                  }}
                >
                  {" "}
                  <i className="fa fa-download" aria-hidden="true" />
                </div>
                <div
                  class="redCloseIcon"
                  onClick={() => {
                    if (!disabled) {
                      setConfirmDeleteModal(true);
                    }
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true" />
                </div>
              </Media>
            )}
          </Media>
        ) : name && !cancel && urlImage ? (
          <Media
            style={
              {
                // marginBottom: 20
              }
            }
          >
            <Media>
              {pdfUpload ? (
                <>
                  <Media left style={{ position: "relative" }}>
                    <img
                      src={pdf}
                      style={{
                        width: 50,
                        height: 50,
                        maxWidth: 75,
                        maxHeight: 75
                        // marginRight: 10
                      }}
                      alt="pdf"
                      onClick={() =>
                        window.open(`${urlImage.split(".pdf")[0]}.pdf`)
                      }
                    ></img>

                    <div
                      class="redCloseIcon pdf"
                      onClick={() => {
                        if (!disabled) {
                          setConfirmDeleteModal(true);
                        }
                      }}
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </div>
                  </Media>
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#60b947",
                      marginLeft: 10
                    }}
                  >
                    {attachmentName && `${attachmentName.split(".pdf")[0]}.pdf`}
                  </p>
                </>
              ) : (
                <Media left style={{ position: "relative" }}>
                  <Media
                    object
                    src={
                      photosVideos
                        ? imageUrl
                          ? imageUrl.includes("://")
                            ? imageUrl
                            : `http://${imageUrl}`
                          : urlImage &&
                            (urlImage.includes("://")
                              ? urlImage
                              : `http://${urlImage}`)
                        : teacherstaff
                        ? imageUrl
                          ? imageUrl.includes("://")
                            ? imageUrl
                            : `http://${imageUrl}`
                          : urlImage &&
                            (urlImage.includes("://")
                              ? urlImage
                              : `http://${urlImage}`)
                        : urlImage &&
                          urlImage.includes(".") &&
                          (urlImage.includes("://")
                            ? urlImage
                            : `http://${urlImage}`)
                    }
                    alt="Generic placeholder image"
                    style={{
                      width: imageSize || 200,
                      height: imageSize || 135,
                      maxWidth: imageSize || 200,
                      maxHeight: imageSize || 135,
                      margin: "1rem 0px"
                    }}
                  />
                  <div
                    class="redCloseIcon"
                    style={{ right: 25 }}
                    onClick={() => {
                      window.open(urlImage, "_blank");
                    }}
                  >
                    {" "}
                    <i className="fa fa-download" aria-hidden="true" />
                  </div>
                  <div
                    class="redCloseIcon"
                    onClick={() => {
                      if (!disabled) {
                        setConfirmDeleteModal(true);
                      }
                    }}
                  >
                    <i className="fa fa-times" aria-hidden="true" />
                  </div>
                </Media>
              )}
            </Media>
            <Media body></Media>
          </Media>
        ) : (
          <section>
            {uploadText ? (
              <div
                {...getRootProps()}
                style={{
                  display: "flex",
                  justifyContent: justify || "center",
                  alignItems: "center",
                  margin: "auto",
                  backgroundColor: disabled && "#f2f2f2"
                }}
              >
                <input {...getInputProps()} />
                <div
                  style={{
                    marginTop: topMargin || "-60px",
                    marginLeft: marginLeft,
                    cursor: "pointer"
                  }}
                >
                  {uploadText}
                </div>
              </div>
            ) : uploadIcon ? (
              <div
                {...getRootProps()}
                style={{
                  border: 0,
                  justifyContent: justify || "center",
                  alignItems: "center",
                  margin: "auto",
                  backgroundColor: disabled && "#f2f2f2"
                }}
              >
                <input {...getInputProps()} />
                <div>
                  <div
                    className="information1"
                    // style={{
                    //   marginTop: topMargin || "-60px",
                    //   marginLeft: marginLeft,
                    //   cursor: "pointer"
                    // }}
                  >
                    {uploadIcon}
                  </div>
                </div>
              </div>
            ) : (
              <div
                {...getRootProps()}
                className="fileUploaderStyle"
                style={{
                  borderColor: error ? "red" : "#ddd",
                  backgroundColor: disabled && "#f2f2f2"
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <span>Drop the files here ...</span>
                ) : (
                  <div className="uploadImage">
                    <div style={{ textAlign: "center" }}>Choose a file</div>
                    {/* <div>Min size - {type === "avatar" ? isPhoto360 ? 200 : 100 : 50} KB</div> */}

                    <img src={download} alt="" />
                  </div>
                )}
              </div>
            )}
          </section>
        )}

        {dimensionErr && (
          <div
            style={{
              display: "block",
              color: "red",
              fontSize: 11,
              fontWeight: 300,
              marginLeft: errMargin,
              marginTop: -15,
              marginBottom: 10
            }}
          >
            {dimensionErr}
          </div>
        )}

        {error && !dimensionErr && (
          <FormFeedback
            style={{
              display: "block",
              marginLeft: errMargin,
              marginTop: -15,
              marginBottom: 10
            }}
          >
            {error && error.includes("_") ? error.split("_").join(" ") : error}
          </FormFeedback>
        )}
      </Col>
      <Modal
        className="modal-dialog-centered"
        isOpen={source}
        toggle={() => {
          setSource(false);
        }}
        style={{ padding: "0 0 40px", position: "relative" }}
      >
        <div className="modal-header" style={{ paddingBottom: "0" }}>
          <h5
            className="modal-title"
            style={{ marginBottom: "10px", fontSize: "16px" }}
          >
            Crop Image
          </h5>
        </div>
        <div
          className="modal-body "
          style={{ minHeight: "200px", maxHeight: "500px" }}
        >
          {source && (
            // <ReactCrop
            //   src={source}
            //   crop={crop}
            //   ruleOfThirds
            //   onImageLoaded={onImageLoaded}
            //   onComplete={onCropComplete}
            //   onChange={onCropChange}
            // />
            <div className="cropContainer">
              <Cropper
                image={source}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={width / height}
                onCropChange={setCrop}
                // onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
              <div className="zoomInOut">
                {/* <i
                  className="fa fa-rotate-right"
                  aria-hidden="true"
                  
                ></i> */}
                <i
                  style={{ fontSize: 15 }}
                  className="fa"
                  onClick={() => rotation <= 360 && setRotation(rotation + 90)}
                >
                  &#xf01e;
                </i>

                <i
                  className="fa fa-undo"
                  aria-hidden="true"
                  style={{ fontSize: 15 }}
                  onClick={() => rotation > 0 && setRotation(rotation - 90)}
                ></i>

                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  style={{ fontSize: 15 }}
                  onClick={() => zoom <= 3 && setZoom(zoom + 0.1)}
                ></i>
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  style={{ fontSize: 15 }}
                  onClick={() => zoom > 0 && setZoom(zoom - 0.1)}
                ></i>
              </div>
            </div>
          )}
        </div>
        <div>
          <Row className="formFlex">
            <Col
              xs="5"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px"
              }}
            >
              <Button
                onClick={() => {
                  // uploadImages();
                  showCroppedImage();
                }}
              >
                {imageLoading ? (
                  <img
                    src={blueSpinner}
                    alt="spinner"
                    style={{ width: 20, height: 20 }}
                  />
                ) : (
                  "Crop"
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={confirmDeleteModal}
        toggle={() => {
          setConfirmDeleteModal(false);
        }}
      >
        <div className="modal-header">
          <div>
            {" "}
            <h5 className="modal-title" id="exampleModalLabel">
              Are you sure want to remove ?
            </h5>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              ref.current.onChange("");
              setCancelImg && setCancelImg(imgId);
              if (images) {
                let d = {};
                d.id = idData && idData;
                deleteMedia(d);
              }
              setCancel(true);
              setConfirmDeleteModal(false);
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
    // <Dropzone
    //   getUploadParams={getUploadParams}
    //   onChangeStatus={handleChangeStatus}
    //   accept="image/*"
    //   maxFiles={1}
    //   multiple={false}
    //   maxSizeBytes={(type === "avatar" ? 50 : 200) * 1024}
    //   SubmitButtonComponent={null}
    // />
  );
};

FileUploader.propTypes = {
  type: PropTypes.oneOf(["avatar", "banner"]).isRequired,
  onChangeCallback: PropTypes.func.isRequired
};

export default FileUploader;
