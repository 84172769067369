import React from 'react';
import PropTypes from 'prop-types';
import PhotoCard from './photoCard';
import image1 from '../../../assets/img/useer1.svg';
import Flex from './flex';
import styled from 'styled-components';
import profileIcon from '../../../assets/img/profilePlaceholder.jpg'

export const TestimonialCard = styled.div`
  width: 100%;
  padding: 30px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-weight: 300;
  background-color: #f3f5f9;
  @media screen and (max-width: 767px) {
  padding: 20px;
 }

`;
export const Name = styled.div`
  color: #30333b;
  font-family: 'Roboto', sans-serif;
  margin-left: 14px;
  font-size: 14px;
  line-height: 19px;
`;
export const CommentIcon = styled.div`
  .iconify {
    width: 40px;
    height: 40px;
    color: #4bd498;
    opacity: 0.2;
  }
`;
export const Comment = styled.div`
  color: #30333b;
  font-family: Quicksand;
  font-size: 14px;
  margin-left: 14px;
  font-weight: 500;
  line-height: 18px;
  @media screen and (max-width: 400px) {
    font-size: 12spx;
   }
`;

const Testimonial = ({ img, name, comment }) => (
  <TestimonialCard>
    <Flex alignCenter>
      <PhotoCard image={(img && img.includes('.') && img) || profileIcon} width="40px" height="40px" />
      <Name>{name}</Name>
    </Flex>
    <Flex>
      <CommentIcon>
        <span className="iconify" data-icon="ls:quote" data-inline="false" />
      </CommentIcon>
      <Comment>{comment}</Comment>
    </Flex>
  </TestimonialCard>
);
Testimonial.propTypes = {
  name: PropTypes.string,
  comment: PropTypes.string,
};
export default Testimonial;
