/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Container,
  CardTitle,
  CardText,
  Progress,
  Form,
  Button,
  Modal
} from "reactstrap";
import moment from "moment";
import AccountReject from "../../../components/AccountReject";

import { createForm } from "rc-form";
import RadioButton from "../../../components/RadioButton";
import TimePicker from "../../../components/TimePicker";
import DatePicker from "../../../components/DatePicker";
import BasicInfo from "./BasicInfo";
import PhotosVideos from "./Photos_Videos";
import Location from "./Location";
import Facilities from "./Facilities";
import history from "../../../utils/history";
import qs from "querystring";
import TeachersStaffs from "./TeachersStaffs";
import ContactDetails from "./ContactDetails";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PROVIDERS } from "../../../config/apiEndpoints";
import Picture from "../../../assets/img/preview/picture-images.png";
import Success from "../../../assets/img/icons/common/success-active.png";
import Unsuccess from "../../../assets/img/icons/common/success-inactive.png";
import FormInput from "../../../components/FormInput";
import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";

import responseToast from "../../../utils/responseToast";

let contentDate = "";
let contentTime = "";
let photoDate = "";
let photoTime = "";
let activeComponentData = null
let TIME_DROPDOWN = [
  { id: "9 AM - 10 AM", name: "9 AM - 10 AM", disabled: false },
  { id: "10 AM - 11 AM", name: "10 AM - 11 AM", disabled: false },
  { id: "11 AM - 12 PM", name: "11 AM - 12 PM", disabled: false },
  { id: "12 PM - 1 PM", name: "12 PM - 1 PM", disabled: false },
  { id: "1 PM - 2 PM", name: "1 PM - 2 PM", disabled: false },
  { id: "2 PM - 3 PM", name: "2 PM - 3 PM", disabled: false },
  { id: "3 PM - 4 PM", name: "3 PM - 4 PM", disabled: false },
  { id: "4 PM - 5 PM", name: "4 PM - 5 PM", disabled: false },
  { id: "5 PM - 6 PM", name: "5 PM - 6 PM", disabled: false },
  { id: "6 PM - 7 PM", name: "6 PM - 7 PM", disabled: false },
  { id: "7 PM - 8 PM", name: "7 PM - 8 PM", disabled: false },
  { id: "8 PM - 9 PM", name: "8 PM - 9 PM", disabled: false }
];
function ProviderProfile(props) {
  // const [component, setComponent] = useState('Basic Info');
  const [component, setComponent] = useState("Basic Info");
  const [providerModal, setProviderModal] = useState(false);
  const [activeComponent, setActiveComponent] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [openContentModal, setOpenContentModal] = useState(false);

  const [contentChanged, setContentChanged] = useState(false);
  const [contentChangedModal, setContentChangedModal] = useState(false);



  const [submit, setSubmit] = useState(false);
  const [contentSucess, setContentSuccess] = useState(false);
  const [pictureSuccess, setPictureSuccess] = useState(false);

  const [lastUpdated, setLastUpdated] = useState("");
  const [contentWriting, setContentWriting] = useState("0");
  const [pictures, setPictures] = useState("0");
  const [contentWritingDate, setContentWritingDate] = useState("");
  const [contentWritingTime, setContentWritingTime] = useState("");
  const [picturesDate, setPicturesDate] = useState("");
  const [picturesTime, setPicturesTime] = useState("");
  const [providerSteps, , , , providerStepsId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}provider-steps/`,
      readId: `${API_PROVIDERS}provider-steps/`
    }
  });

  // useEffect(() => {
  //   if (history.location.hash) {
  //     const { page } = qs.parse(history.location.hash.split("?")[1]);
  //     setComponent(
  //       page === "photos"
  //         ? "Photos/Videos"
  //         : page === "success"
  //           ? "Basic Info"
  //           : "Basic Info"
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (component === "Basic Info") setLastUpdated(new Date().toISOString());
  }, [component]);

  const [scheduleChoice, , , , scheduleChoiceId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}schedule-choice/`,
      readId: `${API_PROVIDERS}schedule-choice/`
    }
  });

  const [formData, , , formdataUpdate, formDataId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}`,
      update: `${API_PROVIDERS}provider-update/`,
      readId: `${API_PROVIDERS}`
    }
  });

  const [
    getBasicInfo,
    deleteForms,
    addForms,
    updateForms,
    getBasicInfoId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}provider-basic-info/`,
      delete: `${API_PROVIDERS}provider-basic-info/`,
      read: `${API_PROVIDERS}provider-basic-info/`,
      readId: `${API_PROVIDERS}provider-basic-info/`,
      update: `${API_PROVIDERS}provider-basic-info-update/`
    }
  });

  const [emergencyService, , , , emergencyServiceId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}emergency-services/`,
      readId: `${API_PROVIDERS}emergency-services/`
    }
  });

  const [languages, , , , languagesId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}languages/`,
      readId: `${API_PROVIDERS}languages/`
    }
  });

  const [staffSupport, , , , staffSupportId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}staff-support/`,
      readId: `${API_PROVIDERS}staff-support/`
    }
  });

  const [
    getFaculty,
    ,
    facultySubmit,
    updateFaculty,
    getFacultyId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}provider-faculty/`,
      readId: `${API_PROVIDERS}provider-faculty/`,

      create: `${API_PROVIDERS}provider-faculty/`,
      update: `${API_PROVIDERS}provider-faculty-update/`
    }
  });

  const [, deleteTeacherApi] = useCommonCRUD({
    CRUDaseUrl: {
      delete: `${API_PROVIDERS}provider-faculty/`
    }
  });

  const [getPhoto, , , , getPhotoId] = useCommonCRUD({
    CRUDaseUrl: {
    
      read: `${API_PROVIDERS}provider-media/`,
      readId: `${API_PROVIDERS}provider-media/`
    }
  });

  const [, deleteMedia] = useCommonCRUD({
    CRUDaseUrl: {
      delete: `${API_PROVIDERS}provider-media/`
    }
  });

  const [, ,submitPhoto] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}provider-media/`,
    }
  });

  const [, deleteWorkingDay] = useCommonCRUD({
    CRUDaseUrl: {
      delete: `${API_PROVIDERS}working-day/`
    }
  });

  const [
    contentSchedule,
    deleteContentSchedule,
    contentSchedulePost,
    updateContentSchedule,
    contentScheduleId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}provider-content-schedule/`,
      read: `${API_PROVIDERS}provider-content-schedule/`,
      readId: `${API_PROVIDERS}provider-content-schedule/`,
      delete: `${API_PROVIDERS}provider-content-schedule/`,

      update: `${API_PROVIDERS}provider-content-schedule-update/`
    }
  });

  const [
    getLocation,
    deleteLocation,
    setLocation,
    updateLocation,
    getLocationId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}provider-location/`,
      read: `${API_PROVIDERS}provider-location/`,
      delete: `${API_PROVIDERS}provider-location/`,
      update: `${API_PROVIDERS}provider-location-update/`,
      readId: `${API_PROVIDERS}provider-location/`
    }
  });

  const [
    getFacility,
    ,
    setFacility,
    updateFacility,
    getFacilityId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}provider-facility/`,
      read: `${API_PROVIDERS}provider-facility/`,
      readId: `${API_PROVIDERS}provider-facility/`,
      update: `${API_PROVIDERS}facility-update/`
    }
  });
  const [
    getContacts,
    ,
    setContacts,
    updateContacts,
    getContactId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}provider-contact-detail/`,
      readId: `${API_PROVIDERS}provider-contact-detail/`,
      read: `${API_PROVIDERS}provider-contact-detail/`,


      update: `${API_PROVIDERS}provider-contact-detail-update/`
    }
  });

  const [, deleteContactdetails] = useCommonCRUD({
    CRUDaseUrl: {
      delete: `${API_PROVIDERS}provider-contact-detail/`,
    }
  });

  const [, , , , submitForm] = useCommonCRUD({
    CRUDaseUrl: {
      readId: `${API_PROVIDERS}provider-submit-for-approval/`
    }
  });
  const [
    getYearlyCalender,
    ,
    yearlyCalender,
    updateYearlyCalender,
    getYearlyCalenderId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}yearly-calendar/`,
      read: `${API_PROVIDERS}yearly-calendar/`,
      // delete: `${API_PROVIDERS}yearly-calendar/`,

      readId: `${API_PROVIDERS}yearly-calendar/`,
      update: `${API_PROVIDERS}yearly-calendar-update/`
    }
  });

  // const [
  //   ,
  //   ,
  //   ,
  //   updateYearlyCalender,

  // ] = useCommonCRUD({
  //   CRUDaseUrl: {
  //     update: `${API_PROVIDERS}yearly-calendar-update/`
  //   }
  // });

  const [, deleteYearApi] = useCommonCRUD({
    CRUDaseUrl: {
      delete: `${API_PROVIDERS}yearly-calendar/`
    }
  });
  const [, deleteSchedulerApi] = useCommonCRUD({
    CRUDaseUrl: {
      delete: `${API_PROVIDERS}schedule-for-the-day/`
    }
  });

  const [remarks] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}provider-remarks/`
    }
  });

  const [
    getScheduleDay,
    ,
    scheduleApi,
    updateScheduleApi,
    getScheduleDayId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}schedule-for-the-day/`,
      read: `${API_PROVIDERS}schedule-for-the-day/`,
      // delete: `${API_PROVIDERS}schedule-for-the-day/`,

      readId: `${API_PROVIDERS}schedule-for-the-day/`,
      update: `${API_PROVIDERS}schedule-for-the-day-update/`
    }
  });

  const [
    getWorkingDay,
    ,
    workinDaysApi,
    updateWorkinDaysApi,
    getWorkingDayId
  ] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}working-day/`,
      read: `${API_PROVIDERS}working-day/`,
      readId: `${API_PROVIDERS}working-day/`,
      update: `${API_PROVIDERS}working-day-update/`
    }
  });

  const updateComponent = val => {
    setComponent(val);
  };

  const updateBeforeComponent = val => {
    if (val === "Location") {
      getLocationId({ data: "" });
    }
    if (val === "Facilities") {
      getFacilityId({ data: "" });
    }
    if (val === "Contact details") {
      getContactId({ data: "" });
    }
    if (val === "Basic Info") {
      getBasicInfoId({ data: "" });
      if (getBasicInfo && getBasicInfo.data.length > 0) {
        getYearlyCalenderId({ data: "" });
        getScheduleDayId({ data: "" });
        getWorkingDayId({ data: "" });
        formDataId({ data: "" });
      }
    }
    if (val === "Teachers and staff") {
      getFacultyId({ data: "" });
      formDataId({ data: "" });
    }
    setComponent(val);
  };

  useEffect(() => {
    if (getBasicInfo && getBasicInfo.data.length > 0) {
      getYearlyCalenderId({ data: "" });
      getScheduleDayId({ data: "" });
      getWorkingDayId({ data: "" });
    }

  }, [getBasicInfo]);


  // useEffect(() => {
  //   if (
  //     props.history.location.state &&
  //     props.history.location.state.component
  //   ) {
  //     setComponent(props.history.location.state.component);
  //   }
  // }, []);

  useEffect(() => {
    // if (contentSchedule.data && contentSchedule.data.length > 0) {
    //   let contentDummy = contentSchedule.data.filter(
    //     d => d.schedule_type === "content_writing"
    //   );
    //   let pictureDummy = contentSchedule.data.filter(
    //     d => d.schedule_type === "photo_360"
    //   );
    //   if (contentDummy && contentDummy[0]) {
    //     setContentWritingDate(contentDummy[0].date);
    //     setContentWritingTime(contentDummy[0].time);
    //   }
    //   if (pictureDummy && pictureDummy[0]) {
    //     setPicturesDate(pictureDummy[0].date);
    //     setPicturesTime(pictureDummy[0].time);
    //   }
    // }
    if (
      contentSchedule.data &&
      contentSchedule.data !== {} &&
      contentSchedule.data !== []
    ) {
      if (contentSchedule.data["content_writing"]) {
        contentSchedule.data["content_writing"].choice === "yes"
          ? setContentWriting("1")
          : setContentWriting("0");
      }
      if (contentSchedule.data["photo_360"]) {
        contentSchedule.data["photo_360"].choice === "yes"
          ? setPictures("1")
          : setPictures("0");
      }
      if (!contentSchedule.data["photo_360"]) {
        setPictures("0");
      }
      if (!contentSchedule.data["content_writing"]) {
        setContentWriting("0");
      }
    }
  }, [contentSchedule.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (component === "Basic Info") {
      // scheduleChoiceId({ data: '' })
      formDataId({ data: "" });
      // getWorkingDayId({ data: '' })
      getBasicInfoId({ data: "" });
      // getYearlyCalenderId({ data: '' })
      // getScheduleDayId({ data: '' })
    } else if (component === "Location") {
      getLocationId({ data: "" });
    } else if (component === "Contact details") {
      getContactId({ data: "" });
      formDataId({ data: "" });
    } else if (component === "Facilities") {
      // languagesId({ data: '' })
      emergencyServiceId({ data: "" });
      getFacilityId({ data: "" });
    } else if (component === "Teachers and staff") {
      getContactId({ data: "" });
      // languagesId({ data: '' })
      staffSupportId({ data: "" });
      getFacultyId({ data: "" });
      formDataId({ data: "" });
    } else if (component === "Photos/Videos") {
      getPhotoId({ data: "" });
    }
  }, [component]);
  useEffect(() => {
    if (providerSteps && providerSteps.data) {
      let objKeys = Object.keys(providerSteps.data);
      objKeys.map((a, i) => {
        console.log(activeComponent, a ,)
        if (providerSteps.data[a] === true && i === 0) {
          activeComponent
            ? activeComponent === 1 && setComponent("Basic Info")
            : setComponent("Basic Info");
          return;
        } else if (providerSteps.data[a] === true && i === 1) {
          activeComponent
            ? activeComponent === 2 && setComponent("Location")
            : setComponent("Location");
          return;
        } else if (providerSteps.data[a] === true && i === 2) {
          activeComponent
            ? activeComponent === 3 && setComponent("Contact details")
            : setComponent("Contact details");
          return;
        } else if (providerSteps.data[a] === true && i === 3) {
          activeComponent
            ? activeComponent === 4 && setComponent("Facilities")
            : setComponent("Facilities");
          return;
        } else if (providerSteps.data[a] === true && i === 4) {
          activeComponent
            ? activeComponent === 5 && setComponent("Teachers and staff")
            : setComponent("Teachers and staff");
          return;
        } else if (providerSteps.data[a] === true && i === 5) {
          activeComponent
            ? activeComponent === 6 && setComponent("Photos/Videos")
            : setComponent("Photos/Videos");
          return;
        } 
      });
    }
  }, [providerSteps.data]);

  const handlePictureSubmit = e => {
    e.preventDefault();
    // setSubmit(true);
    // props.form.validateFields(async (errors, values) => {
    // if (!errors) {
    let sendData = {};
    // sendData.date = picturesDate;
    // sendData.time = picturesTime;
    sendData.date = "2020-02-14";
    sendData.time = "9 AM to 10 AM";

    sendData.schedule_type = "photo_360";
    let dummyArr = [];
    if (contentSchedule.data && contentSchedule.data.length > 0) {
      dummyArr = contentSchedule.data.filter(
        d => d.schedule_type === "photo_360"
      );
    }
    if (dummyArr.length > 0) {
      sendData.id = dummyArr[0].id;
      updateContentSchedule({
        data: sendData,
        cb: err => {
          if (!err) {
            setSubmit(false);
            setOpenContentModal(false);
            setOpenModal(false);
            contentScheduleId({ data: "" });
          } else {
            responseToast(err);
          }
        }
      });
    } else
      contentSchedulePost({
        data: sendData,
        cb: err => {
          if (!err) {
            setSubmit(false);
            setOpenModal(false);
            setOpenContentModal(false);
            contentScheduleId({ data: "" });
            setPictureSuccess(true);
          } else {
            responseToast(err);
          }
        }
      });
    //   }
    // });
  };

  const handleContentSubmit = e => {
    e.preventDefault();
    // setSubmit(true);
    // props.form.validateFields(async (errors, values) => {
    //   if (!errors) {
    let sendData = {};
    // sendData.date = contentWritingDate;
    // sendData.time = contentWritingTime;

    sendData.date = "2020-02-14";
    sendData.time = "9 AM to 10 AM";

    sendData.schedule_type = "content_writing";
    let dummyArr = [];
    if (contentSchedule.data && contentSchedule.data.length > 0) {
      dummyArr = contentSchedule.data.filter(
        d => d.schedule_type === "content_writing"
      );
    }
    if (dummyArr.length > 0) {
      sendData.id = dummyArr[0].id;
      updateContentSchedule({
        data: sendData,
        cb: err => {
          if (!err) {
            setSubmit(false);
            setOpenContentModal(false);
            contentScheduleId({ data: "" });
            setContentSuccess(true);
          } else {
            responseToast(err);
          }
        }
      });
    } else
      contentSchedulePost({
        data: sendData,
        cb: err => {
          if (!err) {
            setSubmit(false);
            setOpenContentModal(false);
            contentScheduleId({ data: "" });
          } else {
            responseToast(err);
          }
        }
      });
    // }
    // });
  };

  const deleteSchedule = (e, type) => {
    e.preventDefault();
    const { data = {} } = contentSchedule;
    const id =
      type === "content"
        ? data.content_writing && data.content_writing.id
        : data.photo_360 && data.photo_360.id;
    deleteContentSchedule({ id });
  };

  useEffect(() => {
    if (picturesDate && picturesTime) {
      setPictureSuccess(true);
      setPictures("1");
    }
    if (contentWritingDate && contentWritingTime) {
      setContentSuccess(true);
      setContentWriting("1");
    }
  }, [contentSchedule]);

  // useEffect(() => {
  //   if (picturesDate && picturesTime) {
  //     setPictureSuccess(true);
  //     setPictures("1");
  //   }
  //   if (contentWritingDate && contentWritingTime) {
  //     setContentSuccess(true);
  //     setContentWriting("1");
  //   }
  // }, [component]);

  // useEffect(() => {
  //   if (picturesDate && picturesTime) {
  //     setPictureSuccess(true);
  //     setPictures("1");
  //   }
  //   if (contentWritingDate && contentWritingTime) {
  //     setContentSuccess(true);
  //     setContentWriting("1");
  //   }
  // }, [picturesDate, picturesTime, contentWritingDate, contentWritingTime]);

  useEffect(() => {
    if (
      formData.isLoading ||
      providerSteps.isLoading ||
      getBasicInfo.isLoading ||
      emergencyService.isLoading ||
      languages.isLoading ||
      staffSupport.isLoading ||
      getFaculty.isLoading ||
      getPhoto.isLoading ||
      contentSchedule.isLoading ||
      getLocation.isLoading ||
      getFacility.isLoading ||
      getContacts.isLoading ||
      remarks.isLoading
      // ||
      // getWorkingDay.isLoading ||
      // getScheduleDay.isLoading ||
      // getYearlyCalender.isLoading
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [
    providerSteps.isLoading,
    formData.isLoading,
    getBasicInfo.isLoading,
    emergencyService.isLoading,
    languages.isLoading,
    staffSupport.isLoading,
    getFaculty.isLoading,
    getPhoto.isLoading,
    contentSchedule.isLoading,
    getLocation.isLoading,
    getFacility.isLoading,
    getContacts.isLoading,
    remarks.isLoading,
    // getWorkingDay.isLoading,
    // getScheduleDay.isLoading,
    // getYearlyCalender.isLoading
  ]);



  useEffect(() => {
    if (formData && formData.data.length > 0) {
      formData.data[0].form_filled === false && props.history.push("form");
    }
  }, [formData])


  return (
    <>

      <div className="header pb-6 ">
        <AdminNavbar {...props} brandText="Kiddenz" />
      </div>
      <div className="main-content" style={{ padding: 30 }}>
     
        {formData.data && (formData.data.provider_status === 'rejected' || formData.data.provider_status === 'approved') && <AccountReject
          classType={formData.data && formData.data.provider_status}
          remarks={
            remarks.data.length > 0 &&
            remarks.data[remarks.data.length - 1].remarks
          }
          description={
            remarks.data.length > 0 &&
            remarks.data[remarks.data.length - 1].remarks_description
          }
        />}
        
        <Container
          className="formClass basicContainer  formClass-container"
          style={{ maxWidth: "100%" }}
        >
          <Row>
            <Col>
              <Card className="shadow">
              <AccountReject classType='show'/>
                <div className="alert-message">
                  <div class="">
                    <p class="">
                      If you need support for professional content writing,
                      pictures and 360 degree picture,
                    </p>
                    <p>
                      please connect on
                      <a className="mailto" href="mailto:admin@kiddenz.com">
                        admin@kiddenz.com.
                      </a>{" "}
                      Kiddenz will provide high quality services at reasonable
                      cost.
                    </p>
                  </div>
                  {/* {formData.data &&
                    formData.data.provider_status === "approved" && (
                      <div className="accountApproved">
                        Yay! Your account is approved
                      </div>
                    )} */}
                  <Row
                    className="extended"
                    style={{
                      margin: "20px 0px 20px",
                      marginLeft: 0,
                      alignItems: "center"
                    }}
                  >
                    <Col xs="2" style={{ paddingLeft: 0, fontWeight: 500, color: '#613A95' }}>
                      Content writing
                    </Col>
                    {/* {contentWritingDate &&
                    contentWritingTime &&
                    contentSucess ? (
                      <Col xs="8" style={{ display: "flex" }}>
                        <span style={{ fontWeight: 300, paddingLeft: 15 }}>
                          Call is scheduled at &nbsp;<b>{contentWritingTime}</b>{" "}
                          &nbsp;<b>{contentWritingDate}</b>{" "}
                        </span>
                        <i
                          class="fa fa-edit"
                          aria-hidden="true"
                          style={{ fontSize: 17, marginLeft: 10 }}
                          onClick={() => {
                            setOpenContentModal(true);
                            setContentSuccess(false);
                          }}
                        ></i>
                      </Col>
                    ) : ( */}
                    <Col xs="8" className="scheduleRow">
                      <Col className="contentWriting">
                        <div
                          className={`form-control-alternative ${contentWriting ===
                            "1" && "activeSchedule"}`}
                          onClick={e => {
                            setContentWriting("1");
                            handleContentSubmit(e);
                            // setOpenContentModal(true);
                          }}
                        >
                          <span>Yes</span>
                        </div>
                        <div
                          className={`form-control-alternative ${contentWriting ===
                            "0" && "activeSchedule"}`}
                          onClick={e => {
                            setContentWriting("0");
                            deleteSchedule(e, "content");
                            // setOpenContentModal(false);
                          }}
                        >
                          <span>No</span>
                        </div>
                      </Col>
                    </Col>
                    {/* )} */}
                  </Row>
                  <Row
                    className="extended"
                    style={{
                      margin: "20px 0",
                      marginLeft: 0,
                      alignItems: "center"
                    }}
                  >
                    <Col xs="2" style={{ paddingLeft: 0, fontWeight: 500, color: '#613A95' }}>
                      {"Pictures, 360° view"}
                    </Col>
                    {/* {picturesDate && picturesTime && pictureSuccess ? (
                      <Col xs="8" style={{ display: "flex" }}>
                        <span style={{ fontWeight: 300, paddingLeft: 15 }}>
                          Call is scheduled at &nbsp;<b>{picturesTime}</b>{" "}
                          &nbsp;<b>{picturesDate}</b>{" "}
                        </span>
                    
                        <i
                          class="fa fa-edit"
                          aria-hidden="true"
                          style={{ fontSize: 17, marginLeft: 10 }}
                          onClick={() => {
                            setOpenModal(true);
                            setPictureSuccess(false);
                          }}
                        ></i>
                      </Col>
                    ) : ( */}
                    <Col xs="8" className="scheduleRow">
                      <Col className="contentWriting">
                        <div
                          className={`form-control-alternative ${pictures ===
                            "1" && "activeSchedule"}`}
                          onClick={e => {
                            setPictures("1");
                            handlePictureSubmit(e);
                            // setOpenModal(true);
                          }}
                        >
                          <span>Yes</span>
                        </div>
                        <div
                          className={`form-control-alternative ${pictures ===
                            "0" && "activeSchedule"}`}
                          onClick={e => {
                            setPictures("0");
                            deleteSchedule(e, "picture");
                            // setOpenModal(false);
                          }}
                        >
                          <span>No</span>
                        </div>
                      </Col>
                    </Col>
                  </Row>
                  <img className="picture" src={Picture} alt="alert-mgs" />
                </div>
                {component === "success" ? (
                  <Card>
                    <CardBody
                      className="flexColumnCenter"
                      style={{ height: "70vh" }}
                    >
                      <CardTitle className="cardTitle">
                        Submitted Successfully{" "}
                      </CardTitle>
                      <CardText className="cardText">
                        {" "}
                        Thanks for updating your school info, our team will do a
                        background check and update the information on your
                        kiddenz school page{" "}
                      </CardText>
                    </CardBody>
                  </Card>
                ) : (
                    <>
                      <CardBody style={{ padding: 0 }}>
                        <Progress
                          value={
                            component === "Basic Info"
                              ? 12
                              : component === "Location"
                                ? 26
                                : component === "Contact details"
                                  ? 45
                                  : component === "Facilities"
                                    ? 59
                                    : component === "Teachers and staff"
                                      ? 82
                                      : 100
                          }
                        />
                        <div className="progressWrapper">
                          <CardText
                            className="cardText"
                            style={{
                              margin: 0,
                              borderBottom: "1px solid rgba(102, 108, 120, 0.2)"
                            }}
                          >
                            <span
                              className={
                                (component === "Photos/Videos" ||
                                  component === "Teachers and staff" ||
                                  component === "Facilities" ||
                                  component === "Contact details" ||
                                  component === "Location" ||
                                  component === "Basic Info") &&
                                `progressComplete ${component === "Basic Info" &&
                                "active"}`
                              }
                              onClick={() => {
                                activeComponentData = 1;
                                if (contentChanged && activeComponentData !== activeComponent) {
                                  if (providerSteps && providerSteps.data) {
                                    let objKeys = Object.keys(providerSteps.data);
                                    objKeys.map((a, i) => {
                                      if (i === (activeComponentData - 1) && providerSteps.data[a] === true) {
                                        setContentChangedModal(true)
                                      }
                                    })
                                  }
                                } else {
                                  setActiveComponent(1);
                                  providerStepsId({ data: "" });
                                }
                              }
                              }
                            >
                              <img
                                src={
                                  component === "Photos/Videos" ||
                                    component === "Teachers and staff" ||
                                    component === "Facilities" ||
                                    component === "Contact details" ||
                                    component === "Location" ||
                                    component === "Basic Info"
                                    ? Success
                                    : Unsuccess
                                }
                                alt="active"
                              />{" "}
                              01. Basic Info
                          </span>
                            <span
                              className={
                                (component === "Photos/Videos" ||
                                  component === "Teachers and staff" ||
                                  component === "Facilities" ||
                                  component === "Contact details" ||
                                  component === "Location") &&
                                `progressComplete ${component === "Location" &&
                                "active"}`
                              }
                              onClick={() => {
                                activeComponentData = 2;
                                if (contentChanged && activeComponentData !== activeComponent) {
                                  if (providerSteps && providerSteps.data) {
                                    let objKeys = Object.keys(providerSteps.data);
                                    objKeys.map((a, i) => {
                                      if (i === (activeComponentData - 1) && providerSteps.data[a] === true) {
                                        setContentChangedModal(true)
                                      }
                                    })
                                  }
                                } else {
                                  setActiveComponent(2);
                                  providerStepsId({ data: "" });
                                }
                              }}
                            >
                              {console.log(contentChanged)
                              }
                              <img
                                src={
                                  component === "Photos/Videos" ||
                                    component === "Teachers and staff" ||
                                    component === "Facilities" ||
                                    component === "Contact details" ||
                                    component === "Location"
                                    ? Success
                                    : Unsuccess
                                }
                                alt="active"
                              />{" "}
                              02. Location
                          </span>
                            <span
                              className={
                                (component === "Photos/Videos" ||
                                  component === "Teachers and staff" ||
                                  component === "Facilities" ||
                                  component === "Contact details") &&
                                `progressComplete ${component ===
                                "Contact details" && "active"}`
                              }
                              onClick={() => {
                                activeComponentData = 3;
                                if (contentChanged && activeComponentData !== activeComponent) {
                                  if (providerSteps && providerSteps.data) {
                                    let objKeys = Object.keys(providerSteps.data);
                                    objKeys.map((a, i) => {
                                      console.log(i)
                                      if (i === (activeComponentData - 1) && providerSteps.data[a] === true) {
                                        console.log(activeComponentData, i)
                                        setContentChangedModal(true)
                                      }
                                    })
                                  }
                                } else {
                                  setActiveComponent(3);
                                  providerStepsId({ data: "" });
                                }
                              }}
                            >
                              <img
                                src={
                                  component === "Photos/Videos" ||
                                    component === "Teachers and staff" ||
                                    component === "Facilities" ||
                                    component === "Contact details"
                                    ? Success
                                    : Unsuccess
                                }
                                alt="active"
                              />{" "}
                              03. Contact details
                          </span>
                            <span
                              className={
                                (component === "Photos/Videos" ||
                                  component === "Teachers and staff" ||
                                  component === "Facilities") &&
                                `progressComplete ${component === "Facilities" &&
                                "active"}`
                              }
                              onClick={() => {
                                activeComponentData = 4;
                                if (contentChanged && activeComponentData !== activeComponent) {
                                  if (providerSteps && providerSteps.data) {
                                    let objKeys = Object.keys(providerSteps.data);
                                    objKeys.map((a, i) => {
                                      if (i === (activeComponentData - 1) && providerSteps.data[a] === true) {
                                        setContentChangedModal(true)
                                      }
                                    })
                                  }
                                } else {
                                  setActiveComponent(4);
                                  providerStepsId({ data: "" });
                                }
                              }}
                            >
                              <img
                                src={
                                  component === "Photos/Videos" ||
                                    component === "Teachers and staff" ||
                                    component === "Facilities"
                                    ? Success
                                    : Unsuccess
                                }
                                alt="active"
                              />{" "}
                              04. Facilities
                          </span>
                            <span
                              className={
                                (component === "Photos/Videos" ||
                                  component === "Teachers and staff") &&
                                `progressComplete ${component ===
                                "Teachers and staff" && "active"}`
                              }
                              onClick={() => {
                                activeComponentData = 5;
                                if (contentChanged && activeComponentData !== activeComponent) {
                                  if (providerSteps && providerSteps.data) {
                                    let objKeys = Object.keys(providerSteps.data);
                                    objKeys.map((a, i) => {
                                      if (i === (activeComponentData - 1) && providerSteps.data[a] === true) {
                                        setContentChangedModal(true)
                                      }
                                    })
                                  }
                                } else {
                                  setActiveComponent(5);
                                  providerStepsId({ data: "" });
                                }
                              }}
                            >
                              <img
                                src={
                                  component === "Photos/Videos" ||
                                    component === "Teachers and staff"
                                    ? Success
                                    : Unsuccess
                                }
                                alt="active"
                              />{" "}
                              05. Teachers and staff
                          </span>
                            <span
                              className={
                                component === "Photos/Videos" &&
                                `progressComplete ${component ===
                                "Photos/Videos" && "active"}`
                              }
                              onClick={() => {
                                activeComponentData = 6;
                                if (contentChanged && activeComponentData !== activeComponent) {
                                  if (providerSteps && providerSteps.data) {
                                    let objKeys = Object.keys(providerSteps.data);
                                    objKeys.map((a, i) => {
                                      if (i === (activeComponentData - 1) && providerSteps.data[a] === true) {
                                        setContentChangedModal(true)
                                      }
                                    })
                                  }
                                } else {
                                  setActiveComponent(6);
                                  providerStepsId({ data: "" });
                                }
                              }}
                            >
                              <img
                                src={
                                  component === "Photos/Videos"
                                    ? Success
                                    : Unsuccess
                                }
                                alt="active"
                              />{" "}
                              06. Photos/Videos
                          </span>
                          </CardText>
                          <div className="progressText"></div>
                        </div>
                      </CardBody>
                    </>
                  )}
                <Loader
                  isOpen={
                    providerSteps.isLoading ||
                    formData.isLoading ||
                    getBasicInfo.isLoading ||
                    emergencyService.isLoading ||
                    languages.isLoading ||
                    staffSupport.isLoading ||
                    getFaculty.isLoading ||
                    getPhoto.isLoading ||
                    contentSchedule.isLoading ||
                    getLocation.isLoading ||
                    getFacility.isLoading ||
                    getContacts.isLoading ||
                    remarks.isLoading
                    // ||
                    // getWorkingDay.isLoading ||
                    // getScheduleDay.isLoading ||
                    // getYearlyCalender.isLoading
                  }
                />
                {/* <Loader
                  isOpen={true} /> */}
                {
                  !providerSteps.isLoading &&
                  !formData.isLoading &&
                  // !getBasicInfo.isLoading &&
                  !emergencyService.isLoading &&
                  !languages.isLoading &&
                  !staffSupport.isLoading &&
                  !getFaculty.isLoading &&
                  !getPhoto.isLoading &&
                  !contentSchedule.isLoading &&
                  !getLocation.isLoading &&
                  !getFacility.isLoading &&
                  !getContacts.isLoading &&
                  !remarks.isLoading &&
                  ((component === "Basic Info" && (
                    <BasicInfo
                      setComponent={updateComponent}
                      component={component}
                      scheduleChoice={scheduleChoice && scheduleChoice.data}
                      addForms={addForms}
                      updateForms={updateForms}
                      deleteForms={deleteForms}
                      lastUpdated={lastUpdated}
                      formData={formData && formData.data}
                      childCare={formData && formData.data.child_care_providers}
                      workinDaysApi={workinDaysApi}
                      updateWorkinDaysApi={updateWorkinDaysApi}
                      yearlyCalender={yearlyCalender}
                      updateYearlyCalender={updateYearlyCalender}
                      scheduleApi={scheduleApi}
                      deleteWorkingDay={deleteWorkingDay}
                      updateScheduleApi={updateScheduleApi}
                      deleteYearApi={deleteYearApi}
                      deleteSchedulerApi={deleteSchedulerApi}
                      setContentChanged={setContentChanged}

                      // activityapi={activityapi}
                      // updateActivityapi={updateActivityapi}
                      getWorkingDayData={getWorkingDay && getWorkingDay.data}
                      getScheduleDayData={getScheduleDay && getScheduleDay.data}
                      data={getBasicInfo && getBasicInfo.data}
                      getYearlyCalenderData={
                        getYearlyCalender && getYearlyCalender.data
                      }
                      formdataUpdate={formdataUpdate}
                      // getActivityData={getActivity && getActivity.data}
                      {...props}
                    />
                  )) ||
                    (component === "Photos/Videos" && (
                      <PhotosVideos
                        submitForm={submitForm}
                        updateBeforeComponent={updateBeforeComponent}
                        submitPhoto={submitPhoto}
                        setComponent={updateComponent}
                        data={getPhoto && getPhoto.data}
                        deleteMedia={deleteMedia}
                        pictures={pictures === "1"}
                        setContentChanged={setContentChanged}
                        {...props}

                      />
                    )) ||
                    (component === "Location" && (
                      <Location
                        updateBeforeComponent={updateBeforeComponent}
                        setComponent={updateComponent}
                        setLocation={setLocation}
                        updateLocation={updateLocation}
                        data={getLocation && getLocation.data}
                        setContentChanged={setContentChanged}

                        // setLastUpdated={setLastUpdated}
                        {...props}
                      />
                    )) ||
                    (component === "Facilities" && (
                      <Facilities
                        updateBeforeComponent={updateBeforeComponent}
                        setComponent={updateComponent}
                        languages={languages && languages.data}
                        emergencyService={emergencyService && emergencyService.data}
                        setFacility={setFacility}
                        updateFacility={updateFacility}
                        data={getFacility && getFacility.data}
                        setContentChanged={setContentChanged}
                        {...props}
                      />
                    )) ||
                    (component === "Teachers and staff" && (
                      <TeachersStaffs
                        updateBeforeComponent={updateBeforeComponent}
                        setComponent={updateComponent}
                        languages={languages && languages.data}
                        staffSupport={staffSupport && staffSupport.data}
                        facultySubmit={facultySubmit}
                        data={getFaculty && getFaculty.data}
                        updateFaculty={updateFaculty}
                        deleteTeacherApi={deleteTeacherApi}
                        formdataUpdate={formdataUpdate}
                        formData={formData && formData.data}
                        contactData={getContacts && getContacts.data}
                        // component={compo}
                        setContentChanged={setContentChanged}
                        contentWriting={contentWriting === "1"}
                        {...props}
                      />
                    )) ||
                    (component === "Contact details" && (
                      <ContactDetails
                        updateBeforeComponent={updateBeforeComponent}
                        setContacts={setContacts}
                        setComponent={updateComponent}
                        deleteContactdetails={deleteContactdetails}
                        // submitForm={submitForm}
                        updateContacts={updateContacts}
                        formData={formData && formData.data}
                        data={getContacts && getContacts.data}
                        setContentChanged={setContentChanged}
                        {...props}
                      />
                    )))}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Modal
                className="modal-dialog-centered"
                isOpen={providerModal && component === 'Basic Info'}
                toggle={() => setProviderModal(false)}
            >
                <div className="modal-header">

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setProviderModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body modalBody">
                    <div className="modalBody-margibBtm" style={{textAlign: 'center'}}>Complete your profile within next 7 days, and get a chance to be promoted for 7 days in your location.</div>
                    <div className="modalBody-margibBtm"><b>More promotion = More Visibility = More leads</b></div>
                </div>
                <div className="modal-footer modalFooter">
                    <Button
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setProviderModal(false)}
                    >
                        Go to profile page
              </Button>

                </div>
            </Modal> */}

      <Modal
        className="modal-dialog-centered"
        isOpen={openModal}
        toggle={() => {
          setOpenModal(false);
          setSubmit(false);
          if (!picturesDate && !picturesTime) {
            setPictures("0");
          }
        }}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setOpenModal(false);
              setSubmit(false);
              if (!picturesDate && !picturesTime) {
                setPictures("0");
              }
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form className="form-horizontal" onSubmit={handlePictureSubmit}>
          <div className="modal-body">
            <Row>
              <Col style={{ padding: "0px 30px" }}>Pictures </Col>
              <Row style={{ width: "100%", marginTop: "20px", marginLeft: 0 }}>
                <DatePicker
                  form={props.form}
                  onChange={val => {
                    contentDate = val;
                  }}
                  isRequired={true}
                  colSpan="6"
                  notYearPicker
                  submit={submit}
                  isWeekday
                  defaultValue={picturesDate}
                />
                <Dropdown
                  form={props.form}
                  label=""
                  xsval="6"
                  options={TIME_DROPDOWN}
                  name="picture"
                  defaultValue={picturesTime}
                  onChange={val => {
                    contentTime = val;
                  }}
                  isRequired={true}
                />
              </Row>
            </Row>
            <Row className="formFlex">
              <Col xs="4"> </Col>
              <Col xs="8">
                <Button
                  style={{ float: "right" }}
                  type="submit"
                  onClick={() => {
                    setPicturesDate(picturesDate);
                    setPicturesTime(contentTime);

                    // setContentWritingDate(photoDate);
                    // setContentWritingTime(photoTime);
                  }}
                >
                  Schedule Call
                </Button>
              </Col>
            </Row>
          </div>
          {/* <div className="modal-footer modalFooter">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => setProviderModal(false)}
          >
            Go to profile page
              </Button>

        </div> */}
        </Form>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={openContentModal}
        toggle={() => {
          setOpenContentModal(false);
          setSubmit(false);
          if (!contentWritingDate && !contentWritingTime) {
            setContentWriting("0");
          }
        }}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setOpenContentModal(false);
              setSubmit(false);
              if (!contentWritingDate && !contentWritingTime) {
                setContentWriting("0");
              }
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form className="form-horizontal" onSubmit={handleContentSubmit}>
          <div className="modal-body">
            <Row>
              <Col style={{ padding: "0px 30px" }}> Content writing </Col>
              <Row style={{ width: "100%", marginTop: "20px", marginLeft: 0 }}>
                <DatePicker
                  form={props.form}
                  onChange={val => {
                    photoDate = val;
                  }}
                  isRequired={true}
                  colSpan="6"
                  notYearPicker
                  defaultValue={contentWritingDate}
                  isWeekday
                  submit={submit}
                />
                <Dropdown
                  form={props.form}
                  label=""
                  xsval="6"
                  options={TIME_DROPDOWN}
                  name="content"
                  defaultValue={contentWritingTime}
                  onChange={val => {
                    photoTime = val;
                  }}
                  isRequired={true}
                />
              </Row>
            </Row>
            <Row className="formFlex">
              <Col xs="4"> </Col>
              <Col xs="8">
                <Button
                  style={{ float: "right" }}
                  type="submit"
                  onClick={() => {
                    setContentWritingDate(photoDate);
                    setContentWritingTime(photoTime);
                  }}
                >
                  Schedule Call
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={contentChangedModal}
        toggle={() => {
          setContentChangedModal(false);
        }}
      >
        <div className="modal-header">
          <div>
            <Row>
              <Col style={{ padding: "0px 30px" }}> Your changes are not saved</Col>

            </Row>
            <Row>
              <Col style={{ padding: "0px 30px" }}> Please save the form before proceeding.</Col>


            </Row>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setContentChangedModal(false);

            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            
            onClick={() => {
              setContentChangedModal(false)
              setContentChanged(false)
              setActiveComponent(activeComponentData);
              providerStepsId({ data: "" });
            }}
          >
            Skip
            </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              setContentChangedModal(false)
              setContentChanged(true)
            }}
          >
            Ok
  </Button>
        </div>
      </Modal>
    </>
  );
}

ProviderProfile.propTypes = {};

export default createForm()(ProviderProfile);
