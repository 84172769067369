/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import Flex from '../../views/Pages/SchoolProfile/flex';
// import Button from "../../views/Pages/SchoolProfile/Button";

const FlexContainer = styled.div`
  span {
    color: #555569;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;

const Title = styled.div`
  color: #ff5353;
  font-family: "Quicksand", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 10px;
`;
const RejectCard = styled.div`
  width: 100%;
  padding: 20px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid #ff5353;
  background-color: #ffffff;
  &.approved {
    border: 1px solid #60b947;
    ${Title} {
      color: #60b947;
      margin-bottom: 0px;
    }
  }
  &.show {
    border: 1px solid #613a95;
    background: #eff1dc79;
    display: flex;
    justify-content: center;
    padding: 5px 24px;

    ${Title} {
      color: #613a95;
      margin-bottom: 0px;
      font-size: 20px;
      span {
        color: #5e72e4;
        cursor: pointer;
        font-size: 20px;
        &:hover {
          color: #233dd2;

        }
      }
    }
  }
`;

const SubTitle = styled.p`
  color: #666c78;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 5px;
`;

const AccountReject = ({ remarks, description, classType }) => (
  <RejectCard className={classType}>
    {classType === "rejected" && (
      <>
        <FlexContainer>
          <Title>Review Feedback</Title>
          <span>Contact admin for more details</span>
          <SubTitle>Remarks: {remarks}</SubTitle>
          <SubTitle>Description: {description}</SubTitle>
        </FlexContainer>
      </>
    )}
    {classType === "approved" && (
      <>
        <FlexContainer>
          <Title>Yay! Your account is approved</Title>
        </FlexContainer>
      </>
    )}
    {classType === "show" && (
      <>
        <FlexContainer>
          <Title>
          Need help filling the form? checkout <span>this</span> link
          </Title>
        </FlexContainer>
      </>
    )}
  </RejectCard>
);
AccountReject.propTypes = {
  classType: PropTypes.string
};
export default AccountReject;
