/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

function KidDetails(props) {
  return (
    <>
      {props.childData && (
        <KidDetail className={props.classType}>
          <Heading className={props.classType} onClick={() => props.toggleEachChild()}>
            <h2>
              {props.childData.first_name} {props.childData.last_name},&nbsp;{props.childData.age} &nbsp;years
            </h2>
            <div className="arrowIcon">
              <span
                class="iconify plus"
                data-icon="fe:plus"
                data-inline="false"
              ></span>
              <span
                class="iconify minus"
                data-icon="uil:minus"
                data-inline="false"
              ></span>
            </div>
          </Heading>
          <Dropdown>
            <div className="dropdownList">
              {/* <Value>
                <span>Joining</span>
                <h3>{props.childData.joining || '--'}</h3>
              </Value> */}
              {/* <Value>
                <span>Type of care</span>
                <h3>{props.childData.type_of_care || '--'}</h3>
              </Value> */}
              <Value>
                <span>Food choice</span>
                <h3>{props.childData.food_choice || '--'}</h3>
              </Value>
              {/* <Value>
                <span>Schedule</span>
                <h3>{props.childData.schedule || '--'}</h3>
              </Value> */}
              <Value>
                <span>Medication details</span>
                <h3>{props.childData.medication_details || '--'}</h3>
              </Value>
              <Value>
                <span>Allergies</span>
                <h3>{props.childData.allergies || '--'}</h3>
              </Value>
              <Value>
                <span>Special care</span>
                <h3>{props.childData.special_care ? 'Yes' : 'No'}</h3>
              </Value>
              {/* <Value>
                <span>Transportation</span>
                <h3>{props.childData.transportaion || '--'}</h3>
              </Value> */}
              <Value>
                <span>Previous childcare experience</span>
                <h3>{props.childData.child_care_experience || '--'}</h3>
              </Value>
            </div>
            <div className="dropdownNeeds">
              <Value width="100%" style={{ marginBottom: 0 }}>
                <span>Any specific needs:</span>
                {props.childData.other_specific_needs ? (
                  props.childData.other_specific_needs.split(',').map(d => <h3>{d}</h3>)
                ) : (
                  <h3>None</h3>
                )}
              </Value>
            </div>
          </Dropdown>
        </KidDetail>
      )}
    </>
  );
}

KidDetails.propTypes = {
  name: PropTypes.string,
  age: PropTypes.string,
  classType: PropTypes.string
};

export default KidDetails;

export const KidDetail = styled.div`
  margin: 24px;
  background-color: #fcfaff;
  border-radius: 5px;
  // height: 37px;
  max-height: 37px;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
  &.active {
    // height: auto;
    max-height: 700px;
    transition: max-height 1s ease-in-out;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: #faf6ff;
  cursor: pointer;
  &.active {
    .plus {
      display: none;
    }
    .minus {
      display: block;
    }
  }
  h2 {
    color: #30333b;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 200;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0px;
  }
  .arrowIcon {
    display: flex;
  }
  .minus {
    display: none;
  }
`;

export const Dropdown = styled.div`
  .dropdownList {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 24px 16px 0px;
    &::after {
      content: "";
      width: 22%;
    }
  }
  .dropdownNeeds {
    padding: 24px 16px;
    border-top: 1px solid rgba(219, 222, 229, 0.4);
  }
`;

export const Value = styled.div`
  width: ${props => props.width || "22%"};
  margin-bottom: 24px;
  span {
    color: #666c78;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }
  h3 {
    color: #30333b;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin: 8px 0px 0px;
  }
  &:nth-child(2) {
    width: 38%;
  }
  &:nth-child(5) {
    width: 38%;
  }
  &:nth-child(8) {
    width: 38%;
  }
`;
