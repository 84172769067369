import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "./colors";
export const IconCard = styled.div`
  margin: ${props => props.margin || "0px"};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  .iconify {
    color: rgba(48, 51, 59, 1);
    width: 30px;
    height: 30px;
  }
  &.square {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 24px;
    width: 24px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    .iconify {
      color: ${colors.white};
  }
`;

const IconButton = ({ iconName, margin, type }) => (
  <IconCard margin={margin} className={type}>
    {/* <Icon path={icon_name} size={1} className="icon" /> */}
    <span className="iconify" data-icon={iconName} data-inline="false"></span>
  </IconCard>
);
IconButton.propTypes = {
  iconName: PropTypes.string,
  margin: PropTypes.string,
  type: PropTypes.string
};
export default IconButton;
