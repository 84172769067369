import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Form,
  CardTitle,
  CardSubtitle,
  CardText
} from "reactstrap";
import { createForm } from "rc-form";
import FormInput from "./../components/FormInput";
import CheckBox from "./../components/CheckBox";
import axios from "../utils/axios.js";
import toast from "../utils/toast";
import { envType } from '../config/baseURL';

import {
  API_LOGIN,
  API_RESET_PSWD,
  API_FORGOT_PSWD
} from "../config/apiEndpoints.js";
import bgImage from "../assets/img/provider login illustration.png";
import kiddenLogo from '../assets/img/Group (1).png';

import { UserContext } from "../App";
import responseToast from "../utils/responseToast";
const Logo = styled.div`
height: 65px;	width: 146px;
  img {
    width: 100%;
    height: 100%;
  }
`;
function Login({ form }) {
  // const [checked, setChecked] = useState(true);
  const [showHide, setShowHide] = useState(false);
  const [showHideOld, setShowHideOld] = useState(false);
  const [showHideNew, setShowHideNew] = useState(false);
  const [showHideConfirmNew, setShowHideConfirmNew] = useState(false);

  const [loggedChangePassword, setChangePassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [newChangePassword, setNewChangePassword] = useState(false);
  const [email, setEmail] = useState('');
  const [compareErr, setCompareErr] = useState(false);





  const [logInRedirect, setLogin] = useState(false);
  const [global, setGlobal] = useState({});
  const [apiErrors, setApiErrors] = useState({});

  const ctx = useContext(UserContext);

  const handelLogin = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (errors, values) => {
        if (!errors) {
          console.log(values)
          const emailReg = new RegExp(
            "^([\\w\\.\\+\\-]+\\@[a-zA-Z0-9\\.\\-]+\\.[a-zA-z0-9]{2,4})$"
          );
          if (!emailReg.test(values.email.trim())) {
            values.email=`${values.email}@dckiddenz.com`
          }
          axios
            .post(API_LOGIN, values)
            .then(res => {
              logInRedirect &&
                !loggedChangePassword &&
                !forgotPassword &&
                ctx.actions.login(res.data);
              setGlobal(res.data);
              res.data && res.data.data.is_bulk ?
              ctx.actions.login(res.data)
              : res.data.data.new_user
                ? setChangePassword(true)
                : ctx.actions.login(res.data);
              return res.data.data;
            })
            .catch(err => {
              err.response &&
                err.response.data &&
                responseToast(
                  err.response.data.data &&
                    Object.keys(err.response.data.data).length === 0
                    ? err.response.data.message || 'Something went wrong'
                    : err.response.data.data.password[0] || 'Something went wrong'
                );
            });
        }
      });
    },
    [ctx.actions, form]
  );

  const handlePassword = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (errors, values) => {
        if (!errors) {
          if (values.new_password && values.confirm_new_password) {
            if (values.new_password.localeCompare(values.confirm_new_password) === 0) {
              setCompareErr(false)
              delete values.confirm_new_password;
            }
            else {
              setCompareErr(true)
              return;
            }
          }

          console.log(values);
          axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem(
            "x-refresh-token"
          ) || window.localStorage.getItem("x-access-token")}`;
          axios
            .post(API_RESET_PSWD, values)
            .then(res => res.data)
            .then(() => {
              window.localStorage.removeItem("x-refresh-token");
              axios.defaults.headers.common.Authorization = "";
              setChangePassword(false);
              setLogin(true);
              setNewChangePassword(false)
            })
            .then(ctx.actions.logout)
            .catch(err => {
              err.response &&
                err.response.data &&
                responseToast(
                  err.response.data.data &&
                    Object.keys(err.response.data.data).length === 0 ? err.response.data.message || 'Something went wrong'
                    : err.response.data.data.old_password &&
                      err.response.data.data.new_password
                      ? `${err.response.data.data.old_password[0]} ${err.response.data.data.new_password[0]}` || 'Something went wrong'
                      : err.response.data.data.old_password
                        ? err.response.data.data.old_password[0] || 'Something went wrong'
                        : Array.isArray(err.response.data.data.new_password)
                          ? err.response.data.data.new_password[0] || 'Something went wrong'
                          : err.response.data.data.new_password || 'Something went wrong'
                );
            });
        }
      });
    },
    [form]
  );

  const handleForgotPassword = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (errors, values) => {
        if (!errors) {
          // values.server_type = 'production'
          values.server_type = envType === 'staging' ? 'staging' : 'production'
          const emailReg = new RegExp(
            "^([\\w\\.\\+\\-]+\\@[a-zA-Z0-9\\.\\-]+\\.[a-zA-z0-9]{2,4})$"
          );
          if (!emailReg.test(values.email.trim())) {
            values.email=`${values.email}@dckiddenz.com`
          }
          axios
            .post(API_FORGOT_PSWD, values)
            .then(res => {
              return res.data
            })
            .then(data => {
              toast.success("Email sent successfully", {}, "ADD");
              setForgotPassword(false);
              setNewChangePassword(true);
              setLogin(true);
              setChangePassword(true)
            })
            .then(ctx.actions.logout)
            .catch(err => {
              err.response &&
                err.response.data &&
                responseToast(err.response.data.message || 'Something went wrong');
                setForgotPassword(false);
                setChangePassword(false);
            });
        }
      });
    },
    [form]
  );

  const formValues = form.getFieldsValue();

  useEffect(() => {
    if (apiErrors.email || apiErrors.password) {
      setApiErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.email, formValues.password]);
  return (
    <>
      <Row className="loginPage">
        <Logo>
          <img src={kiddenLogo} alt="" />
        </Logo>
        <div className="nonLoginContainer">
          <img src={bgImage} alt="" />
        </div>
        <div className="loginContainer">
          {!loggedChangePassword && !forgotPassword && (
            <Card className="loginCard">
              <CardHeader>
                <CardTitle className="cardTitle">Login</CardTitle>
                <CardText className="cardText">
                  Use the login email ID and password sent to you
                </CardText>
              </CardHeader>
              <Form className="form-horizontal" onSubmit={handelLogin}>
                <CardBody className="cardBody">
                  <Row>
                    <FormInput
                      form={form}
                      name="email"
                      rightPadding="0"
                      placeholder="Username / E-mail ID"
                      label=""
                      colSpan="12"
                      onChange={(val) => setEmail(val)}
                      // type="email"
                      inputbox="true"
                    />
                  </Row>
                  <Row style={{marginTop: -20, marginBottom: 20}}>
                    <FormInput
                      form={form}
                      name="password"
                      placeholder="Password"
                      classType='forgotLoginPassword'
                      label=""
                      rightPadding="0"
                      colSpan="12"
                      type={showHide ? "text" : "password"}
                      inputbox="true"
                      passwordshowhide="true"
                      showhide={showHide ? true : undefined}
                      showhidefn={val => setShowHide(val)}
                    />
                    <Col>
                      <CardText
                        className="forgotPassword"
                        onClick={() => {
                          setForgotPassword(true);
                          setChangePassword(false);
                        }}
                      >
                        Forgot Password?
                    </CardText>
                    </Col>
                  </Row>
          
                  <Col className="formFlex" style={{margin: 0}}>
                    <Button type="submit" className="width100" style={{height: 56}}>
                      Login
                    </Button>
                  </Col>

                </CardBody>
              </Form>
            </Card>
          )} 
           {loggedChangePassword && !forgotPassword && !newChangePassword && (
            <Card className="loginCard">
              <CardHeader>
                <CardTitle className="cardTitle">Change Password</CardTitle>
              </CardHeader>
              <Form className="form-horizontal" onSubmit={handlePassword}>
                <CardBody className="cardBody">
                  <Row style={{marginTop: -20, marginBottom: 20}}>
                    <FormInput
                      form={form}
                      name="old_password"
                      placeholder='Enter old password'
                      label=""
                      colSpan="12"
                      type={showHideOld ? "text" : "password"}
                      inputbox="true"
                      rightPadding="0"
                      passwordshowhide="true"
                      showhide={showHideOld ? true : undefined}
                      showhidefn={val => setShowHideOld(val)}
                    />
                  </Row>
                  <Row style={{marginTop: -35}}>
                    <FormInput
                      form={form}
                      name="new_password"
                      placeholder='Enter new password'
                      label=""
                      colSpan="12"
                      type={showHideNew ? "text" : "password"}
                      inputbox="true"
                      passwordshowhide="true"
                      rightPadding="0"
                      showhide={showHideNew ? true : undefined}
                      showhidefn={val => setShowHideNew(val)}

                    />
                  </Row>
                  <Col className="alignItems formFlex" style={{marginTop: -15, marginBottom: 0}}>
                    <Button type="submit"  className="width100">
                      Change Password
                    </Button>
                    <Button
                      
                      className="width100"
                      onClick={() => ctx.actions.login(global)}
                    >
                      Skip
                    </Button>
                  </Col>
                </CardBody>
              </Form>
            </Card>
         )}  

         {forgotPassword && (
            <Card className="loginCard" >
              <CardHeader>
                <CardTitle className="cardTitle">Forgot Password</CardTitle>
                <CardText className="CardText">Enter email ID</CardText>
              </CardHeader>
              <Form className="form-horizontal" onSubmit={handleForgotPassword}>
                <CardBody className="cardBody">
                  <Row style={{marginTop: -10}}>
                    <FormInput
                      form={form}
                      name="email"
                      placeholder="Username / E-mail ID"
                      label=""
                      colSpan="12"
                      // type="email"
                      inputbox="true"
                      rightPadding="0"
                      onChange={(val) => setEmail(val)}
                    />
                  </Row>
                  <Col className="alignItems formFlex" style={{marginTop: -15, marginBottom: 0}}>
                    <Button type="submit" className="width100">
                      Send
                    </Button>
                  </Col>
                </CardBody>
              </Form>
            </Card>
         )}  

          {newChangePassword && (
            <Card className="loginCard">
              <CardHeader>
                <CardTitle className="cardTitle">Forgot password</CardTitle>
                <CardSubtitle className="cardSubtitle">Recovery password has been sent to your mail <b>{email}</b>, please use the same to create a new password</CardSubtitle>

              </CardHeader>
              <Form className="form-horizontal" onSubmit={handlePassword}>
                <CardBody className="cardBody">
                  <Row>
                    <FormInput
                      form={form}
                      name="old_password"
                      placeholder='Enter recovery password'
                      label=""
                      colSpan="12"
                      type={showHideOld ? "text" : "password"}
                      inputbox="true"
                      rightPadding="0"
                      passwordshowhide="true"
                      showhide={showHideOld ? true : undefined}
                      showhidefn={val => setShowHideOld(val)}
                    />
                  </Row>
                  <Row style={{marginTop: -20}}>
                    <FormInput
                      form={form}
                      name="new_password"
                      placeholder='Enter new password'
                      label=""
                      colSpan="12"
                      type={showHideNew ? "text" : "password"}
                      inputbox="true"
                      passwordshowhide="true"
                      rightPadding="0"
                      showhide={showHideNew ? true : undefined}
                      showhidefn={val => setShowHideNew(val)}

                    />
                  </Row>
                  <Row  style={{marginTop: -20}}>
                    <FormInput
                      form={form}
                      name="confirm_new_password"
                      placeholder='Confirm new password'
                      label=""
                      colSpan="12"
                      type={showHideConfirmNew ? "text" : "password"}
                      inputbox="true"
                      passwordshowhide="true"
                      rightPadding="0"
                      showhide={showHideConfirmNew ? true : undefined}
                      showhidefn={val => setShowHideConfirmNew(val)}
                      submit={compareErr}
                      msg={'Password does not match'}
                    />
                  </Row>
                  <Col className="formFlex alignItems"  style={{marginTop: -15, marginBottom: 0}}>
                    <Button type="submit"  className="width100">
                      Change Password
                    </Button>
                  </Col>
                </CardBody>
              </Form>
            </Card>
          )} 
        </div>
      </Row>
    </>
  );
}

export default createForm()(Login);
