import styled from "styled-components";

const Flex = styled.div`
  position: relative;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  display: ${props => props.display || "flex"};
  height: ${props => props.flexHeight || "auto"};
  width: ${props => props.flexWidth || "auto"};
  max-width: ${props => props.maxWidth || "auto"};
  padding: ${props => props.flexPadding || "0px"};
  margin: ${props => props.flexMargin || "0px"};
  background: ${props => props.whitebg || "transparent"};
  flex: ${props => (props.flex1 ? "1" : "none")};
  text-align: ${props => props.textAlign || "left"};
  border-radius: ${props => props.flexBorderRadius || "0px"};
  border: solid ${props => props.flexBorder || "0px transparent"};
  border-bottom: ${props => props.flexBorderdashed || "0px transparent"};
  overflow-x: ${props => props.flexoverflow || "initial"};
  overflow-y: ${props => props.flexoverflowY || "initial"};
  box-shadow: ${props => props.flexboxshadow || "none"};
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-moz-scrollbars {
    display: none;
  }
  &::-o-scrollbar {
    display: none;
  }
  flex-wrap: ${props => {
    if (props.wrapReverse) return "wrap-reverse";
    if (props.noWrap) return "nowrap";
    if (props.wrap) return "wrap";
    return "";
  }};

  flex-flow: row wrap;

  justify-content: ${props => {
    if (props.justifyContent) return props.justifyContent;
    if (props.justifyCenter) return "center";
    if (props.justifyAround) return "space-around";
    if (props.justifyBetween) return "space-between";
    if (props.justifyEvenly) return "space-evenly";
    if (props.justifyEnd) return "flex-end";
    if (props.justifyStart) return "flex-start";
    return "";
  }};
  align-items: ${props => {
    if (props.alignItems) return props.alignItems;
    if (props.alignStretch) return "stretch";
    if (props.alignEnd) return "flex-end";
    if (props.alignCenter) return "center";
    if (props.alignBaseline) return "baseline";
    if (props.alignStart) return "flex-start";
    return "";
  }};
  align-content: ${props => {
    if (props.alignContent) return props.content;
    if (props.contentStart) return "flex-start";
    if (props.contentEnd) return "flex-end";
    if (props.contentCenter) return "center";
    if (props.contentBetween) return "space-between";
    if (props.contentAround) return "contentAround";
    if (props.contentStretch) return "stretch";
    return "";
  }};
  flex-direction: ${props => (props.column ? "column" : "row")};

  @media (max-width: 767px) {
    &.flexResponsive {
      padding: 30px 0 0 !important;
    }
  }
  .send-response-success {
    height: 50px;
    width: 162px;
    border-radius: 5px;
    background-color: #613A95;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    border-color: #613A95;
  }
`;

export const Column = styled.div`
  width: ${props => {
    if (props.two) return "50%";
    if (props.three) return "33.33%";
    if (props.four) return "25%";
    return props.width;
  }};
  padding: ${props => props.padding || "0px"};

  @media (max-width: 991px) {
    width: 100% !important;
    padding: 0px !important;
  }
`;

export const FlexWrap = styled.div`
  width: ${props => props.width};
  padding: ${props => props.padding};
  flex: 1 0 auto;
  position: relative;
  &:first-child {
    margin-left: 16px;
  }
  &:last-child {
    width: calc(${props => props.width} + 20px);
    padding-right: 20px;
  }
`;

export default Flex;
