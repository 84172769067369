/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
// import { css } from 'styled-components';

// import colors from "./../../colors";
import styled from 'styled-components';
import buttonStyles from './buttonstyles';
// import A from './A';
// import StyledButton from './StyledButton';
// import Wrapper from './Wrapper';
const A = styled.button`
  ${buttonStyles}
`;
const Button = props => (
  <A
    // href={props.href}
    onClick={props.onClick}
    fullwidth={props.fullwidth}
    height={props.height}
    marginRight={props.marginRight}
    headerButton={props.headerButton}
    marginTop={props.marginTop}
    className={props.type}
    disabled={props.disabled}
    style={props.style}
  >
    {props.text}
    <Icon path={mdiChevronDown} size={1} className="icon" />
  </A>
);

// If the Button has a handleRoute prop, we want to render a button
// if (props.handleRoute) {
//   button = (
//     <StyledButton onClick={props.handleRoute}>
//       {Children.toArray(props.children)}
//     </StyledButton>
//   );
// }

Button.propTypes = {
  // handleRoute: PropTypes.func,
  // href: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  // children: PropTypes.node.isRequired,
  fullwidth: PropTypes.bool,
  disabled: PropTypes.bool,
  headerButton: PropTypes.string,
};
Button.defaultProps = {
  height: '56px',
};
export default Button;
