/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { CardBody, Col, Row, Button, Form, Modal } from "reactstrap";
import { createForm } from "rc-form";
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";
import FormInput from "../../../components/FormInput";
import FileUploader from "../../../components/Uploader";
import Dropdown from "../../../components/Dropdown";
import uniq from "lodash/uniq";

let globalId;

function StaffForm(props) {
  const [language, setLanguage] = useState([]);
  const [staffModal, setStaffModal] = useState(false);
  const [deleteTeacherModal, setDeleteTeacherModal] = useState(false);

  const staffArr = [];
  const [staff, setStaff] = useState([1]);

  const [detailsArr, setDetailsArr] = useState([]);
  const [languageDropdown, setLanguageDropdown] = useState([]);
  const [dropdownDefValue, setDropdownDefValue] = useState([]);
  const uploadStaffFn = (index, object, value) => {
    props.setContentChanged(true)
    let dummyArr = [...detailsArr];
    dummyArr[index] = dummyArr[index] || {};
    dummyArr[index][object] = value;
    // if (!dummyArr[index]['name']) {
    //     dummyArr[index]['err'] = 'Name is required';
    // } else {
    //     dummyArr[index]['err'] = false;
    // }
    if (object === "experience" && value === "") {
      dummyArr[index]["experience"] = 0;
    }
    dummyArr[index].languages_spoken =
      language && language[index] && language[index].map(d => d.id);
    dummyArr[index].faculty_type = "staff";
    if (object === "photo_id" && value === "") {
      dummyArr[index].photo_deleted = true;
    } else if (object === "photo_id") {
      dummyArr[index].photo_deleted = false;
    }
    props.onSubmitStaff(dummyArr);
    setDetailsArr(dummyArr);
  };
  const deleteTeacher = id => {
    props.setContentChanged(true)

    globalId = id;
    setDeleteTeacherModal(true);
  };

  useEffect(() => {
    setDetailsArr([]);
    if (props.data.length > 0) {
      var teacher_filter = props.data;
      setDetailsArr(props.data);
      var teacher_count = teacher_filter.map(a => a);
      setStaff(teacher_count);
      props.form.setFieldsValue(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.languages.length > 0) {
      let dropVal = [...languageDropdown];
      props.languages.map(dt => dropVal.push({ id: dt.id, name: dt.name }));

      setLanguageDropdown(dropVal);
    }
  }, [props.languages]);

  useEffect(() => {
    setLanguage([]);

    if (props.data && props.data.length > 0 && props.languages.length > 0) {
      let languagesSpoken = props.data.map(a => a.languages_spoken);
      const languageArr = {};
      languageDropdown.map(d => (languageArr[d.id] = d));

      const teacherLanguages = languagesSpoken.map(d => {
        const tempArr = [];
        d.length > 0 && d.map(id => tempArr.push(languageArr[id]));
        return tempArr;
      });
      setLanguage(teacherLanguages);
    }
  }, [props.languages, props.data]);

  return (
    <>
      <Form className="form-horizontal">
        <CardBody className="formContent">
          {staff.map((val, id) => {
            staffArr.push(
              <>
                <Row className="extended">
                  <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
                    Staff {id + 1}
                  </Col>
                  <Col xs="8">
                    <Row style={{ marginLeft: '-10px' }}>
                      <FileUploader
                        imageSize={200}
                        width={1}
                        height={1}
                        form={props.form}
                        label={``}
                        name={`staff_image${id}`}
                        xsval="4"
                        type="avatar"
                        // uploadText="Upload Picture"
                        uploadUrl={ids => {
                          uploadStaffFn(id, "photo_id", ids);
                        }}
                        // topMargin="0px"
                        deleted={
                          detailsArr &&
                          detailsArr.length > 0 &&
                          !detailsArr[id] &&
                          true
                        }
                        setCancelImg={ids => {
                          uploadStaffFn(id, "photo_id", "");
                        }}
                        isRequired={false}
                        urlImage={
                          detailsArr &&
                          detailsArr.length > 0 &&
                          detailsArr[id] &&
                          detailsArr[id][`photo_url`]
                        }
                        teacherstaff={true}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <FormInput
                    form={props.form}
                    name={`Staff Name${id}`}
                    placeholder="Name of the Staff"
                    label="Name"
                    colSpan="12"
                    eachSpan
                    isName
                    fullLength="8"
                    isRequired={
                      detailsArr &&
                        detailsArr.length > 0 &&
                        detailsArr[id] &&
                        !detailsArr[id].photo_id &&
                        !detailsArr[id].experience &&
                        !(
                          language[id] &&
                          language[id].length > 0
                        ) &&
                        !detailsArr[id].additional_comments
                        ? false
                        : true
                    }
                    submit={
                      detailsArr &&
                      detailsArr.length > 0 &&
                      detailsArr[id] &&
                      (detailsArr[id].photo_id ||
                        detailsArr[id].experience ||
                        (language[id] && language[id].length > 0) ||
                        detailsArr[id].additional_comments)
                    }
                    defaultValue={
                      detailsArr && detailsArr.length > 0 && detailsArr[id]
                        ? detailsArr[id][`name`]
                        : ""
                    }
                    msg={
                      detailsArr &&
                      detailsArr.length > 0 &&
                      detailsArr[id] &&
                      ((detailsArr[id].photo_id ||
                        detailsArr[id].experience ||
                        (language[id] && language[id].length > 0) ||
                        detailsArr[id].additional_comments) &&
                        detailsArr[id].name
                        ? ""
                        : "Staff Name is required")
                    }
                    onChange={val => uploadStaffFn(id, "name", val)}
                  />
                  {/* {detailsArr.length > 0 && detailsArr[id] && detailsArr[id].err && 
                                    <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                                        {detailsArr[id].err}
                                    </div>} */}
                </Row>
                <Row>
                  <FormInput
                    label="Experience"
                    name={`experience${id}`}
                    placeholder="Year"
                    form={props.form}
                    colSpan="12"
                    isExp
                    eachSpan
                    fullLength="8"
                    isRequired={props.contentWriting ? false : false}
                    defaultValue={
                      detailsArr && detailsArr.length > 0 && detailsArr[id]
                        ? detailsArr[id][`experience`]
                        : ""
                    }
                    onChange={val => uploadStaffFn(id, "experience", val)}
                  />
                </Row>

                {/* <Row className="scheduleRow">
                                    <Col xs="4" >Languages spoken </Col>
                                    <Col className="schedule" style={{ paddingLeft: 20 }}>
                                        {props.languages &&
                                            props.languages.map(dt => (
                                                <div
                                                    className={`form-control-alternative ${language && language[id] && language[id].includes(dt.id) &&
                                                        "activeSchedule"}`}
                                                    key={dt.id}
                                                    onClick={() => {
                                                        let dummy = [...language];
                                                        if (dummy[id] && dummy[id].includes(dt.id)) {
                                                            dummy[id].splice(dummy[id].indexOf(dt.id), 1);
                                                        } else {
                                                            dummy[id] = dummy[id] || [];
                                                            dummy[id].push(dt.id);
                                                        }
                                                        setLanguage(dummy);
                                                        uploadStaffFn(id)

                                                    }}
                                                >
                                                    <span>{dt.name}</span>
                                                </div>
                                            ))}
                                    </Col>
                                </Row> */}

                <Row className="daySchedule" style={{ marginBottom: 30 }}>
                  <Col xs="4" className="formInputName">Languages spoken</Col>
                  <Col className="dayTime" style={{ marginLeft: "-23px" }}>
                    <Dropdown
                      form={props.form}
                      name={`languages${id + 1}`}
                      label=""
                      xsval="4"
                      onChange={val => {
                        if (val) {
                          let dummy = [...language];
                          let dropVal = [...languageDropdown];
                          let dropFilter =
                            val &&
                            dropVal.filter(
                              dt => dt.id === Number(val) && dt.name
                            );
                          let teacherLanguages = language[id]
                            ? [...language[id]]
                            : [];
                          dummy[id] = [...teacherLanguages, dropFilter[0]];

                          let defaultValue = [...dropdownDefValue];

                          defaultValue[id] = val;
                          dummy[id] = uniq(dummy[id]);
                          setLanguage(dummy);
                          setDropdownDefValue(defaultValue);
                          uploadStaffFn(id);
                        }
                      }}
                      isRequired={props.contentWriting ? false : false}
                      options={languageDropdown}
                      defaultValue={
                        dropdownDefValue.length > 0 && dropdownDefValue[id]
                      }
                    />
                  </Col>
                </Row>
                {language.length > 0 && (
                  <Row
                    className="scheduleRow"
                    style={{ marginBottom: 30, marginTop: "-32px", flexWrap: "nowrap" }}
                  >
                    <Col xs="4"></Col>
                    <Col className="schedule" style={{ marginLeft: 10, flexFlow: "row wrap" }}>
                      {language[id] &&
                        language[id].map(dt => (
                          dt && <div className={`languageChips`}>
                            <span>{dt.name}</span>
                            <Icon
                              path={mdiCloseCircle}
                              onClick={() => {
                                let dummy = [...language];
                                let dummyName = dummy[id].map(d => d.id);
                                let defaultValue = [...dropdownDefValue];
                                defaultValue[id] = "";
                                if (dummyName.includes(dt.id)) {
                                  dummy[id].splice(dummy[id].indexOf(dt), 1);
                                }
                                setLanguage(dummy);
                                setDropdownDefValue(defaultValue);
                                uploadStaffFn(id);
                              }}
                            />
                          </div>
                        ))}
                    </Col>
                  </Row>
                )}

                <Row>
                  <FormInput
                    label="Additional comments"
                    name={`additional_comments${id}`}
                    placeholder="Comments"
                    form={props.form}
                    type="textarea"
                    colSpan="12"
                    eachSpan
                    fullLength="8"
                    maxHeight="6"
                    isRequired={props.contentWriting ? false : false}
                    defaultValue={
                      detailsArr && detailsArr.length > 0 && detailsArr[id]
                        ? detailsArr[id][`additional_comments`]
                        : ""
                    }
                    maxLength={5000}
                    onChange={val =>
                      uploadStaffFn(id, "additional_comments", val)
                    }
                  />
                </Row>
                <Row
                  className="formFlex"
                  style={{ display: "block", paddingLeft: "36%" }}
                >
                  <Button onClick={() => deleteTeacher(id)}>Delete</Button>
                </Row>
                {id + 1 !== staff.length && <hr />}
              </>
            );
          })}
          {staffArr}

          <Row
            className="formFlex"
            style={{ display: "block", paddingLeft: "36%" }}
          >
            {/* <Col xs="4"></Col> */}
            <Col xs="2" style={{ padding: "0px" }}>
              <Button
                onClick={() => setStaffModal(true)}
                disabled={staff.length !== detailsArr.length}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div className="plusIcon1">
                  <span class="iconify" data-icon="fe:plus" data-inline="false"></span>
                </div>
                <span>Add Staff</span>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Form>
      {staffModal && (
        <Modal
          className="modal-dialog-centered"
          isOpen={staffModal}
          toggle={() => {
            setStaffModal(false);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add more Staffs?
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setStaffModal(false);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setStaffModal(false);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                setStaffModal(false);
                const dummy = [...staff];
                dummy.push(1);
                setStaff(dummy);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
      )}
      {deleteTeacherModal && (
        <Modal
          className="modal-dialog-centered"
          isOpen={deleteTeacherModal}
          toggle={() => {
            setDeleteTeacherModal(false);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Are you sure want to delete?
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDeleteTeacherModal(false);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setDeleteTeacherModal(false);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                setDeleteTeacherModal(false);
                let dummyArr = [...detailsArr];
                var teach = [...staff];
                let lang = [...language];

                var data = dummyArr.filter((a, i) => i === globalId);
                var deleteData = data[0];
                if (deleteData && deleteData.id) {
                  props.deleteTeacherApi(deleteData);
                  dummyArr.splice(dummyArr.indexOf(data[0]), 1);
                } else {
                  dummyArr.splice(globalId, 1);
                }
                if (teach.length > 1) teach.splice(globalId, 1);
                lang.splice(globalId, 1);
                setLanguage(lang);

                setDetailsArr(dummyArr);
                setStaff(teach);
                if (dummyArr.length === 0) {
                  let object = {};
                  object[`staff_image0`] = ''
                  object[`Staff Name0`] = ''
                  object[`experience0`] = ''
                  object[`languages1`] = ''
                  object[`additional_comments0`] = ''

                  props.form.setFieldsValue(object)
                }
                props.onSubmitStaff(dummyArr);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

StaffForm.propTypes = {};

export default createForm()(StaffForm);
