import React from 'react';
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';

const Cards = ({ title, content }) => {
  return (
    <div>
      <Card className="emptyCardContainer">
        <Card className="emptyCard"></Card>
        <CardBody className="cardContainer">
          <CardTitle className="cardTitle">{title}</CardTitle>
          <CardText className="cardText">{content}</CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default Cards;