/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const VerfiedCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 5px;
  margin: ${props => props.margin || "0px 0px 20px 0px;"};
  height: 30px;
  width: fit-content;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  border-radius: 5px;
  background-color: #613a95;
  .iconify {
    color: #fff;
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }
`;

const Verfied = props => (
  <VerfiedCard>
    <span
      className="iconify"
      data-icon="ic:baseline-verified-user"
      data-inline="false"
    />
    {props.name}
  </VerfiedCard>
);
Verfied.propTypes = {
  name: PropTypes.string
};
export default Verfied;
