/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";
import { Col, Row } from "reactstrap";
import ScheduleTour from "../../../components/ScheduletourCard";
import Flex from "../../../views/Pages/SchoolProfile/flex.js";
import EmptyState from "../../../components/EmptyState";
import emptyImage from "../../../assets/img/illustrtns_school tour.svg";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import profilePic from "../../../assets/img/user2.svg";
import { API_PARENTS } from "../../../config/apiEndpoints";
import Loader from "../../../components/Loader";
import useCommonCRUD from "../../../hooks/commonCRUD";
const SCHEDULE_DATA = [
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    showChild: false,
    img: profilePic,
    changedDate: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [
      {
        first_name: "Dia",
        age: "4",
        joining: "Immediate",
        type_of_care: "Daycare & preschool",
        food_choice: "Yes",
        schedule: "Flexible",
        medication_details: "Pansac: Before breakfast",
        allergies: "Beans",
        transportaion: "Yes",
        child_care_experience: "None",
        other_specific_needs:
          "My child needs assitance during eating,Potty training is not done, please assist during potty",
        special_care: false,
        showEachChild: false
      },
      {
        first_name: "Aryan",
        age: "2",
        joining: "Immediate",
        type_of_care: "Daycare",
        food_choice: "No",
        schedule: "Flexible",
        medication_details: "Pansac: Before breakfast",
        allergies: "Beans",
        transportaion: "No",
        child_care_experience: "Yes",
        special_care: false,
        other_specific_needs: "",
        showEachChild: false
      }
    ]
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    changedDate: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,
    changedDate: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,
    changedDate: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,
    changedDate: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,
    changedDate: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    showChild: false
  }
];

function ChangedTour(props) {
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [getChangedTour, , , , getChangedTourByFilter] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PARENTS}change-schedule-tour/?search=`,
      readId: `${API_PARENTS}change-schedule-tour/?search=`
    }
  });
  const toggleChild = (data, index, show, childIndex, child) => {
    let dataCopy = [...groupedData];
    if (child) {
      dataCopy.map((dt, i) => {
        if (dt.data === data) {
          dt.data.map((a, i) => {
            if (i === index) {
              console.log(a);
              a.child.map((d, id) => {
                if (id === childIndex) {
                  d.showEachChild = !show;
                } else {
                  d.showEachChild = false;
                }
              });
            }
          });
        }
      });
    } else
      dataCopy.map((dt, i) => {
        if (dt.data === data) {
          dt.data.map((a, i) => {
            if (i === index) {
              a.showChild = !show;
            }
          });
        }
      });
    setGroupedData(dataCopy);
  };

  useEffect(() => {
    if (
      getChangedTour &&
      getChangedTour.data &&
      getChangedTour.data.length > 0
    ) {
      let scheduleData = [];
      getChangedTour.data.map(d =>
        scheduleData.push({
          id: d.id,
          name:
          `${d.parent_name} is visiting the school on`,
          date: d.date,
          tour_id: d.tour_id,
          old_time: d.old_time,
          old_date: d.old_date,
          time: `${moment(`${d.date} ${d.time}`).format(
            "dddd, MMM Do hh:mmA"
          )} Tour ID: ${d.tour_id}`,
          showChild: false,
          img:
            d.provider_pic && d.provider_pic[0] && d.provider_pic[0].media_url,
          child: d.child_info
        })
      );
      setData(scheduleData);
    } else setData([]);
  }, [getChangedTour]);

  useEffect(() => {
    let groups = {};
    for (var i = 0; i < data.length; i++) {
      var groupName = data[i].date;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(data[i]);
    }
    let myArray = [];
    for (let groupName in groups) {
      myArray.push({ date: groupName, data: groups[groupName] });
    }
    setGroupedData(myArray);
  }, [data]);

  useEffect(() => {
    getChangedTourByFilter({
      data: searchValue
    });
}, [searchValue]);
  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar
          {...props}
          brandText="Kiddenz"
          search
          searchValue={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <Loader isOpen={getChangedTour.isLoading} />
      {!getChangedTour.isLoading && (
        <div className="dashboardContainer">
          <div id="acceptTour">
            <div className="mainHeading">
              <Col>
                <h3>Changed scheduled Tours</h3>
                <span>
                  Tour schedules changed from the daycare end will be shown here
                </span>
              </Col>
            </div>
            {groupedData.length > 0 ? (
              <Flex column>
                {groupedData.map((a, i) => (
                  <>
                    <h3>{moment(a.date).format("MMM DD, YYYY")}</h3>
                    <Flex justifyBetween>
                      {a.data.map((d, i) => (
                        <Flex column >
                          <ScheduleTour
                            name={d.name}
                            time={d.time}
                            childData={d.child}
                            img={d.img}
                            classType="changeTour"
                            changedDate={`${moment(`${d.old_date} ${d.old_time}`).format(
                              "dddd, MMM Do hh:mmA"
                            )} Tour ID: ${d.tour_id}`}
                            childDetailType={d.showChild ? "active" : ""}
                            toggleChild={() =>
                              toggleChild(a.data, i, d.showChild)
                            }
                            toggleEachChild={(index, childShow) => {
                              toggleChild(a.data, i, childShow, index, true);
                            }}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </>
                ))}
              </Flex>
            ) : (
              <EmptyState
                text="Changed Tours"
                desc="There are no school tours changed, keep a check on this space to know more"
                emptyImage={emptyImage}
              />
            )}
          </div>
        </div>
      )}{" "}
    </>
  );
}

ChangedTour.propTypes = {};

export default ChangedTour;
