import React from "react";
import Slider from "react-slick";
import {
    Card,
    CardTitle,
    CardSubtitle,
    CardText,
    Button,
    CardBody,
    Row,
    Col,

} from "reactstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Cards from '../../../components/Cards';
import CardFooters from '../../../components/CardFooter';
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";

class LandingPage extends React.Component {
    render() {
        var settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <>
                <div className="header">
                    <AdminNavbar {...this.props} brandText="Kiddenz" />
                </div>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <Slider {...settings}>
                                    <Card className="sliderCard">
                                        <CardTitle className="sliderCardTitle">Be part of our Kiddenz Chain</CardTitle>
                                        <CardSubtitle className="sliderCardSubTitle">Help parents find the best school for kids</CardSubtitle>
                                        <Button className="sliderCardButton"><span>Register Your school now</span></Button>
                                    </Card>
                                    <Card className="sliderCard">
                                        <CardTitle className="sliderCardTitle">Be part of our Kiddenz Chain</CardTitle>
                                        <CardSubtitle className="sliderCardSubTitle">Help parents find the best school for kids</CardSubtitle>
                                        <Button className="sliderCardButton"><span>Register Your school now</span></Button>
                                    </Card>
                                    <Card className="sliderCard">
                                        <CardTitle className="sliderCardTitle">Be part of our Kiddenz Chain</CardTitle>
                                        <CardSubtitle className="sliderCardSubTitle">Help parents find the best school for kids</CardSubtitle>
                                        <Button className="sliderCardButton"><span>Register Your school now</span></Button>
                                    </Card>
                                </Slider>
                            </Col>
                            <Col>
                                <CardTitle className="partOfKiddenz">Why be part of Kiddenz</CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="4">
                                <Cards
                                    title="Grow business"
                                    content="Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit commodo eripuit an, has et natum invenire. An vix nostro accusata omittantur" />
                            </Col>
                            <Col xs="4">
                                <Cards
                                    title="Respond to requests"
                                    content="Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit commodo eripuit an, has et natum invenire. An vix nostro accusata omittantur" />
                            </Col>
                            <Col xs="4">
                                <Cards
                                    title="Keep a track of your business"
                                    content="Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit commodo eripuit an, has et natum invenire. An vix nostro accusata omittantur" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider {...settings} className="sliderWrapper2">
                                    <Card className="sliderCard2">
                                        <CardTitle className="sliderCardHeader">Listen from other providers</CardTitle>
                                        <CardBody className="justifyCardData">
                                            <div className="circle"></div>
                                            <div className="sliderText">
                                                <CardTitle>Name</CardTitle>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit commodo eripuit an, has et natum invenire. An vix nostro accusata omittantur
                                                                </CardText>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit has et natum invenire. An vix nostro accusata omittantur</CardText>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="sliderCard2">
                                        <CardTitle className="sliderCardHeader">Listen from other providers</CardTitle>
                                        <CardBody className="justifyCardData">
                                            <div className="circle"></div>
                                            <div className="sliderText">
                                                <CardTitle>Name</CardTitle>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit commodo eripuit an, has et natum invenire. An vix nostro accusata omittantur
                                                                </CardText>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit has et natum invenire. An vix nostro accusata omittantur</CardText>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="sliderCard2">
                                        <CardTitle className="sliderCardHeader">Listen from other providers</CardTitle>
                                        <CardBody className="justifyCardData">
                                            <div className="circle"></div>
                                            <div className="sliderText">
                                                <CardTitle>Name</CardTitle>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit commodo eripuit an, has et natum invenire. An vix nostro accusata omittantur
                                                                </CardText>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit has et natum invenire. An vix nostro accusata omittantur</CardText>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="sliderCard2">
                                        <CardTitle className="sliderCardHeader">Listen from other providers</CardTitle>
                                        <CardBody className="justifyCardData">
                                            <div className="circle"></div>
                                            <div className="sliderText">
                                                <CardTitle>Name</CardTitle>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit commodo eripuit an, has et natum invenire. An vix nostro accusata omittantur
                                                                </CardText>
                                                <CardText>Lorem ipsum dolor sit amet, mel ex ipsum dolore. Sit has et natum invenire. An vix nostro accusata omittantur</CardText>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Slider>
                            </Col>
                        </Row>
                        <Row className="infoContent">
                            <Col xs='6'>
                                <Card className="cardContent">
                                    <CardTitle className="cardTitle">How to get started?</CardTitle>
                                    <CardText className="cardText">Let’s make searching a childcare easy for the parents.<br />
                                        It just takes few simple steps. Launch your childcare with our assistance</CardText>
                                    <CardText className="cardText">
                                        1. Fill in the form with basic details<br />
                                        2. Submit the form<br />
                                        3. Kiddenz team will contact you and provide you with Login credentials<br />
                                        4. And you are done, Keep a track on your childcare using the dashboard
                                                </CardText>
                                    <Button className="cardButton"><span>Register your school now</span></Button>
                                </Card>
                            </Col>
                            <Col xs='6'>
                                <Card className="cardContent bg-color">
                                    <CardBody className="cardBody">
                                        <CardTitle className="cardTitle">Contact Us</CardTitle>
                                        <CardText className="cardText1">Please dial to our toll free number for any kind of assistance</CardText>
                                        <CardText className="cardText2">080 4034 4059</CardText>
                                        <CardText className="cardText1">Write us your queries or questions to</CardText>
                                        <CardText className="cardText2">support@kiddenz.com</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooters />
                </Card>
            </>
        );
    }
}

export default LandingPage;
