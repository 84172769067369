/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flex from "../../views/Pages/SchoolProfile/flex.js";
import tick from "../../assets/img/tick.svg";
import { Button } from "reactstrap";
import CustomButton from "../../views/Pages/SchoolProfile/Button.js";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";

function Modal({
  type,
  setActive,
  emailType,
  setModalType,
  closeHandler,
  successHandler,
  ...props
}) {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <>
      <ModalWrapper>
        <AdditionalFilterModal>
          {type === "success" && (
            <AdditionalFilterWrapper>
              <Flex justifyEnd>
                <i className="fa fa-times" onClick={closeHandler} />
              </Flex>
              <Flex column alignCenter>
                <img src={tick} alt="tick" />
              </Flex>
              <Flex column alignCenter flexMargin="16px 0px 24px 0px">
                <TypeOrg marginBottom="8px">{props.successHeading}</TypeOrg>
                <OrgName>{props.successSubHeading}</OrgName>
              </Flex>
              {props.noteContent && (
                <Flex
                  column
                  alignCenter
                  flexBorderRadius="5px"
                // style={{ border: "1px dashed #D6D6DE" }}
                >
                  <NoteContent>
                    Note: A notification message has been sent to parents
                    through mail and phone number
                  </NoteContent>
                </Flex>
              )}
              {props.acceptDetails && (
                <Flex column alignCenter flexBorderRadius="5px">
                  <TypeOrg fontSize="16px" fontWeight="700">
                    {props.acceptDetails.split('Tour ID: ')[0]}
                  </TypeOrg>
                  <TypeOrg fontSize="16px" fontWeight="700">
                    Tour ID: {props.acceptDetails.split('Tour ID: ')[1]}
                  </TypeOrg>
                </Flex>
              )}

              <Flex flexMargin="25px 0px 5px 0px" justifyCenter>
                <Button
                  className="send-response-success"
                  onClick={successHandler}
                >
                  Done
                </Button>
              </Flex>
            </AdditionalFilterWrapper>
          )}
          {type === "datefilter" && (
            <AdditionalFilterWrapper>
              <Flex justifyEnd>
                <i className="fa fa-times" onClick={closeHandler} />
              </Flex>
              <Flex column justifyStart>
                <TypeOrg marginBottom="24px" width="70%">
                  Select date period to view {props.tourType} tours
                </TypeOrg>
              </Flex>
              <Flex column flexMargin="0px 20px 0px 0px">
                <InputWrapper width="50%">
                  <div>From Date</div>
                  <div>To Date</div>
                </InputWrapper>
                <InputWrapper>
                  <DatePicker
                    form={props.form}
                    onChange={val => props.setFromValue(val)}
                    isRequired={true}
                    // currentDate={new Date()}
                    notYearPicker
                    marginLeft="-32px"
                    submit={props.fromValueErr}
                    // maxDate={new Date()}
                  // defaultValue={picturesDate}
                  />
                  {/* <DatePicker form={props.form} name='fromDate'/> */}
                  <DatePicker
                    minDate={props.fromValue}
                    disabled={!props.fromValue}
                    form={props.form}
                    onChange={val => props.setToValue(val)}
                    isRequired={true}
                    colSpan="6"
                    filter
                    marginRight="30px"
                    notYearPicker
                    submit={props.toValueErr}
                    minStartDate={props.fromValue}
                    // maxDate={new Date()}
                  />
                  {/* <input
                    type="text"
                    className="form-control form-control-alternative"
                    placeHolder="Enter Date"
                  /> */}
                  {/* <input
                    type="select"
                    className="form-control form-control-alternative"
                    placeHolder="Select a new time slot"
                  /> */}
                </InputWrapper>
              </Flex>
              <Flex justifyBetween flexMargin="40px 20px 0px 0px">
                <CustomButton
                  style={{ width: "45%" }}
                  type="mobile"
                  height="50px"
                  text="Proceed"
                  onClick={successHandler}
                />
                <CustomButton
                  style={{ width: "45%", border: "1px solid #CED1D8" }}
                  type="Contact"
                  height="50px"
                  text="Cancel"
                  onClick={closeHandler}
                />
              </Flex>
            </AdditionalFilterWrapper>
          )}
          {type === "changeDate" && (
            <AdditionalFilterWrapper>
              <Flex justifyEnd>
                <i className="fa fa-times" onClick={closeHandler} />
              </Flex>
              <Flex column justifyStart>
                <TypeOrg marginBottom="24px" width="70%">
                  Select new date and time
                </TypeOrg>
              </Flex>
              <Flex column flexMargin="0px 20px 0px 0px">
                <InputWrapper width="50%">
                  <div>Date</div>
                  <div>Time</div>
                </InputWrapper>
                <InputWrapper>
                  <DatePicker
                    form={props.form}
                    onChange={val => props.setDateValue(val)}
                    isRequired={true}
                    colSpan="6"
                    notYearPicker
                    marginLeft="-32px"
                    submit={props.dateValueErr}
                    currentDate={new Date()}
                  />
                  <TimePicker
                    placeholder="Select time"
                    colSpan="6"
                    name="Time"
                    marginLeft='-14px'
                    form={props.form}
                    isRequired={true}
                    onChange={val => props.setTimeValue(val)}
                    submit={props.timeValueErr}
                  // defaultValue={extendedTime}
                  />
                </InputWrapper>
              </Flex>
              <Flex justifyBetween flexMargin="40px 20px 0px 0px">
                <CustomButton
                  style={{ width: "45%" }}
                  type="mobile"
                  height="50px"
                  text="Next"
                  onClick={successHandler}
                />
                <CustomButton
                  style={{ width: "45%", border: "1px solid #CED1D8" }}
                  type="Contact"
                  height="50px"
                  text="Cancel"
                  onClick={closeHandler}
                />
              </Flex>
            </AdditionalFilterWrapper>
          )}
          {type === "changeTour" && (
            <AdditionalFilterWrapper>
              <Flex justifyEnd>
                <i className="fa fa-times" onClick={closeHandler} />
              </Flex>
              <Flex column justifyStart flexMargin="0px 0px 20px 0px">
                <TypeOrg marginBottom="15px">Changed scheduled tour</TypeOrg>
                <OrgName textAlign="none">
                  Below details will be shared with parents for approval of
                  change of tour schedule
                </OrgName>
              </Flex>
              <Flex column flexMargin="0px 20px 0px 0px">
                <ScheduleWrapper>
                  <div className="schedule">Old schedule</div>
                  <div className="parentName">
                    Parent name: {props.parentName}
                  </div>
                  <div className="date">
                    {props.oldTourDate} Tour ID: {props.oldTourId}
                  </div>
                </ScheduleWrapper>
                <ScheduleWrapper>
                  <div className="schedule">New schedule</div>
                  <div className="parentName">
                    Parent name: {props.parentName}
                  </div>
                  <div className="date">
                    {props.newTourDate} Tour ID: {props.newTourId}
                  </div>
                </ScheduleWrapper>
              </Flex>
              <Flex justifyBetween flexMargin="20px 20px 0px 0px">
                <CustomButton
                  style={{ width: "45%" }}
                  type="mobile"
                  height="50px"
                  text="Next"
                  onClick={successHandler}
                />
                <CustomButton
                  style={{ width: "45%", border: "1px solid #CED1D8" }}
                  type="Contact"
                  height="50px"
                  text="Cancel"
                  onClick={closeHandler}
                />
              </Flex>
            </AdditionalFilterWrapper>
          )}
          {type === "decline" && (
            <AdditionalFilterWrapper>
              <Flex justifyEnd>
                <i className="fa fa-times" onClick={closeHandler} />
              </Flex>
              <Flex column justifyStart flexMargin="0px 0px 20px 0px" flexWidth="80%">
                <TypeOrg marginBottom="15px">
                  Are you sure you want to Decline the Tour request?
                </TypeOrg>
                <OrgName textAlign="none">
                  We will notify the parent
                </OrgName>
              </Flex>
              <Flex column >
                <TextArea>
                  <textarea
                    cols="5"
                    type="text"
                    style={{ height: 100 }}
                    placeholder={'Please let them know the reason for declining the request'}
                    onChange={props.onChange}
                  />
                </TextArea>
                {props.declineValueErr && <Error>This field is required</Error>}
              </Flex>
              <Flex justifyBetween flexMargin="20px 20px 0px 0px">
                <CustomButton
                  style={{ width: "48%", border: "1px solid #CED1D8" }}
                  type="Contact"
                  height="50px"
                  text="Don’t Decline request"
                  onClick={closeHandler}
                />
                <CustomButton
                  style={{ width: "45%" }}
                  type="mobile"
                  height="50px"
                  text="Yes, Decline request"
                  onClick={successHandler}
                />
              </Flex>
            </AdditionalFilterWrapper>
          )}
        </AdditionalFilterModal>
      </ModalWrapper>
    </>
  );
}
Modal.propTypes = {
  type: PropTypes.string,
  setActive: PropTypes.func,
  setModalType: PropTypes.func,
  closeHandler: PropTypes.func,
  emailType: PropTypes.string,
  mediaResponse: PropTypes.object
};
export default Modal;

const ModalWrapper = styled.section``;
const AdditionalFilterModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000000;
  overflow: auto;
  .gallerySection {
    background-color: rgba(0, 0, 0, 0.9);
    max-height: 100vh;

    padding: 50px 0px;
    overflow: hidden;
    position: relative;
    .closeGallery .iconify {
      position: absolute;
      height: 22px;
      width: 22px;
      top: 30px;
      right: 30px;
      color: #fff;
      cursor: pointer;
    }
  }
  .galleryContent {
    width: 100%;
    max-width: 1150px;
    margin: 0px auto;
  }
  .galleryContainer {
    padding-top: 23px;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 70%;
      position: relative;
    }
    &-right {
      width: 28%;
    }
    &-pagination {
      position: absolute;
      left: 10px;
      font-family: "Roboto", sans-serif;
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
    }

    &-slider {
      width: 100%;
      height: 582px;
      .slick-arrow {
        top: 101%;
        height: 30px;
      }
      .slick-prev {
        left: 93%;
      }
      .slick-prev:before {
        font-family: FontAwesome;
        content: "\f104";
        font-size: 32px;
        opacity: 1;
      }
      .slick-next {
        right: 0px;
      }
      .slick-next:before {
        font-family: FontAwesome;
        content: "\f105";
        font-size: 32px;
        opacity: 1;
      }
    }
  }
  .galleryWrapper {
    display: flex;
    flex-wrap: wrap;
  }
`;
const AdditionalFilterWrapper = styled.div`
  width: 480px;
  height: ${props => props.height};
  margin: 5% auto;
  padding: 25px;
  padding-left: 45px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  &.testimonials {
    max-width: 665px;
    width: 100%;
    padding: 50px 25px 50px 36px;
    margin: 4% auto 20px;
  }
  &.teachers {
    max-width: 1022px;
    width: 100%;
    padding: 62px 58px;
    margin: 5% auto;
  }
  &.tour {
    max-width: 500px;
    width: 100%;
    padding: 50px 48px;
    margin: 5% auto 20px;
    .scheduleTour {
      display: flex;
      flex-direction: column;
      .personalDetail {
        color: #30333b;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
  }
  .confirmMessage {
    //   display:flex;
    //   flex-direction:column;
    //   align-items:center;
    text-align: center;
  }
  i {
    cursor: pointer;
  }
  .popupScroll {
    max-height: 590px;
    overflow: scroll;
  }
  .infoIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
    .iconify {
      width: 17px;
      height: 17px;
      color: #666c78;
    }
    &:hover {
      .iconify {
        color: #60b947;
      }
    }
  }
`;

const OrgName = styled.div`
  opacity: 0.6;
  color: #30333b;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: ${props => props.textAlign || "center"};
`;

const TypeOrg = styled.div`
  color: #30333b;
  width:  ${props => props.width};
  font-family: Quicksand;
  font-size: ${props => props.fontSize || "22px"}
  font-weight: ${props => props.fontWeight || "500"};
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: ${props => props.marginBottom};
`;

const NoteContent = styled.div`
  opacity: 0.6;
  color: #666c78;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  padding: 12px 16px;
  &:before {
    content: "";
    position: absolute;
    border: 2px dashed #D6D6DE;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.flexStart || "space-between"};
  input {
    width: ${props => props.width};
  }
  div {
    width: ${props => props.width};
    color: #30333b;
    font-family: Quicksand;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

const ScheduleWrapper = styled.div`
  color: #666c78;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 30px;
  margin-top: 30px;
  &:first-child {
    border-bottom: 1px solid #dbdee5;
  }
  .schedule {
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 17px;
  }
  .parentName {
    font-family: Roboto;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .date {
    font-family: Roboto;
    font-size: 18px;
  }
`;

export const TextArea = styled.div`
  padding: 20px;
  width: 95%;
  border: 1px solid #ced1d8;
  border-radius: 5px;
  textarea {
    width: 100%;
    border: none;
    resize: none;
  }
`;
const Error = styled.div`
  font-size: 12px;
  color: red;
`;