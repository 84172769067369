import React, { useState, useEffect } from "react";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { Col, FormGroup, FormFeedback } from "reactstrap";

import "rc-time-picker/assets/index.css";
const Time = ({
  placeholder,
  colSpan,
  form,
  submit,
  isResponsive,
  onChange,
  isRequired = false,
  error = "",
  ...rest
}) => {
  const [value, setValue] = useState("");
  const [defValue, setDefValue] = useState("");
  const [open, setOpen] = useState(false);
  const [defaults, setDefaults] = useState(false);

  useEffect(() => {
    if (rest.defaultValue) {
      var a = rest.defaultValue.split(":");
      if (a[1].includes(" ")) {
        a[1] = a[1].split(" ")[0];
      }
      setDefValue(moment(new Date(null, null, null, a[0], a[1])));
      setValue(moment(new Date(null, null, null, a[0], a[1])));
    } else {
      setDefValue("");
      setValue("");
    }
  }, [rest.defaultValue]);

  return (
    <Col xs={isResponsive && window.screen.width < 1250 ? "5" : colSpan} >
      <FormGroup style={{ padding: "0px 15px 0 0", marginLeft: rest.marginLeft }}>
        <TimePicker
          showSecond={false}
          //   hideDisabledOptions
          //   minuteStep={5}
          open={open}
          //   hideDisabledOptions
          //   minuteStep={5}
          onOpen={(v) => setOpen(v)}
          onClose={(v) => setOpen(false)}
          placeholder={placeholder}
          showMinute={true}
          use12Hours={true}
          clearIcon={null}
          minuteStep={15}
          onAmPmChange={() => setOpen(false)}
          // onClose={() => setValue('')}
          onChange={e => {
            onChange && e
              ? onChange(moment(e._d).format("HH:mm A"))
              : onChange("");
            e && setValue(moment(e._d));
            setDefaults(true);
          }}
          className="timePicker"
          value={defaults ? value : defValue}
          // value={defaults ? value : defValue}
        />
        {!error && submit && isRequired && !value && (
          <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
            time is required
          </div>
        )}
      </FormGroup>
      {/* {workingArr &&
                          workingArr[0] &&
                          (workingArr[0].err || workingArr[0].err1) && ( */}
      <div
        style={{
          color: "red",
          fontSize: 11,
          fontWeight: 300
        }}
      >
        {/* {workingArr[0].err || workingArr[0].err1} */}
        {error}
      </div>
      {/* )} */}
    </Col>
  );
};

export default Time;
