import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "./Button";
import colors from "./colors";
import IconCard from "./Iconcard";
import Verfied from "./Verified";

const Featured = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 68px;
  border-radius: 5px;
  background-color: ${colors.white};
  color: ${colors.secondary};
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-transform: uppercase;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
`;
export const Photo = styled.div`
  position: relative;

  & > img {
    height: ${props => props.height || "auto"};
    width: ${props => props.width || "148px"};
    border-radius: 10px;
    margin: ${props => props.margin || "0px 10px 10px 0px;"};
  }
  &.viewall {
    position: relative;
    & > img {
      opacity: 0.4;
    }
    .viewall {
      display: flex;
    }
  }
  &.preSchool {
    min-width: 126px;
    height: 76px;
  }
  .verifiedBtn {
    position: absolute;
    right: 10px;
    bottom: -10px;
  }
`;
export const ViewAll = styled.div`
  display: none;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .viewAll {
    background-color: #3b74e7;
    border-color: #3b74e7;
    color: #fff;
  }
`;
const PhotoCard = ({ image, width, height, margin, type }) => (
  <Photo
    // eslint-disable-next-line react/jsx-no-duplicate-props
    className={type}
    width={width}
    height={height}
  >
    <img src={image}  margin={margin} alt="" />
    {type === "featured" && (
      <>
        <IconCard iconName="ei-heart" type="square" />
        <Featured>Featured</Featured>
      </>
    )}
    {type === "liked" && (
      <>
        <IconCard iconName="ei-heart" type="square" />
      </>
    )}
    {type === "viewall" && (
      <ViewAll className="viewall">
        <span>29 Photos</span>
        <Button text="View all" type="viewAll" marginRight="10px" />
      </ViewAll>
    )}
    {type === "verified" && (
      <div className="verifiedBtn">
        <Verfied name="Verified" />
      </div>
    )}
  </Photo>
);
PhotoCard.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  type: PropTypes.string
};
export default PhotoCard;
