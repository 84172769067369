import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PhotoCard from "./photoCard";
export const LocationMap = styled.div`
  position: relative;
  height: 170px;
  width: 100%;
  border-radius: 10px;

  > img {
    height: auto;
    border-radius: 10px;
  }
`;
export const ContactUs = styled.div`
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  padding: 20px;
  height: auto;
  border-radius: 10px;
  background-color: #ffffff;
  font-weight: 300;
  box-shadow: 2px 2px 20px 0 rgba(74, 67, 67, 0.1);
`;
export const Address = styled.div`
  color: #30333b;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
`;
export const Email = styled.div`
  color: #3b74e7;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
`;

const Location = ({ image, address, number, email }) => (
  <LocationMap>
    {image && <PhotoCard image={image} width="100%" height="100%" />}
    <ContactUs>
      <Address>{address}</Address>
      <Address>{number}</Address>
      <Email>{email}</Email>
    </ContactUs>
  </LocationMap>
);

Location.propTypes = {
  image: PropTypes.string,
  address: PropTypes.string,
  number: PropTypes.string,
  email: PropTypes.string
};
export default Location;
