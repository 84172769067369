/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import {
  CardBody,
  Col,
  Row,
  Button,
  Form,
  Label,
  CardTitle,
  CardText,
  FormGroup,
  CardFooter,
} from "reactstrap";
import { RegionDropdown } from "react-country-region-selector";
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { createForm } from "rc-form";
import FormInput from "../../../components/FormInput";
import Dropdown from "../../../components/Dropdown";
import Select from "../../../components/Select";
import FileUploader from "../../../components/Uploader";
import Img from "../../../assets/img/brand/blue.png";
import locationImage from "../../../assets/img/location.png";
import currentLocationIcon from "../../../assets/img/close.png";
import responseToast from "../../../utils/responseToast";
import RadioButton from "../../../components/RadioButton";
import poweredByGoogle from "../../../assets/img/powered_by_google_on_white.png";

const MAPS_API_KEY = "AIzaSyBEUpFksPxI6U3Mc0PWOF4irBXdl1a6O3Q";

const Map = withScriptjs(
  withGoogleMap(
    ({
      latitude,
      longitude,
      setLatitude,
      setLongitude,
      setLocationError,
      ...props
    }) => {
      const marker = useRef(null);
      const [zoom, setZoom] = useState(null);
      useEffect(() => {
        if (
          navigator.geolocation &&
          (!latitude || latitude === 0.0) &&
          (!longitude || longitude === 0.0)
        ) {
          navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: long } = {} }) => {
              setLatitude(lat);
              setLongitude(long);
            }
          );
        }
      }, [latitude, longitude]);

      useEffect(() => {}, [latitude, longitude]);

      // On Marker drag end
      const onDragEndHandler = (e) => {
        props.setContentChanged(true);

        const position = marker.current.getPosition();
        console.log(position, position.lat(), position.lng(), "position");
        setLatitude(position.lat());
        setLongitude(position.lng());
      };

      return (
        <GoogleMap
          defaultZoom={17}
          zoom={zoom || 17}
          center={{ lat: latitude, lng: longitude }}
          defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
        >
          <Marker
            ref={marker}
            position={{ lat: latitude, lng: longitude }}
            defaultDraggable
            onDragStart={() => {
              props.setContentChanged(true);
              setLocationError(false);
              setZoom(20);
            }}
            onDragEnd={onDragEndHandler}
          />
        </GoogleMap>
      );
    }
  )
);

function Location(props) {
  Geocode.setApiKey(MAPS_API_KEY);
  const [parking, setParking] = useState("0");
  const [drafts, setDrafts] = useState(false);
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [locationString, setLocationString] = useState("");
  const [locationError, setLocationError] = useState(false);
  const [region, setRegion] = useState("");
  const [submit, setSubmit] = useState(false);

  const [locationItemClicked, setLocationItemClicked] = useState(true);

  useEffect(() => {
    if (props.data && props.data[0] && props.data[0].id) {
      props.data[0].parking_space ? setParking("1") : setParking("0");
      setRegion(props.data[0].state);
      props.form.setFieldsValue(props.data[0]);
      setLatitude(props.data[0].lattitude ? +props.data[0].lattitude : 0.0);
      setLongitude(props.data[0].longitude ? +props.data[0].longitude : 0.0);
    }
  }, [props.data]);

  useEffect(() => {
    if (latitude && longitude)
      Geocode.fromLatLng(latitude, longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setLocationString(address);
          console.log(address);
        },
        (error) => {
          console.error(error);
        }
      );
  }, [latitude, longitude]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    if (!drafts) {
      props.form.validateFields(async (errors, values) => {
        if (!errors && !locationError && region) {
          setSubmit(false);
          values.parking_space = parking;
          values.lattitude = latitude;
          values.longitude = longitude;
          values.state = region;

          setDrafts(true);
          props.setContentChanged(false);
          if (props.data && props.data.length > 0) {
            values.id = props.data[0].id;
            props.updateLocation({
              data: values,
              cb: (err) => {
                if (!err) {
                  props.setComponent("Contact details");
                } else {
                  responseToast(err);
                }
              },
            });
          } else
            props.setLocation({
              data: values,
              cb: (err) => {
                if (!err) {
                  props.setComponent("Contact details");
                } else {
                  responseToast(err);
                }
              },
            });
        }
      });
    }
  };

  const handleSelect = (selected) => {
    setLocationItemClicked(true);
    props.setContentChanged(true);

    setLocationString(selected);
    geocodeByAddress(selected)
      .then((res) => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        setLatitude(lat);
        setLongitude(lng);
      })
      .catch((error) => {
        // this.setState({ isGeocoding: false });
        console.log("error", error); // eslint-disable-line no-console
      });
  };

  console.log(locationItemClicked, "locationItemClicked");

  return (
    <>
      <Form className="form-horizontal" onSubmit={handleSubmit}>
        <CardBody>
          <div className="cardHeader">
            <CardTitle className="cardTitle">Location</CardTitle>
            <CardText className="cardProfileText">
              Explain a little bit about your location.
            </CardText>
          </div>
        </CardBody>

        <Row>
          <Col xs="7">
            <CardBody className="formContent">
              <div
                className="searchInput"
                style={{ paddingLeft: "0", marginBottom: 50 }}
              >
                <h2 className="formInputName">
                  Location *
                  <span className="information">
                    <Icon path={mdiInformationOutline} />
                    <div className="tooltip-hover">
                      <span>{"Enter business name as in google maps"}</span>
                    </div>
                  </span>
                </h2>
                <PlacesAutocomplete
                  value={locationString}
                  onChange={(address) => {
                    props.setContentChanged(true);

                    setLocationItemClicked(false);
                    if (address) {
                      setLocationError(false);
                    } else {
                      setLocationError(true);
                    }
                    setLocationString(address);
                  }}
                  onSelect={(address) => handleSelect(address)}
                  onError={() => console.log("object")}
                  clearItemsOnError={true}
                  shouldFetchSuggestions
                  searchOptions={{
                    componentRestrictions: { country: ["in"] },
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="searchInput-bar">
                      <input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "textarea",
                          onBlur: () => {
                            props.setContentChanged(true);
                            if (locationString) setLocationError(false);
                            else setLocationError(true);
                            if (locationItemClicked) setLocationError(false);
                            else setLocationError(true);
                          },
                        })}
                        value={locationString}
                        // placeholder="Search location"
                        // onChange={e => setLocationString(e.target.value)}
                      />
                      {locationError && (
                        <span
                          style={{
                            fontWeight: "300",
                            marginTop: "0.25rem",
                            fontSize: "11px",
                            color: "red",
                          }}
                        >
                          Location in required.
                        </span>
                      )}

                      <span
                        // class="iconify"
                        // data-icon="ion:close-outline"
                        // data-inline="false"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "16px",
                        }}
                        onClick={() => {
                          props.setContentChanged(true);

                          setLocationError(true);
                          setLocationString("");
                          setLocationItemClicked(false);
                          // if (navigator.geolocation) {
                          //   navigator.geolocation.getCurrentPosition(
                          //     ({
                          //       coords: { latitude: lat, longitude: long } = {}
                          //     }) => {
                          //       setLatitude(lat);
                          //       setLongitude(long);
                          //     }
                          //   );
                          // }
                        }}
                      >
                        <img
                          height={20}
                          width={20}
                          src={currentLocationIcon}
                          alt=""
                        />
                      </span>
                      {suggestions.length > 0 && (
                        <div className="searchInput-dropdown">
                          {/* {loading && <div>Loading...</div>} */}
                          {suggestions.map((suggestion) => {
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  // className: "",
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                          <span style={{ float: "right" }}>
                            <img src={poweredByGoogle} alt="google-logo" />
                          </span>
                        </div>
                      )}
                      {/* <div className="autocomplete-dropdown-container">
                        {suggestions.length > 0 && (
                          <ul
                            {...getSuggestionItemProps(suggestions, {
                              className: "searchInput-dropdown"
                            })}
                          >
                            {suggestions.map(suggestion => (
                              <li>{suggestion.description}</li>
                            ))}
                          </ul>
                        )}
                      </div> */}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <Row style={{ marginBottom: -10 }}>
                <FormInput
                  form={props.form}
                  name="address_line_1"
                  placeholder="Address Line 1"
                  label="Address"
                  colSpan="12"
                  subLabel="Mention the exact address of your location"
                  setContentChanged={props.setContentChanged}
                />
              </Row>
              <Row>
                <FormInput
                  form={props.form}
                  name="address_line_2"
                  placeholder="Address Line 2"
                  label="  "
                  colSpan="12"
                  isRequired={false}
                  setContentChanged={props.setContentChanged}

                  // subLabel="Mention the exact address of your location"
                />
              </Row>
              <Row>
                <FormInput
                  form={props.form}
                  name="area"
                  placeholder="Enter Area"
                  label="Area"
                  colSpan="12"
                  setContentChanged={props.setContentChanged}
                />
              </Row>
              <Row>
                <FormInput
                  label="City"
                  name="city"
                  placeholder="Enter City"
                  form={props.form}
                  colSpan="12"
                  setContentChanged={props.setContentChanged}
                />
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup className={`formFlex`}>
                    <Col xs="6" style={{ paddingLeft: 0 }}>
                      {" "}
                      <Label className="formInputName">State *</Label>
                    </Col>
                    <Col
                      xs="6"
                      style={{
                        paddingRight: 25,
                      }}
                    >
                      <RegionDropdown
                        country={"India"}
                        value={region}
                        defaultOptionLabel={"Select State"}
                        className="form-control-alternative form-control"
                        onChange={(val) => {
                          props.setContentChanged(true);
                          setRegion(val);
                          setSubmit(false);
                        }}
                      />
                      {submit && !region && (
                        <div
                          style={{
                            fontWeight: 300,
                            fontSize: 11,
                            color: "red",
                          }}
                        >
                          {" "}
                          State is required
                        </div>
                      )}
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <FormInput
                  form={props.form}
                  name="pincode"
                  placeholder="Enter Pincode"
                  label="Pincode"
                  colSpan="12"
                  pincode
                  setContentChanged={props.setContentChanged}
                />
              </Row>

              <Row className="extended">
                <Col xs="6" className="formInputName">
                  Parking space *
                </Col>
                <Col xs="6" style={{ padding: 0 }}>
                  {/* <Row className="dt">
                      <Col xs="6">
                        <Label onClick={() => setParking("1")} check>
                          <Input
                            type="radio"
                            name="yes"
                            checked={parking === "1"}
                          />
                          yes
                        </Label>
                      </Col>
                      <Col xs="6">
                        <Label for="no" onClick={() => setParking("0")}>
                          <Input
                            type="radio"
                            name="No"
                            checked={parking === "0"}
                          />
                          No
                        </Label>
                      </Col>
                    </Row> */}
                  <Row style={{ marginLeft: 0 }}>
                    <RadioButton
                      name="yes"
                      label="Yes"
                      form={props.form}
                      xsval="5"
                      checked={parking === "1"}
                      onClick={(l) => {
                        props.setContentChanged(true);
                        setParking("1");
                      }}
                    />
                    <RadioButton
                      name="no"
                      label="No"
                      xsval="5"
                      form={props.form}
                      checked={parking === "0"}
                      onClick={(l) => {
                        props.setContentChanged(true);
                        setParking("0");
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Col>
          <Col xs="5" style={{ marginLeft: -20 }}>
            <div className="locationImage">
              <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                latitude={latitude}
                longitude={longitude}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
                setLocationError={setLocationError}
                setContentChanged={props.setContentChanged}
              />
            </div>
          </Col>
        </Row>
        <CardFooter>
          <Row style={{ padding: "0px 0px" }}>
            <Col
              xs="12"
              className="formFlex"
              style={{ justifyContent: "center", margin: 0 }}
            >
              <Button
                onClick={() => {
                  props.updateBeforeComponent("Basic Info");
                }}
                color="primary"
              >
                Back
              </Button>
              <Button type="submit" disabled={drafts}>
                {props.data && props.data.length > 0 ? "Update" : "Save"} and
                Continue
              </Button>
              {/* <Button disabled={props.data && props.data.length > 0 ? false : drafts} onClick={() => props.data && props.data.length > 0 ? props.setComponent('Contact details') : !drafts && props.setComponent('Contact details')}>Continue</Button> */}
            </Col>
          </Row>
        </CardFooter>
      </Form>
    </>
  );
}
Location.propTypes = {};

export default createForm()(Location);
