/* eslint-disable import/no-unresolved */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import InputRange from 'react-input-range';
// import Flex from '../common/flex';
import Button from '../../views/Pages/SchoolProfile/Button';
// import CheckBox from '../Checkbox';
// import RadioButton from '../RadioButton';
// import 'react-input-range/lib/css/index.css';

const DROPDOWN_CONFIG = {
  sort: [
    {
      id: '01',
      text: 'Old to New',
      key: 'Old to New',
    },

    {
      id: '04',
      text: 'New to Old',
      key: 'New to Old',
    },
    {
      id: '06',
      text: 'Read',
      key: 'Read',
    },
    {
      id: '07',
      text: 'Unread',
      key: 'Unread',
    },
  ],
};

function Dropdown({ type, dropdownText, sortType, setSortType }) {
  const dropdownRef = useRef(null);
  const [status, setStatus] = useState(false);
  return (
    <DropdownWrapper column>
      <Button
        text={dropdownText}
        type="dropdown"
        onClick={() => setStatus(!status)}
      />
      {status && (
        <DropdownMenu ref={dropdownRef}>
          {DROPDOWN_CONFIG[type].map(items => (
            <DropdownMenuItem
              key={items.id}
              className={sortType === items.key ? 'active' : ''}
              onClick={e => {
                setSortType(e, items.key);
                setStatus(false);
              }}
            >
              {items.text}

              <span
                className="iconify"
                data-icon="mdi-light:check"
                data-inline="false"
              />
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
}

Dropdown.propTypes = {
  dropdownText: PropTypes.string,
  type: PropTypes.string,
  sortType: PropTypes.string,
  setSortType: PropTypes.func,
};

Dropdown.defaultProps = {
  dropdownText: 'Sort By',
};
export default Dropdown;

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;
export const DropdownMenu = styled.ul`
  position: absolute;
  top: ${props => props.top || '80%'};
  left: ${props => props.left || '80%'};
  // right: 0;
  // width: 100%;
  width: ${props => props.width || '80%'};
  height: ${props => props.height};
  overflow: ${props => props.height && 'scroll'};

  padding: 10px;
  border-radius: 10px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);}
  list-style-type: none;
`;
export const DropdownMenuItem = styled.li`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 5px;
  color: #4B5256;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  list-style-type: none;
  .iconify {
    display: none;
    color: #613A95;
    font-size: 20px;
  }
  .dropdownArrow {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
    &.active {
      transform: rotate(180deg);
      transition: transform 0.2s ease;
    }
  }
  &:hover {
    background-color: #ebf1fc;
    color: #613A95;
  }
  &.active {
    color: #613A95;
    .iconify {
      display: inline-block;
    }
  }
`;
