import React from "react";
import { Col, FormGroup, FormFeedback, Label, Input } from "reactstrap";

function Checkbox({ onClick, checked, padding,label, className,paddingBottom, isRequired = false, form, ...rest }) {
    const checkIsChecked = (rule, value, callback) => {
        if (isRequired && !value && !checked) {
            callback("you should agree with terms of service!");
        } else {
            callback();
        }
    }
    const error = form.getFieldError(rest.name);
    const rules = [{
        required: isRequired,
        validator: checkIsChecked,
        message: isRequired && !checked && 'agree to the terms of use and privacy policy'
    }]
    return (
        <Col xs={rest.xsval || "3"} key={rest.key} style={{paddingBottom: paddingBottom}}>
            <FormGroup check className="customCheckBox">
                <Input
                    noValidate

                    type="checkbox"
                    checked={checked}
                    id={rest.name}
                    invalid={!!error}
                    {...rest}
                    {...form.getFieldProps(rest.name, {
                        validateTrigger: "onClick",
                        rules,
                        onClick: (e) => onClick && onClick(e.target.value)
                    })}
                />
                <Label htmlFor={rest.name} check style={{padding: padding}}>
                    <span>{label}</span>
                </Label>
                <FormFeedback>{error}</FormFeedback>

            </FormGroup>
        </Col>
    );
}

Checkbox.propTypes = {};

export default Checkbox;
