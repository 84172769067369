/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { PaginationWrapper, InputWrapper, CustomButton } from "./styled";
const Pagination = ({
  onNextClick,
  onPrevClick,
  onFirstPageClick,
  onLastPageClick,
  onInputChange,
  totalPages,
  currentPage,
  isNextDisabled,
  isPrevDisabled,
  onPageSelect,
  pageSize
}) => (
  <>
    <PaginationWrapper alignCenter justifyCenter>
          <CustomButton onClick={onFirstPageClick} disabled={isPrevDisabled}>
        {/* <FontAwesomeIcon icon={faAngleDoubleLeft} size="1x" /> */}
        <i className='fa fa-angle-double-left' />

      </CustomButton>
  <CustomButton onClick={onPrevClick} disabled={isPrevDisabled}>
        <i className='fa fa-angle-left' />
      </CustomButton>
     <InputWrapper alignCenter>
        <span style={{ marginRight: 5 }}>Page {currentPage}</span>
        <span style={{ marginRight: 5 }}>of</span>
        <span style={{ marginRight: 20 }}>{totalPages}</span>
        {/* <span style={{ marginRight: 20 }}>Go to Page</span>
        <div style={{ marginRight: 20, width: "120px" }}>
          <Input
            nomarginBot
            defaultValue={currentPage}
            onChange={onInputChange}
            style={{ width: "100px" }}
            type="number"
            min="0"
          />
        </div>
        <div style={{ marginRight: 20, width: "120px" }}>
          <CustomSelect
            height="48px"
            placeholder="10 rows"
            // marginBot="30px"
            options={[
              { key: "5", value: "5 Rows" },
              { key: "10", value: "10 Rows" },
              { key: "15", value: "15 Rows" },
              { key: "20", value: "20 Rows" },
              { key: "30", value: "30 Rows" },
              { key: "40", value: "40 Rows" },
              { key: "50", value: "50 Rows" }
            ]}
            onSelect={onPageSelect}
            value={pageSize}
          />
        </div> */}
      </InputWrapper>
       <CustomButton onClick={onNextClick} disabled={isNextDisabled}>
        <i className='fa fa-angle-right' />
      </CustomButton>
     <CustomButton onClick={onLastPageClick} disabled={isNextDisabled}>
        {/* <FontAwesomeIcon icon={faAngleDoubleRight} size="1x" /> */}
        <i className='fa fa-angle-double-right' />


      </CustomButton>
    </PaginationWrapper>
  </>
);

Pagination.propTypes = {
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onFirstPageClick: PropTypes.func,
  onLastPageClick: PropTypes.func,
  onInputChange: PropTypes.func,
  onPageSelect: PropTypes.func,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  isNextDisabled: PropTypes.bool,
  isPrevDisabled: PropTypes.bool
};
export default Pagination;
