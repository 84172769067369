/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Index from "./views/Index.jsx";
// import Profile from "./views/examples/Profile.jsx";
// import Maps from "./views/examples/Maps.jsx";
// import Register from "./views/examples/Register.jsx";
import Login from "./layouts/Login.jsx";
// import Tables from "./views/examples/Tables.jsx";
// import Icons from "./views/examples/Icons.jsx";
import LandingPage from "./views/Pages/LandingPage";
import Review from "./views/Pages/Review/index.js";
import Settings from "./views/Pages/Settings";
import Enquiry from "./views/Pages/Enquiry";
import GeneralEnquiry from "./views/Pages/Enquiry/general";

import Dashboard from "./views/Pages/Dashboard";
import OfflineData from "./views/Pages/Providers/OfflineData";




import ParentsReview from "./views/Pages/Review/parentsReview";


import Form from "./views/Pages/Form";
import FormInitial from "./views/Pages/Form/initial";


import ScheduledTour from "./views/Pages/SchoolTour/ScheduledTour";
import AcceptedTour from "./views/Pages/SchoolTour/AcceptedTour";

import CancelledTour from "./views/Pages/SchoolTour/CancelledTour";
import UserCancelledTour from "./views/Pages/SchoolTour/UserCancelledTour";

import ChangedTour from "./views/Pages/SchoolTour/ChangedTour";



import ProviderProfile from "./views/Pages/ProviderProfile";
import SchoolProfile from './views/Pages/SchoolProfile';
import ReviewDetail from "./views/Pages/Review/ReviewDetail.js";

var routes = [
  {
    path: "/landing-page",
    name: "Landing Page",
    icon: "ni ni-atom text-pink",
    component: LandingPage,
    sideNav: false,
  },
  {
    path: "/form",
    name: "Form",
    icon: "ni ni-ruler-pencil text-pink",
    component: FormInitial,
    layout: "true",
    sideNav: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-ruler-pencil text-pink",
    component: Login,
    sideNav: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-ruler-pencil text-pink",
    component: Dashboard,
    layout: "true",
    sideNav: true,
  },
  {
    path: "/enquiry",
    name: "Enquiry",
    icon: "ni ni-ruler-pencil text-pink",
    component: Enquiry,
    layout: "true",
    sideNav: true,
  },
  {
    path: "/general-enquiry",
    name: "General Enquiry",
    icon: "ni ni-ruler-pencil text-pink",
    component: GeneralEnquiry,
    layout: "true",
    sideNav: true,
  },
  {
    path: "/profile",
    name: "Provider Details",
    icon: "ni ni-ruler-pencil text-pink",
    component: ProviderProfile,
    layout: "true",
    sideNav: true,
  },
    {
    path: "/profile",
    name: "Provider Details",
    icon: "ni ni-ruler-pencil text-pink",
    component: ProviderProfile,
    layout: "true",
    sideNav: true,
  },

  // {
  //   path: "/reviews",
  //   name: "Review",
  //   icon: "ni ni-ruler-pencil text-pink",
  //   component: Review,
  //   layout: "true",
  //   sideNav: true,
  // },
  {
    path: "/parents-review-form",
    name: "Parents Review",
    icon: "ni ni-ruler-pencil text-pink",
    component: ParentsReview,
    layout: "true",
    sideNav: false,
  },
  {
    path: "/form-update",
    name: "Profile",
    icon: "ni ni-ruler-pencil text-pink",
    component: Form,
    layout: "true",
    sideNav: true,
  },
  {
    path: "/offline-data",
    name: "Providers Offline Data",
    icon: "ni ni-ruler-pencil text-pink",
    component: OfflineData,
    layout: "true",
    sideNav: true,
  },
  {
    path: "/review-detail",
    name: "Review Details",
    icon: "ni ni-ruler-pencil text-pink",
    component: ReviewDetail,
    layout: "true",
    sideNav: false,
  },
  {
    path: "/review",
    name: "Review",
    icon: "ni ni-ruler-pencil text-pink",
    component: Review,
    layout: "true",
    sideNav: false,
  },
  {
    path: "/scheduled-tour",
    name: "Scheduled Tour",
    icon: "ni ni-ruler-pencil text-pink",
    layout: "true",
    component: ScheduledTour,
    sideNav: true,
  },
  {
    path: "/accepted-tour",
    name: "Accepted Tour",
    icon: "ni ni-ruler-pencil text-pink",
    layout: "true",
    component: AcceptedTour,
    sideNav: true,
  },
  {
    path: "/cancelled-tour",
    name: "Cancelled Tour",
    icon: "ni ni-ruler-pencil text-pink",
    layout: "true",
    component: CancelledTour,
    sideNav: false,
  },
  {
    path: "/user-cancelled-tour",
    name: "User Cancelled Tour",
    icon: "ni ni-ruler-pencil text-pink",
    layout: "true",
    component: UserCancelledTour,
    sideNav: false,
  },
  {
    path: "/changed-tour",
    name: "Changed Tour",
    icon: "ni ni-ruler-pencil text-pink",
    layout: "true",
    component: ChangedTour,
    sideNav: false,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-ruler-pencil text-pink",
    component: Settings,
    layout: "true",
    sideNav: false,
  }
];
export default routes;
