/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Row, Button } from "reactstrap";
import styled from "styled-components";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import profileImg from "../../../assets/img/user4.svg";
import deleteIcon from "../../../assets/img/delete.svg";
import deleteIconCopy from "../../../assets/img/delete copy.svg";
import addIcon from "../../../assets/img/add.svg";
import addIconCopy from "../../../assets/img/add copy.svg";
import upload from "../../../assets/img/camera.svg";
import profileImage from "../../../assets/img/profilePlaceholder.jpg";
import blueSpinner from "../../../assets/img/blueSpinner.gif";
import Qs from "query-string";

import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PROVIDERS } from "../../../config/apiEndpoints";
import Loader from "../../../components/Loader";
import responseToast from "../../../utils/responseToast";
import Pagination from "../../../components/Pagination";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "../../../components/CustomDropdown";
import axios from "../../../utils/axios";
import { getUploadURL } from "../../../components/Uploader";
const DUMMY_DATA = [
  {
    parent_name: "Rohan",
    review_req_date: "03/05/2019",
    ph_no: "9878767809",
    email: "rohan@gmail.com",
    children: [
      {
        image: profileImg,
        name: "Riaz",
        age: 4,
      },
    ],
  },
  {
    parent_name: "Priya Deshpandey",
    review_req_date: "03/05/2019",
    ph_no: "9878767809",
    email: "priya@gmail.com",
    children: [
      {
        image: profileImg,
        name: "Riaz",
        age: 4,
      },
      {
        image: profileImg,
        name: "Riaz",
        age: 4,
      },
    ],
  },
  {
    parent_name: "Rohan",
    review_req_date: "03/05/2019",
    ph_no: "9878767809",
    email: "rohan@gmail.com",
    children: [
      {
        image: profileImg,
        name: "Riaz",
        age: 4,
      },
    ],
  },
];

const DROPDOWN_CONFIG = [
  {
    id: "01",
    text: "2018",
    key: "2018",
  },
  {
    id: "04",
    text: "2019",
    key: "2019",
  },
  {
    id: "05",
    text: "2020",
    key: "2020",
  },
  {
    id: "06",
    text: "2021",
    key: "2021",
  },
  {
    id: "07",
    text: "2022",
    key: "2022",
  },
];
function Dashboard(props) {
  const dropdownRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [uploadImageDummy, setUploadImageDummy] = useState(upload);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const query = Qs.parse(decodeURIComponent(props.history.location.search));
  const { showforms } = query;
  const [academicYear, setAcademicYear] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [detailsErr, setDetailsErr] = useState({});
  const [dropdownValue, setDropdownValue] = useState("2020");
  const [childrenLength, setChildrenLength] = useState([1]);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone_number: "",
    year: "",
    childrens: [],
  });
  let childrensArr = [];

  const [getOfflineData, , , , getOfflineDataId] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}offline-parent-records/${
        dropdownValue ? `?year=${dropdownValue}` : ""
      }`,
      readId: `${API_PROVIDERS}offline-parent-records/${
        dropdownValue ? `?year=${dropdownValue}` : ""
      }`,
    },
  });

  const [, , postOfflineData] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}offline-parent-records/`,
    },
  });
  const [, , postOfflineChildData] = useCommonCRUD({
    CRUDaseUrl: {
      create: `${API_PROVIDERS}offline-child-records/`,
    },
  });
  const matchRegex = (exp, target, value, condn) => {
    let regExp = exp;
    if (value === "" || (regExp.test(value) && condn)) {
      setValue(target, value);
    }
  };

  const setValue = (field, value) => {
    let fieldData = { ...details };
    let fieldError = { ...detailsErr };
    fieldData[field] = value;
    value === ""
      ? (fieldError[`${field}_error`] = "This Field is required")
      : (fieldError[`${field}_error`] = "");
    setDetailsErr(fieldError);
    setDetails(fieldData);
  };

  const addMoreChildren = () => {
    let dataCopy = { ...details };
    let count = 0;
    let length = [...childrenLength];
    dataCopy.childrens.map((a) => a.name && a.age && a.photo_id && count++);
    if (count > 0 && count === length.length) length.push(1);
    setChildrenLength(length);
  };

  const submitData = () => {
    let output = Object.keys(details);
    let fieldError = { ...detailsErr };
    let errData = output.filter(
      (a) =>
        !details[a] || (Array.isArray(details[a]) && details[a].length === 0)
    );
    if (errData.length > 0) {
      errData.map((d) => {
        if (d === "childrens") {
          fieldError["childrens"] = [];
          fieldError["childrens"][0] = {};
          fieldError["childrens"][0].name_error = "This field is required";
          fieldError["childrens"][0].photo_id_error = "This field is required";
          fieldError["childrens"][0].age_error = "This field is required";
        } else fieldError[`${d}_error`] = "This field is required";
      });

      setDetailsErr(fieldError);
      return;
    }
    const emailReg = new RegExp(
      "^([\\w\\.\\+\\-]+\\@[a-zA-Z0-9\\.\\-]+\\.[a-zA-z0-9]{2,4})$"
    );
    if (!emailReg.test(details.email.trim())) {
      fieldError[`email_error`] = "Enter Valid Email Address";
      setDetailsErr(fieldError);
      return;
    }
    if (details.phone_number.length !== 10) {
      fieldError[`phone_number_error`] = "Enter Valid Mobile Number";
      setDetailsErr(fieldError);
      return;
    }
    if (
      details.year.length !== 4 ||
      details.year < 1900 ||
      details.year > new Date().getFullYear()
    ) {
      fieldError[`year_error`] = "Enter Valid Year";
      setDetailsErr(fieldError);
      return;
    }
    if (childrenLength.length > 0) {
      childrenLength.map((d, i) => {
        fieldError["childrens"] = [];
        fieldError["childrens"][i] = fieldError["childrens"][i] || {};
        details["childrens"][i] = details["childrens"][i] || {};
        if (!details["childrens"][i].name) {
          fieldError["childrens"][i].name_error = "This field is required";
        }
        if (!details["childrens"][i].age) {
          fieldError["childrens"][i].age_error = "This field is required";
        }
        if (!details["childrens"][i].photo_id) {
          fieldError["childrens"][i].photo_id_error = "This field is required";
        }
      });

      setDetailsErr(fieldError);
      let ing = details["childrens"].filter(
        (data) => !data.name || !data.age || !data.photo_id
      );
      if (ing.length > 0) {
        return;
      }
    }
    details.getData = true;
    postOfflineData({
      data: details,
      successCB: (data) => {
        if (data) {
          let childData = [];
          // childData.offline_parent_id = data.id;
          childData = details.childrens;
          childData.map((a) => {
            delete a.photo_url;
            a.offline_parent = data.id;
          });
          postOfflineChildData({
            data: childData,
            cb: (err) => {
              if (!err) {
                if (showforms) {
                  props.history.push("review?activeTab=reviews&addparent=true");
                }
                setShowForm(false);
                getOfflineDataId({ data: "" });
                setDetails({
                  name: "",
                  email: "",
                  phone_number: "",
                  year: "",
                  childrens: [],
                });
                setChildrenLength([1]);
              } else {
                responseToast(err);
              }
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (showforms) {
      setShowForm(true);
    }
  }, []);

  useEffect(() => {
    let yearEst = new Date().getFullYear();
    let yrDrop = [];
    for (let y = Number(yearEst); y >= new Date().getFullYear() - 10; y--) {
      yrDrop.push({ id: `${y}`, text: `${y}`, key: y });
    }
    setAcademicYear(yrDrop);
  }, []);

  const handleClick = (e) => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      dropdownRef.current.contains(e.target)
    ) {
      return 0;
    }
    setOpenDropdown(false);
    return 0;
  };

  const uploadImage = (e, id) => {
    let dataCopy = { ...details };
    let fieldError = { ...detailsErr };
    dataCopy.childrens[id] = dataCopy.childrens[id] || {};
    setUploadImageDummy(blueSpinner);
    const fd = new FormData();
    fd.append("media", e.target.files[0]);
    const url = getUploadURL("avatar");
    axios
      .post(url, fd)
      .then((res) => res.data.data)
      .then((file) => {
        dataCopy.childrens[id] = dataCopy.childrens[id] || {};
        dataCopy.childrens[id]["photo_url"] = file.image_url;
        dataCopy.childrens[id]["photo_id"] = file.id;
        fieldError.childrens = fieldError.childrens || [];
        fieldError.childrens[id] = fieldError.childrens[id] || {};
        fieldError.childrens[id].photo_id_error = "";
        setDetailsErr(fieldError);
        setDetails(dataCopy);
        setUploadImageDummy(upload);
      })
      .catch(() => {
        responseToast("Something went wrong");
        dataCopy.childrens[id] = dataCopy.childrens[id] || {};
        dataCopy.childrens[id]["photo_url"] = '';
        setDetails(dataCopy);
        setUploadImageDummy(upload);
      });
  };

  useEffect(() => {
    if (
      getOfflineData &&
      getOfflineData.data &&
      getOfflineData.data.length > 0
    ) {
      let datas = [];
      datas = getOfflineData.data;
      setTableData(datas);
    } else {
      setTableData([]);
    }
    getOfflineData &&
      getOfflineData.globalData &&
      setCount(getOfflineData.globalData.count);
  }, [getOfflineData]);

  useEffect(() => {
    getOfflineDataId({ data: "" });
  }, [dropdownValue]);

  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar {...props} brandText="Kiddenz" />
      </div>
      <Loader isOpen={getOfflineData.isLoading} />
      {!getOfflineData.isLoading && (
        <div className="dashboardContainer">
          {!showForm ? (
            <div className="offlineData">
              <div className="mainHeading spaceBetweenAlignCenter">
                <div>
                  <h3>Offline data</h3>
                  <span>
                    Find the details of all the students from your school
                  </span>
                </div>
                <ul className="filterBy">
                  <li onClick={() => setShowForm(true)} style={{ padding: 16 }}>
                    <span
                      class="iconify"
                      data-icon="bi:plus-circle"
                      data-inline="false"
                      style={{
                        width: 16,
                        height: 16,
                        color: "#666C78",
                        marginRight: 10,
                      }}
                    ></span>
                    Add student details
                  </li>
                </ul>
              </div>
              <div className="offlineData-filter ">
                <div className="year" ref={dropdownRef}>
                  Academic year: &nbsp;
                  <span onClick={() => setOpenDropdown(!openDropdown)}>
                    {dropdownValue}
                    <i
                      className={
                        openDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down"
                      }
                    />
                  </span>
                  {openDropdown && (
                    <DropdownMenu
                      top="275pxpx"
                      left="156px"
                      width="12%"
                      height="21%"
                    >
                      {academicYear.map((items) => (
                        <DropdownMenuItem
                          key={items.id}
                          className={
                            dropdownValue === items.text ? "active" : ""
                          }
                          onClick={(e) => {
                            setDropdownValue(items.text);
                            setOpenDropdown(false);
                          }}
                        >
                          {items.text}

                          <span
                            className="iconify"
                            data-icon="mdi-light:check"
                            data-inline="false"
                          />
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenu>
                  )}
                </div>
              </div>

              <div className="offlineData-table">
                <ul className="offlineData-tableHeading">
                  <li>Sl.No</li>
                  <li>Name of Parent</li>
                  <li>Phone number</li>
                  <li>Email Address</li>
                  <li>Children Name & Age</li>
                </ul>
                {tableData && tableData.length > 0 ? (
                  tableData.map((data, i) => (
                    <ul className="offlineData-tableData">
                      <li>{i + 1}</li>
                      <li className="items-align">
                        <div>{data.name}</div>
                        {data.review_req_date && (
                          <div className="review-req">
                            Review request sent on {data.review_req_date}
                          </div>
                        )}
                      </li>
                      <li>+91 {data.phone_number} </li>
                      <li>{data.email}</li>
                      <li style={{ flexDirection: "column" }}>
                        {data.child_info && data.child_info.length > 0
                          ? data.child_info.map((child) => (
                              <div className="flex">
                                <div className="profileImage">
                                  <img
                                    src={child.photo_url || profileImage}
                                    alt=""
                                  />
                                </div>
                                <span>
                                  {child.name}, {child.age}yrs
                                </span>
                              </div>
                            ))
                          : "--"}
                      </li>
                    </ul>
                  ))
                ) : (
                  <ul className="offlineData-tableData">
                    <li style={{ width: "100%" }}>
                      <div className="loadercontainer">
                        <h1 className="emptyState">No data found</h1>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
              {/* {count > 10 && (
                <Pagination
                  totalPages={Math.floor(count / 10) + 1}
                  onFirstPageClick={() => setPage(1)}
                  onPrevClick={() => page > 1 && setPage(page - 1)}
                  onNextClick={() =>
                    page < Math.floor(count / 10) + 1 && setPage(page + 1)
                  }
                  onLastPageClick={() => setPage(Math.floor(count / 10) + 1)}
                />
              )} */}
            </div>
          ) : (
            <div className="studentDetails">
              <h2>Add student details</h2>
              <div className="studentDetails-item">
                <h3>Parents details</h3>
                <div className="studentDetails-input">
                  <label>Parent Name *</label>
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={details.name}
                    onChange={(e) =>
                      matchRegex(/^[A-Za-z ]+$/, "name", e.target.value, "name")
                    }
                  />
                  <Error>{detailsErr.name_error}</Error>
                </div>
                <Row>
                  <div className="studentDetails-input">
                    <label>Email ID *</label>
                    <input
                      type="text"
                      placeholder="Enter Email Id"
                      onChange={(e) => setValue("email", e.target.value)}
                      value={details.email}
                    />
                    <Error>{detailsErr.email_error}</Error>
                  </div>
                  <div className="studentDetails-input">
                    <label>Phone Number *</label>
                    <div className="">
                      <span
                        style={{
                          borderBottom: "1px solid rgba(102, 108, 120, 0.13)",
                          fontSize: 14,
                          fontWeight: 500,
                          padding: "11px 0px",
                        }}
                      >
                        +91{" "}
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Phone Number"
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            (Number(e.target.value) &&
                              e.target.value.length <= 10)
                          ) {
                            matchRegex(
                              /^[0-9]*$/,
                              "phone_number",
                              e.target.value,
                              "phone_number"
                            );
                          }
                        }}
                        value={details.phone_number}
                      />
                      <Error>{detailsErr.phone_number_error}</Error>
                    </div>
                  </div>

                  <div className="studentDetails-input">
                    <label>Year *</label>
                    <input
                      type="text"
                      placeholder="Enter Year"
                      value={details.year}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (Number(e.target.value) && e.target.value.length <= 4)
                        ) {
                          matchRegex(
                            /^[0-9]*$/,
                            "year",
                            e.target.value,
                            "year"
                          );
                        }
                      }}
                    />
                    <Error>{detailsErr.year_error}</Error>
                  </div>
                </Row>
              </div>
              <div className="studentDetails-item">
                <h3>Children details</h3>
                {childrenLength &&
                  childrenLength.map((dt, i) => {
                    childrensArr.push(
                      <>
                        <Row style={{ marginBottom: 40 }}>
                          <div
                            className="uploadPicture avatar rounded-circle avatar-sm"
                            style={{ width: 60, height: 60 }}
                          >
                           <img
                                  src={
                                    (details.childrens.length > 0 &&
                                    details.childrens[i] &&
                                    details.childrens[i].photo_url) || 
                                    uploadImageDummy
                                  }
                                  alt=""
                                />
                          </div>
                          <div>
                            <div>
                              <label
                                for={`upload-photo${i}`}
                                className="upload-file-text"
                              >
                                {details.childrens.length > 0
                                  ? (details.childrens[i] &&
                                      details.childrens[i].photo_url &&
                                      "Change Picture") ||
                                    "Upload Picture *"
                                  : "Upload Picture *"}
                              </label>
                              <input
                                type="file"
                                name="photo"
                                id={`upload-photo${i}`}
                                className="upload-photo"
                                accept=".png,.jpeg,.jpg"
                                onChange={(e) => uploadImage(e, i)}
                              />
                            </div>
                            <Error>
                              {detailsErr["childrens"] &&
                                detailsErr["childrens"][i] &&
                                detailsErr["childrens"][i].photo_id_error}
                            </Error>
                          </div>
                        </Row>

                        <Row>
                          <div className="studentDetails-input">
                            <label>Name *</label>
                            <input
                              type="text"
                              placeholder="Enter name"
                              value={
                                details.childrens.length > 0
                                  ? (details.childrens[i] &&
                                      details.childrens[i].name) ||
                                    ""
                                  : ""
                              }
                              onChange={(e) => {
                                let dataCopy = { ...details };
                                let fieldError = { ...detailsErr };
                                if (
                                  e.target.value === "" ||
                                  /^[A-Za-z ]+$/.test(e.target.value)
                                ) {
                                  dataCopy.childrens[i] =
                                    dataCopy.childrens[i] || {};
                                  dataCopy.childrens[i]["name"] =
                                    e.target.value;
                                  fieldError.childrens =
                                    fieldError.childrens || [];
                                  fieldError.childrens[i] =
                                    fieldError.childrens[i] || {};
                                  fieldError.childrens[i].name_error = "";
                                }

                                setDetailsErr(fieldError);
                                setDetails(dataCopy);
                              }}
                            />
                            <Error>
                              {detailsErr["childrens"] &&
                                detailsErr["childrens"][i] &&
                                detailsErr["childrens"][i].name_error}
                            </Error>
                          </div>
                          <div className="studentDetails-input">
                            <label>Child Age *</label>
                            <input
                              type="text"
                              placeholder="Enter Child age"
                              onChange={(e) => {
                                let dataCopy = { ...details };
                                let fieldError = { ...detailsErr };
                                if (
                                  e.target.value === "" ||
                                  (/^[0-9]*$/.test(e.target.value) &&
                                    e.target.value <= 15)
                                ) {
                                  dataCopy.childrens[i] =
                                    dataCopy.childrens[i] || {};
                                  dataCopy.childrens[i].age = e.target.value;
                                  fieldError.childrens =
                                    fieldError.childrens || [];
                                  fieldError.childrens[i] =
                                    fieldError.childrens[i] || {};
                                  fieldError.childrens[i].age_error = "";
                                }

                                setDetailsErr(fieldError);
                                setDetails(dataCopy);
                              }}
                              value={
                                details.childrens.length > 0
                                  ? (details.childrens[i] &&
                                      details.childrens[i].age) ||
                                    ""
                                  : ""
                              }
                            />
                            <Error>
                              {detailsErr["childrens"] &&
                                detailsErr["childrens"][i] &&
                                detailsErr["childrens"][i].age_error}
                            </Error>
                          </div>
                          <div
                            className="studentDetails-icon"
                            onClick={() => {
                              let ingredientCopy = { ...details };
                              let fieldError = { ...detailsErr };
                              let count = [...childrenLength];
                              ingredientCopy.childrens.splice(i, 1);
                              fieldError.childrens &&
                                fieldError.childrens[i] &&
                                fieldError.childrens.splice(i, 1);
                              setDetailsErr(fieldError);
                              setDetails(ingredientCopy);
                              if (count.length > 1) count.pop();
                              setChildrenLength(count);
                            }}
                          >
                            <img className="notHover" src={deleteIcon} alt="" />
                            <img
                              className="onHover"
                              src={deleteIconCopy}
                              alt=""
                            />
                          </div>
                          <div
                            className="studentDetails-icon"
                            onClick={addMoreChildren}
                          >
                            <img className="notHover" src={addIcon} alt="" />
                            <img className="onHover" src={addIconCopy} alt="" />
                          </div>
                        </Row>
                      </>
                    );
                  })}
                {childrensArr}
              </div>
              <Button className="btn-compare" onClick={() => submitData()}>
                Save
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

Dashboard.propTypes = {};

export default Dashboard;

const Error = styled.div`
  font-size: 12px;
  color: red;
`;
