import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import styled from "styled-components";

import Flex from "./flex";
import PhotoCard from "./photoCard";
import profileIcon from "../../../assets/img/profilePlaceholder.jpg";
export const TeacherDetails = styled.div`
  /* width:33.3%; */
  .viewAll {
    width: fit-content;
    font-size: 11px;
  }
  // &:last-child
  // {
  //   margin-left:20px;
  // }
`;
export const Language = styled.div`
  color: #30333b;
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
`;
export const Name = styled.div`
  color: #30333b;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;
export const Experience = styled.div`
  color: #666c78;
  font-family: Roboto;
  font-size: 12px;
  margin: 10px 0px;
  span {
    color: #30333b;
  }
`;
export const Desc = styled.div`
  color: #666c78;
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  padding-right: 10px;
`;

const TeacherDetail = ({
  image,
  name,
  years,
  desc,
  languages,
  type,
  designation
}) => (
  <TeacherDetails>
    <PhotoCard
      image={(image && image.includes(".") && image) || profileIcon}
      width="162px"
      height="190px"
      type={type}
    />
    <Name>{name}</Name>
    <Button text={designation} type="viewAll" marginRight="10px" />
    <Experience>
      Experience<span>{years}</span>
    </Experience>
    <Desc>{desc}</Desc>
    <Flex column flexMargin="10px 0px 0px">
      <Desc>Language</Desc>
      <Language>{languages}</Language>
    </Flex>
  </TeacherDetails>
);
TeacherDetail.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  years: PropTypes.string,
  desc: PropTypes.string,
  languages: PropTypes.string,
  type: PropTypes.string
};
export default TeacherDetail;
