/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import KidDetails from '../../components/KidDetails';
import Flex from '../../views/Pages/SchoolProfile/flex.js';

function ScheduleTour(props) {
    return (
        <>
            <Schedule className={props.classType}>
                <VisitDetails>
                    <div className="profilePic">
                        <img src={props.img} alt="" />
                    </div>
                    <div className="visitDetails-content">
                        <Name>{props.name}</Name>
                        <Time>{props.time}</Time>

                    </div>
                </VisitDetails>
                {props.childData && props.childData.length > 0 && <ChildDetails className={props.childDetailType}>
                    <h2 onClick={() => props.toggleChild()}>
                        Child Details{" "}
                        <span
                            class="iconify"
                            data-icon="ic:round-keyboard-arrow-right"
                            data-inline="false"
                        ></span>
                    </h2>
                  
                    {props.childData.map((d,i) => 
                        <KidDetails classType={d.showEachChild ? 'active' : ''} childData={d} toggleEachChild={() => props.toggleEachChild(i, d.showEachChild)}/>
                    
                    )}
                    
                </ChildDetails>}

                {props.classType === "scheduleTour" &&
                    <>
                        <ScheduleStatus>
                           {!props.noAccept && <h2 onClick={() => props.acceptRequest && props.acceptRequest()}><span class="iconify" data-icon="entypo:dot-single" data-inline="false"></span> Accept</h2>}
                            <h2 onClick={() => props.declineRequest && props.declineRequest()}><span class="iconify" data-icon="entypo:dot-single" data-inline="false"></span> Decline</h2>
                            <h2 onClick={() => props.changeDate && props.changeDate()}><span class="iconify" data-icon="ic:round-access-time" data-inline="false"></span>Change date & time</h2>

                        </ScheduleStatus>
                    </>
                }
                {props.classType === "cancelTour" &&
                    <>
                        <ScheduleStatus>
                            <Value>
                                <span>
                                Reason for cancellation:
                                </span>
                                <h3>
                                {props.cancelReason}
                                </h3>
                            </Value>
                        </ScheduleStatus>
                    </>
                }
                {props.classType === "changeTour" &&
                    <>
                        <ScheduleStatus>
                            <Value>
                                <span>
                                Old schedule details: 


                                </span>
                                <h3>
                                {props.changedDate}

</h3>
                            </Value>
                        </ScheduleStatus>
                    </>
                }
            </Schedule>
        </>
    );
}

ScheduleTour.propTypes = {
    name: PropTypes.string,
    childDetailType: PropTypes.string,
    time: PropTypes.string,
    detailType: PropTypes.string,
    classType: PropTypes.string
};

export default ScheduleTour;

export const Schedule = styled.div`
width:100%;
margin-bottom:24px;
  border: 1px solid #dbdee5;
  border-radius: 5px;
  background-color: #ffffff;
`;

export const VisitDetails = styled.div`
  display: flex;
  width: 100%;
  padding:24px 24px 0px;
  .profilePic
  {
    height: 57px;
    width: 57px;
    border-radius:50%;
    overflow:hidden;
    img
    {
        width:100%;
        height:100%;
    }
  }
  .visitDetails
  {
      &-content
      {
          margin-left:16px;
      }
  }
`;
export const ChildDetails = styled.div`
// height:40px;
max-height:40px;
overflow:hidden;
transition: max-height 1s ease;
&.active
{
    // height:auto;
    max-height:600px;  
    transition:max-height 1s ease;
    h2>.iconify
    {
        transform:rotate(450deg) !important;
        transition:transform 1s ease;
    }
}
&>h2
{
    display: flex;
    align-items:center;
    color: #613A95;
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-left:96px;
    margin-bottom:0px;
    cursor:pointer;
    &:hover
    {
        color: rgb(48, 28, 75);
        font-weight: 600;

    }
    .iconify
    {
        margin-left:20px;
        width:24px;
        height:24px;
        transform:rotate(180deg);
        transition:transform 1s ease;
    }
    &.active
    {
        .iconify
        {
          transform:rotate(45deg);
        }
    }
}`;
export const Name = styled.div`
  color: #666c78;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 8px;
`;
export const Time = styled.div`
  color: #30333b;
  font-family: Quicksand;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`;


export const ScheduleStatus = styled.div`
display:flex;
padding:13px 24px;
justify-content:space-between;
border-top:1px solid #dbdee5;

h2
{
    cursor: pointer;
    display:flex;
    color: #F4807B;
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom:0px;
    .iconify
    {
        width:20px;
        height:20px;
        margin-right:5px;
    }
    &:first-child
    {
        color: #60B947;
    }
    &:last-child
    {
        color: #613A95;
    }
}
`;

export const Value = styled.div`
width: 100%;
span
{
    color: #666C78;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}
h3
{
    color: #30333B;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin:8px 0px 0px;  
}
`;