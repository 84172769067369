/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  CardBody,
  Col,
  Row,
  Button,
  Form,
  Input,
  Label,
  CardTitle,
  Modal,
  CardText
} from "reactstrap";
import { createForm } from "rc-form";
import cloneDeep from "lodash/cloneDeep";

import FormInput from "../../../components/FormInput";
import Dropdown from "../../../components/Dropdown";
import Select from "../../../components/Select";
import FileUploader from "../../../components/Uploader";
import responseToast from "../../../utils/responseToast";
import RadioButton from "../../../components/RadioButton";
import {envType} from "../../../config/baseURL";


const temp = [];

function PhotosVideos(props) {
  const [uploadCover, setUploadCover] = useState("");
  const [uploadLogo, setUploadLogo] = useState("");
  const [uploadThumbnail, setUploadThumbnail] = useState("");

  const [openRewardModal, setOpenRewardModal] = useState(false);

  const [uploadArr, setUploadArr] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const frontViewArr = [];
  const [frontView, setfrontView] = useState([]);

  const receptionArr = [];
  const [reception, setreception] = useState([]);

  const indoorArr = [];
  const [indoor, setindoor] = useState([]);

  const outdoorArr = [];
  const [outdoor, setoutdoor] = useState([]);

  const kitchenArr = [];
  const [kitchen, setkitchen] = useState([]);

  const bathroomArr = [];
  const [bathroom, setbathroom] = useState([]);

  const classroomArr = [];
  const [classroom, setclassroom] = useState([]);

  const sleepingroomArr = [];
  const [sleepingroom, setsleepingroom] = useState([]);

  const photo360Arr = [];
  const [photo360, setPhoto360] = useState([]);

  const addmoreArr = [];
  const [addmore, setaddmore] = useState([]);

  const [picture360, setPicture360] = useState([]);
  const [tagErr, setTagErr] = useState([]);


  const handleRewardSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (errors, values) => {
      if (!errors) {
        setOpenRewardModal(false);
      }
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (!disabled) {
      // if(props.data.length === 0) {
      let idsArr = uploadArr.filter(a => !a.id && a.media_id);
      let filter360 = idsArr.filter(a => a.media_type === 'photo360')
      let filterOther = idsArr.filter(a => a.media_type !== 'photo360')
      filter360.map((a, id) => a.tag_name = picture360[id].tag_name ? picture360[id].tag_name.split(' ').join('_') : '')
      let upload = [...filterOther, ...filter360];
      props.form.validateFields(async (errors, values) => {
        if (!errors) {
          props.setContentChanged(false);
          setDisabled(true);
          props.submitPhoto({
            data: upload,
            cb: err => {
              if (!err) {
                // PROD1
                // window.location.replace(
                //   `http://preview.kiddenz.com/?token=Bearer ${localStorage.getItem(
                //     "x-access-token"
                //   )}`
                // );

                // PROD2
                // window.location.replace(
                //   `http://kiddenz-preview.s3-website.ap-south-1.amazonaws.com/?token=Bearer ${localStorage.getItem(
                //     "x-access-token"
                //   )}`
                // );

                // DEV
                envType === 'staging' ? 
                window.location.replace(
                  `https://devpreview.kiddenz.com/?token=Bearer ${localStorage.getItem(
                    "x-access-token"
                  )}`
                ) :
                // envType === 'prod1' ?
                // window.location.replace(
                //     `http://preview.kiddenz.com/?token=Bearer ${localStorage.getItem(
                //       "x-access-token"
                //     )}`
                //   ) :
                   window.location.replace(
                  `https://preview.kiddenz.com/?token=Bearer ${localStorage.getItem(
                    "x-access-token"
                  )}`
                )

              } else {
                responseToast(err);
              }
            }
          });
        }
      });
    }
  };

  const uploadFunction = (id, type, index) => {
    props.setContentChanged(true);
    let temp1 = [...uploadArr];
    temp1.push({ media_id: id, media_type: type });
    if (type === "photo360") {
      let pic360 = [...picture360];
      pic360[index] = pic360[index] || {}
      pic360[index].picture = id;
      setPicture360(pic360);
    }
    setUploadArr(temp1);
  };

  const deleteUpload = (id, type, array, setFunction) => {
    props.setContentChanged(true);

    const dummy = [...uploadArr];
    let filters = dummy.filter(el => el.media_id === id);
    // if (array) {
    //   let arr = [...array];
    //   arr.pop();
    //   setFunction(arr)
    // }
    dummy.splice(dummy.indexOf(filters[0]), 1);
    setUploadArr(dummy);
  };

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setUploadArr(props.data);
      // logo
      var logo_filter = props.data.filter(a => a.media_type === "logo");
      logo_filter.map((a, id) => {
        props.data[`logo`] = `${a.media_url}`;
        props.data[`logo_id`] = `${a.id}`;
      });
      setUploadLogo(props.data[`logo`]);

         // thumbnail
         var thumbnail_filter = props.data.filter(a => a.media_type === "thumbnail");
         thumbnail_filter.map((a, id) => {
           props.data[`thumbnail`] = `${a.media_url}`;
           props.data[`thumbnail_id`] = `${a.id}`;
         });
         setUploadThumbnail(props.data[`thumbnail`]);

      // cover
      var cover_pic_filter = props.data.filter(
        a => a.media_type === "cover_pic"
      );
      cover_pic_filter.map((a, id) => {
        props.data[`cover_pic`] = `${a.media_url}`;
        props.data[`cover_pic_id`] = `${a.id}`;
      });
      setUploadCover(props.data[`cover_pic`]);

      // front view
      var front_view_filter = props.data.filter(
        a => a.media_type === "front_view"
      );
      front_view_filter.map((a, id) => {
        props.data[`image_frontview_${id}`] = `${a.media_url}`;
        props.data[`image_frontview_id_${id}`] = `${a.id}`;
      });
      var front_view_filter_count = front_view_filter.map(a => a);
      setfrontView(front_view_filter_count);

      // reception
      var reception_filter = props.data.filter(
        a => a.media_type === "reception"
      );
      reception_filter.map((a, id) => {
        props.data[`image_reception_${id}`] = `${a.media_url}`;
        props.data[`image_reception_id_${id}`] = `${a.id}`;
      });
      var reception_filter_count = reception_filter.map(a => a);
      setreception(reception_filter_count);

      // indoor_area
      var indoor_area_filter = props.data.filter(
        a => a.media_type === "indoor_area"
      );
      indoor_area_filter.map((a, id) => {
        props.data[`image_indoor_${id}`] = `${a.media_url}`;
        props.data[`image_indoor_id_${id}`] = `${a.id}`;
      });
      var indoor_area_filter_count = indoor_area_filter.map(a => a);
      setindoor(indoor_area_filter_count);

      // outdoor_area
      var outdoor_area_filter = props.data.filter(
        a => a.media_type === "outdoor_area"
      );
      outdoor_area_filter.map((a, id) => {
        props.data[`image_outdoor_${id}`] = `${a.media_url}`;
        props.data[`image_outdoor_id_${id}`] = `${a.id}`;
      });
      var outdoor_area_filter_count = outdoor_area_filter.map(a => a);
      setoutdoor(outdoor_area_filter_count);

      // kitchen
      var kitchen_filter = props.data.filter(a => a.media_type === "kitchen");
      kitchen_filter.map((a, id) => {
        props.data[`image_kitchen_${id}`] = `${a.media_url}`;
        props.data[`image_kitchen_id_${id}`] = `${a.id}`;
      });
      var kitchen_filter_count = kitchen_filter.map(a => a);
      setkitchen(kitchen_filter_count);

      // bathroom
      var bathroom_filter = props.data.filter(a => a.media_type === "bathroom");
      bathroom_filter.map((a, id) => {
        props.data[`image_bathroom_${id}`] = `${a.media_url}`;
        props.data[`image_bathroom_id_${id}`] = `${a.id}`;
      });
      var bathroom_filter_count = bathroom_filter.map(a => a);
      setbathroom(bathroom_filter_count);

      // classroom
      var classroom_filter = props.data.filter(
        a => a.media_type === "classroom"
      );
      classroom_filter.map((a, id) => {
        props.data[`image_classroom_${id}`] = `${a.media_url}`;
        props.data[`image_classroom_id_${id}`] = `${a.id}`;
      });
      var classroom_filter_count = classroom_filter.map(a => a);
      setclassroom(classroom_filter_count);

      // sleepingroom
      var sleepingroom_filter = props.data.filter(
        a => a.media_type === "sleepingroom"
      );
      sleepingroom_filter.map((a, id) => {
        props.data[`image_sleepingroom_${id}`] = `${a.media_url}`;
        props.data[`image_sleepingroom_id_${id}`] = `${a.id}`;
      });
      var sleepingroom_filter_count = sleepingroom_filter.map(a => a);
      setsleepingroom(sleepingroom_filter_count);

      // 360 photo
      var photo360_filter = props.data.filter(a => a.media_type === "photo360");
      photo360_filter.map((a, id) => {
        props.data[`image_360_${id}`] = `${a.media_url}`;
        props.data[`image_360_id_${id}`] = `${a.id}`;
      });

      let pic360 = [];
      photo360_filter.map((a, i) => {
        console.log(`${a.media_url.split('/')[a.media_url.split('/').length - 1].split('-')[0]}`)
        pic360.push({ picture: a.id, tag_name: a.media_url.split('/')[a.media_url.split('/').length - 1].split('-')[0].split('_').join(' ') || '' })
        props.data[`title_${i}`] = `${a.media_url.split('/')[a.media_url.split('/').length - 1].split('-')[0].split('_').join(' ')}`;
      });
      setPicture360(pic360);
      var photo360_filter_count = photo360_filter.map(a => a);
      setPhoto360(photo360_filter_count);

      // other
      var other_filter = props.data.filter(a => a.media_type === "other");
      other_filter.map((a, id) => {
        props.data[`image_addmore_${id}`] = `${a.media_url}`;
        props.data[`image_addmore_id_${id}`] = `${a.id}`;
      });
      var other_filter_count = other_filter.map(a => a);
      setaddmore(other_filter_count);

      props.form.setFieldsValue(props.data);
    }
  }, [props.data]);

  return (
    <>
      {console.log(picture360)}
      <CardBody>
        <div className="cardHeader">
          {props.pictures && (
            <CardText className="cardProfileText">
              <span className="teacherSubtitle">
                {" "}
                Kiddenz team will keep in touch with you regarding 360° pictures
              </span>
            </CardText>
          )}
          <CardTitle className="cardTitle">Photos/Videos</CardTitle>
          <CardText className="cardProfileText">
            Let’s help parents know how nice your space looks, upload pictures
            of your school.
          </CardText>
        </div>
      </CardBody>

      <Form className="form-horizontal" onSubmit={handleSubmit}>
        <CardBody className="formContent">
          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Logo
            </Col>
            <Col xs="8">
              <Row className="">
                <FileUploader
                  width={1}
                  height={1}
                  name={`logo`}
                  form={props.form}
                  type="avatar"
                  isRequired={false}
                  images={
                    props.data.length > 0 && props.data[`logo_id`] && true
                  }
                  isLogo
                  idVal={props.data.length > 0 && props.data[`logo_id`]}
                  deleteMedia={props.deleteMedia}
                  xsval="4"
                  uploadUrl={id => uploadFunction(id, "logo")}
                  setCancelImg={id => {
                    deleteUpload(id, "logo");
                    setUploadLogo("");
                  }}
                  urlImage={props.data.length > 0 && props.data[`logo`]}
                />
              </Row>
            </Col>
          </Row>
          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Thumbnail
            </Col>
            <Col xs="8">
              <Row className="">
                <FileUploader
                  width={8}
                  height={5}
                  name={`thumbnail`}
                  form={props.form}
                  type="avatar"
                  isRequired={false}
                  images={
                    props.data.length > 0 && props.data[`thumbnail_id`] && true
                  }
                  idVal={props.data.length > 0 && props.data[`thumbnail_id`]}
                  deleteMedia={props.deleteMedia}
                  xsval="4"
                  uploadUrl={id => uploadFunction(id, "thumbnail")}
                  setCancelImg={id => {
                    deleteUpload(id, "thumbnail");
                    setUploadThumbnail("");
                  }}
                  urlImage={props.data.length > 0 && props.data[`thumbnail`]}
                />
              </Row>
            </Col>
          </Row>
          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Cover Image
            </Col>
            <Col xs="8">
              <Row className="">
                <FileUploader
                  width={8}
                  height={5}
                  label=""
                  name={`cover_pic`}
                  form={props.form}
                  type="avatar"
                  isRequired={false}
                  images={
                    props.data.length > 0 && props.data[`cover_pic_id`] && true
                  }
                  idVal={props.data.length > 0 && props.data[`cover_pic_id`]}
                  deleteMedia={props.deleteMedia}
                  xsval="4"
                  uploadUrl={id => uploadFunction(id, "cover_pic")}
                  setCancelImg={id => {
                    deleteUpload(id, "cover_pic");
                    setUploadCover("");
                  }}
                  urlImage={props.data.length > 0 && props.data[`cover_pic`]}
                />
              </Row>
            </Col>
          </Row>
          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Front View
            </Col>
            <Col xs="8">
              <Row className="">
                {frontView.map((val, id) => {
                  frontViewArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`Front View Image ${id + 1}`}
                      label=""
                      name={`image_frontview_${id}`}
                      form={props.form}
                      type="avatar"
                      isRequired={false}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_frontview_id_${id}`] &&
                        true
                      }
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_frontview_id_${id}`]
                      }
                      isResponsive
                      deleteMedia={props.deleteMedia}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "front_view")}
                      setCancelImg={val => {
                        deleteUpload(val, "front_view", frontView, (data) => {
                          setfrontView(data);
                          props.data[`image_frontview_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_frontview_${id}`]
                      }
                      photosVideos={true}
                    />
                  );
                })}
                {frontViewArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...frontView];
                      dummy.push(1);
                      setfrontView(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Reception
            </Col>
            <Col xs="8">
              <Row className="">
                {reception.map((val, id) => {
                  receptionArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      label=""
                      name={`image_reception_${id}`}
                      form={props.form}
                      type="avatar"
                      isRequired={false}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_reception_id_${id}`] &&
                        true
                      }
                      isResponsive
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_reception_id_${id}`]
                      }
                      deleteMedia={props.deleteMedia}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "reception")}
                      setCancelImg={val => {
                        deleteUpload(val, "reception", reception, (data) => {
                          setreception(data);
                          props.data[`image_reception_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_reception_${id}`]
                      }
                    />
                  );
                })}
                {receptionArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...reception];
                      dummy.push(1);
                      setreception(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Outdoor play area
            </Col>
            <Col xs="8">
              <Row className="">
                {outdoor.map((val, id) => {
                  outdoorArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`Outdoor image ${id + 1}`}
                      label=""
                      name={`image_outdoor_${id}`}
                      form={props.form}
                      type="avatar"
                      isRequired={false}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_outdoor_id_${id}`] &&
                        true
                      }
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_outdoor_id_${id}`]
                      }
                      isResponsive
                      deleteMedia={props.deleteMedia}
                      photosVideos={true}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "outdoor_area")}
                      setCancelImg={val => {
                        
                        deleteUpload(val, "outdoor_area", outdoor, (data) => {
                          setoutdoor(data);
                          props.data[`image_outdoor_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_outdoor_${id}`]
                      }
                    />
                  );
                })}
                {outdoorArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...outdoor];
                      dummy.push(1);
                      setoutdoor(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Indoor Play area
            </Col>
            <Col xs="8">
              <Row className="">
                {indoor.map((val, id) => {
                  indoorArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`Indoor image ${id + 1}`}
                      label=""
                      name={`image_indoor_${id}`}
                      form={props.form}
                      type="avatar"
                      isRequired={false}
                      photosVideos={true}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_indoor_id_${id}`] &&
                        true
                      }
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_indoor_id_${id}`]
                      }
                      isResponsive
                      deleteMedia={props.deleteMedia}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "indoor_area")}
                      setCancelImg={val => {
                        
                        deleteUpload(val, "indoor_area", indoor, (data) => {
                          setindoor(data);
                          props.data[`image_indoor_${id}`] = ''
                        });

                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_indoor_${id}`]
                      }
                    />
                  );
                })}
                {indoorArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...indoor];
                      dummy.push(1);
                      setindoor(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Kitchen (If available)
            </Col>
            <Col xs="8">
              <Row className="">
                {kitchen.map((val, id) => {
                  kitchenArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`Kitchen Image ${id + 1}`}
                      label=""
                      name={`image_kitchen_${id}`}
                      form={props.form}
                      type="avatar"
                      isRequired={false}
                      photosVideos={true}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_kitchen_id_${id}`] &&
                        true
                      }
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_kitchen_id_${id}`]
                      }
                      isResponsive
                      deleteMedia={props.deleteMedia}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "kitchen")}
                      setCancelImg={val => {
                        deleteUpload(val, "kitchen", kitchen, (data) => {
                          setkitchen(data);
                          props.data[`image_kitchen_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_kitchen_${id}`]
                      }
                    />
                  );
                })}
                {kitchenArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...kitchen];
                      dummy.push(1);
                      setkitchen(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Bathroom
            </Col>
            <Col xs="8">
              <Row className="">
                {bathroom.map((val, id) => {
                  bathroomArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`Bathroom image ${id + 1}`}
                      label=""
                      name={`image_bathroom_${id}`}
                      form={props.form}
                      type="avatar"
                      photosVideos={true}
                      isRequired={false}
                      xsval="4"
                      images={
                        props.data.length > 0 &&
                        props.data[`image_bathroom_id_${id}`] &&
                        true
                      }
                      isResponsive
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_bathroom_id_${id}`]
                      }
                      deleteMedia={props.deleteMedia}
                      uploadUrl={id => uploadFunction(id, "bathroom")}
                      setCancelImg={val => {
                         deleteUpload(val, "bathroom", bathroom, (data) => {
                          setbathroom(data);
                          props.data[`image_bathroom_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_bathroom_${id}`]
                      }
                    />
                  );
                })}
                {bathroomArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...bathroom];
                      dummy.push(1);
                      setbathroom(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Class room 1-10 (Add according to number of rooms)
            </Col>
            <Col xs="8">
              <Row className="">
                {classroom.map((val, id) => {
                  classroomArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`Classroom image ${id + 1}`}
                      label=""
                      name={`image_classroom_${id}`}
                      form={props.form}
                      photosVideos={true}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_classroom_id_${id}`] &&
                        true
                      }
                      isResponsive
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_classroom_id_${id}`]
                      }
                      deleteMedia={props.deleteMedia}
                      type="avatar"
                      isRequired={false}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "classroom")}
                      setCancelImg={val => {
                        deleteUpload(val, "classroom", classroom, (data) => {
                          setclassroom(data);
                          props.data[`image_classroom_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_classroom_${id}`]
                      }
                    />
                  );
                })}
                {classroomArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...classroom];
                      dummy.push(1);
                      setclassroom(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              Sleeping room
            </Col>
            <Col xs="8">
              <Row className="">
                {sleepingroom.map((val, id) => {
                  sleepingroomArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`Sleepingroom image ${id + 1}`}
                      label=""
                      name={`image_sleepingroom_${id}`}
                      form={props.form}
                      type="avatar"
                      photosVideos={true}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_sleepingroom_id_${id}`] &&
                        true
                      }
                      isResponsive
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_sleepingroom_id_${id}`]
                      }
                      deleteMedia={props.deleteMedia}
                      isRequired={false}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "sleepingroom")}
                      setCancelImg={val => {
                        deleteUpload(val, "sleepingroom", sleepingroom, (data) => {
                          setsleepingroom(data);
                          props.data[`image_sleepingroom_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_sleepingroom_${id}`]
                      }
                    />
                  );
                })}
                {sleepingroomArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...sleepingroom];
                      dummy.push(1);
                      setsleepingroom(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>

          {!props.pictures && <Row className="extended">
            <Col xs="4" style={{ paddingTop: 10 }} className="formInputName">
              360 view
            </Col>
            <Col xs="8">
              <Row className="">
                {photo360.map((val, id) => {
                  photo360Arr.push(
                    <Col xs="4" style={{ paddingLeft: 0 }}>
                      {console.log(tagErr[id])}
                      <FormInput
                        form={props.form}
                        name={`title_${id}`}
                        placeholder="Enter Title"
                        classType="margin0"
                        label=""
                        isRequired={
                          (picture360.length > 0 &&
                            picture360[id] ?
                            picture360[id].picture &&
                            !picture360[id].tag_name
                            : false)
                        }
                        defaultValue={(picture360.length > 0 && picture360[id] && picture360[id].tag_name) || ''}
                        isTitle
                        onChange={val => {
                          props.setContentChanged(true);
                          let pic = cloneDeep(picture360);
                          pic[id] = pic[id] || {}
                          pic[id].tag_name = val;
                          setPicture360(pic);
                        }}
                        colSpan="12"
                        initialLeftPadding={0}
                        rightPadding={"0px"}
                        paddingLeftFirst={15}
                      />
                      {tagErr[id] === true && <Col xs='12' ><div
                        style={{
                          color: "red",
                          fontSize: 11,
                          fontWeight: 300,
                          marginLeft: 0
                        }}
                      >
                        Enter Title first
                        </div></Col>}
                      <FileUploader
                        width={2}
                        height={1}
                        // label={`360 Photo ${id + 1}`}
                        label=""
                        name={`image_360_${id}`}
                        form={props.form}
                        type="360picture"
                        photosVideos={true}
                        images={
                          props.data.length > 0 &&
                          props.data[`image_360_id_${id}`] &&
                          true
                        }
                        cropFeatureDisabled
                        isResponsive
                        idVal={
                          props.data.length > 0 &&
                          props.data[`image_360_id_${id}`]
                        }
                        setTagErr={val => {
                          let err = [...tagErr]
                          err[id] = val;
                          console.log(err)
                          setTagErr(err)
                        }}
                        deleteMedia={props.deleteMedia}
                        isRequired={false}
                        isPhoto360
                        tagName={picture360[id] && picture360[id].tag_name}
                        // xsval="4"
                        uploadUrl={val => uploadFunction(val, "photo360", id)}
                        setCancelImg={val => deleteUpload(val, "photo360")}
                        urlImage={
                          props.data.length > 0 && props.data[`image_360_${id}`]
                        }
                      // is360
                      />
                    </Col>
                  );
                })}
                {photo360Arr}
                {photo360.length < 5 && (
                  <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                    <Button
                      color="secondary"
                      onClick={() => {
                        const dummy = [...photo360];
                        dummy.push(1);
                        setPhoto360(dummy);
                      }}
                      className="uploadMore"
                    >
                      <i
                        className="fa fa-plus"
                        aria-hidden="true"
                        style={{ fontSize: 23 }}
                      ></i>
                    </Button>
                  </Row>
                )}
              </Row>
            </Col>
          </Row>}

          <Row className="extended">
            <Col xs="4" className="formInputName">
              {" "}
              Add more pictures
            </Col>
            <Col xs="8">
              <Row className="">
                {addmore.map((val, id) => {
                  addmoreArr.push(
                    <FileUploader
                      width={8}
                      height={5}
                      // label={`More Images ${id + 1}`}
                      label=""
                      name={`image_addmore_${id}`}
                      form={props.form}
                      type="avatar"
                      isRequired={false}
                      photosVideos={true}
                      images={
                        props.data.length > 0 &&
                        props.data[`image_addmore_id_${id}`] &&
                        true
                      }
                      isResponsive
                      idVal={
                        props.data.length > 0 &&
                        props.data[`image_addmore_id_${id}`]
                      }
                      deleteMedia={props.deleteMedia}
                      xsval="4"
                      uploadUrl={id => uploadFunction(id, "other")}
                      setCancelImg={val => {
                        deleteUpload(val, "other", addmore, (data) => {
                          setaddmore(data);
                          props.data[`image_addmore_${id}`] = ''
                        });
                      }}
                      urlImage={
                        props.data.length > 0 &&
                        props.data[`image_addmore_${id}`]
                      }
                    />
                  );
                })}
                {addmoreArr}
                <Row style={{ alignItems: "flex-end", marginLeft: 16 }}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      const dummy = [...addmore];
                      dummy.push(1);
                      setaddmore(dummy);
                    }}
                    className="uploadMore"
                  >
                    <i
                      className="fa fa-plus"
                      aria-hidden="true"
                      style={{ fontSize: 23 }}
                    ></i>
                  </Button>
                </Row>
              </Row>
            </Col>
          </Row>
          {/* <Row className="formFlex">
            <Col xs="4"></Col>
            <Col xs="8">
              <Button onClick={() => props.updateBeforeComponent('Teachers and Staff')}
              >
                Back
              </Button>
              <Button
                type="submit"

              >
                Save as draft
              </Button>
              <Button
                onClick={() => ((proof === 'no' && outDoor === 'no' && indoor === 'no' && schoolPic === 'no') || !disabled) && props.setComponent('Location')}
                disabled={proof === 'no' && outDoor === 'no' && indoor === 'no' && schoolPic === 'no' ? true : disabled}
              >
                Continue
              </Button>
            </Col>
          </Row> */}

          <Row className="formFlex">
            <Col xs="4"></Col>
            <Col xs="8">
              <Button
                onClick={() =>
                  props.updateBeforeComponent("Teachers and staff")
                }
                color="primary"
              >
                Back
              </Button>

              <Button type="submit" disabled={disabled}>
                Preview
              </Button>
              {/* <Button onClick={() => props.submitForm({ data: '', cb: () => props.setComponent('success') })} disabled={props.data.length > 0 ? false : disabled}>

                Submit for Approval
                                        </Button> */}
            </Col>
          </Row>
        </CardBody>
      </Form>

      <Modal
        className="modal-dialog-centered rewardsModal"
        isOpen={openRewardModal}
        toggle={() => {
          setOpenRewardModal(false);
        }}
      >
        <div className="modal-header">
          <div>
            <h1>Liked our Platform</h1>
            <p>Please refer other preschool/daycare to win exciting rewards</p>
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setOpenRewardModal(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form className="form-horizontal" onSubmit={handleRewardSubmit}>
          <div className="modal-body">
            <Row>
              <FormInput
                form={props.form}
                name="name"
                placeholder="Name of the Childcare"
                colSpan="12"
                rightPadding="0px"
                label="Name"
              />
              <FormInput
                form={props.form}
                name="email"
                placeholder="Enter Email ID"
                colSpan="12"
                rightPadding="0px"
                label="Email"
                type="email"
              />
              <FormInput
                form={props.form}
                name="phone"
                placeholder="Enter Phone Number"
                colSpan="12"
                rightPadding="0px"
                label="Phone Number"
                isMobile
              />
            </Row>
            <Row className="formFlex" style={{ marginBottom: 0 }}>
              <Col xs="4"> </Col>
              <Col xs="8">
                <Button style={{ float: "right" }} type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
}

PhotosVideos.propTypes = {};

export default createForm()(PhotosVideos);
