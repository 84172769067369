/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext } from "react";
import Logo from "../../assets/img/whitelogo.png";
import dummyImg from "../../assets/img/Providers profile 2.svg";

import { Link } from "react-router-dom";
// reactstrap components
import { UserContext } from "../../App";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  CardTitle,
  CardText,
  Media,
  NavItem,
  NavLink,
  Button
} from "reactstrap";
import classnames from "classnames";
import profileImage from "../../assets/img/images.jpeg";

function AdminNavbar(props) {
  const [activeTab, setActiveTab] = useState("For Providers");
  const ctx = useContext(UserContext);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark " expand="md" id="navbar-main">
        <Container fluid className="alignItems">
          <div
            className="h1 mb-0 d-none d-lg-inline-block"
            style={{ fontWeight: 400, color: "#ffffff" }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{ opacity: props.whiteLogo ? 1 : 0 }}
            />
          </div>
          {props.search && (
            <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Search"
                    type="text"
                    onChange={props.onChange}
                    value={props.searchValue}
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          )}
          <div className="alignItems">
            {/* <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'About' })}
                  onClick={() => { toggle('About'); }}
                >
                  About
          </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'Find Care' })}
                  onClick={() => { toggle('Find Care'); }}
                >
                  Find Care
          </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'Parenting Articles' })}
                  onClick={() => { toggle('Parenting Articles'); }}
                >
                  Parenting Articles
          </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'For Providers' })}
                  onClick={() => { toggle('For Providers'); }}
                >
                  For Providers
          </NavLink>
              </NavItem>
            </Nav> */}

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <div className="headerStyle rightContentStyle">
                <span
                  style={{
                    fontWeight: 300,
                    marginRight: 20,
                    color: "#fff",
                    fontSize: 14,
                    textAlign: "right"
                  }}
                >
                  Call us at
                  <CardTitle
                    style={{ fontWeight: 400, marginBottom: 0, color: "#fff" }}
                    //    onClick={() => deleteLocation()}
                  >
                    8088340733
                  </CardTitle>
                </span>
                <span
                  style={{
                    fontWeight: 300,
                    marginRight: 20,
                    color: "#fff",
                    fontSize: 14,
                    textAlign: "right"
                  }}
                >
                  Mail us at
                  <CardTitle
                    style={{ fontWeight: 400, marginBottom: 0, color: "#fff" }}
                  >
                    <a
                      className="mailto"
                      href="mailto:sales@kiddenz.com"
                      style={{
                        fontWeight: 400,
                        marginBottom: 0,
                        color: "#fff"
                      }}
                    >
                      sales@kiddenz.com
                    </a>
                  </CardTitle>
                </span>
                {/* <span className="avatar avatar-sm rounded-circle">
                  <img
                        alt="..."
                        // src={dummyImg}
                        src={props.userImage || (ctx.user && ctx.user.profile && (((ctx.user.profile.data && ctx.user.profile.data.provider_image) || ctx.user.profile.provider_image) || dummyImg))}

                        style={{width: '90%', height: '90%'}}
                      />
                </span> */}
              </div>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={dummyImg}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        User name
                      </span>
                      <i className='fa fa-angle-down' style={{color: '#fff', marginLeft: 10}} />
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/form-update" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>View profile details</span>
                  </DropdownItem>
                 
                  <DropdownItem href="#pablo" onClick={e => {e.preventDefault(); ctx.actions.logout()}}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              {/* <Button type="secondary" onClick={() => ctx.actions.logout()}>
                Logout
              </Button> */}
              {/* <Button  type="secondary"> */}
              {/* <Link to='/profile' style={{ color: '#09090a' }}> */}
              {/* Register */}
              {/* </Link> */}
              {/* </Button> */}
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
