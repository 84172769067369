/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";
import { Col, Row } from "reactstrap";
import ScheduleTour from "../../../components/ScheduletourCard";
import Flex from "../../../views/Pages/SchoolProfile/flex.js";
import profilePic from "../../../assets/img/user2.svg";
import EmptyState from "../../../components/EmptyState";
import emptyImage from "../../../assets/img/illustrtns_school tour.svg";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import { API_PARENTS } from "../../../config/apiEndpoints";
import Loader from "../../../components/Loader";
import useCommonCRUD from "../../../hooks/commonCRUD";
import Modal from "../../../components/CustomModal";
import responseToast from "../../../utils/responseToast";

import { createForm } from "rc-form";

const SCHEDULE_DATA = [
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    showChild: false,
    img: profilePic,

    child: [
      {
        first_name: "Dia",
        age: "4",
        joining: "Immediate",
        type_of_care: "Daycare & preschool",
        food_choice: "Yes",
        schedule: "Flexible",
        medication_details: "Pansac: Before breakfast",
        allergies: "Beans",
        transportaion: "Yes",
        child_care_experience: "None",
        other_specific_needs:
          "My child needs assitance during eating,Potty training is not done, please assist during potty",
        special_care: false,
        showEachChild: false
      },
      {
        first_name: "Aryan",
        age: "2",
        joining: "Immediate",
        type_of_care: "Daycare",
        food_choice: "No",
        schedule: "Flexible",
        medication_details: "Pansac: Before breakfast",
        allergies: "Beans",
        transportaion: "No",
        special_care: false,
        child_care_experience: "Yes",
        other_specific_needs: "",
        showEachChild: false
      }
    ]
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  }
];
function Dashboard(props) {
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [fromValue, setFromValue] = useState("");
  const [fromValueErr, setFromValueErr] = useState("");
  const [toValueErr, setToValueErr] = useState("");
  const [toValue, setToValue] = useState("");
  const [detailsPopUp, setDetailsPopUp] = useState("");
  const [acceptDetails, setAcceptDetails] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [parentName, setParentName] = useState("");

  const [dateValueErr, setDateValueErr] = useState("");
  const [timeValue, setTimeValue] = useState("");
  const [timeValueErr, setTimeValueErr] = useState("");
  const [declineValue, setDeclineValue] = useState("");
  const [declineValueErr, setDeclineValueErr] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [oldDate, setOldDate] = useState("");
  const [oldTime, setOldTime] = useState("");
  const [changeDateId, setChangedDateId] = useState("");
  const [tourId, setTourId] = useState("");
  const [declineId, setDeclineId] = useState("");


  const [getAcceptedTour, , , , getAcceptedTourByFilter] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PARENTS}get-filtered-scheduled-tour/?tour_type=accept${
        fromValue && toValue ? `&from_date=${fromValue}&to_date=${toValue}` : ""
      }${
        filter && filter !== "Date"
          ? filter === "Accepted by parent"
            ? "&parent_acceptance=true"
            : "&parent_acceptance=false"
          : ""
      }&search=`,
      readId: `${API_PARENTS}get-filtered-scheduled-tour/?tour_type=accept${
        fromValue && toValue ? `&from_date=${fromValue}&to_date=${toValue}` : ""
      }${
        filter && filter !== "Date"
          ? filter === "Accepted by parent"
            ? "&parent_acceptance=true"
            : "&parent_acceptance=false"
          : ""
      }&search=`
    }
  });

  const [, , , updateToursApi] = useCommonCRUD({
    CRUDaseUrl: {
      update: `${API_PARENTS}update-scheduled-tour/`
    }
  });

  const [, , , changeDateApi] = useCommonCRUD({
    CRUDaseUrl: {
      update: `${API_PARENTS}change-schedule-tour/`
    }
  });

  const toggleChild = (data, index, show, childIndex, child) => {
    let dataCopy = [...groupedData];
    if (child) {
      dataCopy.map((dt, i) => {
        if (dt.data === data) {
          dt.data.map((a, i) => {
            if (i === index) {
              console.log(a);
              a.child.map((d, id) => {
                if (id === childIndex) {
                  d.showEachChild = !show;
                } else {
                  d.showEachChild = false;
                }
              });
            }
          });
        }
      });
    } else
      dataCopy.map((dt, i) => {
        if (dt.data === data) {
          dt.data.map((a, i) => {
            if (i === index) {
              a.showChild = !show;
            }
          });
        }
      });
    setGroupedData(dataCopy);
  };


  useEffect(() => {
    if (
      getAcceptedTour &&
      getAcceptedTour.data &&
      getAcceptedTour.data.length > 0
    ) {
      let scheduleData = [];
      getAcceptedTour.data.map(d =>
        scheduleData.push({
          id: d.id,
          name: `${d.parent_name} is visiting the school on`,
          date: d.date,
          old_date: d.date,
          old_time: d.time,
          tour_id: d.tour_id,
          parent_name: d.parent_name,
          time: `${moment(`${d.date} ${d.time}`).format(
            "dddd, MMM Do hh:mmA"
          )} Tour ID: ${d.tour_id}`,
          showChild: false,
          img:
            d.provider_pic && d.provider_pic[0] && d.provider_pic[0].media_url,
          child: d.child_info
        })
      );
      setData(scheduleData);
    } else setData([]);
  }, [getAcceptedTour]);

  useEffect(() => {
    let groups = {};
    for (var i = 0; i < data.length; i++) {
      var groupName = data[i].date;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(data[i]);
    }
    let myArray = [];
    for (let groupName in groups) {
      myArray.push({ date: groupName, data: groups[groupName] });
    }
    setGroupedData(myArray);
  }, [data]);

  const applyFilter = () => {
    if (!fromValue || !toValue) {
      if (!fromValue) setFromValueErr(true);
      if (!toValue) setToValueErr(true);
      return;
    }
    getAcceptedTourByFilter({
      data: searchValue,
      cb: () => {
        setFromValueErr("");
        setToValueErr("");
        setDetailsPopUp("");
        setFromValue("");
        setToValue("");
      }
    });
  };

  useEffect(() => {
    if (fromValue && toValue) {
      if (new Date(fromValue) > new Date(toValue)) {
        setToValue("");
      }
    }
  }, [fromValue, toValue]);

  useEffect(() => {
    if (filter !== "Date") {
      getAcceptedTourByFilter({
        data: searchValue
      });
    }
  }, [filter]);

  useEffect(() => {
    getAcceptedTourByFilter({
      data: searchValue
    });
  }, [searchValue]);

  const declineRequest = () => {
    if (!declineValue) {
      setDeclineValueErr(true);
      return;
    }
    updateTour(declineId, "decline");
  };

  const changeDate = () => {
    if (!dateValue || !timeValue) {
      if (!dateValue) setDateValueErr(true);
      if (!timeValue) setTimeValueErr(true);
      return;
    }
    setDateValueErr("");
    setTimeValueErr("");
    setDetailsPopUp("changeTour");
  };

  const updateTour = (id, status) => {
    let updateData = {};
    updateData.id = id;
    updateData.idValue = id;
    updateData.status = status;
    updateData.remarks = declineValue;
    updateToursApi({
      data: updateData,
      cb: err => {
        if (!err) {
          if (status === "decline") {
            setDeclineValueErr("");
            setDetailsPopUp("");
            setDeclineValue("");
          }
          getAcceptedTourByFilter({ data: searchValue });
        } else {
          responseToast(err);
        }
      }
    });
  };
  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar
          {...props}
          brandText="Kiddenz"
          search
          searchValue={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <Loader isOpen={getAcceptedTour.isLoading} />

      {!getAcceptedTour.isLoading && (
        <div className="dashboardContainer">
          <div id="acceptTour">
            <div className="mainHeading">
              <Col>
                <h3>Accepted Tours</h3>
                <span>
                  All the tours accepted by parents and from your end will be
                  listed here
                </span>
              </Col>
            </div>
            <Row>
              <ul className="filterBy">
                <span>Filters by: </span>
                {/* <li
                  onClick={() => {
                    setFilter("Date");
                    setDetailsPopUp("dateFilter");
                  }}
                  className={filter === "Date" ? "active" : ""}
                >
                  Date
                </li> */}
                <li
                  onClick={() => {
                    setFilter("Accepted by parent");
                  }}
                  className={filter === "Accepted by parent" ? "active" : ""}
                >
                  Accepted by parent
                </li>
                <li
                  onClick={() => {
                    setFilter("Accepted by DC/PS");
                  }}
                  className={filter === "Accepted by DC/PS" ? "active" : ""}
                >
                  Accepted by DC/PS
                </li>
              </ul>
            </Row>
            {groupedData.length > 0 ? (
              <Flex column>
                {groupedData.map((a, i) => (
                  <>
                    <h3>{moment(a.date).format("MMM DD, YYYY")}</h3>
                    <Flex justifyBetween>
                      {a.data.map((d, i) => (
                        <Flex column>
                          <ScheduleTour
                            name={d.name}
                            time={d.time}
                            childData={d.child}
                            img={d.img}
                            classType="scheduleTour"
                            noAccept
                            childDetailType={d.showChild ? "active" : ""}
                            toggleChild={() =>
                              toggleChild(a.data, i, d.showChild)
                            }
                            toggleEachChild={(index, childShow) => {
                              toggleChild(a.data, i, childShow, index, true);
                            }}
                            changeDate={() => {
                              setOldDate(d.old_date)
                              setOldTime(d.old_time)
                              setChangedDateId(d.id)
                              setTourId(d.tour_id)
                              setParentName(d.parent_name)
                              setDetailsPopUp("changeDate");
                            }}
                            declineRequest={() => {
                              setDetailsPopUp("decline");
                              setDeclineId(d.id);
                            }}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </>
                ))}
              </Flex>
            ) : (
              <EmptyState
                text="Accepted Tours"
                desc={
                  filter === "Date"
                    ? "There are no school tours for applied filter"
                    : "There are no school tours accepted for your school, keep a check on this space to know more"
                }
                emptyImage={emptyImage}
              />
            )}
          </div>
        </div>
      )}

      {detailsPopUp === "changeDate" && (
        <Modal
          type={"changeDate"}
          successHandler={changeDate}
          closeHandler={() => {
            setDetailsPopUp("");
            setTimeValueErr("");
            setDateValueErr("");
            setDateValue("");
            setTimeValue("");
          }}
          setDateValue={val => {
            setDateValueErr("");
            setDateValue(val);
          }}
          dateValueErr={dateValueErr}
          setTimeValue={val => setTimeValue(val)}
          timeValueErr={timeValueErr}
          {...props}
        />
      )}
      {detailsPopUp === "decline" && (
        <Modal
          type={"decline"}
          successHandler={declineRequest}
          closeHandler={() => {
            setDetailsPopUp("");
            setDeclineValue("");
            setDeclineValueErr("");
          }}
          onChange={e => {
            setDeclineValueErr("");
            setDeclineValue(e.target.value);
          }}
          declineValueErr={declineValueErr}
          {...props}
        />
      )}
      {filter === "Date" && detailsPopUp === "dateFilter" && (
        <Modal
          type={"datefilter"}
          successHandler={applyFilter}
          closeHandler={() => {
            setFilter("");
            setDetailsPopUp("");
            setFromValueErr("");
            setToValueErr("");
            setToValue("");
            setFromValue("");
          }}
          tourType="accepted"
          setFromValue={val => {
            setFromValueErr("");
            setFromValue(val);
          }}
          setToValue={val => {
            setToValueErr("");
            setToValue(val);
          }}
          fromValue={fromValue}
          toValue={toValue}
          fromValueErr={fromValueErr}
          toValueErr={toValueErr}
          {...props}
        />
      )}
      {detailsPopUp === "changeTour" && (
        <Modal
          type={"changeTour"}
          successHandler={() => {
            let data = {};
            data.id = changeDateId;
            data.idValue = changeDateId;
            data.old_date = oldDate;
            data.old_time = oldTime;
            data.time = timeValue;
            data.date = dateValue;
            data.schedule_type = 'pending';
            changeDateApi({
              data: data,
              cb: err => {
                if (!err) {
                  setDetailsPopUp("");
                  getAcceptedTourByFilter({ data: searchValue });
                } else {
                  responseToast(err);
                }
              }
            });
          }}
          closeHandler={() => {
            setDateValue("");
            setTimeValue("");
            setDetailsPopUp("");
          }}
          parentName={parentName}
          oldTourDate={moment(`${oldDate} ${oldTime}`).format(
            "dddd, MMM Do hh:mmA"
          )}
          oldTourId={tourId}
          newTourDate={`${moment(dateValue).format(
            "dddd, MMM Do"
          )} ${timeValue}`}
          newTourId={tourId}
          {...props}
        />
      )}
    </>
  );
}

Dashboard.propTypes = {};

export default createForm()(Dashboard);
