const colors = {
  white: "#FFFFFF",
  black: "#000",
  lightBlack: "#30333B",
  primary: "#60B947",
  secondary: "#3B74E7",
  lightBlue: "#63BBEE",
  lightGrey: "#666C78",
  lighterGrey: "#A3A9B7",
  darkBlue: "#113E98",
  darkGrey: "#30333B",
  navyBlue: "#3B74E7",
  inputPlaceholder: "#4B5256",
  filterText: "#7E888E",
  filterBorder: "#F5F7FA"
};
export default colors;
