/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useCallback, useEffect } from "react";
import {
  CardBody,
  Col,
  Row,
  Button,
  Form,
  Input,
  Label,
  CardTitle,
  CardText,
  CardFooter
} from "reactstrap";
import { createForm } from "rc-form";
import FormInput from "../../../components/FormInput";
import Dropdown from "../../../components/Dropdown";
import RadioButton from "../../../components/RadioButton";

import Select from "../../../components/Select";
import FileUploader from "../../../components/Uploader";
import responseToast from "../../../utils/responseToast";
import cloneDeep from "lodash/cloneDeep";

const images = {};
let globalManager = false;
let globalDirector = false;


function ContactDetails(props) {
  const [drafts, setDrafts] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [directorImage, setDirectorImage] = useState("");
  const [managerImage, setManagerImage] = useState("");

  const [directorPhotoDeleted, setDirectorPhotoDeleted] = useState(false);
  const [managerPhotoDeleted, setManagerPhotoDeleted] = useState(false);
  const [showOnWebsite, setShowOnWebsite] = useState("");

  const [detailsArr, setDetailsArr] = useState([]);

  const storeDetails = (index, object, value) => {
    props.setContentChanged(true)
    let dummyArr = [...detailsArr];
    dummyArr[index] = dummyArr[index] || {};
    dummyArr[index][object] = value;

    if (index === 0) {
      dummyArr[index].contact_type = "child_care";
    } else if (index === 1) {
      dummyArr[index].contact_type = "manager";
    } else if (index === 2) {
      dummyArr[index].contact_type = "director";
    }
    if (object === 'photo_id' && value === '') {
      dummyArr[index].photo_url = ''
    }
    if (dummyArr[index].id) {
      if (!dummyArr[index].name && !dummyArr[index].email_id && !dummyArr[index].phone_number && !dummyArr[index].alt_phone_number && !dummyArr[index].alt_phone_number && !dummyArr[index].photo_id) {
        if (index === 1) {
          dummyArr[index].photo_deleted = managerPhotoDeleted;
          dummyArr[index].photo_id = null;
          dummyArr[index].photo_url = '';

        }
        if (index === 2) {
          dummyArr[index].photo_deleted = directorPhotoDeleted;
          dummyArr[index].photo_id = null;
          dummyArr[index].photo_url = '';

        }

        props.deleteContactdetails(dummyArr[index])
        if (index === 1) {
          setDirectorImage('')
          if (checkbox2) {
            setCheckbox2(false)
            setCheckbox1(false)

          }
        } else {
          setManagerImage('')
        }
        delete dummyArr[index];
      }
    }
    setDetailsArr(dummyArr);
    let datas = cloneDeep(dummyArr);
    if (checkbox1) {
      if (globalManager) {
        if (datas.length > 0 && datas[0] && datas[1]) {
          datas[1] = cloneDeep(datas[0]);
          datas[1].id = dummyArr[1].id;
          datas[1].photo_id = dummyArr[1].photo_id;
          datas[1].photo_url = dummyArr[1].photo_url;

          datas[1].contact_type = 'manager'
        }
      } else {
        if (datas.length > 0 && datas[0] && datas[1]) {

          datas[1] = cloneDeep(datas[0]);
          delete datas[1].id
          datas[1].contact_type = 'manager'
          datas[1].photo_id = dummyArr[1].photo_id;
          datas[1].photo_url = dummyArr[1].photo_url;
        }
      }
      setDetailsArr(datas);
      let obj = {};
      obj['manager photo'] = '';
      obj['manager_name'] = datas[0].name
      obj['manager_email_id'] = datas[0].email_id
      obj['manager_phone_number'] = datas[0].phone_number
      obj['manager_alt_phone_number'] = datas[0].alt_phone_number

      props.form.setFieldsValue(obj);
    }

    if (checkbox2) {
      let datas1 = cloneDeep(datas);
      console.log(datas1, globalDirector)
      if (globalDirector) {
        if (datas1.length > 0 && datas1[1] && datas1[2]) {
          datas1[2] = cloneDeep(datas1[1]);
          datas1[2].id = datas[2].id;
          datas1[2].contact_type = 'director'
          datas1[2].photo_id = datas[1].photo_id;
          datas1[2].photo_url = datas[1].photo_url;
        } else if (datas1[1] === undefined) {
          props.deleteContactdetails(datas1[2]);
          datas1[2] = cloneDeep(datas1[1]);
        }
        setDetailsArr(datas1);
      } else {
        if (datas1.length > 0 && datas1[1] && datas1[2]) {
          datas1[2] = cloneDeep(datas1[1]);
          delete datas1[2].id
          datas1[2].contact_type = 'director'
          datas1[2].photo_id = datas[1].photo_id;
          datas1[2].photo_url = datas[1].photo_url;
        } else if (datas1[1] === undefined) {
          props.deleteContactdetails(datas1[2]);
          datas1[2] = cloneDeep(datas1[1]);
        }

        setDetailsArr(datas1);
      }
      let obj1 = {};
      if (datas1[1] && datas1[2]) {
        obj1['director photo'] = datas1[2].photo_url;
        obj1['director_name'] = datas1[2].name
        obj1['director_email_id'] = datas1[2].email_id
        obj1['director_phone_number'] = datas1[2].phone_number
        obj1['director_alt_phone_number'] = datas1[2].alt_phone_number
      } else {
        obj1['director photo'] = '';
        obj1['director_name'] = ''
        obj1['director_email_id'] = ''
        obj1['director_phone_number'] = ''
        obj1['director_alt_phone_number'] = ''
      }

      props.form.setFieldsValue(obj1);


    }
  };

  useEffect(() => {
    let details = cloneDeep(detailsArr);
    if (checkbox1 && !globalManager) {
      if (details.length > 0 && details[1]) {
        if (details[1].id) {
          props.deleteContactdetails(details[1])
        }
        details[1] = {};
      }
      if (details[0]) {
        details[1] = cloneDeep(details[0]);
        details[1].id && delete details[1].id;
        details[1].contact_type = 'manager';
        details[1].show_on_website = checkbox1;
      }
      let obj = {};
      obj['manager photo'] = '';
      obj['manager_name'] = details[0].name
      obj['manager_email_id'] = details[0].email_id
      obj['manager_phone_number'] = details[0].phone_number
      obj['manager_alt_phone_number'] = details[0].alt_phone_number

      props.form.setFieldsValue(obj);
      props.setContentChanged(true)


      setDetailsArr(details);
    }
    if (checkbox2 && !globalDirector) {
      let details1 = cloneDeep(details);
      if (details1.length > 0 && details1[2]) {
        if (details1[2].id) {
          props.deleteContactdetails(details1[2])
        }
        details1[2] = {};
      }
      if (details1[1]) {
        details1 = cloneDeep(details);
        details1[2] = cloneDeep(details1[1]);
        details1[2].show_on_website = checkbox2;
        details1[2].id && delete details1[2].id;
        details1[2].contact_type = 'director';
        details1[2].photo_id = details1[1].photo_id;
        details1[2].photo_url = details1[1].photo_url;
      }
      let obj1 = {};
      obj1['director photo'] = details1[2] ? details1[2].photo_url : managerImage;
      obj1['director_name'] = details1[2] && details1[2].name
      obj1['director_email_id'] = details1[2] && details1[2].email_id
      obj1['director_phone_number'] = details1[2] && details1[2].phone_number
      obj1['director_alt_phone_number'] = details1[2] && details1[2].alt_phone_number


      props.form.setFieldsValue(obj1);
      props.setContentChanged(true)

      setDetailsArr(details1);
    }
  }, [checkbox1, checkbox2])

  const handleSubmit = e => {
    e.preventDefault();
    setFinalSubmit(true);
    if (!drafts) {

      props.form.validateFields(async (errors, values) => {
        if (!errors) {
          setFinalSubmit(false);
          let newDetails = [...detailsArr];
          for (let s = 0; s < newDetails.length; s++) {
            if (s === 1 && newDetails[s]) {
              newDetails[s].photo_deleted = managerPhotoDeleted;
            }
            if (s === 2 && newDetails[s]) {
              newDetails[s].photo_deleted = directorPhotoDeleted;
            }
          }

          newDetails.map(a => {
            if (a && !a.photo_id) {
              delete a.photo_id;
            }
          });

          newDetails.map((a, i) => {
            if (a) {
              if (i === 1) {
                checkbox1
                  ? (a.show_on_website = 1)
                  : (a.show_on_website = 0);
              }
              if (i === 2) {
                checkbox2
                  ? (a.show_on_website = 1)
                  : (a.show_on_website = 0);
              }

            }
          });

          newDetails = newDetails.filter(a => a && a.name)
          setDrafts(true);
          props.setContentChanged(false)
          if (props.data && props.data.length > 0) {
            props.updateContacts({
              data: {
                contact_detail: newDetails
              },
              cb: err => {
                if (!err) {
                  props.setComponent("Facilities");
                } else {
                  responseToast(err);
                }
              }
            });
          } else
            props.setContacts({
              data: newDetails,
              cb: err => {
                if (!err) {
                  props.setComponent("Facilities");
                } else {
                  responseToast(err);
                }
              }
            });
        }
      });
    }
  };

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      let cc = props.data.filter(a => a.contact_type === "child_care");
      let direct = props.data.filter(a => a.contact_type === "director");
      let manage = props.data.filter(a => a.contact_type === "manager");
      props.form.setFieldsValue({});
      if (direct[0]) {

        setDirectorImage(direct[0].photo_id);
        if (!direct[0].photo_url && !direct[0].name && !direct[0].email_id && !direct[0].phone_number && !direct[0].alt_phone_number) {
          if (direct[0].show_on_website) {
            setCheckbox2(true);
            globalDirector = true
          } else {
            setCheckbox2(false)
            globalDirector = false
          }
        } else {
          if (direct[0].show_on_website) {
            setCheckbox2(true)
            globalDirector = true
          } else {
            setCheckbox2(false)
            globalDirector = false
          }
        }
      }
      if (manage[0]) {
        setManagerImage(manage[0].photo_id);
        if (!manage[0].photo_url && !manage[0].name && !manage[0].email_id && !manage[0].phone_number && !manage[0].alt_phone_number) {
          if (manage[0].show_on_website) {
            console.log('checkbox')
            setCheckbox1(true)
            globalManager = true
          } else {
            setCheckbox1(false)
            globalManager = false
          }
          setCheckbox1(false);
          globalManager = false
        } else {
          if (manage[0].show_on_website) {
            setCheckbox1(true);
            globalManager = true
          } else {
            setCheckbox1(false);
            globalManager = false
          }
        }
      }
      let details = []
      details = [cc[0], manage[0] || {}, direct[0] || {}];

      setDetailsArr(details);
    }

    if (props.data && props.data.length === 1) {
      setCheckbox1(false)
      setCheckbox2(false)

    }
  }, [props.data]);



  return (
    <>
      <CardBody>
        <div className="cardHeader">
          <CardTitle className="cardTitle">Contact Details </CardTitle>
          <CardText className="cardProfileText">
            These information will be displayed in the contact Section for the parents to contact you.
          </CardText>
        </div>
      </CardBody>
      <Form className="form-horizontal" onSubmit={handleSubmit}>
        <CardBody className="formContent">
          <div>
            <Row>
              <FormInput
                form={props.form}
                name="child_care_name"
                isName
                colSpan="8"
                rightPadding="0px"
                placeholder="Name"
                label="Name"
                subLabel='Name of the contact person'
                onChange={val => storeDetails(0, "name", val)}
                defaultValue={(detailsArr.length > 0 && detailsArr[0] && detailsArr[0].name) || ''}
              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="child_care_email_id"
                placeholder="support@gmail.com"
                colSpan="8"
                rightPadding="0px"
                label="E-mail"
                type="email"
                onChange={val => storeDetails(0, "email_id", val)}
                defaultValue={(detailsArr.length > 0 && detailsArr[0] && detailsArr[0].email_id) || ''}
              />
            </Row>
            {/* <Row>
              <FormInput
                form={props.form}
                name="child_care_landline_number"
                placeholder="080 - 239483902"
                label="Landline Number"
                colSpan="8"
                rightPadding="0px"
                isLandline
                onChange={val => storeDetails(0, "landline_number", val)}
              />
            </Row> */}
            <Row>
              <FormInput
                form={props.form}
                name="child_care_phone_number"
                placeholder="Enter phone number"
                colSpan="8"
                rightPadding="0px"
                onChange={val => storeDetails(0, "phone_number", val)}
                label="Phone Number"
                isMobile
                contact="contact"
                defaultValue={(detailsArr.length > 0 && detailsArr[0] && detailsArr[0].phone_number) || ''}

              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                onChange={val => storeDetails(0, "alt_phone_number", val)}
                name="child_care_alt_phone_number"
                placeholder="Enter Alternate phone number"
                label="Alternate Phone Number"
                colSpan="8"
                rightPadding="0px"
                isMobile
                isRequired={false}
                contact="contact"
                defaultValue={(detailsArr.length > 0 && detailsArr[0] && detailsArr[0].alt_phone_number) || ''}

              />
            </Row>
          </div>
          <hr />

          <div class="customCheckBox form-check" onClick={() => {
            props.setContentChanged(true)
            setCheckbox1(!checkbox1)
            globalManager = false;
          }} style={{ marginBottom: 20 }}>
            <input
              novalidate=""
              name={`sameCalender`}
              xsval="6"
              type="checkbox"
              class="form-check-input"
              checked={checkbox1}

            />
            <label
              for={`sameCalender`}
              class="form-check-label"
              style={{ marginBottom: 0 }}
            >
              <span>Same as above Contact Details</span>

            </label>
          </div>
          <div>
            <Row style={{ marginBottom: 20 }}>

              {/* <RadioButton
                name="manager"
                label="Branch Manager contact details"
                form={props.form}
                xsval="4"
                checked={showOnWebsite === "manager"}
                onClick={label => {
                  storeDetails();
                  setShowOnWebsite("manager");
                }}

              /> */}
              <Col xs='4' className="formInputName"><div>Center Head/ Center coordinator Contact Details</div></Col>
              <FileUploader
                width={34}
                height={40}
                name="manager photo"
                form={props.form}
                type="avatar"
                isRequired={false}
                xsval="4"
                uploadUrl={id => {
                  setManagerImage(id);
                  setManagerPhotoDeleted(false);
                  if (checkbox2) {
                    setDirectorImage(id)
                    setDirectorPhotoDeleted(false);
                  }
                  storeDetails(1, 'photo_id', id)

                }}
                setCancelImg={id => {
                  setManagerImage("");
                  setManagerPhotoDeleted(true);
                  if (checkbox2) {
                    setDirectorImage('')
                    setDirectorPhotoDeleted(true);
                  }
                  storeDetails(1, 'photo_id', '')

                }}
                photoUrl={val => {
                  setManagerImage(val)
                  if (checkbox2) {
                    setDirectorImage(val)
                  }
                }
                }
                urlImage={(detailsArr.length > 0 && detailsArr[1] && (detailsArr[1].photo_url || (managerImage && (managerImage.toString()).includes('://') ? managerImage : ''))) || ''}
                imageSize={200}

              // errMargin={255}
              // justify="flex-start"
              // topMargin="0px"
              // uploadText="Upload Picture"
              // justify="start"

              // marginLeft="-450px"
              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="manager_name"
                isName
                placeholder="Name of the Center head/ Center Coordinator"
                label="Name of the Center head/ Center Coordinator"
                colSpan="8"
                alphanumeric={true}
                // defaultValue={(detailsArr.length > 0 && detailsArr[2] && (detailsArr[2].name || '')}
                rightPadding="0px"
                disabled={checkbox1}
                onChange={val => storeDetails(1, "name", val)}
                isRequired={detailsArr.length > 0 && managerImage ? true : (detailsArr[1] !== undefined && ((!managerImage && !detailsArr[1].email_id && !detailsArr[1].phone_number && !detailsArr[1].alt_phone_number) ? false : true))}
                defaultValue={(detailsArr.length > 0 && detailsArr[1] && detailsArr[1].name) || ''}

              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="manager_email_id"
                placeholder="support@gmail.com"
                label="E-mail ID"
                type="email"
                colSpan="8"
                rightPadding="0px"
                disabled={checkbox1}

                onChange={val => storeDetails(1, "email_id", val)}
                isRequired={false}
                defaultValue={(detailsArr.length > 0 && detailsArr[1] && detailsArr[1].email_id) || ''}

              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="manager_phone_number"
                placeholder="Enter phone number"
                label="Phone Number"
                colSpan="8"
                onChange={val => storeDetails(1, "phone_number", val)}
                isRequired={false}
                rightPadding="0px"
                isMobile
                disabled={checkbox1}

                contact="contact"
                defaultValue={(detailsArr.length > 0 && detailsArr[1] && detailsArr[1].phone_number) || ''}

              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="manager_alt_phone_number"
                placeholder="Enter alternate phone number"
                label="Alternate Phone Number"
                colSpan="8"
                rightPadding="0px"
                isMobile
                isRequired={false}
                disabled={checkbox1}

                onChange={val => storeDetails(1, "alt_phone_number", val)}
                contact="contact"
                defaultValue={(detailsArr.length > 0 && detailsArr[1] && detailsArr[1].alt_phone_number) || ''}

              />
            </Row>
          </div>
          <hr />
          {detailsArr.length > 0 && detailsArr[1] && detailsArr[1].name &&
            <div class="customCheckBox form-check" onClick={() => {
              props.setContentChanged(true)
              setCheckbox2(!checkbox2)
              globalDirector = false;
            }}
              style={{ marginBottom: 20 }}>
              <input
                novalidate=""
                name={`sameCalender`}
                xsval="6"
                type="checkbox"
                class="form-check-input"
                checked={checkbox2}

              />
              <label
                for={`sameCalender`}
                class="form-check-label"
                style={{ marginBottom: 0 }}
              >
                <span>Same as Center Head/ Center Coordinator Details</span>            </label>
            </div>}
          <div>
            <Row style={{ marginBottom: 20 }}>
              {/* <RadioButton
                name="director"
                label="Director contact details"
                form={props.form}
                xsval="4"
                checked={showOnWebsite === "director"}
                onClick={label => {
                  storeDetails();
                  setShowOnWebsite("director");
                }}
              /> */}
              <Col xs='4'>
                <div className="formInputName">Director Details</div></Col>

              <FileUploader
                width={34}
                height={40}
                imageSize={200}
                name="director photo"

                form={props.form}
                type="avatar"
                xsval="4"
                isRequired={false}
                disabled={checkbox2}
                // justify="flex-start"
                // topMargin="0px"
                // uploadText="Upload Picture"
                // justify="start"
                uploadUrl={id => {
                  setDirectorImage(id);
                  setDirectorPhotoDeleted(false);
                  storeDetails(2, 'photo_id', id)

                }}
                photoUrl={val => setDirectorImage(val)}
                // marginLeft="-450px"
                urlImage={(detailsArr.length > 0 && detailsArr[2] && (detailsArr[2].photo_url || (directorImage && (directorImage.toString()).includes('://') ? directorImage : ''))) || ''}


                // errMargin={255}
                setCancelImg={id => {
                  setDirectorImage("");
                  setDirectorPhotoDeleted(true);
                  storeDetails(2, 'photo_id', '')
                }}
              />
              {/* </Col> */}
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="director_name"
                colSpan="8"
                rightPadding="0px"
                alphanumeric={true}
                isName
                placeholder="Name of the Director"
                label="Name of the Director"
                onChange={val => storeDetails(2, "name", val)}
                isRequired={detailsArr.length > 0 && directorImage ? true : (detailsArr[2] !== undefined && ((!directorImage && !detailsArr[2].email_id && !detailsArr[2].phone_number && !detailsArr[2].alt_phone_number) ? false : true))}
                defaultValue={(detailsArr.length > 0 && detailsArr[2] && detailsArr[2].name) || ''}
                disabled={checkbox2}

              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="director_email_id"
                placeholder="support@gmail.com"
                label="E-mail ID"
                type="email"
                colSpan="8"
                rightPadding="0px"
                onChange={val => storeDetails(2, "email_id", val)}
                isRequired={false}
                defaultValue={(detailsArr.length > 0 && detailsArr[2] && detailsArr[2].email_id) || ''}
                disabled={checkbox2}

              />
            </Row>
            {/* <Row>
              <FormInput
                form={props.form}
                name="director_landline_number"
                placeholder="080 - 239483902"
                label="Landline Number"
                colSpan="8"
                rightPadding="0px"
                onChange={val => storeDetails(1, "landline_number", val)}
                isRequired={true}
                isLandline
              />
            </Row> */}
            <Row>
              <FormInput
                form={props.form}
                name="director_phone_number"
                placeholder="Enter phone number"
                label="Phone Number"
                colSpan="8"
                rightPadding="0px"
                onChange={val => storeDetails(2, "phone_number", val)}
                isRequired={false}
                isMobile
                contact="contact"
                defaultValue={(detailsArr.length > 0 && detailsArr[2] && detailsArr[2].phone_number) || ''}
                disabled={checkbox2}

              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="director_alt_phone_number"
                placeholder="Enter alternate phone number"
                label="Alternate Phone Number"
                colSpan="8"
                rightPadding="0px"
                isMobile
                onChange={val => storeDetails(2, "alt_phone_number", val)}
                isRequired={false}
                defaultValue={(detailsArr.length > 0 && detailsArr[2] && detailsArr[2].alt_phone_number) || ''}
                contact="contact"
                disabled={checkbox2}

              />
            </Row>
          </div>
        </CardBody>
        <CardFooter>
          <Row style={{ padding: "0px 0px" }}>
            <Col xs="12" className="formFlex" style={{ justifyContent: "center", margin: 0 }}>
              <Button onClick={() => props.updateBeforeComponent("Location")} color="primary">
                Back
              </Button>
              <Button type="submit" disabled={drafts}>
                {props.data && props.data.length > 0 ? "Update" : "Save"} and
                Continue
              </Button>
              {finalSubmit && (
                <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                  Some fields are not filled
                </div>
              )}
              {/* <Button disabled={props.data && props.data.length > 0 ? false : drafts} onClick={() => props.data && props.data.length > 0 ? props.setComponent('Facilities') : !drafts && props.setComponent('Facilities')}>Continue</Button> */}
            </Col>
          </Row>
        </CardFooter>
      </Form>
    </>
  );
}

ContactDetails.propTypes = {};

export default createForm()(ContactDetails);
