import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, Col, FormFeedback } from "reactstrap";
import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";
import { Tooltip } from "chart.js";

const toNumber = v => {
  if (v === undefined) {
    return v;
  }
  if (v === "") {
    return undefined;
  }
  if (v && v.trim && v.trim() === "") {
    return NaN;
  }
  return Number(v);
};

function FormInput({
  form,
  setContentChanged,
  isRequired = true,
  label,
  errorMessage,
  rightPadding,
  onBlur,
  isTitle,
  type = "text",
  isMobile,
  pincode,
  isName,
  disabled = false,
  alphanumeric=false,
  year,
  onChange,
  snacks,
  ratio1,ratio2,
  isAge,
  isLandline,
  maxLength,
  TooltipHover,
  minWidth,
  left,
  isExp,
  absolute,
  right,
  top,
  isfee,
  paddingLeft,
  paddingLeftFirst,
  contact,
  initialLeftPadding,
  ...rest
}) {
  // { if (rest.name.startsWith('staffName')) debugger; }
  // const test = useCallback(() => (rest.name === 'manager_name' && !isRequired) || (rest.name === 'director_name' && !isRequired) || (rest.name.startsWith('teacherName') && !isRequired) || (rest.name.startsWith('staffName') && !isRequired) ? null : form.getFieldError(rest.name), [rest.name, isRequired])

  // const error = useMemo(test, [rest.name, isRequired])

  if (
    rest.name.startsWith("teacherName") ||
    rest.name.startsWith("teacherName")
  ) {
    if (rest.submit && rest.msg) {
      isRequired = true;
    } else {
      isRequired = false;
    }
  }

  const error =
    (rest.name === "manager_name" && !isRequired) ||
      (rest.name === "director_name" && !isRequired) ||
      (rest.name.startsWith("teacherName") && !isRequired) ||
      (rest.name.startsWith("staffName") && !isRequired)
      ? null
      : form.getFieldError(rest.name);

  const [rules, setRules] = useState([
    { required: isRequired, message: errorMessage, max: maxLength || 250 }
  ]);
  let inputType = type;

  useEffect(() => {
    setRules([
      { required: isRequired, message: errorMessage, max: maxLength || 250 }
    ]);
  }, [isRequired]);

  const validateEndDate = (rule, value, callback) => {
    let errors = [];
    if (value > new Date().getFullYear()) {
      errors = [new Error("Year should be less than current year")];
    }
    callback(errors.length ? errors : undefined);
  };

  if (type === "number") {
    rules[0].type = "number";
    rules[0].transform = toNumber;
    delete rules[0].max;
  }
  if (type === "textarea") {
    rules[0].max = maxLength || 1000;
  }
  if (isMobile) {
    rules[0].min = 10;
    rules[0].max = 10;
    rules[0].pattern = /^(\d *){10}$/;
    rules[0].message = "Mobile number should be number and 10 digits long.";
  }
  if (isLandline) {
    rules[0].min = 10;
    rules[0].max = 10;
    rules[0].pattern = /^(\d *){10}$/;
    rules[0].message = "Landline number should be number  and 10 digits long.";
  }
  if (pincode) {
    rules[0].min = 6;
    rules[0].pattern = /^(\d *){6}$/;
    rules[0].max = 6;
    rules[0].message = "Pin code should be number and 6 digits long.";
  }

  if (isfee) {
    rules[0].min = 3;
    rules[0].pattern = /^\d{0,6}$/;
    rules[0].max = 6;
    rules[0].message =
      "Maximum fee should be number and less than 6 digits long.";
  }
  if (year) {
    rules[0].pattern = /^(\d *){4}$/;
    rules[0].min = 4;
    rules[0].max = 4;
    // rules[0].validator = validateEndDate;
    rules[0].message =
      "Year should be number, 4 digits long and less than current year";
  }

  if (isName && !alphanumeric) {
    rules[0].pattern = /^[A-Za-z_ ]+$/
    rules[0].message =
      "Name should be string";
  }
  if (isExp) {
    rules[0].pattern = /^\d{0,2}$/;

    rules[0].min = 1;
    rules[0].max = 2;
    // rules[0].validator = validateEndDate;
    rules[0].message =
      "Experience should be two digits";
  }
  // if (isAge) {
  //   // rules[0].pattern = /^\d{2}$/;
  //   rules[0].min = 2;
  //   rules[0].max = 2;
  //   rules[0].message = "Age should be number and 1 or 2 digits long.";
  // }
  if (type === "email") {
    rules[0].type = "email";
    rules[0].message = "Enter valid email address";
  }
  if (snacks) {
    // rules[0].pattern = /^\d+(\.\d+)?$/;
    rules[0].min = 3;
    rules[0].max = 5;
    rules[0].message = "Should not be more than 3 digits before decimal point";
  }
  if (ratio1) {
    rules[0].pattern = /^\d{0,1}$/;
    rules[0].message = "Enter ratio";
  }
  if (ratio2) {
    rules[0].pattern = /^\d{0,2}$/;
    rules[0].message = "Enter ratio";
  }

  if(isTitle) {
    rules[0].message = "Title is required";
  }

  // useEffect(() => {
  //   form.setFieldsValue(
  //     {
  //       [rest.name]: rest.defaultValue
  //     },
  //     () => console.log("after")
  //   );
  // }, [rest.defaultValue]);

  return (
    <Col
      xs={
        rest.responsive && window.screen.width < 1920
          ? "8"
          : rest.colSpan || "6"
      }
      style={{paddingLeft: initialLeftPadding}}
    >
      <FormGroup className={`formFlex ${rest.classType}`}>
        {label && (
          <Col
            style={{ paddingLeft: paddingLeftFirst || "0" }}
            xs={label ? (rest.eachSpan ? "4" : "6") : rest.inputbox ? "0" : "6"}
          >
            <Label htmlFor={rest.name} className="formInputName">
              {label}
              {isRequired && label
                ? label === "  " || TooltipHover
                  ? ""
                  : " *"
                : ""}
              {TooltipHover && (
                <span className="information">
                  <Icon path={mdiInformationOutline} />
                  <div className="tooltip-hover" style={{ minWidth: minWidth }}>
                    <span>{TooltipHover}</span>
                  </div>
                </span>
              )}
              {rest.subLabel && <div className="sublabel">{rest.subLabel}</div>}
            </Label>
          </Col>
        )}
        {contact === "contact" ? (
          <>
            <Col
              xs={rest.eachSpan && (rest.fullLength || "4")}
              style={{
                paddingRight: rightPadding || 25,
                paddingLeft: paddingLeft
              }}
            >
              <div className="phoneNumber">
                <span>+91 -</span>
                <Input
                  noValidate
                  id={rest.name}
                  {...rest}
                  type={inputType}
                  invalid={!!error}
                  autoComplete="off"
                  className='form-control-alternative'
                  style={{ border: 0, padding: 0 }}
                  {...form.getFieldProps(rest.name, {
                    validateTrigger: (type === "email" || isMobile) ? "onBlur" : "onChange",
                    initialValue: rest.defaultValue,
                    // valuePropName: rest.defaultValue,
                    rules,
                    onWheel: () => {
                      document.getElementById(rest.name).blur();
                    },

                    // setFieldsValue: () => ({ [rest.name]: rest.defaultValue }),
                    onChange: e => {
                      setContentChanged && setContentChanged(true);
                      if (onChange) {
                        onChange(e.target.value)
                      }
                    }
                  })}
                  // value={rest.defaultValue}
                  // defaultValue={rest.defaultValue}
                  disabled={disabled}
                  rows={inputType === "textarea" && rest.maxHeight}
                // maxLength={isMobile ? 10 : pincode ? 6 : year && 4}
                // onChange={(e)  => onChange && onChange(e.target.value)}
                />
                <FormFeedback style={{position: 'absolute', top: 46}}>
                  {error && error[0] && error[0].includes("_")
                    ? error[0].charAt(0).toUpperCase() +
                    error[0]
                      .slice(1)
                      .split("_")
                      .join(" ")
                    : error}
                </FormFeedback>
                {!error && rest.submit && rest.msg && (
                  <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                    {rest.msg}
                  </div>
                )}
              </div>


            </Col>
          </>
        ) : (
            <Col
              xs={rest.eachSpan && (rest.fullLength || "4")}
              style={{
                paddingRight: rightPadding || 25,
                paddingLeft: paddingLeft
              }}
            >
              <Input
                noValidate
                id={rest.name}
                {...rest}
                type={inputType}
                invalid={!!error}
                autoComplete="off"
                className="form-control-alternative"
                {...form.getFieldProps(rest.name, {
                  validateTrigger: (type === "email" || isMobile) ? "onBlur" : "onChange",
                  initialValue: rest.defaultValue,
                  // valuePropName: rest.defaultValue,
                  rules,
                  onWheel: () => {
                    document.getElementById(rest.name).blur();
                  },

                  // setFieldsValue: () => ({ [rest.name]: rest.defaultValue }),
                  onChange: e => {

                    setContentChanged && setContentChanged(true);
                    if (onChange) {
                      onChange(e.target.value)
                    }
                  }
                })}
                // value={rest.defaultValue}
                // defaultValue={rest.defaultValue}
                disabled={disabled}
                rows={inputType === "textarea" && rest.maxHeight}
              // maxLength={isMobile ? 10 : pincode ? 6 : year && 4}
              // onChange={(e)  => onChange && onChange(e.target.value)}
              />
              {rest.passwordshowhide && (
                <i
                  className={`fa ${
                    rest.showhide ? "fa-eye-slash" : "fa-eye"
                    } password`}
                  onClick={() => rest.showhidefn(!rest.showhide)}
                ></i>
              )}
              <FormFeedback>
                {error && error[0] && error[0].includes("_")
                  ? error[0].charAt(0).toUpperCase() +
                  error[0]
                    .slice(1)
                    .split("_")
                    .join(" ")
                  : error}
              </FormFeedback>
              {!error && rest.submit && rest.msg && (
                <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                  {rest.msg}
                </div>
              )}
              {!error && rest.yearErr && (
                <div
                  style={{
                    color: "red",
                    fontSize: 11,
                    fontWeight: 300,
                    marginTop: 0,
                    marginLeft: 0
                  }}
                >
                  {rest.yearErr}{" "}
                </div>
              )}
            </Col>
          )}
      </FormGroup>
    </Col>
  );
}

FormInput.propTypes = {
  showhide: PropTypes.bool
};

export default FormInput;
