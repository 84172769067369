/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  CardBody,
  Col,
  Row,
  Button,
  Form,
  Input,
  Label,
  CardTitle,
  CardText,
  CardFooter
} from "reactstrap";
import Icon from "@mdi/react";
import { mdiInformationOutline, mdiCloseCircle } from "@mdi/js";
import { createForm } from "rc-form";
import FormInput from "../../../components/FormInput";
import Dropdown from "../../../components/Dropdown";
import Select from "../../../components/Select";
import responseToast from "../../../utils/responseToast";
import RadioButton from "../../../components/RadioButton";
import TimePicker from "../../../components/TimePicker";
import CheckBox from "../../../components/CheckBox";

function Facilities(props) {
  const [languageDropdown, setLanguageDropdown] = useState([]);
  const [activeSchedule, setActiveSchedule] = useState([]);
  const [dropdownDefValue, setDropdownDefValue] = useState('');
  const [courseValue, setCourseValue] = useState('');
  const [courseArr, setCourseArr] = useState([]);
  const [checkedReasonsErr, setCheckedReasonsErr] = useState(false);


  const [ac, setAc] = useState("");
  const [report, setreport] = useState("");
  const [cctv, setCctv] = useState("");
  const [live, setLive] = useState("");
  const [activeService, setActiveService] = useState([]);
  const [fire, setFire] = useState("");
  const [transport, setTransport] = useState("");
  const [snacks, setSnacks] = useState("");
  const [food, setfood] = useState("");
  const [childproof, setchildproof] = useState("");
  const [app, setapp] = useState("");
  const [sleep, setsleep] = useState("");
  const [waitingarea, setwaitingarea] = useState("");
  const [washroom, setwashroom] = useState("");
  const [sickkids, setsickkids] = useState("");
  const [outdoor, setoutdoor] = useState("");
  const [indoor, setindoor] = useState("");
  const [weekendcare, setweekendcare] = useState("");
  const [extendedHrs, setExtendedHrs] = useState("");
  const [extendedTime, setExtendedTime] = useState("");
  const [parking, setparking] = useState("");
  const [specialcare, setspecialcare] = useState("");
  const [hourlyBasis, setHourlyBasis] = useState("");

  const [courses, setcourses] = useState("");


  const [customText, setCustomText] = useState("");
  const [finalSubmit, setFinalSubmit] = useState(false);


  const [submit, setSubmit] = useState(false);





  const [drafts, setDrafts] = useState(false);


  const handleSubmit = e => {
    e.preventDefault();
    setSubmit(true);
    setFinalSubmit(true);
    if (!drafts) {
      props.form.validateFields(async (errors, values) => {
        var checkedReasons = courseArr.filter(k => k.checked && k.label);
        if (!errors && ac && report && cctv && live && fire && transport && snacks && food && childproof && app && sleep && waitingarea && washroom && sickkids && outdoor && indoor && weekendcare && extendedHrs && parking && specialcare && hourlyBasis && courses) {
          if (activeSchedule.length === 0 || activeService.length === 0) {
            setSubmit(true);
            setFinalSubmit(true);

            return;
          }
          if (courses === 'custom' && checkedReasons.length === 0) {
            setSubmit(true);
            setFinalSubmit(true);
            setCheckedReasonsErr(true);
            return;
          }
          if (extendedHrs === 'Yes' && !extendedTime) {
            setSubmit(true);
            setFinalSubmit(true);
            return;
          }
          setSubmit(false);
          setFinalSubmit(false);
          setCheckedReasonsErr(false);
          values.languages_spoken = activeSchedule.map(a => a.id);

          values.ac_rooms = ac;
          values.reports = report;
          values.cctv = cctv;
          values.hourly_basis = hourlyBasis
          values.live_streaming = live;
          values.emergency_options = activeService;
          values.snacks_provided = snacks === "1" ? true : false;

          // values.potty_training = pottyTraining;

          values.sleeping_area_facility = sleep;
          values.waiting_area_for_parents = waitingarea;
          if (extendedHrs === "Yes") {
            values.extended_time = extendedTime;
          }
          if (extendedHrs === "No") {
            values.extended_time = "";
          }

          if (!activeService.includes(1)) {
            values.emergency_details = '';
          }

          // if (app === '0') {
          //   values.app_name = "";
          // }
          values.fire_emergency_contact = fire;
          values.transportation = transport;
          values.provide_food_to_day_care = food;
          values.child_proof = childproof;

          values.parent_teacher_app = app;
          values.washroom_for_children_staff = washroom;
          values.take_care_of_sick_kids = sickkids;
          values.outdoor_play_area = outdoor;
          values.indoor_play_area = indoor;
          values.weekend_care = weekendcare;
          values.car_parking = parking;
          values.facility_for_special_care_kids = specialcare;
          values.courses = courses;
          var rsn = courseArr.filter(k => k.checked && k.label).map(a => a.label);
          values.custom_course_text = rsn.join(',')
          setDrafts(true);
          props.setContentChanged(false);
          if (props.data && props.data.length > 0) {
            values.id = props.data[0].id;
            props.updateFacility({
              data: values,
              cb: err => {
                if (!err) {
                  props.setComponent("Teachers and staff");
                } else {
                  responseToast(err);
                }
              }
            });
          } else
            props.setFacility({
              data: values,
              cb: err => {
                if (!err) {
                  props.setComponent("Teachers and staff");
                } else {
                  responseToast(err);
                }
              }
            });
        }
      });
    }
  };

  useEffect(() => {
    if (props.languages.length > 0) {
      let dropVal = [];
      props.languages.map(dt => dropVal.push({ id: dt.id, name: dt.name }));

      setLanguageDropdown(dropVal);
    }
  }, [props.languages]);

  useEffect(() => {
    if (props.data && props.data.length > 0 && props.languages.length > 0) {
      let activeScheduleData = []
      if (languageDropdown.length > 0) {
        languageDropdown.map(d => {
          if (props.data[0].languages_spoken.includes(d.id)) {
            activeScheduleData.push({ id: d.id, name: d.name })
          }
        })
      }
      setActiveSchedule(activeScheduleData);

    }
  }, [props.languages, props.data]);

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setActiveService(props.data[0].emergency_options);
      props.data[0].ac_rooms ? setAc("1") : setAc("0");
      props.data[0].reports ? setreport("yes") : setreport("no");
      props.data[0].cctv ? setCctv("1") : setCctv("0");
      props.data[0].hourly_basis ? setHourlyBasis("1") : setHourlyBasis("0");


      props.data[0].live_streaming ? setLive("1") : setLive("0");
      props.data[0].transportation ? setTransport("yes") : setTransport("no");
      props.data[0].snacks_provided ? setSnacks("1") : setSnacks("2");
      props.data[0].provide_food_to_day_care ? setfood("1") : setfood("0");
      props.data[0].fire_emergency_contact ? setFire('1') : setFire('0');
      props.data[0].child_proof ? setchildproof("1") : setchildproof("0");
      props.data[0].parent_teacher_app ? setapp("1") : setapp("0");
      props.data[0].sleeping_area_facility ? setsleep("1") : setsleep("0");
      props.data[0].waiting_area_for_parents
        ? setwaitingarea("1")
        : setwaitingarea("0");
      props.data[0].washroom_for_children_staff
        ? setwashroom("1")
        : setwashroom("0");
      props.data[0].take_care_of_sick_kids
        ? setsickkids("1")
        : setsickkids("0");
      props.data[0].outdoor_play_area ? setoutdoor("1") : setoutdoor("0");

      props.data[0].indoor_play_area ? setindoor("1") : setindoor("0");
      props.data[0].weekend_care ? setweekendcare("1") : setweekendcare("0");
      props.data[0].extended_time
        ? setExtendedTime(props.data[0].extended_time)
        : setExtendedTime("");
      props.data[0].extended_time
        ? setExtendedHrs("Yes")
        : setExtendedHrs("No");
      props.data[0].car_parking ? setparking("1") : setparking("0");
      props.data[0].facility_for_special_care_kids
        ? setspecialcare("1")
        : setspecialcare("0");
      props.data[0].courses && setcourses(props.data[0].courses);
      if (props.data[0].courses) {
        var dummyAct = props.data[0].custom_course_text && props.data[0].custom_course_text.split(",");
        let custom = [];
        dummyAct.length > 0 && dummyAct.map(a => {
          a && custom.push({ label: a, checked: true })
        })
        setCourseArr(custom)
      }

      props.form.setFieldsValue(props.data[0]);
    }
  }, [props.data]);


  return (
    <>
      <CardBody style={{ paddingLeft: 50 }}>
        <div className="cardHeader">
          <CardTitle className="cardTitle">Facilities </CardTitle>
          <CardText className="cardProfileText">
            {" "}
            Mention all the facilities provided.
          </CardText>
        </div>
      </CardBody>

      <Form className="form-horizontal" onSubmit={handleSubmit}>
        <CardBody className="formContent">
          <Row className="daySchedule" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Languages spoken *
            </Col>
            <Col className="dayTime" style={{ marginLeft: '-32px' }}>
              <Dropdown
                form={props.form}
                name="language"
                label=""
                xsval="4"
                placeholder='Select Language'
                onChange={val => {
                  props.setContentChanged(true);

                  let dummy = [...activeSchedule];
                  let dummyName = dummy.map(d => d.id)
                  let dropVal = [...languageDropdown];
                  let dropFilter = val && dropVal.filter(dt => dt.id === Number(val) && dt.name);
                  if (val && !dummyName.includes(Number(val))) {
                    dummy.push(dropFilter[0])
                  }
                  setActiveSchedule(dummy);
                  setDropdownDefValue(val);
                }}
                isRequired={activeSchedule.length === 0 && true}
                options={languageDropdown}
                defaultValue={dropdownDefValue}
              />
            </Col>
          </Row>
          {activeSchedule.length > 0 && <Row className="scheduleRow" style={{ marginBottom: 30, marginTop: '-32px' }}>
            <Col xs='5'></Col>
            <Row className="schedule" style={{ paddingLeft: 30, width: "50%" }}>
              {activeSchedule &&
                activeSchedule.map(dt => (
                  <div
                    className={`languageChips`}

                  >
                    <span>{dt.name}</span>
                    <Icon path={mdiCloseCircle} onClick={() => {
                      props.setContentChanged(true);
                      let dummy = [...activeSchedule];
                      let dummyName = dummy.map(d => d.id)
                      if (dummyName.includes(dt.id)) {
                        dummy.splice(dummy.indexOf(dt), 1);
                      }
                      setActiveSchedule(dummy);
                      setDropdownDefValue('')
                    }} />
                  </div>
                ))}

              {submit && activeSchedule.length === 0 && (
                <span
                  className="scheduleText"
                  style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                >
                  Languages is required
                </span>
              )}
            </Row>
          </Row>}

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              AC Rooms *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yess"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={ac === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setAc("1")
                }}
              />
              <RadioButton
                name="Noo"
                label="No"
                xsval="5"
                form={props.form}
                checked={ac === "0"}
                onClick={label => {
                  props.setContentChanged(true); setAc("0")
                }}
              />
              {ac === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}
            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Daily/Monthly report *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yesr"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={report === "yes"}
                onClick={label => {
                  props.setContentChanged(true); setreport("yes")
                }}
              />
              <RadioButton
                name="Nor"
                label="No"
                xsval="5"
                form={props.form}
                checked={report === "no"}
                onClick={label => {
                  props.setContentChanged(true); setreport("no")
                }}
              />
              {report === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>
          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              CCTV *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yes1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={cctv === "1"}
                onClick={label => {
                  props.setContentChanged(true); setCctv("1")
                }}
              />
              <RadioButton
                name="No1"
                label="No"
                xsval="5"
                form={props.form}
                checked={cctv === "0"}
                onClick={label => {
                  props.setContentChanged(true); setCctv("0")
                }}
              />
              {cctv === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>
          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Live Streaming *
              <div className="livestream subText" style={{ paddingRight: 30 }}>
                Will parents be able to view the on going classes live?
              </div>
            </Col>
            {/* <Col xs="2">
              <Label check onClick={() => setLive('1')}>
                <Input type="radio" name="yes2" checked={live === '1'}/>
                yes
              </Label>
            </Col>
            <Col xs="2">
              <Label for="no" onClick={() => setLive('0')}>
                <Input type="radio" name="No2" checked={live === '0'}/>
                No
              </Label>
            </Col> */}
            <Row className="facilityRadio">
              <RadioButton
                name="yes2"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={live === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setLive("1")
                }}
              />
              <RadioButton
                name="No2"
                label="No"
                xsval="5"
                form={props.form}
                checked={live === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setLive("0")
                }}
              />
              {live === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>
          <Row className=" scheduleRow">
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Emergency Services *
              <div className="livestream subText" style={{ paddingRight: 30 }}>
                What all emergency services are provided? If you choose for
                Doctor, please mention the name of the Doctor & Hospital
              </div>
            </Col>
            <Col>
              <Row>
                <Col className="schedule">
                  {props.emergencyService &&
                    props.emergencyService.map(dt => (
                      <div
                        className={`form-control-alternative ${activeService.includes(dt.id) &&
                          "activeSchedule"}`}
                        key={dt.id}
                        onClick={() => {
                          props.setContentChanged(true);
                          let dummy = [...activeService];
                          if (dummy.includes(dt.id)) {
                            dummy.splice(dummy.indexOf(dt.id), 1);
                          } else {
                            dummy.push(dt.id);
                          }
                          setActiveService(dummy);
                        }}
                      >
                        <span>{dt.name}</span>
                      </div>
                    ))}
                  {submit && activeService.length === 0 && (
                    <span
                      className="scheduleText"
                      style={{ color: "red", fontSize: 11, fontWeight: 300 }}
                    >
                      Emergency services is required
                    </span>
                  )}
                </Col>
              </Row>
              {activeService.includes(1) && <Row style={{ marginTop: 20, marginLeft: -30 }}>
                <FormInput
                  form={props.form}
                  name="emergency_details"
                  placeholder="Enter Doctor Details"
                  label=""
                  colSpan="6"
                  setContentChanged={props.setContentChanged}
                />
              </Row>}
            </Col>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Fire emergency contact *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="fireyes"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={fire === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setFire("1")
                }}
              />
              <RadioButton
                name="fireno"
                label="No"
                xsval="5"
                form={props.form}
                checked={fire === "0"}
                onClick={label => {
                  setFire("0")
                  props.setContentChanged(true);

                }}
              />
              {fire === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Transportation *
              <div className="livestream subText" style={{ paddingRight: 30 }}>
                Does your school provide pickup and drop for children
              </div>
            </Col>

            {/* <Col> */}
            <Row className="facilityRadio">
              <RadioButton
                name="transport1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={transport === "yes"}
                onClick={label => {
                  setTransport("yes")
                  props.setContentChanged(true);

                }}
              />
              <RadioButton
                name="transport2"
                label="No"
                xsval="5"
                form={props.form}
                checked={transport === "no"}
                onClick={label => {
                  props.setContentChanged(true);

                  setTransport("no")
                }}
              />
              {transport === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
            {/* </Col> */}
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you provide snacks to preschool and daycare children? *
            </Col>

            <Row className="facilityRadio">
              <RadioButton
                name="snacks1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={snacks === "1"}
                onClick={label => {
                  props.setContentChanged(true);

                  setSnacks("1")
                }}
              />
              <RadioButton
                name="snacks2"
                label="No"
                xsval="5"
                form={props.form}
                checked={snacks === "2"}
                onClick={label => {
                  props.setContentChanged(true);
                  setSnacks("2")
                }}
              />
              {snacks === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you provide food to daycare children? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="food1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={food === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setfood("1")
                }}
              />
              <RadioButton
                name="food2"
                label="No"
                xsval="5"
                form={props.form}
                checked={food === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setfood("0")
                }}
              />
              {food === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Is your facility childproof? *
              <span className="information">
                <Icon path={mdiInformationOutline} />
                <div className="tooltip-hover">
                  <span>Childproofing (also called baby proofing) is the act of making an environment safe for children.</span>
                  <p>Such as: </p>
                  <p>1. Electrical proofing.</p>
                  <p>2. Restricting physical access, water access.</p>
                  <p>3. Fire safety (smoke alarms)</p>
                  <p>4. Covering solid corners with soft materials, no toxic chemical products.</p>
                </div>
              </span>
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="child1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={childproof === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setchildproof("1")
                }}
              />
              <RadioButton
                name="child2"
                label="No"
                xsval="5"
                form={props.form}
                checked={childproof === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setchildproof("0")
                }}
              />
              {childproof === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          {/* <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }}>
              Is potty training required *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="p1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={potty === "1"}
                onClick={label => setpotty('1')}
              />
              <RadioButton
                name="p2"
                label="No"
                xsval="5"
                form={props.form}

                checked={potty === "0"}
                onClick={label => setpotty('0')}
              />
            </Row>
          </Row> */}

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you have parent-teacher app? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="app1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={app === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setapp("1")
                }}
              />
              <RadioButton
                name="app2"
                label="No"
                xsval="5"
                form={props.form}
                checked={app === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setapp("0")
                }}
              />
              {app === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>
          {app === "1" && (
            <Row style={{ marginTop: '-15px', marginLeft: '-30px' }}>
              <Col xs="5" style={{ paddingLeft: 30 }}>
              </Col>
              <Col>
                <div
                  className="dayTime"
                  style={{ display: "flex", marginLeft: -20 }}
                >
                  <FormInput
                    form={props.form}
                    name="parent_teacher_app_name"
                    placeholder="Name of the App"
                    label=""
                    colSpan="6"
                    setContentChanged={props.setContentChanged}

                  />
                </div>
              </Col>
            </Row>
          )}
          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you have sleeping area & bed for children? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="sl1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={sleep === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setsleep("1")
                }}
              />
              <RadioButton
                name="sl2"
                label="No"
                xsval="5"
                form={props.form}
                checked={sleep === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setsleep("0")
                }}
              />
              {sleep === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Is there a waiting area for parents? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="w1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={waitingarea === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setwaitingarea("1")
                }}
              />
              <RadioButton
                name="w2"
                label="No"
                xsval="5"
                form={props.form}
                checked={waitingarea === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setwaitingarea("0")
                }}
              />
              {waitingarea === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Seperate washroom for children and staff? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="wash1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={washroom === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setwashroom("1")
                }}
              />
              <RadioButton
                name="wash2"
                label="No"
                xsval="5"
                form={props.form}
                checked={washroom === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setwashroom("0")
                }}
              />
              {washroom === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30, paddingRight: 0 }} className="formInputName">
              Do you take care of sick kids for few hours? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yesssr"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={sickkids === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setsickkids("1")
                }}
              />
              <RadioButton
                name="Nosssr"
                label="No"
                xsval="5"
                form={props.form}
                checked={sickkids === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setsickkids("0")
                }}
              />
              {sickkids === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>
          <Row style={{ marginBottom: 20, paddingLeft: 15 }}>
            <FormInput
              form={props.form}
              name="restrictions"
              placeholder="Mention any restrictions from your end"
              label="Any Restrictions"
              type="textarea"
              subLabel="Ex: we do not provide special care"
              colSpan="10"
              TooltipHover="Enter restrictions with comma separator"
              absolute="absolute"
              left="auto"
              right="2px"
              paddingLeft={7}
              isRequired={false}
              // eachSpan="4"
              classtype="justifyStart"
              setContentChanged={props.setContentChanged}

            />
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you have outdoor play area? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="o1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={outdoor === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setoutdoor("1")
                }}
              />
              <RadioButton
                name="o2"
                label="No"
                xsval="5"
                form={props.form}
                checked={outdoor === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setoutdoor("0")
                }}
              />
              {outdoor === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you have indoor play area? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yesinr"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={indoor === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setindoor("1")
                }}
              />
              <RadioButton
                name="Noinr"
                label="No"
                xsval="5"
                form={props.form}
                checked={indoor === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setindoor("0")
                }}
              />
              {indoor === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you provide weekend care? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yeswr"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={weekendcare === "1"}
                onClick={label => {
                  props.setContentChanged(true); setweekendcare("1")
                }}
              />
              <RadioButton
                name="Nowr"
                label="No"
                xsval="5"
                form={props.form}
                checked={weekendcare === "0"}
                onClick={label => {
                  props.setContentChanged(true); setweekendcare("0")
                }}
              />
              {weekendcare === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: "30px" }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you provide extended hours? *
            </Col>
            {/* <Col xs="8"> */}
            <Row className="facilityRadio">
              <RadioButton
                name="extend1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={extendedHrs === "Yes"}
                onClick={label => {
                  props.setContentChanged(true);
                  setExtendedHrs(label)
                }}
              />
              <RadioButton
                name="extend2"
                label="No"
                xsval="5"
                form={props.form}
                checked={extendedHrs === "No"}
                onClick={label => {
                  props.setContentChanged(true);
                  setExtendedHrs("No")
                }}
              />
              {extendedHrs === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
            {/* </Col> */}
          </Row>
          {extendedHrs === "Yes" && (
            <Row style={{ marginTop: 30 }}>
              <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
                Extended hours
              </Col>
              <Col>
                <div className="monthlyFee">
                  <li>Extension of time</li>
                </div>
                <div
                  className="dayTime"
                  style={{ display: "flex", marginLeft: -5 }}
                >
                  {/* <FormInput
                    placeholder="Till 5:30 PM"
                    colSpan="4"
                    form={props.form}
                    name="extended_time"
                    label=""
                    isRequired={!extendedTime? true : false}
                    // onChange={val => setExtendedTime(val)}
                    // submit={submit}
                  /> */}
                  <TimePicker
                    placeholder="Till 5:30 PM"
                    colSpan="4"
                    name="extended_time"
                    form={props.form}
                    isRequired={true}
                    onChange={val => {
                      props.setContentChanged(true);
                      setExtendedTime(val)
                    }}
                    submit={submit && !extendedTime}
                    defaultValue={extendedTime}
                  />

                  {/* <FormInput
                    form={props.form}
                    name="extended_price"
                    placeholder="₹ 2,000"
                    label=""
                    colSpan="4"
                  /> */}
                </div>
              </Col>
            </Row>
          )}
          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you have car parking for parents? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yecsr"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={parking === "1"}
                onClick={label => {
                  setparking("1")
                  props.setContentChanged(true);
                }}
              />
              <RadioButton
                name="Nocr"
                label="No"
                xsval="5"
                form={props.form}
                checked={parking === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setparking("0")
                }}
              />
              {parking === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30, paddingRight: 0 }} className="formInputName">
              Do you have facility for special care kids? *{" "}
              <span className="information">
                <Icon path={mdiInformationOutline} />
                <div className="tooltip-hover">
                  <span>Specially abled kids</span>
                </div>
              </span>
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="yessc"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={specialcare === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setspecialcare("1")
                }}
              />
              <RadioButton
                name="nosc"
                label="No"
                xsval="5"
                form={props.form}
                checked={specialcare === "0"}
                onClick={label => {
                  props.setContentChanged(true);
                  setspecialcare("0")
                }}
              />
              {specialcare === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>

          <Row className="extended" style={{ marginBottom: 30 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Do you provide daycare at hourly basis? *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="hourly1"
                label="Yes"
                form={props.form}
                xsval="5"
                checked={hourlyBasis === "1"}
                onClick={label => {
                  props.setContentChanged(true);
                  setHourlyBasis("1")
                }}
              />
              <RadioButton
                name="hourly2"
                label="No"
                xsval="5"
                form={props.form}
                checked={hourlyBasis === "0"}
                onClick={label => {
                  props.setContentChanged(true); setHourlyBasis("0")
                }}
              />
              {hourlyBasis === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>


          <Row className="extended" style={{ marginBottom: 20 }}>
            <Col xs="5" style={{ paddingLeft: 30 }} className="formInputName">
              Courses *
            </Col>
            <Row className="facilityRadio">
              <RadioButton
                name="course1"
                label="Montessori"
                form={props.form}
                xsval="5"
                checked={courses === "montessori"}
                onClick={label => {
                  props.setContentChanged(true);
                  setcourses("montessori")
                }}
              />
              <RadioButton
                name="course2"
                label="International"
                xsval="5"
                form={props.form}
                checked={courses === "international"}
                onClick={label => {
                  props.setContentChanged(true);
                  setcourses("international")
                }}
              />
              <RadioButton
                name="course3"
                label="Integrated"
                form={props.form}
                xsval="5"
                checked={courses === "integrated"}
                onClick={label => {
                  props.setContentChanged(true);
                  setcourses("integrated")
                }}
              />
              <RadioButton
                name="course4"
                label="Custom"
                xsval="5"
                form={props.form}
                checked={courses === "custom"}
                onClick={label => {
                  props.setContentChanged(true);
                  setcourses("custom")
                }}
              />
              {courses === '' && submit && <div style={{ color: "red", fontSize: 11, fontWeight: 300, marginLeft: 15 }} > Select any one of the fields</div>}

            </Row>
          </Row>
          {/* {courses === "custom" && (
            <Row className="scheduleRow">
              <Col xs="5"></Col>
              {courseArr.length > 0 && <Col xs='8'></Col>}
            </Row>
          )} */}
          {courses === "custom" && (
            <Row className="scheduleRow">
              <Col xs="5" className="formInputName" style={{ paddingLeft: 30 }}>Custom courses</Col>
              <Col
                xs="5"
                style={{
                  paddingLeft: 5,
                  paddingTop: 10,
                  display: "flex",
                  flexFlow: "row wrap"
                }}
              >
                {courseArr &&
                  courseArr.map((k, i) => (
                    <CheckBox
                      label={k.label}
                      name={`reason${i}`}
                      isRequired={false}
                      xsval="12"
                      key={`reason${i}`}
                      onClick={() => {
                        props.setContentChanged(true);
                        let value = [...courseArr];
                        value[i].checked = !value[i].checked;
                        setCourseArr(value);
                        setCheckedReasonsErr(false);
                      }}
                      form={props.form}
                      checked={k.checked}
                    />
                  ))}

              </Col>
            </Row>
          )}
          {courses === "custom" && (
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col xs="5"></Col>
              {courses === "custom" && (
                <Col xs="5" style={{ marginTop: -20, paddingLeft: 0, marginBottom: 30 }}>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <Col xs="5">
                      <Input
                        type="text"
                        value={courseValue}
                        onChange={e => {
                          props.setContentChanged(true);
                          setCourseValue(e.target.value)
                        }}
                        placeholder="Enter course"
                      />
                    </Col>
                    <Col xs="3" style={{ paddingLeft: 0 }}>
                      <Button
                        // type="links"
                        className="addActivity"
                        onClick={() => {
                          if (courseValue) {
                            props.setContentChanged(true);
                            var actD = [...courseArr];
                            var actName = actD.map(a => a.label);

                            courseValue.split(",").map(d => {
                              if (!actName.includes(d)) {
                                actD.push({ label: d, checked: true });
                              }
                            });
                            setCheckedReasonsErr(false)
                            setCourseArr(actD);
                            setCourseValue("");
                          }
                        }}
                        style={{ display: "flex", alignItems: 'center' }}
                      >
                        <div className="plusIcon1">
                          <span class="iconify" data-icon="fe:plus" data-inline="false"></span>
                        </div>
                        <span>Add course</span>
                      </Button>
                    </Col>
                  </div>
                  {checkedReasonsErr && (
                    <Col style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                      Enter course
                  </Col>
                  )}
                </Col>
              )}
            </Row>
          )}

          <Row style={{ marginBottom: 20 }}>
            <FormInput
              form={props.form}
              name="additional_facilities"
              placeholder="Add Additional facilities if provided"
              label="Additional facilities"
              type="textarea"
              TooltipHover="Please enter additional facilities with comma seperator"
              top="0px"
              colSpan="10"
              isRequired={false}
              classtype="justifyStart"
              paddingLeftFirst={15}
              paddingLeft={15}
              setContentChanged={props.setContentChanged}

            />
          </Row>
        </CardBody>
        <CardFooter>
          <Row className="formFlex" style={{ justifyContent: "center", margin: 0 }}>
            <Col xs=""></Col>
            <Col xs="8">
              <Button
                onClick={() => props.updateBeforeComponent("Contact details")}
                color="primary"
              >
                Back
              </Button>
              <Button type="submit" disabled={drafts}>
                {props.data && props.data.length > 0 ? "Update" : "Save"} and
                Continue
              </Button>
              {finalSubmit && (
                <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                  Some fields are not filled
                </div>
              )}
              {/* <Button
                onClick={() => props.data.length > 0? props.setComponent('Teachers and staff'): !drafts && props.setComponent('Teachers and staff')}
                disabled={props.data.length > 0 ? false : drafts}
              >
                Continue
              </Button> */}
            </Col>
          </Row>
        </CardFooter>
      </Form>
    </>
  );
}

Facilities.propTypes = {};

export default createForm()(Facilities);
