/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from "react";
import axios from "../../utils/axios";
import * as reducerConstants from "./constants.js";
import toast from "../../utils/toast";
const TOAST = {};

const initalState = {
  data: [],
  globalData: {},
  lastUpdated: 0,
  isLoading: true
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case reducerConstants.LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case reducerConstants.FETCH_SUCCESS:
        const nextData = Array.isArray(state.data) ? [...state.data] : {...state.data};
        const nextGlobalData = { ...state.globalData };
      return {
        ...state,
        data: (payload.data || payload) || nextData,
        globalData: (typeof payload.count === 'object' ? payload.count : {count: payload.count}) || nextGlobalData,

        isLoading: false
      };
    case reducerConstants.DELETE_SUCCESS: {
      const nextData = Array.isArray(state.data) ? [...state.data] : state.data;
      delete nextData[payload.index];
      return {
        ...state,
        isLoading: false,
        data: nextData
      };
    }
    case reducerConstants.UPDATE_SUCCESS: {
      const { index, formData, ...updatedData } = payload;
      let forms = {};
      const nextData = Array.isArray(state.data) && [...state.data];
      if (formData) {
        forms = formData;
      } else {
        nextData[0] = { ...nextData[0], ...updatedData };
      }
      return {
        ...state,
        isLoading: false,
        data: formData ? forms : nextData
      };
    }
    case reducerConstants.ADD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload ? [{ ...payload }] : state.data
      };
    }
    default:
      return state;
  }
};

const useCommonCRUD = ({ CRUDaseUrl, getParams = {} }) => {
  const [reducerData, dispatch] = useReducer(reducer, initalState);
  const setLoading = (payload = true) =>
    dispatch({ type: reducerConstants.LOADING, payload });

  const fetchData = data => {
    if (!reducerData.isLoading) {
      setLoading();
    }
    if (
      CRUDaseUrl.read !== "providers/working-day/" &&
      CRUDaseUrl.read !== "providers/schedule-for-the-day/" &&
      CRUDaseUrl.read !== "providers/yearly-calendar/"
    )
      CRUDaseUrl.read &&
        axios
          .get(CRUDaseUrl.read, getParams)
          .then(res => {
            if (res.data && res.data.form_filled === false) {
              res.data.data.push({ form_filled: res.data.form_filled });
              return res.data.data;
            } else  if(res.data.count) {
              return res.data
            } else return res.data.data;
          })
          .then(payload => {
            dispatch({
              type: reducerConstants.FETCH_SUCCESS,
              payload
            });
          })
          .catch(err => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.form_filled === false
            ) {
              dispatch({
                type: reducerConstants.FETCH_SUCCESS,
                payload: [{ form_filled: err.response.data.form_filled }]
              });
            }
            CRUDaseUrl.read === "providers/" ? null : toast.error(
              (err.response && err.response.data.message) ||
              "Something went wrong",
              TOAST,
              CRUDaseUrl
            );

            setLoading(false);
          });
  };

  const deleteEntry = ({ id, url, cb }) => {
    setLoading();
    axios
      .delete(`${CRUDaseUrl.delete}?id=${id}`)
      .then(payload => {
        toast.success("Deleted successfully", TOAST, "DELETE");
        dispatch({
          type: reducerConstants.DELETE_SUCCESS,
          payload
        });
        // cb && cb();
      })
      .catch(err => {
        setLoading(false);
        // cb && cb(err.response);
      });
  };

  const addNewEntry = ({ data, getDataObj, cb, successCB }) => {
    setLoading();
    axios
      .post(CRUDaseUrl.create, data)
      .then(res => {
        if (typeof res.data.data == "number") {
          return { id: res.data.data };
        } else {
          return res.data.data;
        }
      })
      .then(payload => {
        toast.success("Added successfully", TOAST, "ADD");
        dispatch({
          type: reducerConstants.ADD_SUCCESS,
          payload: { ...(getDataObj ? getDataObj(data) : data), ...payload }
        });
        successCB && successCB(payload);
        cb && cb();
      })
      .catch(err => {
        setLoading(false);
        cb && cb(err.response);
      });
  };

  const updateEntry = async ({ data: nextData, cb }) => {
    const { id, banner_id,noToast, index, ...data } = nextData;
    if(data.idValue) {
      data.id = id;
    }
    setLoading();
    axios
      .put(`${CRUDaseUrl.update}${!data.idValue ? (id ? id + "/" : "") : ''}`, data)
      .then(res => res.data.data)
      .then(payload => {
        !noToast && toast.success("Updated successfully", TOAST, "UPDATE");
        dispatch({
          type: reducerConstants.UPDATE_SUCCESS,
          payload:
            CRUDaseUrl.update === "providers/provider-update/"
              ? { formData: payload }
              : { ...nextData, ...(payload || {}) }
        });
        cb && cb();
      })
      .catch(err => {
        setLoading(false);
        cb && cb(err.response);
      });
  };

  const fetchSpecificData = ({ data, cb }) => {

    axios
      .get(`${CRUDaseUrl.readId}${data}`, getParams)
      .then(res =>  (res.data.count ? res.data : res.data.data))
      .then(payload => {
        dispatch({
          type: reducerConstants.FETCH_SUCCESS,
          payload
        });
        cb && cb(payload);
      })

      .catch(err => {
        if (CRUDaseUrl.readId !== "providers/") {
          toast.error(
            (err.response && err.response.data.message) || "Something went wrong",
            TOAST,
            CRUDaseUrl
          );
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return [
    reducerData,
    deleteEntry,
    addNewEntry,
    updateEntry,
    fetchSpecificData,
    fetchData
  ];
};

export default useCommonCRUD;
