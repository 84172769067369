import toast from "./toast";
import responseErrorParser from "./responseErrorParser";

function responseToast(err) {
  let msg = err && err.data && err.data.message;
  if (err && err.data && err.data.data) {
    msg += ` ${Object.values(responseErrorParser(err && err.data && err.data.data)).join()}`;
  }
  else if(typeof err === 'string')
  {
    msg = err;
  }
  toast.error(msg, {}, "QU");
}

export default responseToast;
