/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Button, CardText } from "reactstrap";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import illustration from "../../../assets/img/dashboardImage2.svg";
import location from "../../../assets/img/dashboard/school tour.svg";
import map from "../../../assets/img/dashboard/map.svg";
import enquire from "../../../assets/img/dashboard/enquire.svg";
import star from "../../../assets/img/dashboard/star.svg";
import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PROVIDERS, API_PARENTS } from "../../../config/apiEndpoints";
import Loader from "../../../components/Loader";

const percentageData = {
  basic_info: 0,
  location: 12,
  contact_details: 26,
  facilities: 45,
  faculties: 59,
  media: 85
};

function Dashboard(props) {
  const [reportedDate, setReportedDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const [dashboardShow, setDashboardShow] = useState(false);
  const [showHeaderText, setShowHeaderText] = useState(false);
  const [countData, setCountData] = useState({});

  const [providerSteps] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}provider-steps/`
    }
  });

  const [
    getBasicInfo
  ] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}provider-basic-info/`,
    }
  });


  const [getCounts] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PARENTS}dashboard-api/`
    }
  });

  const [remarks] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PROVIDERS}provider-remarks/`
    }
  });

  useEffect(() => {
    setReportedDate(moment(countData.onboard_date).format("DD MMM, YYYY"));
  }, [countData]);

  useEffect(() => {
    if (providerSteps && providerSteps.data) {
      let objKeys = Object.keys(percentageData);
      // const datas = {
      //   basic_info: true,
      //   location: true,
      //   contact_details: false,
      //   facilities: true,
      //   faculties: true,
      //   media: true
      // };
      for (let i = 0; i < objKeys.length; i++) {
        if (providerSteps.data[objKeys[i]] === false) {
          setPercentage(percentageData[objKeys[i]]);
          break;
        }
      }
    }
  }, [providerSteps]);

  useEffect(() => {
    if (getCounts && getCounts.data) {
      setCountData(getCounts.data);
    }
  }, [getCounts]);

  useEffect(() => {
    if (!percentage) {
      setDashboardShow(true);
      setShowHeaderText(true);
    } else {
      setDashboardShow(false);
      setShowHeaderText(false);
    }
  }, [percentage]);

  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar {...props} brandText="Kiddenz" />
      </div>
      {console.log(getBasicInfo.data)}
      <Loader isOpen={providerSteps.isLoading || getCounts.isLoading} />
      {(!providerSteps.loading || !getCounts.isLoading) && (
        <div
          className="dashboardContainer"
          style={{ padding: !dashboardShow && "115px 45px" }}
        >
          <div className="dashboardHeader">
            <CardText className="dashboardHeader-title">
              Welcome back {getBasicInfo.data && getBasicInfo.data[0] && getBasicInfo.data[0].child_care_name && `, ${getBasicInfo.data[0].child_care_name}`}
            </CardText>
            {showHeaderText && (
              <CardText className="dashboardHeader-text">
                Hey avail exsiting discounts and offers by choosing our
                privileged member plans, talk to our admin to know more about it.
              </CardText>
            )}
            <img className="dashboardHeader-img" src={illustration} alt="" />
          </div>
          <h2 className="dashboardHeader-title">
            Report <span> Onboarded on {reportedDate}</span>
          </h2>
          {dashboardShow && remarks && remarks.data.length > 0 && (
            <div className="dashboardBody">
              <h5>
                <div className="circle"></div>
                <span>
                  Admin has reviewed and given changes for your profile, Kindly
                  update the same
                </span>
              </h5>
              <h6
                onClick={() =>
                  props.history.push("profile?activeTab=profile&open=providers")
                }
              >
                Update Changes
              </h6>
            </div>
          )}
          {dashboardShow ? (
            <div className="dashboardReport">
              <div className="dashboardReport-item school">
                <img src={location} alt="" />
                <div className="dashboardReport-content">
                  <h1>{countData.schedule_tour_today_count}</h1>
                  <div className="reportValue">
                    Scheduled Tour requests
                    <h2>
                      Total:<span> {countData.schedule_tour_count}</span>
                    </h2>
                  </div>
                  <div className="dashboardReport-desc">
                    You can respond to requests - accept, decline.
                    <br /> Send confirmation for parents
                    <span
                      onClick={() =>
                        props.history.push(
                          "scheduled-tour?activeTab=scheduledTour&open=schoolTour"
                        )
                      }
                    >
                      View all
                    </span>
                  </div>
                </div>
              </div>
              <div className="dashboardReport-item cancel">
                <img src={map} alt="" />
                <div className="dashboardReport-content">
                  <h1 style={{ color: "#EC716C" }}>
                    {countData.cancelled_tour_today_count}
                  </h1>
                  <div className="reportValue">
                    Cancelled Tours
                    <h2>
                      Total:<span> {countData.cancelled_tour_count}</span>
                    </h2>
                  </div>
                  <div className="dashboardReport-desc">
                    If any of the parents cancel the requests, you can view them
                    here
                    <span
                      onClick={() =>
                        props.history.push(
                          "user-cancelled-tour?activeTab=UserCancelledTour&open=schoolTour"
                        )
                      }
                    >
                      View all
                    </span>
                  </div>
                </div>
              </div>
              <div className="dashboardReport-item enquiry">
                <img src={enquire} alt="" />
                <div className="dashboardReport-content">
                  <h1 style={{ color: "#3B74E7" }}>
                    {" "}
                    {countData.enquiry_today_count}
                  </h1>
                  <div className="reportValue">
                    Enquiries
                    <h2>
                      Total:<span> {countData.enquiry_count}</span>
                    </h2>
                  </div>
                  <div className="dashboardReport-desc">
                    Any parent Enquiring information related to school - connect
                    to parent, price requests
                    <span
                      onClick={() =>
                        props.history.push(
                          "enquiry?activeTab=enquiries&open=enquiry"
                        )
                      }
                    >
                      View all
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="dashboardReport-item review">
                <img src={star} alt="" />
                <div className="dashboardReport-content">
                  <h1 style={{ color: "#F8C53B" }}>
                    {countData.review_today_count}
                  </h1>
                  <div className="reportValue">
                    Reviews
                    <h2>
                      Total:<span> {countData.review_count}</span>
                    </h2>
                  </div>
                  <div className="dashboardReport-desc">
                    User reviews will be shown here - you can accept, cancel and
                    reprot reviews here
                    <span
                      onClick={() =>
                        props.history.push("review?activeTab=reviews")
                      }
                    >
                      View all
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          ) : (
            <div className="dashboardStatus">
              <div className="dashboardStatus-left">
                <div className="dashboardStatus-graph">
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    strokeWidth={2}
                    styles={buildStyles({
                      textColor: "red",
                      pathColor: "#60B947",
                      trailColor: "#DBDEE5"
                    })}
                  />
                </div>
                <h3>
                  Complete your <span>“Care Profile”</span> now to make it easy
                  for the parents to find you and get more responses for your
                  school
                </h3>
              </div>
              <Button
                className="btn-compare"
                onClick={() =>
                  props.history.push("profile?activeTab=profile&open=providers")
                }
              >
                Complete Profile
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
