/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import ScheduleTour from "../../../components/ScheduletourCard";
import Flex from "../../../views/Pages/SchoolProfile/flex.js";
import Modal from "../../../components/CustomModal";
import { createForm } from "rc-form";
import profilePic from "../../../assets/img/user2.svg";
import EmptyState from "../../../components/EmptyState";
import emptyImage from "../../../assets/img/illustrtns_school tour.svg";
import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PARENTS } from "../../../config/apiEndpoints";
import Loader from "../../../components/Loader";
import responseToast from "../../../utils/responseToast";

import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import {
  DropdownMenu,
  DropdownMenuItem
} from "../../../components/CustomDropdown";
const DROPDOWN_CONFIG = [
  // {
  //   id: "06",
  //   text: "Date",
  //   key: "Date"
  // },
  {
    id: "01",
    text: "Old to New",
    key: "ASC"
  },

  {
    id: "04",
    text: "New to Old",
    key: "DESC"
  }
];

const SCHEDULE_DATA = [
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    showChild: false,
    img: profilePic,
    child: [
      {
        name: "Dia",
        age: "4",
        joining: "Immediate",
        type_of_care: "Daycare & preschool",
        food_required: "Yes",
        schedule: "Flexible",
        medication: "Pansac: Before breakfast",
        allergies: "Beans",
        transportaion: "Yes",
        childcare_experience: "None",
        specific_needs: [
          "My child needs assitance during eating",
          "Potty training is not done, please assist during potty"
        ],
        showEachChild: false
      },
      {
        name: "Aryan",
        age: "2",
        joining: "Immediate",
        type_of_care: "Daycare",
        food_required: "No",
        schedule: "Flexible",
        medication: "Pansac: Before breakfast",
        allergies: "Beans",
        transportaion: "No",
        childcare_experience: "Yes",
        specific_needs: [],
        showEachChild: false
      }
    ]
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  },
  {
    name: "Rohan is visiting the school on",
    time: "Monday, Nov 1st 5:30PM Tour ID: BKS29018",
    child: [],
    img: profilePic,

    showChild: false
  }
];

function Dashboard(props) {
  const [detailsPopUp, setDetailsPopUp] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [order, setOrder] = useState("");
  const [acceptDetails, setAcceptDetails] = useState("");
  const [oldDate, setOldDate] = useState("");
  const [oldTime, setOldTime] = useState("");
  const [changeDateId, setChangedDateId] = useState("");
  const [tourId, setTourId] = useState("");
  const [declineId, setDeclineId] = useState("");
  const [fromValue, setFromValue] = useState("");
  const [fromValueErr, setFromValueErr] = useState("");
  const [toValueErr, setToValueErr] = useState("");
  const [toValue, setToValue] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [dateValueErr, setDateValueErr] = useState("");
  const [timeValue, setTimeValue] = useState("");
  const [timeValueErr, setTimeValueErr] = useState("");
  const [declineValue, setDeclineValue] = useState("");
  const [declineValueErr, setDeclineValueErr] = useState("");
  const [parentName, setParentName] = useState("");

  const [getScheduledTour, , , , getScheduledTourByFilter] = useCommonCRUD({
    CRUDaseUrl: {
      read: `${API_PARENTS}get-scheduled-tour/?sort_by=${order}${
        fromValue && toValue ? `&from_date=${fromValue}&to_date=${toValue}` : ""
      }&search=`,
      readId: `${API_PARENTS}get-scheduled-tour/?sort_by=${order}${
        fromValue && toValue ? `&from_date=${fromValue}&to_date=${toValue}` : ""
      }&search=`
    }
  });

  const [, , , updateToursApi] = useCommonCRUD({
    CRUDaseUrl: {
      update: `${API_PARENTS}update-scheduled-tour/`
    }
  });

  const [, , , changeDateApi] = useCommonCRUD({
    CRUDaseUrl: {
      update: `${API_PARENTS}change-schedule-tour/`
    }
  });

  const dropdownRef = useRef(null);
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      dropdownRef.current.contains(e.target)
    ) {
      return 0;
    }
    setOpenDropdown(false);
    return 0;
  };

  const toggleChild = (data, index, show, childIndex, child) => {
    let dataCopy = [...groupedData];
    if (child) {
      dataCopy.map((dt, i) => {
        if (dt.data === data) {
          dt.data.map((a, i) => {
            if (i === index) {
              console.log(a);
              a.child.map((d, id) => {
                if (id === childIndex) {
                  d.showEachChild = !show;
                } else {
                  d.showEachChild = false;
                }
              });
            }
          });
        }
      });
    } else
      dataCopy.map((dt, i) => {
        if (dt.data === data) {
          dt.data.map((a, i) => {
            if (i === index) {
              a.showChild = !show;
            }
          });
        }
      });
    setGroupedData(dataCopy);
  };

  const applyFilter = () => {
    console.log(fromValue, toValue);

    if (!fromValue || !toValue) {
      if (!fromValue) setFromValueErr(true);
      if (!toValue) setToValueErr(true);
      return;
    }
    getScheduledTourByFilter({
      data: searchValue,
      cb: () => {
        setFromValueErr("");
        setToValueErr("");
        setDetailsPopUp("");
        setFromValue("");
        setToValue("");
      }
    });
  };

  const changeDate = () => {
    if (!dateValue || !timeValue) {
      if (!dateValue) setDateValueErr(true);
      if (!timeValue) setTimeValueErr(true);
      return;
    }
    setDateValueErr("");
    setTimeValueErr("");
    setDetailsPopUp("changeTour");
  };

  const declineRequest = () => {
    if (!declineValue) {
      setDeclineValueErr(true);
      return;
    }
    updateTour(declineId, "decline");
  };

  const updateTour = (id, status) => {
    let updateData = {};
    updateData.id = id;
    updateData.idValue = id;
    updateData.status = status;
    if (status === "decline") {
      updateData.remarks = declineValue;
    }
    updateToursApi({
      data: updateData,
      cb: err => {
        if (!err) {
          if (status === "decline") {
            setDeclineValueErr("");
            setDetailsPopUp("");
            setDeclineValue("");
          } else setDetailsPopUp("accept");
          getScheduledTourByFilter({ data: searchValue });
        } else {
          responseToast(err);
        }
      }
    });
  };

  useEffect(() => {
    if (
      getScheduledTour &&
      getScheduledTour.data &&
      getScheduledTour.data.length > 0
    ) {
      let scheduleData = [];
      getScheduledTour.data.map(d =>
        scheduleData.push({
          id: d.id,
          name:
          `${d.parent_name} is visiting the school on`,
          date: d.date,
          old_date: d.date,
          old_time: d.time,
          tour_id: d.tour_id,
          parent_name: d.parent_name,
          time: `${moment(`${d.date} ${d.time}`).format(
            "dddd, MMM Do hh:mmA"
          )} Tour ID: ${d.tour_id}`,
          showChild: false,
          img:
            d.provider_pic && d.provider_pic[0] && d.provider_pic[0].media_url,
          child: d.child_info
        })
      );
      setData(scheduleData);
    } else setData([]);
  }, [getScheduledTour]);

  useEffect(() => {
    let groups = {};
    for (var i = 0; i < data.length; i++) {
      var groupName = data[i].date;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(data[i]);
    }
    let myArray = [];
    for (let groupName in groups) {
      myArray.push({ date: groupName, data: groups[groupName] });
    }
    setGroupedData(myArray);
  }, [data]);

  useEffect(() => {
    getScheduledTourByFilter({ data: searchValue });
  }, [order]);

  useEffect(() => {
    if (fromValue && toValue) {
      if (new Date(fromValue) > new Date(toValue)) {
        setToValue("");
      }
    }
  }, [fromValue, toValue]);

  useEffect(() => {
    getScheduledTourByFilter({
      data: searchValue
    });
}, [searchValue]);

  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar
          {...props}
          brandText="Kiddenz"
          search
          searchValue={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>

      <Loader isOpen={getScheduledTour.isLoading} />

      {!getScheduledTour.isLoading && (
        <div className="dashboardContainer">
          <div id="scheduleTour">
            <div className="mainHeading spaceBetweenAlignCenter">
              <div>
                <h3>Scheduled Tours</h3>
                <span>
                  You can respond to requests - accept, decline. Send
                  confirmation for parents
                </span>
              </div>
              <ul className="filterBy" ref={dropdownRef}>
                <li onClick={() => setOpenDropdown(!openDropdown)}>
                  {" "}
                  View By{" "}
                  <i
                    className={
                      openDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down"
                    }
                    style={{ marginLeft: 10 }}
                  />
                </li>
                {openDropdown && (
                  <DropdownMenu top="188px" left="78%" width="20%">
                    {DROPDOWN_CONFIG.map(items => (
                      <DropdownMenuItem
                        key={items.id}
                        className={dropdownValue === items.key ? "active" : ""}
                        onClick={e => {
                          setDropdownValue(items.key);
                          if (items.key !== "Date") {
                            setOrder(items.key);
                          }
                          setDetailsPopUp("dateFilter");
                          setOpenDropdown(false);
                        }}
                      >
                        {items.text}

                        <span
                          className="iconify"
                          data-icon="mdi-light:check"
                          data-inline="false"
                        />
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenu>
                )}
              </ul>
            </div>
            {groupedData.length > 0 ? (
              <Flex column>
                {groupedData.map((a, index) => (
                  <>
                    <h3>{moment(a.date).format("MMM DD, YYYY")}</h3>
                    <Flex justifyBetween>
                      {" "}
                      {a.data.map((d, i) => (
                        <Flex column >
                          <ScheduleTour
                            name={d.name}
                            time={d.time}
                            classType="scheduleTour"
                            childData={d.child}
                            childDetailType={d.showChild ? "active" : ""}
                            toggleChild={() =>
                              toggleChild(a.data, i, d.showChild)
                            }
                            toggleEachChild={(index, childShow) => {
                              toggleChild(a.data, i, childShow, index, true);
                            }}
                            img={d.img}
                            acceptRequest={() => {
                              setAcceptDetails(d.time);
                              updateTour(d.id, "accept");
                            }}
                            changeDate={() => {
                              setOldDate(d.old_date)
                              setOldTime(d.old_time)
                              setChangedDateId(d.id)
                              setTourId(d.tour_id)
                              setParentName(d.parent_name)
                              setDetailsPopUp("changeDate");
                            }}
                            declineRequest={() => {
                              setDetailsPopUp("decline");
                              setDeclineId(d.id);
                            }}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  </>
                ))}
              </Flex>
            ) : (
              <EmptyState
                text="Scheduled Tours"
                desc={
                  dropdownValue === "Date"
                    ? "There are no school tours for applied filter"
                    : "There are no school tours scheduled for your school, keep a check on this space to know more"
                }
                emptyImage={emptyImage}
              />
            )}
          </div>
        </div>
      )}

      {detailsPopUp === "accept" && (
        <Modal
          type={"success"}
          successHeading={"Request has been accepted"}
          successSubHeading={"We will send a confirmation mail to the parent"}
          successHandler={() => setDetailsPopUp("")}
          closeHandler={() => setDetailsPopUp("")}
          acceptDetails={acceptDetails}
          // tourDate="Monday, Nov 1st 5:30PM"
          // tourId="BKS29018"
          {...props}
        />
      )}
      {dropdownValue === "Date" && detailsPopUp === "dateFilter" && (
        <Modal
          type={"datefilter"}
          successHandler={applyFilter}
          closeHandler={() => {
            setDropdownValue("");
            setDetailsPopUp("");
            setFromValueErr("");
            setToValueErr("");
            setToValue("");
            setFromValue("");
          }}
          setFromValue={val => {
            setFromValueErr("");
            setFromValue(val);
          }}
          setToValue={val => {
            setToValueErr("");
            setToValue(val);
          }}
          tourType="scheduled"
          fromValue={fromValue}
          toValue={toValue}
          fromValueErr={fromValueErr}
          toValueErr={toValueErr}
          {...props}
        />
      )}
      {detailsPopUp === "changeDate" && (
        <Modal
          type={"changeDate"}
          successHandler={changeDate}
          closeHandler={() => {
            setDetailsPopUp("");
            setTimeValueErr("");
            setDateValueErr("");
            setDateValue("");
            setTimeValue("");
          }}
          setDateValue={val => {
            setDateValueErr("");
            setDateValue(val);
          }}
          dateValueErr={dateValueErr}
          setTimeValue={val => setTimeValue(val)}
          timeValueErr={timeValueErr}
          {...props}
        />
      )}
      {detailsPopUp === "changeTour" && (
        <Modal
          type={"changeTour"}
          successHandler={() => {
            let data = {};
            data.id = changeDateId;
            data.idValue = changeDateId;
            data.old_date = oldDate;
            data.old_time = oldTime;
            data.time = timeValue;
            data.date = dateValue;
            changeDateApi({
              data: data,
              cb: err => {
                if (!err) {
                  setDetailsPopUp("");
                  getScheduledTourByFilter({data: searchValue})
                } else {
                  responseToast(err);
                }
              }
            });
          }}
          closeHandler={() => {
            setDateValue("");
            setTimeValue("");
            setDetailsPopUp("");
          }}
          parentName={parentName}
          oldTourDate={moment(`${oldDate} ${oldTime}`).format(
            "dddd, MMM Do hh:mmA"
          )}
          oldTourId={tourId}
          newTourDate={`${moment(dateValue).format(
            "dddd, MMM Do"
          )} ${timeValue}`}
          newTourId={tourId}
          {...props}
        />
      )}
      {detailsPopUp === "decline" && (
        <Modal
          type={"decline"}
          successHandler={declineRequest}
          closeHandler={() => {
            setDetailsPopUp("");
            setDeclineValue("");
            setDeclineValueErr("");
          }}
          onChange={e => {
            setDeclineValueErr("");
            setDeclineValue(e.target.value);
          }}
          declineValueErr={declineValueErr}
          {...props}
        />
      )}
    </>
  );
}

Dashboard.propTypes = {};

export default createForm()(Dashboard);
