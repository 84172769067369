


export const API_LOGIN = "users/provider-login/";
export const API_LOGOUT = "users/provider-logout/";
export const API_MY_PROFILE = "users/provider-check-login/";

export const API_REVIEWS = "reviews/";

export const API_RESET_PSWD = "users/provider-change-password/";
export const API_PROVIDERS = "providers/";
export const API_MEDIA = "media/";


export const API_PARENTS = "parents/";

export const API_FORGOT_PSWD = 'users/provider-reset-password/'


