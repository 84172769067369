/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  CardBody,
  Col,
  Row,
  Button,
  Form,
  CardFooter,
  CardTitle,
  CardText
} from "reactstrap";
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { createForm } from "rc-form";
import remove from "lodash/remove";

import FormInput from "../../../components/FormInput";
import TeacherForm from "./TeacherForm";
import StaffForm from "./StaffForm";
import responseToast from "../../../utils/responseToast";
import FileUploader from "../../../components/Uploader";
import RadioButton from "../../../components/RadioButton";


let cancel = false;

function TeachersStaffs(props) {
  const [facultydata, setFacultyData] = useState([]);
  const [teacherModal, setTeacherModal] = useState(true);
  const [staffModal, setStaffModal] = useState(true);
  const [photoId, setPhotoId] = useState(null);
  const [teacherArrdata, setTeacherArrData] = useState([]);
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [staffArrdata, setStaffArrData] = useState([]);
  const [providerData, setProviderData] = useState({});
  const [nameData, setName] = useState("");
  const [teachersUpdate, setTeachersUpdate] = useState([]);
  const [staffUpdate, setStaffUpdate] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [designation, setDesignation] = useState('manager');
  const [photoUrl, setPhotoUrl] = useState('')

  const onSubmitTeacher = values => {
    setTeachersUpdate(values);
  };

  const onSubmitStaff = values => {
    setStaffUpdate(values);
  };

  const handleSubmit = () => {
    setFinalSubmit(true);
    if (!disabled) {
      props.form.validateFields(async (errors, values) => {
        if (!errors) {
          setFinalSubmit(false);
          let teachersUpdateDummy = [];
          let staffUpdateDummy = [];

          if (teachersUpdate.length > 0) {
            teachersUpdate.map(a => {
              if (
                a.id &&
                !a.name &&
                !a.photo_id &&
                !a.experience &&
                !(a.languages_spoken && a.languages_spoken.length > 0) &&
                !a.words_from_teacher
              ) {
                props.deleteTeacherApi(a);
                remove(a, (v, i) => teachersUpdate[i]);
                // delete a;
              } else if (
                !a.id &&
                !a.name &&
                !a.photo_id &&
                !a.experience &&
                !(a.languages_spoken && a.languages_spoken.length > 0) &&
                !a.words_from_teacher
              ) {
                remove(a, (v, i) => teachersUpdate[i]);
                // delete a;
              } else {
                teachersUpdateDummy.push(a);
              }
            });
          }
          if (staffUpdate.length > 0) {
            staffUpdate.map(a => {
              if (
                a.id &&
                !a.name &&
                !a.photo_id &&
                !a.experience &&
                !(a.languages_spoken && a.languages_spoken.length > 0) &&
                !a.additional_comments
              ) {
                props.deleteTeacherApi(a);
                remove(a, (v, i) => teachersUpdate[i]);
              } else if (
                !a.id &&
                !a.name &&
                !a.photo_id &&
                !a.experience &&
                !(a.languages_spoken && a.languages_spoken.length > 0) &&
                !a.additional_comments
              ) {
                remove(a, (v, i) => teachersUpdate[i]);
              } else {
                staffUpdateDummy.push(a);
              }
            });
          }
          let teacherErr = teachersUpdateDummy.filter(a => !a.name);
          let staffErr = staffUpdateDummy.filter(a => !a.name);
          if (teacherErr.length > 0 || staffErr.length > 0) {
            setFinalSubmit(true);
            return;
          }
          var facultydataDummy = [...teachersUpdateDummy, ...staffUpdateDummy];

          if (props.data.length === 0) {
            facultydataDummy.map((a, i) => {
              if (a) {
                if (!a.photo_id && a.photo_id === "" && a.photo_deleted) {
                  delete a.photo_id;
                } else if (a.photo_id) {
                  a.photo_deleted = false;
                } else if (!a.photo_id && !a.photo_deleted) {
                  delete a.photo_id;
                }
              }
            });
          }
          let provides = { ...providerData };
          if (
            provides.director_photo_id === null ||
            !provides.director_photo_id
          ) {
            delete provides.director_photo_id;
          }
          if (
            provides.director_photo_ids &&
            provides.director_photo_ids !== ""
          ) {
            provides.director_photo_id = photoId;
            provides.photo_deleted = false;
          } else if (cancel) {
            delete provides.director_photo_id;
            provides.photo_deleted = true;
          }
          if (props.data.length > 0) {
            facultydataDummy.map((a, i) => {
              if (a) {
                if (!a.photo_id && a.photo_id === "" && a.photo_deleted) {
                  delete a.photo_id;
                } else if (a.photo_id) {
                  a.photo_deleted = false;
                } else if (!a.photo_id && !a.photo_deleted) {
                  delete a.photo_id;
                }
              }
            });
          }

          let faculyFilter = facultydataDummy.filter(a => a && a.err);
          facultydataDummy.map(d => {
            if (d.name && !d.languages_spoken) {
              d.languages_spoken = [];
            }
          });
          if (faculyFilter.length > 0) {
            setFinalSubmit(true);
            return;
          }
          provides.director_designation = designation === 'manager' ? 'central_head' : 'director'
          provides.director_name = providerData['director name'];
          provides.words_from_director = values.words_from_director;
          provides.about_school = values.about_school;

          setDisabled(true);
          provides.id = Array.isArray(props.formData) && props.formData.length > 0 ? props.formData[0].id : props.formData.id;
          props.setContentChanged(false)
          console.log(provides)
          if (props.data.length > 0) {
            props.formdataUpdate({
              data: provides,
              cb: err => {
                if (!err) {
                  props.updateFaculty({
                    data: {
                      faculty: facultydataDummy
                    },
                    cb: err => {
                      if (!err) {
                        cancel = false;
                        props.setComponent("Photos/Videos");
                      } else {
                        responseToast(err);
                      }
                    }
                  });
                } else {
                  responseToast(err);
                }
              }
            });
          } else
            props.formdataUpdate({
              data: provides,
              cb: err => {
                if (!err) {
                  props.facultySubmit({
                    data: facultydataDummy,
                    cb: err => {
                      if (!err) {
                        cancel = false;
                        props.setComponent("Photos/Videos");
                      } else {
                        responseToast(err);
                      }
                    }
                  });
                } else {
                  responseToast(err);
                }
              }
            });
      
          }
      });
    }
  };

  useEffect(() => {
    setFacultyData([]);
    setTeacherArrData([]);
    setStaffArrData([]);
    if (props.data.length > 0) {
      setFacultyData(props.data);
      var teacherArrs = props.data.filter(a => a.faculty_type === "teacher");
      setTeacherArrData(teacherArrs);
      setTeachersUpdate(teacherArrs);
      var staffArr = props.data.filter(a => a.faculty_type === "staff");
      setStaffArrData(staffArr);
      setStaffUpdate(staffArr);
    }
  }, [props.data]);



  useEffect(() => {
    if (designation && props.contactData && props.contactData.length > 0) {
      let filters = props.contactData.filter(a => a.contact_type === designation)
      setName((filters.length > 0 && filters[0].name) || (props.contactData[0] && props.contactData[0].name));
      // console.log((filters.length > 0 && filters[0].name), (props.contactData[0] && props.contactData[0].name))
      props.form.setFieldsValue({ 'director name': (filters.length > 0 && filters[0].name) || (props.contactData[0] && props.contactData[0].name) });
      uploadDirector(0, "director_name", (filters.length > 0 && filters[0].name) || (props.contactData[0] && props.contactData[0].name))
      props.setContentChanged(false)

    }
  }, [props.contactData, designation]);

  // useEffect(() => {
  //   if (props.formData) {
  //     if (Array.isArray(props.formData) && props.formData.length > 0) {
  //       setPhotoId(props.formData[0].director_photo_id);
  //       props.form.setFieldsValue({ 'director_photo_ids': props.formData[0].director_photo_url || '' })
  //     } else {
  //       setPhotoId(props.formData.director_photo_id);
  //       props.form.setFieldsValue({ 'director_photo_ids': props.formData.director_photo_url || '' })
  //     }
  //   }
  // }, [props.formData]);

  useEffect(() => {
    if (props.contactData) {
      let filters = props.contactData.filter(a => a.contact_type === designation)
      filters[0] ? props.form.setFieldsValue({ 'director_photo_ids': filters[0].photo_url }) : props.form.setFieldsValue({ 'director_photo_ids': photoUrl })
      setPhotoId(filters[0] && (filters[0].photo_id || ''))
    }
  }, [props.contactData, designation])

  const uploadDirector = (index, obj, val) => {
    setFinalSubmit(false);
    // props.form.validateFields(async (errors, values) => {
      // if (!errors) {
        props.setContentChanged(true)
        let data ={...providerData}
        data[obj] = val;
        setProviderData({ ...providerData, ...data });
      // } else  {
      //   console.log(errors)
      // }
    // });
  };

  const image_url = () => {
    if (props.formData) {
      if (props.contactData) {
        let filters = props.contactData.filter(a => a.contact_type === designation)
        if (filters[0] && filters[0].photo_url)
          return filters[0] && filters[0].photo_url;
        // else if (props.formData.director_photo_url)
        //   return props.formData.director_photo_url;
        else 
          return ''
      }
    }
  };


  useEffect(() => {
    if (props.formData) {
      if (Array.isArray(props.formData) && props.formData.length > 0) {
        setProviderData(props.formData[0]);
        props.form.setFieldsValue(props.formData[0]);
        setPhotoId(props.formData[0].director_photo_id);
        setDesignation(props.formData[0].director_designation === 'central_head' ? 'manager' : 'director')
      } else {
        setProviderData(props.formData);

        props.form.setFieldsValue(props.formData);
        setPhotoId(props.formData.director_photo_id);
        setDesignation(props.formData.director_designation === 'central_head' ? 'manager' : 'director')

      }
    }
  }, [props.formData]);



  return (
    <>
      <CardBody>
        <div className="cardHeader">
          {props.contentWriting && (
            <CardText className="cardProfileText">
              <span className="teacherSubtitle">
                Kiddenz team will keep in touch with you regarding content
                writing
              </span>
            </CardText>
          )}
          <CardTitle className="cardTitle">Teachers and staff </CardTitle>
          <CardText className="cardProfileText">
            Almost there, Provide details about teachers and staff, so parents
            know that their kids are in good hands.
          </CardText>
        </div>

        <Form className="form-horizontal">
          <CardBody className="formContent" style={{ padding: "1.5rem 0" }}>

            {props.contactData && props.contactData.length > 0 && <Row style={{ paddingLeft: 15, marginBottom: 20 }}>
              <Col xs="4" style={{ paddingLeft: 0 }}>
                <div className="formInputName" >Words from</div>
                <div> (Please tell parents why they should send their kids to your school)</div></Col>
              <Col>
                <Row style={{ marginTop: 10 }}>
                  <RadioButton
                    name="Central Head"
                    label="Central Head"
                    xsval="4"
                    form={props.form}
                    checked={designation === "manager"}
                    onClick={() => {
                      setDesignation("manager")
                      props.setContentChanged(true)
                    }}
                    className="marginRight-24"
                    isDesignation={designation === "manager"}
                  />

                  <RadioButton
                    name="director"
                    label="Director"
                    xsval="4"
                    form={props.form}
                    checked={designation === "director"}
                    className="marginRight-24"
                    onClick={() => {
                      setDesignation("director");
                      props.setContentChanged(true)

                    }}
                  />

                </Row>
              </Col>
            </Row>}

            <Row className="extended">
              <Col xs="4" style={{ paddingTop: 10 }}>
              </Col>
              <Col xs="8" style={{ padding: 0 }}>
                <Row style={{ marginLeft: '7px' }}>
                  <FileUploader
                    width={1}
                    height={1}
                    name="director_photo_ids"
                    form={props.form}
                    label=""
                    type="avatar"
                    xsval="4"
                    teacherUpload
                    disabled={image_url() !== (props.formData && props.formData.director_photo_url)}
                    photoUrl={v => setPhotoUrl(v)}
                    uploadUrl={id => {
                      uploadDirector(0, 'director_photo_id', id);
                      setPhotoId(id);
                    }}
                    imageSize={200}
                    setCancelImg={id => {
                      cancel = true;
                      uploadDirector(0, 'director_photo_id', null);
                      setPhotoId("");
                      setPhotoUrl('')
                    }}
                    urlImage={image_url()}
                    isRequired={false}
                  // teacherstaff={true}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="director name"
                placeholder="Name of the director"
                label="Name"
                onChange={val => uploadDirector(0, "director_name", val)}
                isRequired={false}
                colSpan="12"
                eachSpan
                alphanumeric={true}
                isName
                defaultValue={(designation && props.contactData && props.contactData.length === 3 ? props.contactData.filter(a => a.contact_type === designation)[0].name : props.contactData[0].name)}
                disabled={designation ? true : false}
                fullLength="8"
              />
            </Row>
            <Row>
              <FormInput
                form={props.form}
                name="words_from_director"
                placeholder="Maximum 5000 characters."
                label={`Words from ${designation === 'manager' ? 'Central Head' : 'Director'}`}
                isRequired={false}
                TooltipHover="Please tell parents why they should send their kids to your school, you may speak about your passion, mission, vision, location of facility. Try to connect with parents on a personal level"
                minWidth="320px"
                colSpan="12"
                type="textarea"
                maxLength={5000}
                eachSpan
                fullLength="8"
                maxHeight="6"
                onChange={val => uploadDirector(0, "words_from_director", val)}
              />
            </Row>

            <Row>
              <FormInput
                form={props.form}
                name="about_school"
                placeholder="About Programme"
                label="About Programme"
                colSpan="12"
                onChange={val => uploadDirector(0, "about_school", val)}
                type="textarea"
                eachSpan
                maxLength={5000}
                fullLength="8"
                maxHeight="6"
                isRequired={props.contentWriting ? false : true}
              />
            </Row>
          </CardBody>
        </Form>
      </CardBody>
      <hr />
      {
        teacherModal && (
          <TeacherForm
            deleteTeacherApi={props.deleteTeacherApi}
            staffSupport={props.staffSupport}
            languages={props.languages}
            onSubmitTeacher={onSubmitTeacher}
            setTeacherModal={setTeacherModal}
            data={teacherArrdata}
            contentWriting={props.contentWriting}
            setContentChanged={props.setContentChanged}
          />
        )
      }

      <hr />
      {
        staffModal && (
          <StaffForm
            deleteTeacherApi={props.deleteTeacherApi}
            staffSupport={props.staffSupport}
            languages={props.languages}
            onSubmitStaff={onSubmitStaff}
            setStaffModal={setStaffModal}
            data={staffArrdata}
            contentWriting={props.contentWriting}
            setContentChanged={props.setContentChanged}

          />
        )
      }
      <CardFooter>
        <Row className="formFlex" style={{marginBottom:0}}>
          <Col xs="4"></Col>
          <Col xs="8">
            {finalSubmit && (
              <div style={{ color: "red", fontSize: 11, fontWeight: 300 }}>
                Some fields are not filled
              </div>
            )}
            <Button onClick={() => props.updateBeforeComponent("Facilities")}  color="primary">
              Back
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              disabled={disabled}
            >
              {nameData ? "Update" : "Save"} and Continue
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </>
  );
}

TeachersStaffs.propTypes = {};

export default createForm()(TeachersStaffs);
