import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "./colors";
export const Subheading = styled.div`
  color: ${colors.lightBlack};
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 23px;
  margin: 0 0 20px;
`;

const SubHeading = ({ text }) => <Subheading>{text}</Subheading>;
SubHeading.propTypes = {
  text: PropTypes.string
};
export default SubHeading;
