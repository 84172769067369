import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'
import { Col, FormGroup } from "reactstrap";

const Time = ({ placeholder, filter, colSpan, maxDate, isWeekday, minStartDate, disabled, notYearPicker, form, submit, onChange, isRequired = false, ...rest }) => {
    const [value, setValue] = useState('')
    const [err, setErr] = useState('')

    const error = form.getFieldError(rest.name);
    const rules = [{ required: isRequired, message: "Select Time" }];

    useEffect(() => {
        if (rest.defaultValue) {
            var a = rest.defaultValue.split('-')
            setValue(new Date(a[0], a[1] - 1, a[2]))
        } else {
            setValue('')
        }
    }, [rest.defaultValue]);

    const isWeekdayFn = date => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <Col xs={colSpan} style={{ marginRight: rest.marginRight, marginLeft: rest.marginLeft }}>
            <FormGroup style={{ padding: "0px 15px" }}>
                <DatePicker
                    onChange={(e) => {
                        if (!notYearPicker) {
                            if (rest.minDate) {
                                onChange(moment(moment(e)._d).format('YYYY-MM-DD'))
                                setValue(e)
                                setErr('')
                            } else {
                                setErr('select year first')
                            }
                        } else {
                            onChange(moment(moment(e)._d).format('YYYY-MM-DD'))
                            setValue(e)
                        }
                    }}
                    disabled={disabled}
                    className={((submit && isRequired && !value) || err) ? 'border-datepicker' : disabled && 'disabledDatePicker'}
                    placeholderText="Select Date"
                    selected={value}
                    dateFormat={'dd/MM/yyyy'}
                    minDate={(rest.minDate && (filter ? new Date(`${rest.minDate}`) : new Date(`${(rest.minDate).toString().split('-')[0]}-01-01`))) || rest.currentDate}
                    openToDate={minStartDate && new Date(minStartDate.split('-').join('/'))}

                    maxDate={maxDate || (rest.minDate && new Date(`${(rest.minDate).toString().split('-')[1]}-12-31`))}
                    filterDate={isWeekday && isWeekdayFn}
                    startDate={isWeekday && new Date()}
                // minDate={rest.minDate && new Date(`${rest.minDate}-01-01`)}
                // maxDate={rest.minDate && new Date(`${rest.minDate}-12-31`)}

                />
                {submit && isRequired && !value && <div style={{ color: 'red', fontSize: 11, fontWeight: 300 }}>Date is required</div>}
                {err && <div style={{ color: 'red', fontSize: 11, fontWeight: 300 }}>{err}</div>}

            </FormGroup>
        </Col>
    )
}



export default Time;