import React from "react";
import {
    CardFooter,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import facebook from '../../assets/img/icons/common/facebook.svg'
import instagram from '../../assets/img/icons/common/instagram.svg'

class Footer extends React.Component {
    render() {
        return (
            <CardFooter className="cardFooter">
                <Nav className="footerList formCheckbox">
                    <NavItem className="footerItems">
                        <NavLink href="#" active>Shortcuts</NavLink>
                        <NavLink href="#" active>Home</NavLink>
                        <NavLink href="#" active>Daycare Search</NavLink>
                        <NavLink href="#" active>Parenting Articles</NavLink>
                        <NavLink href="#" active>Parenting community</NavLink>
                    </NavItem>
                    <NavItem className="footerItems">
                        <NavLink href="#" active>Kiddenz Locations</NavLink>
                        <NavLink href="#" active>Malleshwaram, Blr</NavLink>
                        <NavLink href="#" active>RT Nagar, Blr</NavLink>
                        <NavLink href="#" active>KR Puram, Blr</NavLink>
                        <NavLink href="#" active>Cunningham Road, Blr</NavLink>
                    </NavItem>
                    <NavItem className="footerItems">
                        <NavLink href="#" active>Company</NavLink>
                        <NavLink href="#" active>About us</NavLink>
                        <NavLink href="#" active>Careers</NavLink>
                        <NavLink href="#" active>FAQ</NavLink>
                        <NavLink href="#" active>Feedback</NavLink>
                    </NavItem>
                    <NavItem className="footerItems">
                        <NavLink href="#" active>For Providers</NavLink>
                        <NavLink href="#" active>Register</NavLink>
                        <NavLink href="#" active>Advertise on kiddenz</NavLink>
                        <NavLink href="#" active>FAQ</NavLink>
                        <NavLink href="#" active>Contact Us</NavLink>
                    </NavItem>
                    <NavItem className="footerItems">
                        <NavLink href="#" active>Contact Us</NavLink>
                        <NavLink href="#" active>Ph No.: +91 8088340733</NavLink>
                        {/* <NavLink href="#" active>Address to be given here</NavLink> */}
                        <NavLink href="#" active>sales@kiddenz.com</NavLink>
                        <NavLink href="#" active><img
                            alt="..."
                            src={facebook}
                            style={{ width: 34, height: 34 }}
                        />
                            <img
                                alt="..."
                                src={instagram}
                                style={{ width: 26, height: 26 }}
                            /></NavLink>
                    </NavItem>
                </Nav>
                <Nav className="footerLink">
                    <NavItem className="footerItem">
                        <NavLink href="#" active>Terms & Conditions</NavLink>
                        <NavLink href="#" active>Privacy Policy</NavLink>
                        <NavLink href="#" active>©2019 Kiddense· All rights reserved.</NavLink>
                    </NavItem>
                </Nav>
            </CardFooter>
        );
    };
};

export default Footer;