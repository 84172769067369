import { css } from "styled-components";
// eslint-disable-next-line import/no-named-as-default-member
import colors from "./colors";

const buttonStyles = css`
// height: 56px;
height: ${props => props.height};
width: ${props => (props.fullwidth ? "100%" : "auto")};
// min-width: 166px;
// display: inline-block;
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
padding: ${props => (props.headerButton ? "29px" : "56px")};
padding-top: 0px;
padding-bottom: 0px;
margin-top: ${props => props.marginTop};
margin-right: ${props => props.marginRight};
text-decoration: none;
border-radius: 5px;
-webkit-font-smoothing: antialiased;
-webkit-touch-callout: none;
user-select: none;
cursor: pointer;
outline: 0;
font-family: 'Quicksand', sans-serif;
font-weight: 500;
font-size: 16px;
line-height: 20px;
text-align: center;
// box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
border: 2px solid ${colors.primary};
background-color: ${colors.primary};
color:  ${colors.white};
white-space: nowrap;
&:disabled{
  cursor: default;
  background: ${colors.lightGrey};
  border-color:${colors.lightGrey};
}
.icon{
  display: none;
}
&.secondary {
  background-color: ${colors.secondary};
  border-color: ${colors.secondary};
}
&.filter{
  height: 40px;
  // width: fit-content;
  padding: 22px;
  font-size: 13px;
  line-height: 18px;
}
&.outline {
  border-width: 1px;
  background-color: ${colors.white};
  border-color: ${colors.secondary};
  color: ${colors.secondary};
}
&.btnWhite {
  color: ${colors.secondary};
  background-color: ${colors.white};
  border-color: ${colors.white};
  &.footer{
    color: ${colors.darkBlue};
  }
}
&.links {
  height: auto;
  padding: 0px;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: ${colors.black};
}
&.attach
{
  height: auto;
  font-size:14px;
  padding: 0px;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color:#3B74E7;
}
&.dropdown{
  height: 36px;
  width: fit-content;
  padding: 10px;
  border: 1px solid #C0C8CD;
  background-color: transparent;
  color: ${colors.inputPlaceholder};
  font-size: 14px;	line-height: 16px;
  box-shadow: none;
  .icon{
    display: inline-block;
    margin-left: 5px;
  }
}
&.profile
{
  height: auto;
  padding:12px 20px;
  background-color: #613A95;
  border-color: #613A95;
}
&.viewAll
{
  height: auto;
  padding:0px 10px;
  font-size: 12px;
  color:#3B74E7;
  border-radius: 12.5px;
  background-color: #EBF1FC;
  border-color: #EBF1FC;
  box-shadow:none;
}
&.preCare
{
  border-color:${colors.white};
  color: ${colors.secondary};
   font-size: 20px;	
   font-weight: bold;	
   line-height: 25px;
  height: 70px;	
  width: 300px;	
  border-radius: 35px;	
  background-color:${colors.white};	
  box-shadow: 0 20px 40px 0 rgba(0,0,0,0.1);
  @media screen and (max-width: 767px)
  {
    font-size: 16px;	
    height: 60px;	
    width: 260px;	
  }
}
&.Contact
{
  background-color:transparent;
  border:1px solid #666C78;
  color: #666C78;	
  font-family: 'Quicksand', sans-serif;	
  font-size: 16px;
  font-weight: bold;	
  line-height: 20px;
  box-shadow:none;
  padding:0px 25px;
  &:hover
  {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  }
}
`;

// const headerButton = styled.buttonStyles`
//   padding: 29px;
// `;
export default buttonStyles;
