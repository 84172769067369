/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Col, Row } from "reactstrap";
import EnquiryQuestion from "../../../components/EnquiryQuestion";
import EmptyState from "../../../components/EmptyState";
import emptyImage from "../../../assets/img/Kiddenz_Enquiry.png";
import moment from "moment";
import { createForm } from "rc-form";
import axios from "../../../utils/axios";
import { getUploadURL } from "../../../components/Uploader";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.jsx";
import imgEnquiry from "../../../assets/img/user3.svg";
import Modal from "../../../components/CustomModal";
import useCommonCRUD from "../../../hooks/commonCRUD";
import { API_PARENTS } from "../../../config/apiEndpoints";
import responseToast from "../../../utils/responseToast";
import Loader from "../../../components/Loader";

import {
  DropdownMenu,
  DropdownMenuItem
} from "../../../components/CustomDropdown";

const DROPDOWN_CONFIG = [
  {
    id: "01",
    text: "Old to New",
    key: "ASC"
  },

  {
    id: "04",
    text: "New to Old",
    key: "DESC"
  }
];
let globalIndex;
function Dashboard(props) {
  const [detailsPopUp, setDetailsPopUp] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [responseValue, setResponseValue] = useState("");
  const [uploadDoc, setUploadDoc] = useState("");

  const [uploadDocName, setUploadDocName] = useState("");

  const [responseId, setResponseId] = useState("");
  const [fromValue, setFromValue] = useState("");
  const [fromValueErr, setFromValueErr] = useState("");
  const [toValueErr, setToValueErr] = useState("");
  const [toValue, setToValue] = useState("");
  const [responseErr, setResponseErr] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);

  const dropdownRef = useRef(null);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("Parent");
  const [groupedData, setGroupedData] = useState([]);

  const [getEnquiry, , , , getEnquiryFilter] = useCommonCRUD({
    CRUDaseUrl: {
      readId: `${API_PARENTS}parent-connect/?connect=${filter}${
        dropdownValue ? `&sort=${dropdownValue}` : ""
      }${
        fromValue && toValue ? `&from_date=${fromValue}&to_date=${toValue}` : ""
      }&search=`,
      read: `${API_PARENTS}parent-connect/?connect=${filter}${
        dropdownValue ? `&sort=${dropdownValue}` : ""
      }${
        fromValue && toValue ? `&from_date=${fromValue}&to_date=${toValue}` : ""
      }&search=`
    }
  });
  const [, , , updateData] = useCommonCRUD({
    CRUDaseUrl: {
      update: `${API_PARENTS}parent-connect-update/`
    }
  });
  useEffect(() => {
    let groups = {};
    for (var i = 0; i < data.length; i++) {
      var groupName = data[i].date;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(data[i]);
    }
    let myArray = [];
    for (let groupName in groups) {
      myArray.push({ date: groupName, data: groups[groupName] });
    }
    setGroupedData(myArray);
  }, [data]);

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const applyFilter = () => {
    console.log(fromValue, toValue);

    if (!fromValue || !toValue) {
      if (!fromValue) setFromValueErr(true);
      if (!toValue) setToValueErr(true);
      return;
    }
    getEnquiryFilter({
      data: searchValue,
      cb: () => {
        setFromValueErr("");
        setToValueErr("");
        setDetailsPopUp("");
        setFromValue("");
        setToValue("");
      }
    });
  };
  useEffect(() => {
    if (fromValue && toValue) {
      if (new Date(fromValue) > new Date(toValue)) {
        setToValue("");
      }
    }
  }, [fromValue, toValue]);
  const openTextArea = (index, date, textData, action) => {
    let dataCopy = [...groupedData];
    dataCopy.map((a, i) => {
      if (action === "Respond") {
        if (a.date === date) {
          a.data.map((d, i) => {
            if (i === index) {
              d.textarea = textData;
            } else {
              d.textarea = false;
            }
          });
        }
      } else if (action === "closeModal") {
        a.data.map((d, i) => {
          d.textarea = false;
        });
      } else {
        if (a.date === date) {
          a.data.map((d, i) => {
            if (i === index) {
              d.response = textData;
            } else {
              d.response = false;
            }
          });
        }
      }
    });
    console.log(dataCopy);
    setGroupedData(dataCopy);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      dropdownRef.current.contains(e.target)
    ) {
      return 0;
    }
    setOpenDropdown(false);
    return 0;
  };
  useEffect(() => {
    if (getEnquiry && getEnquiry.data && getEnquiry.data.length > 0) {
      let enquiryData = [];
      getEnquiry.data.map(d =>
        enquiryData.push({
          id: d.id,
          name: d.parent_name,
          time: moment(`${d.created_on}`).format("hh:mmA"),
          question:
            filter === "Parent"
              ? "Requested for Connect with parent"
              : filter === "Price"
              ? "Requested for price"
              : "Requested you to Connect with counsellor",
          date: moment(`${d.created_on}`).format("MMM Do, YYYY"),
          disable: false,
          btnText: d.admin_responded ? "View Response" : "Respond",
          imgUrl: d.parent_image,
          textarea: false,
          response: false,
          responseData: d.respond_message ||"Admin has responded to your request"
        })
      );
      setData(enquiryData);
    } else setData([]);
  }, [getEnquiry]);

  useEffect(() => {
    getEnquiryFilter({ data: searchValue });
  }, [filter, dropdownValue, searchValue]);

  const uploadImage = (e, id) => {
    setUploadDocName("Uploading....");
    const fd = new FormData();
    console.log(e.target.files[0]);
    fd.append("media", e.target.files[0]);
    const url = getUploadURL("banner");
    axios
      .post(
        e.target.files[0].name.includes(".pdf") ||
          e.target.files[0].name.includes(".zip")
          ? "https://kiddenzbc.cartoonmango.com/api/media/upload-misc-file/"
          : "https://kiddenzbc.cartoonmango.com/api/media/upload-file/",
        fd
      )
      .then(res => res.data.data || res.data)
      .then(file => {
        if (file.image_name) {
          let format = ["zip", "png", "jpeg", "pdf", "jpg"];
          let final = format.filter(a => file.image_name.includes(a));
          if (final.length > 0) {
            setUploadDocName(
              `${file.image_name.split(final[0])[0]}.${final[0]}`
            );
          } else setUploadDocName(file.image_name);
        } else
          setUploadDocName(
            `${file.image_url.split("/")[file.image_url.split("/").length - 1]}`
          );
        setUploadDoc(file.id);
      })
      .catch(() => {
        responseToast("Something went wrong");
        setUploadDocName("");
      });
  };

  const sendResponse = id => {
    let data = {};
    data.id = id;
    data.respond_message = responseValue;
    data.admin_responded = true;
    if (uploadDoc) data.attachment_id = uploadDoc;

    updateData({
      data,
      cb: err => {
        if (!err) {
          setDetailsPopUp("success");
        } else {
          responseToast(err);
        }
      }
    });
  };
  return (
    <>
      <div className="header pb-6 ">
        <AdminNavbar
          {...props}
          brandText="Kiddenz"
          search
          searchValue={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <Loader isOpen={getEnquiry.isLoading} />
      {!getEnquiry.isLoading && (
      <div className="dashboardContainer">
        <>
          <div className="enquiryList">
            <div className="mainHeading">
              <Col>
                <h3>Enquiry</h3>
                <span>
                  Any parent Enquiring information related to school - connect
                  to parent, connect to counsellor, price requests
                </span>
              </Col>
            </div>

            <Row className="spaceBetweenAlignCenter">
              <ul className="filterBy">
                <span>Filters by: </span>
                {/* <li
                  onClick={() => {
                    setDateFilter(true);
                    setDetailsPopUp("dateFilter");
                  }}
                  className={dateFilter ? "active" : ""}
                >
                  Date
                </li> */}
                <li
                  onClick={() => {
                    setDateFilter(false);
                    setFilter("Parent");
                  }}
                  className={filter === "Parent" ? "active" : ""}
                >
                  Connect with Parent
                </li>
                <li
                  onClick={() => {
                    setDateFilter(false);
                    setFilter("Counselor");
                  }}
                  className={filter === "Counselor" ? "active" : ""}
                >
                  Connect with Counsellor
                </li>
                <li
                  onClick={() => {
                    setDateFilter(false);
                    setFilter("Price");
                  }}
                  className={filter === "Price" ? "active" : ""}
                >
                  Cost Request
                </li>
              </ul>
              <ul className="filterBy" ref={dropdownRef}>
                <li onClick={() => setOpenDropdown(!openDropdown)}>
                  {" "}
                  Sort By{" "}
                  <i
                    className={
                      openDropdown ? "fa fa-chevron-up" : "fa fa-chevron-down"
                    }
                    style={{ marginLeft: 10 }}
                  />
                </li>
                {openDropdown && (
                  <DropdownMenu top="290px" left="78%" width="20%">
                    {DROPDOWN_CONFIG.map(items => (
                      <DropdownMenuItem
                        key={items.id}
                        className={dropdownValue === items.key ? "active" : ""}
                        onClick={e => {
                          setDropdownValue(items.key);
                          setOpenDropdown(false);
                        }}
                      >
                        {items.text}

                        <span
                          className="iconify"
                          data-icon="mdi-light:check"
                          data-inline="false"
                        />
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenu>
                )}
              </ul>
            </Row>
          </div>
          {groupedData.length > 0 ? (
            <div className="enquiryReport" style={{ marginTop: 25 }}>
              {groupedData.map(a => (
                <>
                  <h3>{a.date}</h3>
                  <div style={{ marginBottom: 30 }}>
                    {a.data.map((d, i) => (
                      <EnquiryQuestion
                        // bgColor={getRandomColor()}
                        classType={d.disable}
                        imgpath={d.imgUrl}
                        imgText={!d.imgUrl && d.name.match(/\b(\w)/g).join("")}
                        name={d.name}
                        time={d.time}
                        textarea={d.textarea}
                        onChange={e => {
                          setResponseValue(e.target.value);
                          setResponseErr(false);
                          setResponseId(d.id);
                        }}
                        responseErr={responseErr}
                        setResponseErr={setResponseErr}
                        responseValue={responseValue}
                        question={d.question}
                        btnText2={d.response ? "Close" : d.btnText}
                        // btnText="Enquire"
                        response={d.response}
                        responseData={d.responseData}
                        sendResponse={() => {
                          globalIndex = i;
                          sendResponse(d.id);
                        }}
                        cancelTextArea={() => {
                          openTextArea(i, a.date, false, d.btnText);
                          setResponseErr(false);
                          setResponseValue("");
                          setUploadDoc("");
                          setUploadDocName("");
                        }}
                        uploadImage={e => uploadImage(e, i)}
                        openTextArea={() => {
                          openTextArea(
                            i,
                            a.date,
                            "Enter details here",
                            d.btnText
                          );
                          setResponseValue("");
                        }}
                        uploadDocName={uploadDocName}
                      />
                    ))}
                  </div>
                </>
              ))}
              {/* <h3>Oct 29, 2020</h3>
                    <div style={{ marginBottom: 30 }}>
                        <EnquiryQuestion imgText="Pk" name="Priya Kumar. S" time="2:30PM" question="Requested for price for Euro Kids" btnText2="Respond" btnText="Enquire" />
                        <EnquiryQuestion imgpath={imgEnquiry} name="PNandiesh Gowda. HP" time="2:30PM" question="Requested for Connect with parent" btnText2="Respond" btnText="Enquire" />
                        <EnquiryQuestion imgText="CT" name="Carolen Thomas" time="2:30PM" question="Requested you to Connect with counsellor" btnText2="Respond" btnText="Enquire" />
                        <EnquiryQuestion classType="disable" imgText="Pk" name="Priya Kumar. S" time="2:30PM" question="Requested for price for Euro Kids" btnText2="Respond" btnText="Enquire" />
                    </div>

                    <h3>Oct 30, 2020</h3>
                    <div>
                        <EnquiryQuestion classType="disable" imgText="CT" name="Carolen Thomas" time="2:30PM" question="Requested you to Connect with counsellor" btnText2="View Response" btnText="Enquire" />
                        <EnquiryQuestion imgpath={imgEnquiry} name="Priya Kumar. S" time="2:30PM" question="Requested for price for Euro Kids" btnText2="Respond" btnText="Enquire" />
                        <EnquiryQuestion imgText="Pk" name="Priya Kumar. S" time="2:30PM" question="Requested for price for Euro Kids" btnText2="Respond" btnText="Enquire" />
                        <EnquiryQuestion imgText="Pk" name="Priya Kumar. S" time="2:30PM" question="Requested for price for Euro Kids" btnText2="Respond" btnText="Enquire" textarea="Enter details here" />
                    </div> */}
            </div>
          ) : (
            <EmptyState
              text="No Enquiries yet"
              desc="Looks like you dont have any enquiries yet. Keep a check on this space"
              emptyImage={emptyImage}
            />
          )}
        </>

        {detailsPopUp === "success" && (
          <Modal
            type={"success"}
            successHeading={"Details have been shared with parent"}
            successSubHeading={
              "Admin will also be notified with the details shared"
            }
            successHandler={() => {
              setFilter(filter);
              setDetailsPopUp("");
              getEnquiryFilter({ data: searchValue });
              setResponseErr(false);
              setResponseValue("");
              setUploadDoc("");
              setUploadDocName("");
              openTextArea(null, null, null, "closeModal");
            }}
            closeHandler={() => {
              setFilter(filter);

              setDetailsPopUp("");
              getEnquiryFilter({ data: searchValue });
              setResponseErr(false);
              setResponseValue("");
              setUploadDoc("");
              setUploadDocName("");
              openTextArea(null, null, null, "closeModal");
            }}
            noteContent
            // authActionSelected={authActionSelected}
            {...props}
          />
        )}
        {detailsPopUp === "dateFilter" && (
          <Modal
            type={"datefilter"}
            successHandler={applyFilter}
            closeHandler={() => {
              setDropdownValue("");
              setDetailsPopUp("");
              setFromValueErr("");
              setToValueErr("");
              setToValue("");
              setFromValue("");
              setDateFilter(false);
            }}
            setFromValue={val => {
              setFromValueErr("");
              setFromValue(val);
            }}
            setToValue={val => {
              setToValueErr("");
              setToValue(val);
            }}
            tourType="scheduled"
            fromValue={fromValue}
            toValue={toValue}
            fromValueErr={fromValueErr}
            toValueErr={toValueErr}
            {...props}
          />
        )}
      </div>
      )}</>
  );
}

Dashboard.propTypes = {};

export default createForm()(Dashboard);
